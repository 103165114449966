
import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link';

import config from '../utils/envConfig';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// inferring what this value was put here for, assuming it delays query to ensure subscription is established
export const TIME_FOR_SUBSCRIPTION_CONNECTION = 1000;
export const MAX_APPSYNC_TIMEOUT = 900000;

const getClient = cb => {
  const auth = { type: AUTH_TYPE.AWS_LAMBDA, token: () => cb.core.iam.getAuthorizationToken() };
  const region = config.appsync_region;
  const url = config.appsync_graphqlEndpoint;

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'X-CB-Catapult-Authentication-Token': cb.core.iam.getAuthenticationToken(),
        'X-CB-Catapult-Authorization-Token': cb.core.iam.getAuthorizationToken()
      }
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      authLink,
      createAuthLink({ url, region, auth }),
      createSubscriptionHandshakeLink({ url, region, auth }, createHttpLink({ uri: url }))
    ]),
    cache: new InMemoryCache()
  });

  return client;
};

export default getClient;

import React, {useContext} from 'react';
import './reportInfoModal.css';
import { Modal } from '@cb/apricot-react';
import { AppContext } from '../../AppContext';
import propTypes from 'prop-types';

const SReportInfoModal = (props) => {
  const appContext = useContext(AppContext);
  return (
    <Modal title={<b>Summary Reports</b>} trigger={props.trigger} withFooter={false}>
      <div>
        <p>School and district administrators have access to the following reports:</p>
        <div>
          <ul>
            <li>Current Year Score Summary</li>
            <li>Five Year Score Summary</li>
            <li>Summary by Student Demographics</li>
            <li>Equity and Excellence</li>
            <li>District Summary by School</li>
            <li>Scholar Summary</li>
          </ul>
        </div>
        <p>These reports are best viewed on a large screen such as a desktop computer. <a href={appContext?.content?.ap_central_links?.reportDescLink} target='_blank' rel='noopener noreferrer'><strong>View detailed descriptions of these reports.</strong></a></p>
      </div>
    </Modal>
  );
};

SReportInfoModal.propTypes = {
  trigger: propTypes.string
};

export default SReportInfoModal;

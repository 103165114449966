import React, { useContext, Fragment } from 'react';
import Login from './login';
import HomeSignedIn from './homeSignedIn';
import { AppContext } from '../../AppContext';

const Home = props => {
  const appContext = useContext(AppContext);
  return (
    appContext.isLoggedIn ? (
      <Fragment>
        <HomeSignedIn
          {...props}
        />
      </Fragment>
    ) : (
      <Login />
    )
  );
};

export default Home;

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cb/apricot-react';
import { GROUP_DISTRIBUTION_HEADER } from '../instructional-planning/utils';

const IconAndTitle = ({icon, title}) => {

  return (
    <div>
      <div className='row  cb-padding-left-32 cb-padding-right-32 cb-padding-16 align-items-center'>
        <Icon name={icon} className='cb-margin-right-4 cb-font-weight-bold' circular={true} color='blue4' />
        <h4 aria-hidden={title === GROUP_DISTRIBUTION_HEADER ? true : null} className='cb-gray1-color cb-font-weight-bold cb-font-size-small'>{title}</h4>
      </div>
    </div>
  );
};

IconAndTitle.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string
};

export default IconAndTitle;

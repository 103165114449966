import { INSTRUCTIONAL_PLANNING_REPORT_TITLE_DA } from '../../constants/reports';
import { getAuthIprAggregateData } from '../../graphql/queries';
import { subscribeToIprAggregateData } from '../../graphql/subscriptions';
import getDataAppSync from './getData';
import gql from 'graphql-tag';

const getIprDataAppSync = async ({
  sttVal,
  navYear,
  navSchool,
  navSubject,
  navSection,
  isDistrictAdmin,
  isAggregate,
  navForm,

  fltScore,
  fltGrade,
  fltTeacher,
  fltInstruction,
  fltStandardIndicator,

  selectedOrgId,
  year,
  roleCd
}) => {
  const cb = window.cb;

  const data = await getDataAppSync({
    cb,
    subscribeParams: {
      query: gql(subscribeToIprAggregateData),
      variables: {
        catapultId: cb.core.iam.getTempAWSCredsSubject(),
        orgId: parseInt(selectedOrgId),
        adminYear: parseInt(year),
        roleCd: parseInt(roleCd),
        reportName: INSTRUCTIONAL_PLANNING_REPORT_TITLE_DA
      }},
    queryParams: {
      query: gql(getAuthIprAggregateData),
      variables: {
        // required params
        sttVal,
        navYear,
        navSubject,
        // optional
        navSchool: isDistrictAdmin && !isAggregate ? navSchool : undefined,
        navForm,
        navSection: !isAggregate ? navSection : undefined,
        fltTeacher,
        fltScore,
        fltGrade,
        fltStandardIndicator,
        fltInstruction
      }
    }
  });

  if (data?.data?.subscribeToIprAggregateData?.url) {
    return {data: JSON.parse(data.data.subscribeToIprAggregateData.url)};
  }
  if (data?.data?.subscribeToIprAggregateData?.error === 'No Data returned.') {
    return {data: { noData: true}};
  }
  throw new Error('An error occurred.');

};

export default getIprDataAppSync;

import React from 'react';
import './spinner.css';
import { Spinner as ReactSpinner } from '@cb/apricot-react';
import PropTypes from 'prop-types';

const Spinner = (props) => {

  // default size to large
  const size = props.size ?? 'lg';

  // if alignment prop has been passed in and it's NOT center, do not use centerLoader class
  const alignmentClass = props.alignment === 'left' ? '' : 'centerLoader';

  // conditionally remove container class that adds padding to loading spinner
  const paddingClass = props.removePadding ? '' : 'cb-padding-48';

  return (
    <div className="container">
      <div className="row">
        <div className={paddingClass + ' display-flex justify-content-center ' + alignmentClass}>
          <ReactSpinner show ariaLabel='Loading…' className={`customSpinner-${size}`} />
        </div>
      </div>
    </div>
  );
};

Spinner.propTypes = {
  removePadding: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  alignment: PropTypes.oneOf(['center', 'left'])
};

export default Spinner;

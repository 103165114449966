import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { badgeImageMap } from '../../utils';
import { LARGE_TEXT_FONT_SIZE, H5_FONT_SIZE } from '../constants';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 5,
    paddingBottom: 5
  },
  tierFont: {
    fontSize: H5_FONT_SIZE,
    fontFamily: 'Roboto-Bold',
    paddingTop: 15,
    borderBottom: 2,
    borderBottomColor: '#009CDE'
  },
  subFont: {
    fontSize: LARGE_TEXT_FONT_SIZE,
    fontFamily: 'Roboto-Bold',
    paddingTop: 5,
    width: 145
  },
  badgeContainer: {
    height: 75,
    width: 85,
    justifyContent: 'center',
    alignItems: 'center'
  },
  badgeImage: {
    height: 70,
    width: 70
  },
  tierContainer: {
    flexDirection: 'row'
  },
  textContainer: {
    marginLeft: 8,
    marginRight: 4
  }
});

const HonorRollBadgeDisplayPdf = ({tier, school, year}) => {
  return (
    <View style={styles.container}>
      <View style={styles.badgeContainer}>
        <Image style={styles.badgeImage} src={badgeImageMap[tier]} />
      </View>
      <View style={styles.textContainer}>
        <View style={styles.tierContainer}>
          <Text style={styles.tierFont}>{tier.toUpperCase()}</Text>

          {/* This spacer ensures blue underline on above text doesn't take full width */}
          <View style={{flexGrow: 1}}></View>

        </View>
        <Text style={styles.subFont} className='cb-margin-top-8'>AP {year} School Honor Roll</Text>
        <Text style={styles.subFont} className='cb-margin-top-8'>{school}</Text>
      </View>
    </View>
  );
};

HonorRollBadgeDisplayPdf.propTypes = {
  tier: PropTypes.string,
  year: PropTypes.number,
  school: PropTypes.string
};

export default HonorRollBadgeDisplayPdf;

import React, { useState, useEffect, useContext } from 'react';
import { Input, Table, Column, SpacerV } from '@cb/apricot-react';
import PropTypes from 'prop-types';
import './regionalUserSearchPage.css';
import config from '../../utils/envConfig.js';
import Spinner from '../common/loader/spinner';
import ShowError from '../ap-osre-error-handling/ErrorBoundary';
import analytics from '../../utils/analytics';
import OrgSelection from '../ap-osre-org-selection/orgSelection';
import PageTitle from '../../components/common/PageTitle';
import { AppContext } from '../../AppContext';
import { getOrganizations } from '../../api';

const aiPrecedence = aiArray => {
  const aiCc = aiArray.find(x => x.ai_source_cd === 'CC' && x.ai_code);
  const aiMs = aiArray.find(x => x.ai_source_cd === 'MS' && x.ai_code);
  const aiApo = aiArray.find(x => x.ai_source_cd === 'APO' && x.ai_code);

  return aiCc?.ai_code ?? aiMs?.ai_code ?? aiApo?.ai_code ?? '';
};

const RegionalSearch = ({
  selectedOrg,
  setSelectedOrg,
  searchedOrg,
  setSearchedOrg,
  showRegionalSearch,
  setShowRegionalSearch,
  showOrgSelection,
  setShowOrgSelection,
  isLoadingDashboard,
  setIsLoadingDashboard,
  setNotCourseAuthorized
}) => {
  const [apiError, setApiError] = useState(undefined);
  const [searchedAICode, setSearchedAICode] = useState('');
  const [searchedOrgId, setSearchedOrgId] = useState('');
  const [showAIErrorMsg, setShowAIErrorMsg] = useState(false);
  const [results, setResults] = useState('');
  const [noData, setNoData] = useState(false);
  const [AICode, setAICode] = useState(undefined);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [isSchool, setIsSchool] = useState(false);
  const [isDistrict, setIsDistrict] = useState(false);
  const [showOrgErrorMsg, setShowOrgErrorMsg] = useState(false);
  const [isLoadingOrgData, setIsLoadingOrgData] = useState(false);
  const appContext = useContext(AppContext);

  const getRegionCode = () => {
    if (selectedOrg && selectedOrg.orgId) {
      switch (selectedOrg.orgId.toString()) {
        case '100059': return 'NERO';
        case '100060': return 'MSRO';
        case '100061': return 'SRO';
        case '100062': return 'MRO';
        case '100063': return 'SWRO';
        case '100064': return 'WRO';
        case '102910': return 'IRO';
        default: return '';
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!appContext.isCustomerService) {
      analytics.reportCurrentView('adminRequestForm', appContext.roleCd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callToMsOrg = async isAIRequest => {
    let url = '';
    let aiCode = '';
    const regex = /^[0-9\b]+$/;
    setShowSearchResults(false);
    setNoData(false);
    if (isAIRequest && !searchedAICode) {
      setShowAIErrorMsg('error');
      return;
    } else if (!isAIRequest && !searchedOrgId) {
      setShowAIErrorMsg('error');
      return;
    }
    let baseURL = config.msOrgBaseUrl;
    if (isAIRequest && regex.test(searchedAICode)) {
      url = `${baseURL}aisearch?aicode=${searchedAICode}`;
    } else if (!isAIRequest && regex.test(searchedOrgId)) {
      url = `${baseURL}orgsearch?orgid=${searchedOrgId}`;
    }
    if (url) {
      setIsLoadingOrgData(true);
      try {
        let res = await getOrganizations(url);
        let res2 = res.data;
        if (res2.hits.hits.length > 0 && res2.hits.hits[0]._source.org_id !== selectedOrg.orgId &&
          ((res2.hits.hits[0]._source.org_region === getRegionCode()) || (getRegionCode() === 'IRO' && res2.hits.hits[0]._source.org_country_iso_cd !== 'US'))) {
          if (res2.hits.hits[0]._source.ais && res2.hits.hits[0]._source.ais.length > 0) {
            aiCode = aiPrecedence(res2.hits.hits[0]._source.ais);
          }
          if (res2.hits.hits[0]._source.org_type_cd === '1' || res2.hits.hits[0]._source.org_type_cd === '9') {
            setResults(res2.hits.hits[0]._source);
            setAICode(aiCode);
            setShowSearchResults(true);
            setIsSchool(true);

          } else if (res2.hits.hits[0]._source.org_type_cd === '6' || res2.hits.hits[0]._source.org_type_cd === '7') {
            setResults(res2.hits.hits[0]._source);
            setAICode(aiCode);
            setShowSearchResults(true);
            setIsDistrict(true);
          }
        } else {
          setNoData(true);
        }
      } catch (err) {
        setApiError(err);
      }
      setIsLoadingOrgData(false);

    }
  };

  const onAICodeInput = event => {
    setShowAIErrorMsg(false);
    const regex = /^\d{6}$/g;
    const value = event.target.value;
    if (value === '' || regex.test(value)) {
      setShowAIErrorMsg(false);
      setNoData(false);
      setSearchedAICode(value);
    } else {
      setShowAIErrorMsg('error');
      setSearchedAICode(value);
    }
  };

  const onOrgInput = event => {
    setShowOrgErrorMsg(false);
    const regex = /^[0-9\b]+$/;
    const value = event.target.value;
    if (value === '' || regex.test(value)) {
      setShowOrgErrorMsg(false);
      setNoData(false);
      setSearchedOrgId(value);
    } else {
      setShowOrgErrorMsg('error');
      setSearchedOrgId(value);
    }
  };

  const getOrgSelection = () => {
    setShowRegionalSearch(false);
    setShowOrgSelection(true);
    setSearchedOrg(undefined);
    setSelectedOrg(undefined);
    setNotCourseAuthorized(false);
  };

  const loadDataForSearchedOrg = () => {
    setShowOrgSelection(false);
    setShowRegionalSearch(false);
    setSearchedOrg({
      orgId: Number(results.org_id),
      orgName: results.org_full_name,
      roleCd: (isSchool ? '3' : (isDistrict ? 5 : ''))
    });
    setIsLoadingDashboard(true);
  };

  return (
    apiError ? (
      <div><ShowError error={apiError}/></div>
    ) : (
      showOrgSelection || isLoadingDashboard ? (
        <OrgSelection />
      ) : (
        <div className='container'>
          <PageTitle />
          <div className="org-name-container ">
            <div className="org-name vertical-center-align">
              {selectedOrg ? selectedOrg.orgName : ''}
              {appContext.orgs.length > 1
                ? <span className="changeOrg">
                  <a href='!#' onClick={e => {
                    e.preventDefault();
                    getOrgSelection();
                  }}>Change Organization</a>
                </span> : null
              }
            </div>
          </div>
          <div className ="form-container">
            <h3>Organization Search</h3>
            <SpacerV size={24} />
            <h5>View reports for a school or district in your region</h5>
            <div className="row">
              <div className="col-xs-10 col-sm-8 col-lg-6">
                <hr className = "linebreak" />
                <div className="search-container">
                  <Input
                    name = 'InputAICode'
                    label = {<div><b>Find a School by AI Code</b>
                      <p>6 digit AI (Attending Institution) code. Also known as school or CEEB code.</p></div>}
                    placeholder = 'Type to search...'
                    onChange={onAICodeInput}
                    floating = {false}
                    maxLength = {6}
                    validation = {showAIErrorMsg}
                    validationMsg = "Please enter a 6 digit numeric value."
                    clearable={false}
                    labelClassName="search-label"
                    className="search-input-container"
                    button="search"
                    onClick={() => callToMsOrg(true)}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        callToMsOrg(true);
                      }
                    }}
                  />
                </div>
                <hr className = "linebreak" />
                <div className="search-container">
                  <Input
                    name = 'InputOrgId'
                    label = {<div><b>Find a District or School by Org ID</b>
                      <p>Unique, numeric ID for an organization; internal to the College Board.</p></div>}
                    placeholder = 'Type to search...'
                    onChange={onOrgInput}
                    floating = {false}
                    maxLength = {22}
                    validation = {showOrgErrorMsg}
                    validationMsg = "Please enter a numeric value."
                    clearable={false}
                    labelClassName="search-label"
                    className="search-input-container"
                    button="search"
                    onClick={() => callToMsOrg(false)}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        callToMsOrg(false);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <SpacerV size={24} />
            {isLoadingOrgData ? <Spinner />
              : showSearchResults
                ? <div>
                  <h4>Search Results</h4>
                  <SpacerV size={24} />
                  <Table
                    a11yHighlightText={undefined}
                    a11yHighlightType="append"
                    summary="table to show the results of the Org ID or AI code search"
                    data={[{
                      org_full_name: <a href='!#' role="button" className="linkPointer cb-palette-blue2" tabIndex="0" onClick={e => {
                        e.preventDefault();
                        loadDataForSearchedOrg();
                      }}>{results.org_full_name}</a>,
                      ai_code: AICode,
                      org_id: results.org_id,
                      org_street_addr1: results.org_street_addr1,
                      org_city: results.org_city,
                      org_state_cd: results.org_state_cd
                    }]}
                  >
                    <Column
                      field="org_full_name"
                      rowHeader
                      title="Organization Name"
                    />
                    <Column
                      field="ai_code"
                      rowHeader
                      title="AI Code"
                    />
                    <Column
                      field="org_id"
                      rowHeader
                      title="Org Id"
                    />
                    <Column
                      field="org_street_addr1"
                      rowHeader
                      title="Address"
                    />
                    <Column
                      field="org_city"
                      rowHeader
                      title="City"
                    />
                    <Column
                      field="org_state_cd"
                      rowHeader
                      title="State"
                    />
                  </Table>
                </div> : null }
            { noData
              ? <div>No results returned. Please check the search criteria entered and try again.</div> : null
            }
          </div>
        </div>
      )
    )
  );
};

RegionalSearch.propTypes = {
  selectedOrg: PropTypes.object,
  setSelectedOrg: PropTypes.func,
  searchedOrg: PropTypes.object,
  setSearchedOrg: PropTypes.func,
  showRegionalSearch: PropTypes.bool,
  setShowRegionalSearch: PropTypes.func,
  showOrgSelection: PropTypes.bool,
  setShowOrgSelection: PropTypes.func,
  isLoadingDashboard: PropTypes.bool,
  setIsLoadingDashboard: PropTypes.func,
  setNotCourseAuthorized: PropTypes.func
};

export default RegionalSearch;

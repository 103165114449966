import React from 'react';
import './backModal.css';
import PropTypes from 'prop-types';
import { Modal, BlackButton, YellowButton } from '@cb/apricot-react';

const BackModal = ({ startNewRequest, trigger }) => {
  return (
    <Modal
      title="Discard Request?"
      trigger={trigger}
      footer={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <BlackButton className="cb-margin-right-16" data-cb-modal-close
          aria-label="Close this dialog window">Return to Request</BlackButton>
        <YellowButton onClick={startNewRequest}>Continue &amp; Cancel</YellowButton></div>}
    >
      <p>If you leave this page you will lose any information you have entered.</p>
    </Modal>
  );
};

BackModal.propTypes = {
  startNewRequest: PropTypes.func,
  trigger: PropTypes.string
};

export default BackModal;

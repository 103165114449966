/* eslint-disable react/prop-types */
import React from 'react';
import LabelWithTooltip from '../../common/labelWithTooltip';
import { CREDIT, CULTURE, OPTIMIZATION } from '../constants';
import { ACCESS_AWARD_TITLE, ADDITIONAL_INFO, AP_CENTRAL_HONOR_ROLL_LINK, CREDIT_TOOLTIP_DESC, CULTURE_TOOLTIP_DESC, OPTIMIZATION_TOOLTIP_DESC } from '../utils';

export const APCentralLink = () => (<a target='_blank' href={AP_CENTRAL_HONOR_ROLL_LINK} rel="noreferrer">
AP Central
</a>);

// eslint-disable-next-line react/prop-types
export const AccessAwardTooltip = ({tooltipId}) => {
  return (
    <LabelWithTooltip
      label={''}
      toolTipTitle={`${ACCESS_AWARD_TITLE} Tooltip`}
      modalContent={(<p>
            An additional recognition is given to schools when the percentage of underrepresented and/or low income students accessing the school&apos;s AP Program is the same as, or greater than the percentage in the school&apos;s graduating class. School level enrollment for this award is sourced from the National Center for Education Statistics using the most recent finalized dataset. {ADDITIONAL_INFO}
        <APCentralLink />.
      </p>)}
      modalTrigger={`accessModalTrigger_${tooltipId}`}
    />

  );
};

export const CultureTooltip = ({source}) => {
  return (
    <LabelWithTooltip
      label={''}
      toolTipTitle={CULTURE}
      toolTipLabel={CULTURE + ' Tooltip'}
      modalContent={<p className='cb-font-weight-regular'>
        {CULTURE_TOOLTIP_DESC}
        {ADDITIONAL_INFO}
        <APCentralLink />.
      </p>}
      modalTrigger={`cultureModalTrigger${source}`}
    />

  );
};

export const CreditTooltip = ({source}) => {
  return (
    <LabelWithTooltip
      label={''}
      toolTipTitle={CREDIT}
      toolTipLabel={CREDIT + ' Tooltip'}
      modalContent={<p className='cb-font-weight-regular'>
        {CREDIT_TOOLTIP_DESC}
        {ADDITIONAL_INFO}
        <APCentralLink />.
      </p>}
      modalTrigger={`creditModalTrigger${source}`}
    />

  );
};

export const OptimizationTooltip = ({source}) => {
  return (
    <LabelWithTooltip
      label={''}
      toolTipTitle={OPTIMIZATION}
      toolTipLabel={OPTIMIZATION + ' Tooltip'}
      modalContent={<p className='cb-font-weight-regular'>
        {OPTIMIZATION_TOOLTIP_DESC}
        {ADDITIONAL_INFO}
        <APCentralLink />.
      </p>}
      modalTrigger={`optimizationModalTrigger${source}`}
    />

  );
};

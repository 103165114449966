import PropTypes from 'prop-types';
import React from 'react';
import DetailTable from '../common/detailTable';
import LabelWithTooltip from '../common/labelWithTooltip';

const columns = [
  { title: 'Exam Year', field: 'ap_admin_yyyy', className: 'cb-font-weight-bold', rowHeader: true },
  { title: 'Exam Name', field: 'exam_name', className: 'cb-font-weight-bold', rowHeader: true },
  { title: 'Score',
    field: 'ap_exam_score',
    className: 'cb-font-weight-bold',
    headerComponent: () => (
      <div className='row cb-padding-left-16'>
        <span className='cb-padding-right-8'>Score</span>
        <LabelWithTooltip
          lightTooltip={true}
          modalTitleComponent={<h2 className='cb-gray1-color cb-font-weight-regular cb-font-size-sm-res'>Score</h2>}
          label={''}
          toolTipTitle={'Score'}
          toolTipLabel={'Score Tooltip'}
          modalContent={(<p className='cb-font-weight-regular cb-black1-color'>
          AP scores signify how qualified students are to receive college credit or placement. In most cases, a score is a weighted combination of the scores on the multiple-choice section and on the free-response section of the exam. Some AP courses, including AP Computer Science Principles, AP Research, AP Seminar, and AP Studio Art, include through-course performance tasks, either in place of or in addition to multiple-choice or free-response questions. The final score is reported on a 5-point scale as follows:
            <br />
            <br />
            <b>5</b> = Extremely well qualified<br />
            <b>4</b> = Well qualified<br />
            <b>3</b> = Qualified<br />
            <b>2</b> = Possibly qualified<br />
            <b>1</b> = No recommendation
            <br />
            <br />
            &quot;Qualified&quot; means that a student has proven him/herself capable of doing the work of an introductory-level course in a particular subject at college. Many colleges and universities grant credit and placement for scores of 3, 4 or 5; however, each college decides which scores it will accept and establishes their own individual credit and placement policies, not the College Board.
          </p>)}
          modalTrigger={`studentScoreReportScoreTooltipTrigger`}
        />
      </div>
    ) },
  { title: 'Event Code',
    field: 'ap_event_cd',
    className: 'cb-font-weight-bold',
    headerComponent: () => (
      <div className='row cb-padding-left-16'>
        <span className='cb-padding-right-8'>Event Code</span>
        <LabelWithTooltip
          lightTooltip={true}
          modalTitleComponent={<h2 className='cb-gray1-color cb-font-weight-regular cb-font-size-sm-res'>Event Code</h2>}
          label={''}
          toolTipTitle={'Event Code'}
          toolTipLabel={'Event Code Tooltip'}
          modalContent={(<p className='cb-font-weight-regular cb-black1-color'>
        AP Exam scores may include an additional event code and description indicating the type of irregularity or event related to the student&apos;s exam, if any. For example, if an exam is still in the process of being scored, and the score is delayed, an event will appear for that exam.
          </p>)}
          modalTrigger={`studentScoreReportEventCodeTooltipTrigger`}
        />
      </div>
    ) },
  { title: 'Event Description', field: 'ap_event_descr', className: 'cb-font-weight-bold' }
];

const ScoresDisplay = ({ scoreData }) => {
  if (!scoreData) {
    return <></>;
  }

  return (
    <div
      id="divScoresSection"
    >
      <h5 className='cb-margin-left-16'>Student Scores</h5>
      <div className='cb-margin-16 cb-white-bg'>
        <DetailTable
          columns={columns}
          data={scoreData}
          responsive
        />
      </div>

    </div>

  );
};

ScoresDisplay.propTypes = {
  scoreData: PropTypes.array
};

export default ScoresDisplay;

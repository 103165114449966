import React, { useEffect, useContext, Fragment } from 'react';
import analytics from '../../utils/analytics';
import './errorPages.css';
import { AppContext } from '../../AppContext';
import AccountConfig from '../../utils/cbAccountConfig.js';
import config from '../../utils/envConfig.js';
import PropTypes from 'prop-types';
import { NakedButton, Input } from '@cb/apricot-react';
import { MSORG_ERROR } from '../../constants/errors';
import ErrorDisplay from './errorDisplay';

const ClosedOrg = ({
  showLogout,
  showOrg
}) => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!appContext.isCustomerService) {
      analytics.reportCurrentView('ClosedOrgErrorPage', appContext.roleCd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const titleMessage = MSORG_ERROR;
  return (
    <Fragment>
      {showLogout ? (
        <div>
          <div className = "general-error-page-container">
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-sm-12">
                <div className = "home-school-container">
                  <ErrorDisplay
                    title={<span className="error-title">{titleMessage}</span>}
                  />
                </div>
                <form name = "submit" method="post" action= {AccountConfig.cbAccountLinks.loginLogoutLink}>
                  <Input type="hidden" name="DURL" value= {config.UIURL}></Input>
                  <Input type="hidden" name="formState" value="1"></Input>
                  <Input type="hidden" name="appId" value={appContext.appId}></Input>
                  <NakedButton noPadding className="logout-button" type="submit" name="lv-logout-button">
                    Logout
                  </NakedButton>
                </form>
              </div>
            </div>
          </div>
        </div>) : (showOrg
        ? (
          <div className = "general-error-page-container" style = {{height: 'auto'}}>
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-sm-12">
                <div className = "home-school-container">
                  <ErrorDisplay
                    title={<span className="error-title">{titleMessage}</span>}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null)
      }
    </Fragment>
  );
};

ClosedOrg.propTypes = {
  showLogout: PropTypes.bool,
  showOrg: PropTypes.bool
};

export default ClosedOrg;

import React, {useContext} from 'react';
import './reportInfoModal.css';
import { Modal } from '@cb/apricot-react';
import { AppContext } from '../../AppContext';
import propTypes from 'prop-types';

const RosterReportInfoModal = (props) => {
  const appContext = useContext(AppContext);
  return (
    <Modal title={<b>Roster and Student Reports</b>} trigger={props.trigger} withFooter={false}>
      <div>
        <p>Teachers, school and district administrators have access to the following report:
          <li><a className="modal-link" href={appContext?.content?.ap_central_links?.availReportsLink} target='_blank' rel='noopener noreferrer' tabIndex="0"><strong>Subject Score Roster</strong></a></li>
        </p>

        <p>Additionally, school and district administrators have access to the following reports:</p>
        <div>
          <ul>
            <li>
              <a className="modal-link" href={appContext?.content?.ap_central_links?.studentDataFileLink} target='_blank' rel='noopener noreferrer' tabIndex="0"><strong>Student Datafile</strong></a> -
              <a className="modal-link" href={appContext?.content?.ap_central_links?.studentDataFileFormatLink} target='_blank' rel='noopener noreferrer' tabIndex="0">View the file layout specification.</a>
            </li>
            <li><strong>Organization Score Roster</strong></li>
            <li><strong>Student Score Report</strong></li>
            <li><strong>Scholar Roster</strong></li>
          </ul>
        </div>
        <br />
        <a className="modal-link" href={appContext?.content?.ap_central_links?.availReportsLink} target='_blank' rel='noopener noreferrer' tabIndex="0"><strong>View detailed descriptions of these reports.</strong></a>
      </div>
    </Modal>
  );
};

RosterReportInfoModal.propTypes = {
  trigger: propTypes.string
};

export default RosterReportInfoModal;

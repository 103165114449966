module.exports = {
  // Tiers
  NONE: 'None Achieved',
  BRONZE: 'Bronze',
  SILVER: 'Silver',
  GOLD: 'Gold',
  PLATINUM: 'Platinum',
  BEYOND_PLATINUM: 'Beyond Platinum',

  AP_ACCESS_BADGE_NAME: 'AP Access',

  // Categories
  CULTURE: 'College Culture',
  CREDIT: 'College Credit',
  OPTIMIZATION: 'College Optimization',

  // Colors
  BRONZE_HEX: '#C47E5A',
  SILVER_HEX: '#B2B2B2',
  GOLD_HEX: '#FFD16C',
  PLATINUM_HEX: '#B7D1DC'

};

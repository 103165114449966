import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import analytics from '../../utils/analytics';
import './errorPages.css';
import { AppContext } from '../../AppContext';
import ErrorDisplay from './errorDisplay';

const GetCourseAuth = ({ selectedYear }) => {
  const appContext = useContext(AppContext);
  const year = selectedYear || appContext.currentYear;
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!appContext.isCustomerService) {
      analytics.reportCurrentView('401errorpage', appContext.roleCd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className = "course-auth-content-container">
      <ErrorDisplay
        title={<span className="error-title">You do not have the proper course authorization.</span>}
      >
        <p >You must have the appropriate AP course authorization for the
          <b> {year - 1}-{year} </b>
    school year thru AP Course Audit to access score reports for
    this organization. Course authorization must be renewed every year to continue to have access to current and previous years’ reports.</p>
        <br />
        <b>Please contact your AP coordinator or principal to obtain your students’ scores offline.</b>
      </ErrorDisplay>
    </div>
  );
};

GetCourseAuth.propTypes = {
  selectedYear: PropTypes.number
};

export default GetCourseAuth;

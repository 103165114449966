import { ORGS_URI } from '../constants/reports';
import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';

// returns list of orgs for a given district and year
const getReportOrgSelect = ({adminYear, reportName, sttVal}) => {

  const url =
  config.apiBaseUrl +
  config.genesisUri +
  ORGS_URI;

  const params = {
    sttVal,
    reportName,
    adminYear
  };

  return axiosInstance.get(url, { params });
};

export default getReportOrgSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { InfoNotification } from '@cb/apricot-react';
import './alert.css';

const Alert = (props) => {
  return (
    <div>
      <InfoNotification className="aposre-alert-warning" role="alert" title={''} icon={null}>
        {props.children}
      </InfoNotification>
    </div>
  );
};

Alert.propTypes = {
  children: PropTypes.element
};

export default Alert;

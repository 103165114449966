import React from 'react';
import propTypes from 'prop-types';
import './summaryCard.css';
import './loading.css';
import { Icon } from '@cb/apricot-react';

// @TODO - this component is only used by FYSS report, others are using common/summaryCard implementation. Should consolidate to use common implementation.

/* component expects to receive children like the following
        <SummaryCard title="% OF TOTAL AP STUDENTS WITH SCORES 3+" iconClass="cb-barchart" style={{order:1, minWidth:"calc(50% - 30px)", position: "relative", width:"calc(50% - 30px)"}}>
            <FiveYearScoreSummaryChart />
        </SummaryCard>
*/
const SummaryCard = (props) => {
  return (
    <div
      className="card-wrapper"
      style={props.style}
      aria-hidden={props.ariaHidden || 'false'}
    >
      <div className="card-content">
        <div className="card-header">
          <Icon name={props.icon} circular={true} color='blue4' className={'cb-font-weight-bold'}/>
          <span className="card-title cb-spacerh-left-8">{props.title}</span>
        </div>
        <div
          className="card-data load-card"
          style={{ position: 'relative', width: '100%' }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

SummaryCard.propTypes = {
  icon: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  style: propTypes.object,
  children: propTypes.instanceOf(Object),
  ariaHidden: propTypes.string
};

export default SummaryCard;

import PropTypes from 'prop-types';
import React from 'react';
import { Notification } from '@cb/apricot-react';
import './subscoreNotes.css';

const SubScoreNotes = ({subScoreNotes, subScoreDescr, width}) => {
  return (
    <div id="divSubScore" className="bg-white" style={{width}}>
      <Notification
        title={subScoreDescr}
        icon={'lightbulb'}
      >
        {subScoreNotes}
      </Notification>
    </div>);
};

SubScoreNotes.propTypes = {
  subScoreNotes: PropTypes.string,
  subScoreDescr: PropTypes.string,
  width: PropTypes.string
};
export default SubScoreNotes;

import React, { useEffect, useContext, Fragment } from 'react';
import analytics from '../../utils/analytics';
import './errorPages.css';
import { AppContext } from '../../AppContext';
import PageTitle from '../common/PageTitle';
import AccountConfig from '../../utils/cbAccountConfig.js';
import config from '../../utils/envConfig.js';
import PropTypes from 'prop-types';
import { NakedButton, Input } from '@cb/apricot-react';
import ErrorDisplay from './errorDisplay';

const HomeSchool = ({
  showLogout,
  showOrg
}) => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!appContext.isCustomerService) {
      analytics.reportCurrentView('BlockHsErrorPage', appContext.roleCd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const titleMessage = `Your account is not authorized to access AP Score Reports for Educators under this organization.`;
  const message = `As a user under a general home school designation you are not authorized to view AP score reports. Contact AP Services at (877) 274-6474 (toll free in the United States and Canada) or (212) 632-1781 for questions.`;
  return (
    <Fragment>
      {showLogout ? (
        <div>
          <PageTitle />
          <div className = "general-error-page-container">
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-sm-12">
                <div className = "home-school-container">
                  <ErrorDisplay title={<span className="error-title">{titleMessage}</span>}>
                    <p>{message}</p>
                  </ErrorDisplay>
                </div>
                <form name = "submit" method="post" action= {AccountConfig.cbAccountLinks.loginLogoutLink}>
                  <Input type="hidden" name="DURL" value= {config.UIURL}></Input>
                  <Input type="hidden" name="formState" value="1"></Input>
                  <Input type="hidden" name="appId" value={appContext.appId}></Input>
                  <NakedButton noPadding className="logout-button" type="submit" name="lv-logout-button">
                    Logout
                  </NakedButton>
                </form>
              </div>
            </div>
          </div>
        </div>) : (showOrg
        ? (
          <div className = "general-error-page-container" style = {{height: 'auto'}}>
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-sm-12">
                <div className = "home-school-container">
                  <ErrorDisplay title={<span className="error-title">{titleMessage}</span>}>
                    <p>{message}</p>
                  </ErrorDisplay>
                </div>
              </div>
            </div>
          </div>) : null)
      }
    </Fragment>
  );
};

HomeSchool.propTypes = {
  showLogout: PropTypes.bool,
  showOrg: PropTypes.bool
};

export default HomeSchool;

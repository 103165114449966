import React from 'react';
import { APCentralLink } from './summaryTab/tooltips';

const HonorRollNotEligibleDisplay = () => {
  return (
    <div>
      <h5>
    Only U.S. and Canadian schools are eligible for the AP School Honor Roll. Go to {<APCentralLink />} for more information about the AP School Honor Roll.
      </h5>
    </div>);
};

export default HonorRollNotEligibleDisplay;

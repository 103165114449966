import { Modal } from '@cb/apricot-react';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import PseudoLink from './pseudoLink';

const ComparableGroupsModal = ({show, setShow}) => {
  return (<Modal
    title="Comparable Groups"
    escClose={true}
    clickOverlayToClose={true}
    open={show}
    onClose={() => setShow(false)}
  >
    Based on your organization&apos;s geographical location, your comparable groups will differ.
    <div className='cb-margin-top-16'>
      <span className='cb-roboto-bold'>Within the United States:</span> State and Global
    </div>
    <div className='cb-margin-top-8'>
      <span className='cb-roboto-bold'>Canada:</span> Province and Global
    </div>
    <div className='cb-margin-top-8'>
      <span className='cb-roboto-bold'>Outside of the United States (with the exception of Canada) and US Territories:</span> Country and Global
    </div>
  </Modal>);
};

ComparableGroupsModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func
};

const ComparableGroups = () => {

  const [show, setShow] = useState(false);

  return (<div className='container cb-padding-bottom-8 cb-padding-top-8'>
    <div className='col-sm-12'>
      <div className='row cb-roboto-italic'>
        Your comparison groups are based on location.
        <div className='cb-margin-left-8'>
          <PseudoLink isDialog={true} text='Learn More' linkId='iprComparableGroups' handler={() => setShow(!show)}/>
        </div>
      </div>
      <ComparableGroupsModal show={show} setShow={setShow} />
    </div>
  </div>);
};

ComparableGroups.propTypes = {};

export default ComparableGroups;

import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { SMALL_TEXT_FONT_SIZE } from '../constants';
import copyrightImage from './copyright.png';

const styles = StyleSheet.create({
  footer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 45,
    bottom: 5,
    padding: 15
  },
  copyrightFont: {
    fontSize: SMALL_TEXT_FONT_SIZE
  },
  pageFont: {
    fontSize: SMALL_TEXT_FONT_SIZE,
    textAlign: 'right'
  },
  copyrightImage: {
    height: SMALL_TEXT_FONT_SIZE - 1,
    width: SMALL_TEXT_FONT_SIZE - 1,
    marginRight: 3,
    marginTop: 1
  },
  row: {
    flexDirection: 'row'
  }
});

const FooterPdf = ({reportName}) => (
  <View style={styles.footer}>
    {/*
        DEV NOTE: typing © is not working with react-pdf v3.1.4, issue related to font families.
        Using Ω somehow works to show the copyright symbol but avoiding this incase future versions cause this hack to no longer work. (https://github.com/diegomura/react-pdf/issues/2277)
    */}
    <View style={styles.row}>
      <Image src={copyrightImage} style={styles.copyrightImage} />
      <Text style={styles.copyrightFont}>{new Date().getFullYear()} The College Board / {reportName}</Text>
    </View>

    <Text
      style={styles.pageFont}
      render={({ pageNumber, totalPages }) => (
        `${pageNumber} of ${totalPages}`
      )} />
  </View>
);

FooterPdf.propTypes = {
  reportName: PropTypes.string
};

export default FooterPdf;

export const IG_TEMPLATE_SVG = `<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" height="1080" width="1080">
  <defs>
    <style>
      .cls-1 {
        font-size: 24px;
        letter-spacing: -.02em;
      }

      .cls-1, .cls-2, .cls-3 {
        fill: #009ada;
      }

      .cls-1, .cls-3 {
        font-family: Lexia-XBold, Lexia;
      }

      .cls-1, .cls-3, .cls-4 {
        font-weight: 700;
      }

      .cls-5 {
        fill: none;
        stroke: #009ada;
        stroke-width: 18.4px;
      }

      .cls-6 {
        fill: #006298;
      }

      .cls-7, .cls-4 {
        fill: #fff;
      }

      .cls-3 {
        font-size: 91.09px;
        letter-spacing: -.04em;
      }

      .cls-4 {
        font-family: AktivGrotesk-XBold, 'Aktiv Grotesk';
        font-size: 36px;
      }
    </style>
  </defs>
  <g>
    <rect class="cls-7" x="14.93" y="14.93" width="1050.14" height="1050.14"/>
    <path class="cls-6" d="m1050.14,29.86v1020.28H29.86V29.86h1020.28m29.86-29.86H0v1080h1080V0h0Z"/>
  </g>
  <g>
    <text class="cls-3" transform="translate(266.76 547.14)"><tspan x="0" y="0"> </tspan></text>
    <g>
      <path class="cls-2" d="m132,535.82l18.86-56.58h18.77l18.12,56.58h5.15c.31.98.54,1.95.69,2.9s.23,1.86.23,2.71-.08,1.78-.23,2.76c-.15.98-.38,1.96-.69,2.94h-26.77c-.31-.86-.54-1.76-.69-2.71-.15-.95-.23-1.82-.23-2.62s.08-1.66.23-2.58c.15-.92.38-1.81.69-2.67h5.52l-2.48-8.65h-19.69l-2.67,8.65h5.61c.31.86.54,1.75.69,2.67.15.92.23,1.78.23,2.58s-.08,1.67-.23,2.62c-.15.95-.38,1.86-.69,2.71h-26.04c-.31-.98-.54-1.96-.69-2.94-.15-.98-.23-1.9-.23-2.76s.08-1.76.23-2.71c.15-.95.38-1.92.69-2.9h5.61Zm34.5-18.22l-6.81-24.66-7.27,24.66h14.08Z"/>
      <path class="cls-2" d="m204.4,535.82v-44.44h-5.7c-.31-.98-.54-1.95-.69-2.9-.15-.95-.23-1.85-.23-2.71s.08-1.78.23-2.76c.15-.98.38-1.96.69-2.94h33.21c3.5,0,6.58.52,9.25,1.56s4.91,2.47,6.72,4.28c1.81,1.81,3.17,3.93,4.09,6.35.92,2.42,1.38,5.05,1.38,7.87,0,2.39-.35,4.82-1.06,7.27-.71,2.45-1.9,4.66-3.59,6.62-1.69,1.96-3.9,3.57-6.62,4.83-2.73,1.26-6.12,1.89-10.17,1.89h-12.05v15.09h6.99c.31.98.54,1.95.69,2.9.15.95.23,1.86.23,2.71s-.08,1.78-.23,2.76c-.15.98-.38,1.96-.69,2.94h-28.15c-.31-.98-.54-1.96-.69-2.94-.15-.98-.23-1.9-.23-2.76s.08-1.76.23-2.71c.15-.95.38-1.92.69-2.9h5.7Zm24.1-26.59c2.82,0,5-.74,6.53-2.21,1.53-1.47,2.3-3.74,2.3-6.81,0-2.76-.75-4.88-2.25-6.35-1.5-1.47-3.6-2.21-6.3-2.21h-8.92v17.57h8.65Z"/>
      <path class="cls-2" d="m255.51,485.03c0-.77.14-1.47.43-2.13.29-.65.68-1.22,1.17-1.69s1.07-.85,1.73-1.11c.66-.27,1.35-.4,2.09-.4s1.42.13,2.07.4c.65.27,1.23.64,1.72,1.11.5.48.89,1.04,1.18,1.69.29.65.43,1.36.43,2.13s-.14,1.49-.43,2.15-.68,1.22-1.18,1.7c-.49.48-1.07.85-1.72,1.12s-1.34.41-2.07.41-1.43-.14-2.09-.41c-.66-.27-1.23-.64-1.73-1.12-.49-.48-.89-1.04-1.17-1.7-.29-.66-.43-1.37-.43-2.15Zm5.43,4.17c.56,0,1.08-.1,1.57-.31.49-.21.92-.5,1.28-.87.36-.37.65-.81.85-1.31.21-.51.31-1.07.31-1.67s-.1-1.13-.31-1.64c-.21-.5-.49-.94-.85-1.31-.36-.37-.79-.66-1.28-.87-.49-.21-1.01-.31-1.57-.31s-1.1.1-1.6.31-.92.5-1.29.87c-.36.37-.65.8-.85,1.31-.21.5-.31,1.05-.31,1.64s.1,1.16.31,1.67c.21.51.49.95.85,1.31.36.37.79.66,1.29.87s1.03.31,1.6.31Zm-2.07-2.21v-3.93h-.42c-.04-.08-.06-.16-.08-.25-.01-.09-.02-.19-.02-.28s0-.19.02-.29c.01-.1.04-.18.08-.26h2.94c.31,0,.58.05.83.15.25.1.46.23.63.4s.31.36.4.59c.09.22.14.46.14.71,0,.12-.01.26-.04.41s-.08.3-.15.45c-.07.15-.17.3-.29.44-.12.14-.28.26-.46.36l.66,1.5h.42c.04.07.06.16.08.26.01.1.02.19.02.29s0,.19-.02.28-.04.18-.08.25h-1.59l-.91-2.38h-.74v1.3h.39c.04.07.06.16.08.26.01.1.02.19.02.29s0,.19-.02.28-.04.18-.08.25h-2.21c-.04-.07-.06-.16-.08-.25-.01-.09-.02-.19-.02-.28s0-.19.02-.29c.01-.1.04-.18.08-.26h.41Zm2.21-2.42c.53,0,.8-.25.8-.74,0-.22-.07-.4-.2-.53-.14-.13-.32-.2-.57-.2h-.83v1.47h.8Z"/>
      <path class="cls-2" d="m291.58,523.03c.86-.49,1.87-.84,3.04-1.06,1.16-.21,2.27-.32,3.31-.32s2.13.11,3.27.32c1.13.21,2.13.57,2.99,1.06v3.77c0,1.04.32,2.13.97,3.27.64,1.14,1.61,2.18,2.9,3.13,1.29.95,2.88,1.73,4.78,2.35,1.9.61,4.11.92,6.62.92,3.74,0,6.5-.66,8.28-1.98,1.78-1.32,2.67-3.05,2.67-5.2,0-3.8-2.94-6.56-8.83-8.28l-12.05-3.5c-2.21-.61-4.32-1.41-6.35-2.39-2.02-.98-3.8-2.21-5.34-3.68-1.53-1.47-2.76-3.23-3.68-5.29-.92-2.05-1.38-4.52-1.38-7.41,0-3.5.69-6.52,2.07-9.06,1.38-2.54,3.16-4.66,5.34-6.35,2.18-1.69,4.6-2.93,7.27-3.73,2.67-.8,5.29-1.2,7.87-1.2,3.86,0,7.07.57,9.61,1.7,2.54,1.14,4.49,2.59,5.84,4.37v-3.68c.86-.49,1.84-.84,2.94-1.06,1.1-.21,2.15-.32,3.13-.32s2.04.11,3.17.32c1.13.22,2.13.57,2.99,1.06v21.44c-.86.49-1.86.84-2.99,1.06s-2.22.32-3.27.32-2.15-.11-3.31-.32c-1.17-.21-2.18-.57-3.04-1.06v-2.21c0-1.23-.26-2.41-.78-3.54-.52-1.13-1.3-2.15-2.35-3.04-1.04-.89-2.39-1.61-4.05-2.16-1.66-.55-3.59-.83-5.8-.83-1.96,0-3.63.31-5.01.92s-2.47,1.41-3.27,2.39c-.86,1.04-1.29,2.27-1.29,3.68,0,1.29.41,2.48,1.24,3.59.83,1.1,2.5,1.99,5.01,2.67l11.41,3.22c2.82.8,5.47,1.73,7.96,2.81,2.48,1.07,4.66,2.42,6.53,4.05,1.87,1.63,3.34,3.6,4.42,5.93,1.07,2.33,1.61,5.18,1.61,8.56,0,3.62-.72,6.72-2.16,9.29-1.44,2.58-3.34,4.69-5.7,6.35-2.36,1.66-5.01,2.87-7.96,3.63-2.94.77-5.89,1.15-8.83,1.15-3.74,0-7.22-.6-10.44-1.79-3.22-1.2-5.63-2.87-7.22-5.01v4.32c-.86.49-1.86.84-2.99,1.06-1.14.21-2.19.32-3.17.32s-2.01-.11-3.08-.32c-1.07-.21-2.04-.57-2.9-1.06v-23.18Z"/>
      <path class="cls-2" d="m396,544.1c-1.6.86-3.9,1.78-6.9,2.76-3.01.98-6.59,1.47-10.76,1.47s-7.84-.64-10.99-1.93c-3.16-1.29-5.8-3.07-7.91-5.34-2.12-2.27-3.71-4.95-4.78-8.05-1.07-3.1-1.61-6.45-1.61-10.07s.46-7.1,1.38-10.26c.92-3.16,2.28-5.9,4.09-8.23,1.81-2.33,4.03-4.15,6.67-5.47,2.64-1.32,5.67-1.98,9.11-1.98,2.94,0,5.32.49,7.13,1.47,1.81.98,3.14,2.12,4,3.4v-2.85c.67-.31,1.49-.55,2.44-.74.95-.18,1.95-.28,2.99-.28s2.04.09,2.99.28c.95.18,1.76.43,2.44.74v20.06c-.68.31-1.56.58-2.67.83-1.1.25-2.21.37-3.31.37s-2.21-.12-3.31-.37c-1.1-.24-1.99-.52-2.67-.83v-3.31c0-2.64-.64-4.57-1.93-5.79-1.29-1.23-2.98-1.84-5.06-1.84-1.53,0-2.84.37-3.91,1.1-1.07.74-1.96,1.73-2.67,2.99-.71,1.26-1.23,2.75-1.56,4.46-.34,1.72-.51,3.53-.51,5.43,0,4.78,1.18,8.31,3.54,10.58,2.36,2.27,5.38,3.4,9.06,3.4,3.07,0,5.81-.54,8.23-1.61,2.42-1.07,4.58-2.31,6.49-3.73v13.34Z"/>
      <path class="cls-2" d="m408.51,536.74v-48.94h-5.34c-.61-1.72-.92-3.44-.92-5.15s.31-3.53.92-5.24h20.79v23.92c1.41-1.29,3.14-2.36,5.2-3.22,2.05-.86,4.55-1.29,7.5-1.29,4.78,0,8.6,1.4,11.45,4.19,2.85,2.79,4.28,6.82,4.28,12.1v23.64h5.34c.61,1.72.92,3.47.92,5.24s-.31,3.44-.92,5.15h-20.79v-31.28c0-2.45-.4-4.32-1.2-5.61-.8-1.29-2.21-1.93-4.23-1.93-.86,0-1.73.12-2.62.37-.89.25-1.7.75-2.44,1.52-.74.77-1.33,1.84-1.79,3.22-.46,1.38-.69,3.17-.69,5.38v17.94h3.96c.61,1.72.92,3.47.92,5.24s-.31,3.44-.92,5.15h-24.75c-.61-1.72-.92-3.43-.92-5.15s.31-3.53.92-5.24h5.34Z"/>
      <path class="cls-2" d="m488.64,548.43c-4.23,0-7.88-.72-10.95-2.16-3.07-1.44-5.61-3.36-7.64-5.75-2.02-2.39-3.53-5.14-4.51-8.23-.98-3.1-1.47-6.33-1.47-9.71s.49-6.52,1.47-9.61c.98-3.1,2.48-5.84,4.51-8.23,2.02-2.39,4.57-4.31,7.64-5.75,3.07-1.44,6.72-2.16,10.95-2.16s7.9.72,10.99,2.16c3.1,1.44,5.66,3.36,7.68,5.75,2.02,2.39,3.53,5.14,4.51,8.23.98,3.1,1.47,6.3,1.47,9.61s-.49,6.61-1.47,9.71c-.98,3.1-2.48,5.84-4.51,8.23-2.02,2.39-4.59,4.31-7.68,5.75-3.1,1.44-6.76,2.16-10.99,2.16Zm0-11.5c1.59,0,2.96-.38,4.09-1.15,1.13-.77,2.05-1.81,2.76-3.13.7-1.32,1.23-2.85,1.56-4.6.34-1.75.51-3.57.51-5.47s-.17-3.71-.51-5.43c-.34-1.72-.86-3.22-1.56-4.51-.71-1.29-1.63-2.31-2.76-3.08-1.14-.77-2.5-1.15-4.09-1.15s-2.94.38-4.05,1.15c-1.1.77-2.01,1.79-2.71,3.08-.71,1.29-1.23,2.79-1.56,4.51-.34,1.72-.51,3.53-.51,5.43s.17,3.73.51,5.47c.34,1.75.86,3.28,1.56,4.6.71,1.32,1.61,2.36,2.71,3.13,1.1.77,2.45,1.15,4.05,1.15Z"/>
      <path class="cls-2" d="m545.04,548.43c-4.23,0-7.88-.72-10.95-2.16-3.07-1.44-5.61-3.36-7.64-5.75-2.02-2.39-3.53-5.14-4.51-8.23-.98-3.1-1.47-6.33-1.47-9.71s.49-6.52,1.47-9.61c.98-3.1,2.48-5.84,4.51-8.23,2.02-2.39,4.57-4.31,7.64-5.75,3.07-1.44,6.72-2.16,10.95-2.16s7.9.72,10.99,2.16c3.1,1.44,5.66,3.36,7.68,5.75,2.02,2.39,3.53,5.14,4.51,8.23.98,3.1,1.47,6.3,1.47,9.61s-.49,6.61-1.47,9.71c-.98,3.1-2.48,5.84-4.51,8.23-2.02,2.39-4.59,4.31-7.68,5.75-3.1,1.44-6.76,2.16-10.99,2.16Zm0-11.5c1.59,0,2.96-.38,4.09-1.15,1.13-.77,2.05-1.81,2.76-3.13.7-1.32,1.23-2.85,1.56-4.6.34-1.75.51-3.57.51-5.47s-.17-3.71-.51-5.43c-.34-1.72-.86-3.22-1.56-4.51-.71-1.29-1.63-2.31-2.76-3.08-1.14-.77-2.5-1.15-4.09-1.15s-2.94.38-4.05,1.15c-1.1.77-2.01,1.79-2.71,3.08-.71,1.29-1.23,2.79-1.56,4.51-.34,1.72-.51,3.53-.51,5.43s.17,3.73.51,5.47c.34,1.75.86,3.28,1.56,4.6.71,1.32,1.61,2.36,2.71,3.13,1.1.77,2.45,1.15,4.05,1.15Z"/>
      <path class="cls-2" d="m581.75,536.74v-48.94h-5.15c-.61-1.72-.92-3.44-.92-5.15s.31-3.53.92-5.24h20.61v59.34h5.15c.61,1.72.92,3.47.92,5.24s-.31,3.44-.92,5.15h-25.76c-.61-1.72-.92-3.43-.92-5.15s.31-3.53.92-5.24h5.15Z"/>
      <path class="cls-2" d="m138.44,627.82v-44.44h-5.7c-.31-.98-.54-1.95-.69-2.9-.15-.95-.23-1.85-.23-2.71s.08-1.78.23-2.76c.15-.98.38-1.96.69-2.94h26.22c.31.98.54,1.96.69,2.94.15.98.23,1.9.23,2.76s-.08,1.76-.23,2.71c-.15.95-.38,1.92-.69,2.9h-4.69v14.54h21.8v-14.54h-4.69c-.31-.98-.54-1.95-.69-2.9-.15-.95-.23-1.85-.23-2.71s.08-1.78.23-2.76c.15-.98.38-1.96.69-2.94h26.22c.31.98.54,1.96.69,2.94.15.98.23,1.9.23,2.76s-.08,1.76-.23,2.71c-.15.95-.38,1.92-.69,2.9h-5.7v44.44h5.7c.31.98.54,1.95.69,2.9.15.95.23,1.86.23,2.71s-.08,1.78-.23,2.76c-.15.98-.38,1.96-.69,2.94h-26.22c-.31-.98-.54-1.96-.69-2.94-.15-.98-.23-1.9-.23-2.76s.08-1.76.23-2.71c.15-.95.38-1.92.69-2.9h4.69v-17.66h-21.8v17.66h4.69c.31.98.54,1.95.69,2.9.15.95.23,1.86.23,2.71s-.08,1.78-.23,2.76c-.15.98-.38,1.96-.69,2.94h-26.22c-.31-.98-.54-1.96-.69-2.94-.15-.98-.23-1.9-.23-2.76s.08-1.76.23-2.71c.15-.95.38-1.92.69-2.9h5.7Z"/>
      <path class="cls-2" d="m228.14,640.43c-4.23,0-7.88-.72-10.95-2.16-3.07-1.44-5.61-3.36-7.64-5.75-2.02-2.39-3.53-5.14-4.51-8.23-.98-3.1-1.47-6.33-1.47-9.71s.49-6.52,1.47-9.61c.98-3.1,2.48-5.84,4.51-8.23,2.02-2.39,4.57-4.31,7.64-5.75,3.07-1.44,6.72-2.16,10.95-2.16s7.9.72,10.99,2.16c3.1,1.44,5.66,3.36,7.68,5.75,2.02,2.39,3.53,5.14,4.51,8.23.98,3.1,1.47,6.3,1.47,9.61s-.49,6.61-1.47,9.71c-.98,3.1-2.48,5.84-4.51,8.23-2.02,2.39-4.59,4.31-7.68,5.75-3.1,1.44-6.76,2.16-10.99,2.16Zm0-11.5c1.59,0,2.96-.38,4.09-1.15,1.13-.77,2.05-1.81,2.76-3.13.7-1.32,1.23-2.85,1.56-4.6.34-1.75.51-3.57.51-5.47s-.17-3.71-.51-5.43c-.34-1.72-.86-3.22-1.56-4.51-.71-1.29-1.63-2.31-2.76-3.08-1.14-.77-2.5-1.15-4.09-1.15s-2.94.38-4.05,1.15c-1.1.77-2.01,1.79-2.71,3.08s-1.23,2.79-1.56,4.51c-.34,1.72-.51,3.53-.51,5.43s.17,3.73.51,5.47c.34,1.75.86,3.28,1.56,4.6.71,1.32,1.61,2.36,2.71,3.13,1.1.77,2.45,1.15,4.05,1.15Z"/>
      <path class="cls-2" d="m263.55,628.74v-28.24h-5.34c-.61-1.72-.92-3.43-.92-5.15s.31-3.53.92-5.24h18.4l.55,5.24c1.29-1.78,3.11-3.31,5.47-4.6,2.36-1.29,5.38-1.93,9.06-1.93,4.78,0,8.6,1.4,11.45,4.19,2.85,2.79,4.28,6.82,4.28,12.1v23.64h5.34c.61,1.72.92,3.47.92,5.24s-.31,3.44-.92,5.15h-20.79v-31.28c0-2.45-.4-4.32-1.2-5.61-.8-1.29-2.21-1.93-4.23-1.93-.86,0-1.73.12-2.62.37-.89.25-1.7.75-2.44,1.52-.74.77-1.33,1.84-1.79,3.22s-.69,3.17-.69,5.38v17.94h3.96c.61,1.72.92,3.47.92,5.24s-.31,3.44-.92,5.15h-24.75c-.61-1.72-.92-3.43-.92-5.15s.31-3.53.92-5.24h5.34Z"/>
      <path class="cls-2" d="m340.93,640.43c-4.23,0-7.88-.72-10.95-2.16-3.07-1.44-5.61-3.36-7.64-5.75-2.02-2.39-3.53-5.14-4.51-8.23-.98-3.1-1.47-6.33-1.47-9.71s.49-6.52,1.47-9.61c.98-3.1,2.48-5.84,4.51-8.23,2.02-2.39,4.57-4.31,7.64-5.75,3.07-1.44,6.72-2.16,10.95-2.16s7.9.72,10.99,2.16c3.1,1.44,5.66,3.36,7.68,5.75,2.02,2.39,3.53,5.14,4.51,8.23.98,3.1,1.47,6.3,1.47,9.61s-.49,6.61-1.47,9.71c-.98,3.1-2.48,5.84-4.51,8.23-2.02,2.39-4.59,4.31-7.68,5.75-3.1,1.44-6.76,2.16-10.99,2.16Zm0-11.5c1.59,0,2.96-.38,4.09-1.15,1.13-.77,2.05-1.81,2.76-3.13.7-1.32,1.23-2.85,1.56-4.6.34-1.75.51-3.57.51-5.47s-.17-3.71-.51-5.43c-.34-1.72-.86-3.22-1.56-4.51-.71-1.29-1.63-2.31-2.76-3.08-1.14-.77-2.5-1.15-4.09-1.15s-2.94.38-4.05,1.15c-1.1.77-2.01,1.79-2.71,3.08s-1.23,2.79-1.56,4.51c-.34,1.72-.51,3.53-.51,5.43s.17,3.73.51,5.47c.34,1.75.86,3.28,1.56,4.6.71,1.32,1.61,2.36,2.71,3.13,1.1.77,2.45,1.15,4.05,1.15Z"/>
      <path class="cls-2" d="m376.71,628.74v-28.24h-5.24c-.61-1.72-.92-3.43-.92-5.15s.31-3.53.92-5.24h18.68l.55,7.64c1.47-3,3.25-5.03,5.34-6.07,2.08-1.04,4.05-1.56,5.89-1.56,1.23,0,2.35.11,3.36.32,1.01.22,2.04.57,3.08,1.06l-1.56,12.42c-.92-.31-1.83-.58-2.71-.83-.89-.24-1.89-.37-2.99-.37-1.23,0-2.44.25-3.63.74-1.2.49-2.22,1.26-3.08,2.3-.68.8-1.21,1.81-1.61,3.04-.4,1.23-.6,2.67-.6,4.32v15.64h6.26c.61,1.72.92,3.47.92,5.24s-.31,3.44-.92,5.15h-26.96c-.61-1.72-.92-3.43-.92-5.15s.31-3.53.92-5.24h5.24Z"/>
      <path class="cls-2" d="m444.06,627.82v-44.44h-5.7c-.31-.98-.54-1.95-.69-2.9-.15-.95-.23-1.85-.23-2.71s.08-1.78.23-2.76c.15-.98.38-1.96.69-2.94h34.87c3.37,0,6.41.54,9.11,1.61,2.7,1.07,4.98,2.53,6.85,4.37s3.3,3.99,4.28,6.44c.98,2.45,1.47,5.06,1.47,7.82,0,1.35-.17,2.88-.51,4.6-.34,1.72-.92,3.42-1.75,5.11-.83,1.69-1.95,3.31-3.36,4.88-1.41,1.56-3.16,2.84-5.24,3.82l9.2,17.11h6.16c.31.98.54,1.95.69,2.9.15.95.23,1.86.23,2.71s-.08,1.78-.23,2.76c-.15.98-.38,1.96-.69,2.94h-17.94l-12.79-26.59h-9.2v15.27h6.62c.31.98.54,1.95.69,2.9.15.95.23,1.86.23,2.71s-.08,1.78-.23,2.76c-.15.98-.38,1.96-.69,2.94h-27.78c-.31-.98-.54-1.96-.69-2.94-.15-.98-.23-1.9-.23-2.76s.08-1.76.23-2.71c.15-.95.38-1.92.69-2.9h5.7Zm25.94-27.05c5.95,0,8.92-2.85,8.92-8.56,0-2.7-.75-4.77-2.25-6.21-1.5-1.44-3.67-2.16-6.49-2.16h-10.67v16.93h10.49Z"/>
      <path class="cls-2" d="m527.41,640.43c-4.23,0-7.88-.72-10.95-2.16-3.07-1.44-5.61-3.36-7.64-5.75-2.02-2.39-3.53-5.14-4.51-8.23-.98-3.1-1.47-6.33-1.47-9.71s.49-6.52,1.47-9.61c.98-3.1,2.48-5.84,4.51-8.23,2.02-2.39,4.57-4.31,7.64-5.75,3.07-1.44,6.72-2.16,10.95-2.16s7.9.72,10.99,2.16c3.1,1.44,5.66,3.36,7.68,5.75,2.02,2.39,3.53,5.14,4.51,8.23.98,3.1,1.47,6.3,1.47,9.61s-.49,6.61-1.47,9.71c-.98,3.1-2.48,5.84-4.51,8.23-2.02,2.39-4.59,4.31-7.68,5.75-3.1,1.44-6.76,2.16-10.99,2.16Zm0-11.5c1.59,0,2.96-.38,4.09-1.15,1.13-.77,2.05-1.81,2.76-3.13.7-1.32,1.23-2.85,1.56-4.6.34-1.75.51-3.57.51-5.47s-.17-3.71-.51-5.43c-.34-1.72-.86-3.22-1.56-4.51-.71-1.29-1.63-2.31-2.76-3.08-1.14-.77-2.5-1.15-4.09-1.15s-2.94.38-4.05,1.15c-1.1.77-2.01,1.79-2.71,3.08s-1.23,2.79-1.56,4.51c-.34,1.72-.51,3.53-.51,5.43s.17,3.73.51,5.47c.34,1.75.86,3.28,1.56,4.6.71,1.32,1.61,2.36,2.71,3.13,1.1.77,2.45,1.15,4.05,1.15Z"/>
      <path class="cls-2" d="m561.82,628.74v-48.94h-5.15c-.61-1.72-.92-3.43-.92-5.15s.31-3.53.92-5.24h20.61v59.34h5.15c.61,1.72.92,3.47.92,5.24s-.31,3.44-.92,5.15h-25.76c-.61-1.72-.92-3.43-.92-5.15s.31-3.53.92-5.24h5.15Z"/>
      <path class="cls-2" d="m594.2,628.74v-48.94h-5.15c-.61-1.72-.92-3.43-.92-5.15s.31-3.53.92-5.24h20.61v59.34h5.15c.61,1.72.92,3.47.92,5.24s-.31,3.44-.92,5.15h-25.76c-.61-1.72-.92-3.43-.92-5.15s.31-3.53.92-5.24h5.15Z"/>
    </g>
    <line class="cls-5" x1="124.5" y1="676" x2="615.5" y2="676"/>
  </g>
</svg>`;

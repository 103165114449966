import propTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FACEBOOK_TEMPLATE_SVG } from './svgs/facebookTemplate';
import { CB_LOGO_SVG } from './svgs/cbLogo';
import { addImage, getBadgeImage, writeText } from './utils';

// coordinates and width consts
const BADGE_WIDTH = 275;
const BADGE_X = 80;
const BADGE_Y = 165;
const HEIGHT = 628;
const WIDTH = 1200;
const YEAR_X = 410;
const YEAR_Y = 320;
const LOGO_Y = YEAR_Y + 145;
const LOGO_WIDTH = 148;
const YEAR_FONT_SIZE = 96;

export const addBackgroundImagesFb = ({year, badge, context, addImageFunc = addImage, writeTextFunc = writeText}) => () => {
  writeTextFunc(context, { text: year, x: YEAR_X, y: YEAR_Y }, { fontSize: YEAR_FONT_SIZE });
  addImageFunc({
    ctx: context,
    src: getBadgeImage(badge),
    x: BADGE_X,
    y: BADGE_Y,
    width: BADGE_WIDTH
  });
  addImageFunc({
    ctx: context,
    src: CB_LOGO_SVG,
    x: YEAR_X,
    y: LOGO_Y,
    width: LOGO_WIDTH
  });
};

export const drawFb = ({canvasRef, badge, year, addImageFunc = addImage}) => {
  if (canvasRef.current) {
    const context = canvasRef.current.getContext('2d');
    addImageFunc({
      ctx: context,
      src: FACEBOOK_TEMPLATE_SVG,
      x: 0,
      y: 0,
      width: WIDTH,

      // we have to below additions after the above image loads, as it has a full white background
      doAfter: addBackgroundImagesFb({year, badge, context})
    });
  }
};

const FbImageGenerator = ({
  canvasRef,
  year,
  badge
}) => {

  useEffect(() => {
    drawFb({ canvasRef, badge, year });
  }, [canvasRef]);

  return (
    <>
      <canvas style={{display: 'none'}} ref={canvasRef} height={HEIGHT} width={WIDTH} />
    </>

  );
};

FbImageGenerator.propTypes = {
  canvasRef: propTypes.object,
  year: propTypes.number,
  badge: propTypes.string
};

export default FbImageGenerator;

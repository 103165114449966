import PropTypes from 'prop-types';
import React from 'react';
import stringUtils from '../../../utils/stringUtils';
import DetailTable from '../common/detailTable';
import DownloadModal from '../common/downloads/downloadModal';
import '../shared.css';
import SubHeader from '../subHeader';
import SummaryCard from '../summaryCard';
import './report.css';
import FiveYearScoreSummaryChart from './summaryChart';

// utility function to take main data object with summary data, along with start index for the 5 years of row data
const getSummaryTableRowData = (objAllData, formatFunction) => {
  return {
    yearOneValue: formatFunction(objAllData.val1),
    yearTwoValue: formatFunction(objAllData.val2),
    yearThreeValue: formatFunction(objAllData.val3),
    yearFourValue: formatFunction(objAllData.val4),
    yearFiveValue: formatFunction(objAllData.val5)
  };
};

const getTableData = (summaryMetricData) => ([
  {
    id: 1,
    label: 'Total AP Students',
    ...getSummaryTableRowData(summaryMetricData[0], stringUtils.formatCountValue)
  },
  {
    id: 2,
    label: 'Number of Exams',
    ...getSummaryTableRowData(summaryMetricData[1], stringUtils.formatCountValue)
  },
  {
    id: 3,
    label: 'AP Students with Scores 3+',
    ...getSummaryTableRowData(summaryMetricData[2], stringUtils.formatCountValue)
  },
  {
    id: 4,
    label: '% of Total AP Students with Scores 3+',
    ...getSummaryTableRowData(summaryMetricData[3], stringUtils.formatPercentageValue)
  }
]);

const getChartData = (summaryMetricData) => ([
  parseFloat(summaryMetricData[3].val1),
  parseFloat(summaryMetricData[3].val2),
  parseFloat(summaryMetricData[3].val3),
  parseFloat(summaryMetricData[3].val4),
  parseFloat(summaryMetricData[3].val5)
]);

const FiveYearScoreSummary = ({ apiData, currentYear, sttVal, isDistrictAdmin, selectedOrgId, isAggregate }) => {
  const downloadModalTrigger = 'openDownloadModal';
  const { summaryMetricData, detailedScoreData: detailTableData } = apiData;

  // Set data object for summary table
  const tableData = getTableData(summaryMetricData);

  // Set data object for summary chart
  const chartData = getChartData(summaryMetricData);

  // Set data for csv headers
  const csvHeader = [
    'Subject',
    'Score Level',
    `${currentYear - 4}`,
    `${currentYear - 3}`,
    `${currentYear - 2}`,
    `${currentYear - 1}`,
    `${currentYear}`
  ];

  const detailTableColumns = [
    { title: ' ', field: 'Subject', className: 'font-table-heavy' },
    { title: ' ', field: 'Score Level', className: 'font-table-heavy' },
    { title: `${currentYear - 4}`, field: `${currentYear - 4}` },
    { title: `${currentYear - 3}`, field: `${currentYear - 3}` },
    { title: `${currentYear - 2}`, field: `${currentYear - 2}` },
    { title: `${currentYear - 1}`, field: `${currentYear - 1}`},
    { title: `${currentYear}`, field: `${currentYear}` }
  ];

  const SummaryCardColumn = [
    { title: ' ', field: 'label', className: 'font-table-heavy' },
    { title: `${currentYear - 4}`, field: 'yearOneValue', className: 'text-right-align' },
    { title: `${currentYear - 3}`, field: 'yearTwoValue', className: 'text-right-align' },
    { title: `${currentYear - 2}`, field: 'yearThreeValue', className: 'text-right-align' },
    { title: `${currentYear - 1}`, field: 'yearFourValue', className: 'text-right-align' },
    { title: `${currentYear}`, field: 'yearFiveValue', className: 'text-right-align' }
  ];

  const getDetailTableData = (data) => {
    const formatValue = (lbl, val) => {
      return lbl === 'Mean Score' ? stringUtils.formatPercentageValue(val) : val;
    };

    let group = 0;
    return data.reduce((acc, row) => {
      const {csvData, formattedDetailTableData} = acc;
      if (row.lbl === '1') {
        group += 1;
      }
      const data = { Subject: row.lbl === '1' ? row.exam_name : '',
        'Score Level': row.lbl,
        [currentYear - 4]: formatValue(row.lbl, row.val1),
        [currentYear - 3]: formatValue(row.lbl, row.val2),
        [currentYear - 2]: formatValue(row.lbl, row.val3),
        [currentYear - 1]: formatValue(row.lbl, row.val4),
        [currentYear ]: formatValue(row.lbl, row.val5) };
      csvData.push({...data, Subject: row.exam_name });
      formattedDetailTableData.push({
        ...row,
        ...data,
        highlighted: (group % 2) === 0
      });
      return acc;
    }, {csvData: [], formattedDetailTableData: []});
  };
  const {csvData, formattedDetailTableData} = getDetailTableData(detailTableData);

  const rowHighlighted = ({ highlighted }) => highlighted;

  return (
    <>
      <SubHeader showDownload={true} downloadModalTrigger={downloadModalTrigger} />
      <DownloadModal
        title={`Five Year Score Summary ${currentYear}`}
        csvFilterText={isDistrictAdmin ? 'Selected School; All Subjects; No Filters' : 'All Subjects; No Filters'}
        csvData={csvData}
        filename={`AP School Five Year Score Summary ${currentYear}`}
        csvHeader={csvHeader}
        org={selectedOrgId}
        auditTitle='FiveYearSchoolScoreSummaryExport'
        sttVal={sttVal}
        year={currentYear}
        trigger={downloadModalTrigger}
        isAggregate={isAggregate}
        isDistrictAdmin={isDistrictAdmin}
      />
      <div
        id="divSummaries"
        className="cb-panel-blue container-fluid"
      >
        <div className="content-gutter-cards summary-card-container">
          <SummaryCard ariaHidden="true" title="% OF TOTAL AP STUDENTS WITH SCORES 3+" icon="graph" style={{ order: 1, minWidth: 'calc(50% - 30px)', position: 'relative', width: 'calc(50% - 30px)' }}>
            <FiveYearScoreSummaryChart data={chartData} labels={[
              (currentYear - 4).toString(),
              (currentYear - 3).toString(),
              (currentYear - 2).toString(),
              (currentYear - 1).toString(),
              currentYear.toString()
            ]} />
          </SummaryCard>
          <SummaryCard title="SCHOOL SUMMARY" icon="note" style={{ order: 2, minWidth: 'calc(50% - 30px)' }}>
            <DetailTable
              data={tableData}
              columns={SummaryCardColumn}
              striped={false}
              responsive
            />
          </SummaryCard>
        </div>
      </div>
      <div
        className="cb-scrollbar-container content-gutter"
        tabIndex="-1"
      >
      </div>
      <div className="content-gutter">
        <DetailTable
          tableId="fyss_detail_table"
          data={formattedDetailTableData}
          columns={detailTableColumns}
          striped={false}
          rowHighlighted={rowHighlighted}
          responsive
        />
      </div>
      <div className="content-gutter padding-v-16-t">
        “Success” on an AP Exam is defined as an exam score of 3 or higher, which represents the score point that research finds predictive of college success and college graduation. These findings have held consistent across the decades. One example of such a study comes from the National Center for Educational Accountability, which found that an AP Exam score, and a score of 3 or higher in particular, is a strong predictor of a student’s ability to persist in college and earn a bachelor’s degree.
      </div>
      <div className="content-gutter">
        The data in this report differs from other College Board reports, such as The AP Cohort Data Report, which tracks exams taken by seniors throughout their time in high school (cohort-based) and includes public school data only.
      </div>
    </>

  );
};

FiveYearScoreSummary.propTypes = {
  apiData: PropTypes.object,
  currentYear: PropTypes.number,
  sttVal: PropTypes.string,
  isDistrictAdmin: PropTypes.bool,
  selectedOrgId: PropTypes.number,
  isAggregate: PropTypes.bool
};

export default FiveYearScoreSummary;

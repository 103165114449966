import { formatPercentageValue, getDisplayNameWithAi } from '../../../utils/stringUtils';
import { deriveFilterOptions } from '../../../utils/tableUtils';

const TOTAL_SCHOLARS_KEY = 'totalScholars';
const TOTAL_EXAMS_KEY = 'totalExams';
const TOTAL_SCORE_KEY = 'totalScore';
const SCHOOL_KEY = 'school';
const SCHOOL_NAME_KEY = 'schoolName';
const AI_CODE_KEY = 'aiCode';

const AWARD_SORT_KEY = 'ap_scholar_award_cd';

const NON_AWARD_KEYS = [SCHOOL_KEY, TOTAL_SCHOLARS_KEY, TOTAL_EXAMS_KEY, TOTAL_SCORE_KEY, SCHOOL_NAME_KEY, AI_CODE_KEY];

export const applyFilters = (arrData, appliedFilters = {gradeLevelFilters: [], awardFilters: []}) => {
  return arrData.filter(item => {
    return (
      (appliedFilters.gradeLevelFilters.length === 0 || appliedFilters.gradeLevelFilters.some((elem) => elem.value === item.education_level_cd)) &&
      (appliedFilters.awardFilters.length === 0 || appliedFilters.awardFilters.some((elem) => elem.value === item.ap_scholar_award_cd))
    );
  });
};

export const getAwardArr = (arrApiData, appliedFilters) => {
  const filteredData = applyFilters(arrApiData, appliedFilters);
  filteredData.sort((a, b) => a[AWARD_SORT_KEY] > b[AWARD_SORT_KEY] ? 1 : -1);
  return [...new Set(filteredData.map((i) => i.award_descr))];
};

const getInitialSchoolObj = (schoolName, aiCode) => ({
  [SCHOOL_KEY]: getDisplayNameWithAi(schoolName, aiCode),
  [SCHOOL_NAME_KEY]: schoolName,
  [AI_CODE_KEY]: aiCode ?? '',
  [TOTAL_SCHOLARS_KEY]: 0,
  [TOTAL_EXAMS_KEY]: 0,
  [TOTAL_SCORE_KEY]: 0
});

const getInitialAwardObj = () => ({
  scholars: 0, // number of scholars
  examCount: 0, // num exams taken by scholars with this award
  totalScore: 0 // total score on exams taken by scholars with this award
});

const isAwardKey = key => typeof (NON_AWARD_KEYS.find(i => key === i)) === 'undefined';

const getFormattedAverage = (totalScore, examCount) => formatPercentageValue(parseInt(totalScore, 10) / parseInt(examCount, 10));

const getFormattedSchoolData = school => {
  // each school obj will produce two rows of table data (num scholars and average score)
  const scholarCounts = Object.keys(school).reduce((acc, item) => {
    // if this is an award key
    if (isAwardKey(item)) {
      acc[item] = school[item].scholars;
    }
    return acc;
  }, {});
  const scoreCounts = Object.keys(school).reduce((acc, item) => {
    // if this is an award key
    if (isAwardKey(item)) {
      acc[item] = getFormattedAverage(school[item].totalScore, school[item].examCount);
    }
    return acc;
  }, {});
  return [{
    schoolName: school.schoolName,
    aiCode: school.aiCode,
    school: school.school,
    label: 'Number of Scholars',
    ...scholarCounts,
    total: school[TOTAL_SCHOLARS_KEY]
  },
  {
    schoolName: school.schoolName,
    aiCode: school.aiCode,
    school: school.school,
    label: 'Average Score',
    ...scoreCounts,
    total: getFormattedAverage(school[TOTAL_SCORE_KEY], school[TOTAL_EXAMS_KEY])
  }];
};

export const parseScholarData = (arrApiData, appliedFilters) => {
  const filteredData = applyFilters(arrApiData, appliedFilters);
  const dataBySchool = {};
  filteredData.forEach(i => {
    if (typeof dataBySchool[i.school_org_short_nm] === 'undefined') {
      dataBySchool[i.school_org_short_nm] = getInitialSchoolObj(i.school_org_short_nm, i.ai_cd);
    }
    if (typeof dataBySchool[i.school_org_short_nm][i.award_descr] === 'undefined') {
      dataBySchool[i.school_org_short_nm][i.award_descr] = getInitialAwardObj();
    }
    const award = dataBySchool[i.school_org_short_nm][i.award_descr];
    const newScholars = parseInt(i.reportable_student_count, 10);
    const newExams = parseInt(i.total_exam_count, 10);
    const newScores = parseInt(i.total_score, 10);
    award.scholars += newScholars;
    award.examCount += newExams;
    award.totalScore += newScores;

    dataBySchool[i.school_org_short_nm][TOTAL_SCHOLARS_KEY] += newScholars;
    dataBySchool[i.school_org_short_nm][TOTAL_EXAMS_KEY] += newExams;
    dataBySchool[i.school_org_short_nm][TOTAL_SCORE_KEY] += newScores;
  });

  // ensure that each school has zeros for the non

  const formatted = [];
  for (const [_key, school] of Object.entries(dataBySchool).sort((a, b) => a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1)) {
    formatted.push(...(getFormattedSchoolData(school)));
  }

  // alternating highlighted every 2
  formatted.forEach((item, index) => {
    if (index % 4 > 1) {
      item.highlighted = true;
    }
  });

  return formatted;
};

export const getGradeLevelFilterOptions = (data) => deriveFilterOptions({
  data,
  labelKey: 'education_level_descr',
  valueKey: 'education_level_cd',
  sortKey: 'education_level_cd'
});

export const getAwardFilterOptions = (data) => deriveFilterOptions({
  data,
  labelKey: 'award_descr',
  valueKey: 'ap_scholar_award_cd',
  sortKey: AWARD_SORT_KEY
});

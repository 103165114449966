import React from 'react';
import ProgressChart from './progressChart';
import PropTypes from 'prop-types';
import CongratulationsHeader from '../congratulationsHeader';
import {
  CULTURE,
  CREDIT,
  OPTIMIZATION
} from '../constants';
import { CreditTooltip, CultureTooltip, OptimizationTooltip } from './tooltips';
import AccessAwardSection from './accessAward';
import NextTierDisplay from './nextTierDisplay';
import PseudoLink from '../../common/pseudoLink';
import BadgeDisplay from './badgeDisplay';
import { getNumberOfCategoriesWithTiers, isTier } from '../utils';

const SECTION_IDENTIFIER = 'summary'; // used for ids that are found on both tabs

const HonorRollProgressSummary = ({
  overall,
  school,
  year,
  culturePercent,
  cultureTier,
  creditPercent,
  creditTier,
  optimizationPercent,
  optimizationTier,
  nextTierValues,
  accessAward,
  cultureMetadata,
  creditMetadata,
  optimizationMetadata,
  grade12Enrollment,
  cultureCount,
  creditCount,
  optimizationCount,
  setTabTwoHandler,
  cultureRef,
  creditRef,
  optimizationRef
}) => {

  const numCategoriesWithTiers = getNumberOfCategoriesWithTiers({cultureTier, creditTier, optimizationTier});

  const hasTier = isTier(overall);

  const CLASS_HIDE_SM_AND_XS = 'display-sm-none-only display-xs-none-only';
  const CLASS_HIDE_MD_AND_ABOVE = 'display-md-none';

  return (
    <div className='row' id={`${SECTION_IDENTIFIER}TabContainer`}>

      {/* set width charts to be used for pdf export */}
      <div style={{visibility: 'hidden', width: '0px', height: '0px', overflowY: 'scroll', overflowX: 'scroll'}}>
        <div style={{minWidth: '500px', width: '500px', zIndex: -1000}}>
          <ProgressChart
            chartRef={cultureRef}
            year={year}
            tier={cultureTier}
            title={CULTURE}
            val={parseInt(culturePercent)}
            hasTier={isTier(cultureTier)}
            levels={cultureMetadata}
            grade12Enrollment={grade12Enrollment}
            labelCount={cultureCount}
          />

          <ProgressChart
            chartRef={creditRef}
            year={year}
            tier={creditTier}
            title={CREDIT}
            val={parseInt(creditPercent)}
            hasTier={isTier(creditTier)}
            levels={creditMetadata}
            grade12Enrollment={grade12Enrollment}
            labelCount={creditCount}
          />

          <ProgressChart
            chartRef={optimizationRef}
            year={year}
            tier={optimizationTier}
            title={OPTIMIZATION}
            val={parseInt(optimizationPercent)}
            hasTier={isTier(optimizationTier)}
            levels={optimizationMetadata}
            grade12Enrollment={grade12Enrollment}
            labelCount={optimizationCount}
          />
        </div>
      </div>

      {/* Left display with 3 charts */}
      <div className='col-sm-12 col-md-6'>
        <CongratulationsHeader school={school} overall={overall} year={year} source={SECTION_IDENTIFIER} />

        {/* When sm, show badge display at top */}
        {
          hasTier &&
          <div className={`${CLASS_HIDE_MD_AND_ABOVE} col-sm-12`}>
            <BadgeDisplay tier={overall} school={school} year={year} />
          </div>
        }

        <div className='cb-margin-top-16'>

          <ProgressChart
            year={year}
            tier={cultureTier}
            title={CULTURE}
            val={parseInt(culturePercent)}
            hasTier={isTier(cultureTier)}
            levels={cultureMetadata}
            grade12Enrollment={grade12Enrollment}
            labelCount={cultureCount}
            tooltip={<CultureTooltip source={SECTION_IDENTIFIER} />}
          />

          <ProgressChart
            year={year}
            tier={creditTier}
            title={CREDIT}
            val={parseInt(creditPercent)}
            hasTier={isTier(creditTier)}
            levels={creditMetadata}
            grade12Enrollment={grade12Enrollment}
            labelCount={creditCount}
            tooltip={<CreditTooltip source={SECTION_IDENTIFIER}/>}
          />

          <ProgressChart
            year={year}
            tier={optimizationTier}
            title={OPTIMIZATION}
            val={parseInt(optimizationPercent)}
            hasTier={isTier(optimizationTier)}
            levels={optimizationMetadata}
            grade12Enrollment={grade12Enrollment}
            labelCount={optimizationCount}
            tooltip={<OptimizationTooltip source={SECTION_IDENTIFIER}/>}
          />

          {
            grade12Enrollment &&
            (<div className='cb-margin-top-16 cb-margin-bottom-16 cb-align-right cb-font-weight-bold'>
              <PseudoLink handler={setTabTwoHandler} text='View a table version of this information on the next tab.' linkId='honorRollSwitchTabsLinkId' />
            </div>)
          }

        </div>
      </div>

      {/* E&I award section for small screen */}
      {
        accessAward &&
        (<div className={`${CLASS_HIDE_MD_AND_ABOVE} col-sm-12`}>
          <AccessAwardSection year={year} school={school} tooltipId='top' />
        </div>)
      }

      {/* Right display, messaging on current tier and what to do to achieve next tier */}
      <div className='col-sm-12 col-md-6'>
        <NextTierDisplay
          hasTier={hasTier} overall={overall} school={school} year={year} numCategoriesWithTiers={numCategoriesWithTiers} nextTierValues={nextTierValues} hideBadgeClass={CLASS_HIDE_SM_AND_XS}
        />
      </div>

      {/* E&I award section for non-small screen */}
      {
        accessAward &&
        (<div className={`${CLASS_HIDE_SM_AND_XS} col-md-6`}>
          <AccessAwardSection year={year} school={school} tooltipId='bottom' />
        </div>)
      }

    </div>);
};

HonorRollProgressSummary.propTypes = {
  school: PropTypes.string,
  overall: PropTypes.string,
  year: PropTypes.number,
  culturePercent: PropTypes.string,
  creditPercent: PropTypes.string,
  optimizationPercent: PropTypes.string,
  cultureTier: PropTypes.string,
  creditTier: PropTypes.string,
  optimizationTier: PropTypes.string,
  nextTierValues: PropTypes.object,
  accessAward: PropTypes.bool,
  cultureMetadata: PropTypes.array,
  creditMetadata: PropTypes.array,
  optimizationMetadata: PropTypes.array,
  grade12Enrollment: PropTypes.number,
  cultureCount: PropTypes.number,
  creditCount: PropTypes.number,
  optimizationCount: PropTypes.number,
  setTabTwoHandler: PropTypes.func,
  cultureRef: PropTypes.object,
  creditRef: PropTypes.object,
  optimizationRef: PropTypes.object
};

export default HonorRollProgressSummary;

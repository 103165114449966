// below is shared config currently used for FYSS and IPR charts
export const getDefaultSharedBarChartConfig = ({formatter, height}) => ({
  maintainAspectRatio: false,
  height,
  plugins: {
    tooltip: {
      enabled: true,
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          label += Math.round(context.parsed.y * 100) / 100;
          return label + '%';
        }
      }
    },
    legend: {
      display: false
    },
    datalabels: {
      backgroundColor: 'white',
      anchor: 'end',
      align: 'top',
      offset: 0,
      color: '#505050',
      font: {
        weight: 'bold'
      },
      formatter
    }
  },
  layout: {
    padding: {
      top: 20
    }
  },
  scales: {
    xAxes: {
      grid: {
        display: false
      },
      ticks: {
        font: {
          size: 21,
          family: '"Roboto Slab", Roboto, sans-serif',
          weight: 'bold'
        }
      }
    },
    yAxes: {
      beginAtZero: true,
      min: 0,
      max: 100,
      ticks: {
        stepSize: 20,
        padding: 10,
        labelOffset: -5,
        font: {
          size: 12,
          family: 'Roboto, sans-serif',
          weight: 'bold'
        },
        fontColor: 'rgb(54, 59, 66)',
        callback: function (value) {
          return value + '%';
        }
      },
      grid: {
        color: function (context) {
          if (context.tick.value === 0) {
            return 'rgba(0, 0, 0, 0)';
          }
          return 'rgba(0, 0, 0, 0.2)';
        },
        borderDash: [4, 3],
        drawBorder: false
      }
    }
  }

});

export const getDefaultSharedBarChartData = ({data, labels}) => ({
  labels,
  datasets: [
    {
      data,
      backgroundColor: 'rgba(0, 138, 201, 1)',
      borderColor: 'rgba(0, 138, 201, 1)',
      hoverBackgroundColor: 'rgba(25, 163, 226, 1)',
      hoverBorderColor: 'rgba(25, 163, 226, 1)',
      borderWidth: 1
    }
  ]
});

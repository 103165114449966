import React from 'react';

const AttendedModalContent = () => {
  return (<>
    <div className="cb-padding-bottom-8 cb-font-weight-bold">All Students</div>
    <div className="cb-padding-bottom-8 cb-font-weight-regular">Students who either attend your school/district <span className='cb-font-weight-bold' style={{textDecoration: 'underline'}}>or</span> received instruction in this particular subject at your school/district.</div>

    <div className="cb-padding-bottom-8 cb-font-weight-bold">Attending Students</div>
    <div className="cb-padding-bottom-8 cb-font-weight-regular">Students who attend your school/district, regardless of where they received instruction in this particular subject. Summary reports are based on this same subset of Attending Students.</div>

    <div className="cb-padding-bottom-8 cb-font-weight-bold">Instructed Students</div>
    <div className="cb-padding-bottom-8 cb-font-weight-regular">Students who received instruction in this particular subject at your school/district, regardless of where they attend. This filter excludes, for example, students who self-studied for this exam.</div>

    <div className="cb-padding-bottom-8 cb-font-weight-bold">Students Both Attending & Instructed at your Organization</div>
    <div className="cb-padding-bottom-8 cb-font-weight-regular">Students who attend your school/district <span className='cb-font-weight-bold' style={{textDecoration: 'underline'}}>and</span> received instruction in this particular subject at your school/district. This filter excludes, for example, students who self-studied or attend another school.</div>

  </>);
};

export default AttendedModalContent;

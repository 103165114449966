import { SECTIONS_URI } from '../constants/reports';
import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';

// returns list of subjects for a given org and year
const getReportSectionSelect = ({adminYear, reportName, navSchool, navSubject, sttVal, isDistrictAdmin, isAggregate}) => {
  const url =
  config.apiBaseUrl +
  config.genesisUri +
  SECTIONS_URI;

  const params = {
    sttVal,
    reportName,
    adminYear,
    navSubject
  };

  if (isDistrictAdmin && !isAggregate) {
    params.navSchool = navSchool;
  }

  return axiosInstance.get(url, { params });
};

export default getReportSectionSelect;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DetailTable from '../../common/detailTable';
import { getComparablesData } from './dataParser';
import ExpandIcon from '../../common/expandIcon';
import { GROUP_TYPE } from '../utils';

const TABLE_ID = 'ssr_comparables_table';

const getRowAttributes = (row, summaryData, isGroupExpanded) => {
  // if there are no collapsable rows, do not include aria attributes
  if ((!row.isSectionRow && row.card_caption !== GROUP_TYPE) || !summaryData) {
    return [];
  }

  const ariaExpanded = [];
  if (row.card_caption === GROUP_TYPE) {
    ariaExpanded.push(isGroupExpanded ? ['aria-expanded', 'true'] : ['aria-expanded', 'false']);
  }

  const tabIndex = [];
  if (!row.isSectionRow) {
    tabIndex.push(['tabIndex', '0']);
  }

  const setSize = !row.isSectionRow ? 1 : summaryData.reduce((acc, i) => {
    if (i.card_caption === '') {
      acc++;
    }
    return acc;
  }, 0);
  const setPosition = !row.isSectionRow ? 1 : summaryData.findIndex(i => i.table_caption === row.table_caption);
  const level = !row.isSectionRow ? 1 : 2;

  const ariaPosInSet = [['aria-posinset', setPosition]];
  const ariaSetSize = [['aria-setsize', setSize]];
  return [...ariaExpanded, ...tabIndex, ...ariaPosInSet, ...ariaSetSize, ['role', 'row'], ['aria-level', level]];
};

const SsrComparablesSection = ({summaryData}) => {

  const [isGroupExpanded, setIsGroupExpanded] = useState(true);

  const columns = [
    { title: 'Comparable groups',
      field: 'group',
      className: 'cb-font-weight-bold',
      attributes: [['role', 'rowheader']]
    },
    { title: 'Total Students',
      field: 'formattedTotal',
      attributes: [['role', 'gridcell']] },
    { title: 'Score 1',
      field: 'score1',
      attributes: [['role', 'gridcell']] },
    { title: 'Score 2',
      field: 'score2',
      attributes: [['role', 'gridcell']] },
    { title: 'Score 3',
      field: 'score3',
      attributes: [['role', 'gridcell']] },
    { title: 'Score 4',
      field: 'score4',
      attributes: [['role', 'gridcell']] },
    { title: 'Score 5',
      field: 'score5',
      attributes: [['role', 'gridcell']] },
    { title: 'Mean score',
      field: 'mean',
      attributes: [['role', 'gridcell']] }
  ];

  const formattedRowData = getComparablesData(summaryData, isGroupExpanded);

  return (
    <div className='cb-gray5-bg' id='ssrComparablesSection'>
      <div className='container cb-padding-bottom-16 cb-padding-top-32'>
        <h5 className='cb-margin-left-16'>Score Distributions with Comparables</h5>
        <div className='cb-margin-16 cb-white-bg' id='ssrComparablesTableContainer'>
          <DetailTable
            tableId={TABLE_ID}
            tableAttributesFunc={() => [['role', 'treegrid']]}
            rowAttributesFunc={row => {
              return getRowAttributes(row, summaryData, isGroupExpanded);
            }}
            rowClassNameFunc={r => r.className}
            columns={columns}
            data={formattedRowData.map((i, index) => {
              return {
                ...i,
                group: i.card_caption === GROUP_TYPE ? (<div className='row cb-padding-left-16' style={{alignItems: 'center'}}>
                  <ExpandIcon clickHandler={() => {
                    setIsGroupExpanded(!isGroupExpanded);
                  }} text={i.table_caption} id={`ssr_expand_${index}`} isExpanded={isGroupExpanded} />{i.table_caption}
                </div>) : i.titleCellValue
              };
            })}
            responsive
          />
        </div>
      </div>
    </div>);
};

SsrComparablesSection.propTypes = {
  summaryData: PropTypes.array
};

export default SsrComparablesSection;

import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';

// returns report list for current request
const getReportList = (params) => {
  const url = `${config.apiBaseUrl}${config.genesisUri}reportList`;
  return axiosInstance.get(url, { params });
};

export default getReportList;

import React from 'react';
import PropTypes from 'prop-types';
import { AccessAwardTooltip } from './tooltips';
import accessAwardBadge from '../images/accessAward.png';
import { ACCESS_AWARD_TITLE, getAccessAwardText } from '../utils';

const HonorRollAccessAwardSection = ({year, school, tooltipId}) => {

  return (
    <div className='row flex-nowrap cb-padding-32 cb-blue2-tint-2' >
      <div>
        <img className='display-sm-none-only display-xs-none-only' src={accessAwardBadge} alt={ACCESS_AWARD_TITLE} style={{width: '150px', height: '150px'}}></img>
        <img className='display-md-none' src={accessAwardBadge} alt={ACCESS_AWARD_TITLE} style={{width: '110px', height: '110px'}}></img>
      </div>

      <div className='cb-padding-left-32'>
        <div className='row'>
          <h6 className='cb-margin-right-8 cb-blue1-color'>{ACCESS_AWARD_TITLE}</h6>
          <AccessAwardTooltip tooltipId={tooltipId} />
        </div>
        <div className='row cb-margin-top-8'>{getAccessAwardText({year, school})}</div>
      </div>
    </div>);
};
HonorRollAccessAwardSection.propTypes = {
  school: PropTypes.string,
  year: PropTypes.number,
  tooltipId: PropTypes.string
};

export default HonorRollAccessAwardSection;

import React, { useState, useContext, useEffect, Fragment } from 'react';
import './orgSelection.css';
import ShowError from '../ap-osre-error-handling/ErrorBoundary';
import HomeSchoolError from '../../components/ap-osre-error-handling/errorHomeSchool';
import ErrorCaRole from '../../components/ap-osre-error-handling/noCourseAuditRole';
import NoAccessOrg from '../../components/ap-osre-error-handling/noAccessOrg';
import ClosedOrg from '../../components/ap-osre-error-handling/closedOrg';
import {isHomeSchoolOrg} from '../../utils/homeSchool';
import DataSetup from '../ap-osre-navigation/DataSetup';
import Spinner from '../common/loader/spinner';
import RegionalSearch from '../ap-osre-regional-users/regionalUserSearchPage';
import {isRegionalUser} from '../../utils/regionalUtil.js';
import NoCourseAuth from '../ap-osre-error-handling/noCourseAuth';
import NoCourseAuthContainer from '../ap-osre-error-handling/noCourseAuthContainer';
import analytics from '../../utils/analytics';
import MediaQuery from 'react-responsive';
import PageTitle from '../../components/common/PageTitle';
import '../../App.css';
import { AppContext } from '../../AppContext';
import { YellowButton, Select } from '@cb/apricot-react';

const SelectOrg = () => {
  const [enableContinue, setEnableContinue] = useState(false);
  const [showRegionalSearch, setShowRegionalSearch] = useState(false);
  const [showOrgSelection, setShowOrgSelection] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(undefined);
  const [searchedOrg, setSearchedOrg] = useState(undefined);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingHS, setIsLoadingHS] = useState(false);
  const [notCourseAuthorized, setNotCourseAuthorized] = useState(false);
  const [showBlockHomeSchool, setShowBlockHomeSchool] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [orgError, setOrgError] = useState(false);
  const [orgClosedError, setClosedOrgError] = useState(false);
  const [selectVal, setSelectVal] = useState('');
  const appContext = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!appContext.isCustomerService) {
      analytics.reportCurrentView('select-org', appContext.roleCd);
    }

    if (appContext.singleOrgUser) {
      setSelectedOrg({
        orgId: Number(appContext.singleOrgId),
        orgName: appContext.singleOrgName
      });
      if (isRegionalUser(appContext.singleOrgId)) {
        setShowRegionalSearch(true);
      } else {
        setIsLoadingDashboard(true);
      }
    } else {
      setShowOrgSelection(true);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOrgChange = evnt => {
    setIsLoadingDashboard(false);
    setSelectVal(evnt);
    if (evnt) {
      setSelectedOrg({
        orgId: Number(evnt),
        orgName: appContext.orgs.find(item => item.orgId === evnt).orgName
      });
      setEnableContinue(true);
    } else {
      setEnableContinue(false);
    }
  };

  const loadDataForSelectedOrg = () => {
    setEnableContinue(false);
    setSelectVal('');
    setShowRegionalSearch(false);
    setShowOrgSelection(false);
    setShowBlockHomeSchool(false);
    setNotCourseAuthorized(false);
    setRoleError(false);
    setOrgError(false);
    setClosedOrgError(false);
    const orgId = selectedOrg && selectedOrg.orgId ? Number(selectedOrg.orgId) : undefined;
    if (isHomeSchoolOrg(orgId, appContext.orgHs)) {
      setIsLoadingHS(true);
      setTimeout(() => {
        setIsLoadingHS(false);
      }, 750);
      setSelectedOrg(undefined);
      setSearchedOrg(undefined);
      setShowBlockHomeSchool(true);
    } else {
      if (isRegionalUser(orgId)) {
        setShowRegionalSearch(true);
      } else {
        setIsLoadingDashboard(true);
      }
    }
  };

  return (
    <Fragment>
      {
        !appContext.orgs ? (
          <ShowError error={{error: 'No Orgs Exist'}}/>
        ) : (
          isLoading ? <Spinner /> : (
            showRegionalSearch ? (
              <RegionalSearch
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg}
                searchedOrg={searchedOrg}
                setSearchedOrg={setSearchedOrg}
                showOrgSelection={showOrgSelection}
                showRegionalSearch={showRegionalSearch}
                setShowOrgSelection={setShowOrgSelection}
                setShowRegionalSearch={setShowRegionalSearch}
                setIsLoadingDashboard={setIsLoadingDashboard}
                setNotCourseAuthorized={setNotCourseAuthorized}
              />
            ) : (
              isLoadingDashboard && (selectedOrg || searchedOrg) ? (
                <DataSetup
                  selectedOrg={selectedOrg}
                  setSelectedOrg={setSelectedOrg}
                  searchedOrg={searchedOrg}
                  setSearchedOrg={setSearchedOrg}
                  showOrgSelection={showOrgSelection}
                  showRegionalSearch={showRegionalSearch}
                  setShowOrgSelection={setShowOrgSelection}
                  setShowRegionalSearch={setShowRegionalSearch}
                  setNotCourseAuthorized={setNotCourseAuthorized}
                  setRoleError={setRoleError}
                  setOrgError={setOrgError}
                  setClosedOrgError={setClosedOrgError}
                />
              ) : (
                showBlockHomeSchool && isLoadingHS ? (
                  <div className='container'>
                    <PageTitle />
                    <Spinner />
                  </div>
                ) : (
                  <div className='container'>
                    <PageTitle />
                    {showBlockHomeSchool ? <HomeSchoolError showOrg /> : (
                      notCourseAuthorized ? (!appContext.singleOrgUser ? <NoCourseAuth /> : <NoCourseAuthContainer />) : (
                        roleError ? (appContext.singleOrgUser ? <ErrorCaRole showLogout /> : <ErrorCaRole showOrg />)
                          : (orgError ? (appContext.singleOrgUser ? <NoAccessOrg showLogout /> : <NoAccessOrg showOrg />)
                            : (orgClosedError ? (appContext.singleOrgUser ? <ClosedOrg showLogout /> : <ClosedOrg showOrg />) : null))
                      ))}
                    {!appContext.singleOrgUser ? (
                      <div className="row">
                        <div className="col-lg-6 col-sm-12">
                          <div className = "org-selection">
                            <Select id="selectOrg" name="selectOrg" value={selectVal} onChange={onOrgChange}
                              labelNoValue="Select an Organization" ariaLabel="Select an Organization"
                              label={<div className="space-above-title">
                                <h2 className = "select-org-heading">Select an Organization</h2>
                                <MediaQuery query="(min-width: 769px)">
                                  <div className = "normal-font-weight">Choose the organization you would like to view AP score reports for.</div>
                                </MediaQuery>
                              </div>}
                              labelClassName="space-below-org-label"
                              values=
                                {
                                  appContext.orgs.map(item => (
                                    {
                                      label: item.orgName,
                                      value: item.orgId
                                    }
                                  ))
                                }
                            />
                            {
                              enableContinue
                                ? <div className ="continue-button-container">
                                  <YellowButton className="general-continue-button" onClick={loadDataForSelectedOrg}>Continue</YellowButton>
                                </div>
                                : null
                            }
                          </div>
                        </div>
                      </div>
                    ) : null
                    }
                  </div>
                )
              )
            )
          )
        )
      }
    </Fragment>
  );
};

export default SelectOrg;

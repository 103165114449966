import React from 'react';
import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import cbLogo from './cbLogo.png';
import { H3_FONT_SIZE } from '../constants';

// Create styles
const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingBottom: 25
  },
  logoImage: {
    height: 34,
    width: 75
  },
  siteTitleText: {
    fontSize: H3_FONT_SIZE,
    fontFamily: 'Roboto-Bold',
    color: 'black',
    paddingRight: 5
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

// Create Document Component
const ReportHeaderPdf = () => (
  <View style={styles.container}>
    <View style={styles.row}>
      <Image src={cbLogo} style={styles.logoImage} />
      <Text style={styles.siteTitleText}>AP Score Reports for Educators</Text>
    </View>
  </View>
);

export default ReportHeaderPdf;

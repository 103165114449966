/* eslint-disable camelcase */
import { Modal, CircularButton } from '@cb/apricot-react';
import '@cb/apricot/CBBase';
import PropTypes from 'prop-types';
import React from 'react';
import {formatPercentageValue, formatCountValue} from '../../../utils/stringUtils';
import DetailTable from '../common/detailTable';
import DownloadModal from '../common/downloads/downloadModal';
import '../shared.css';
import SubHeader from '../subHeader';
import './index.css';
import { renameKeys } from 'ramda-adjunct';

const formatSummaryData = ({ summaryData }) => {

  // we want an array of 3 items, [{# exams data}, {% total exams}, {# students}]
  const formatted = [];
  for (const { lbltype, ap_exam_score, label, total_exams_per_score } of summaryData) {
    // if undefined, initialize this row obj
    if (!formatted[lbltype - 1]) {
      formatted[lbltype - 1] = { label, totalExams: 0 };
    }

    const row = formatted[lbltype - 1];

    const count = +total_exams_per_score || '';
    if (label === `Percentage of Total Exams`) {
      row[`${ap_exam_score}`] = count ? `${formatPercentageValue(count * 100, 0)}%` : '';
      row.totalExams = `100%`;
    } else if (label === `Number of AP Students`) {
      row[`${ap_exam_score}`] = formatCountValue(count);
      row.totalExams = null;
    } else {
      row[`${ap_exam_score}`] = formatCountValue(count);
      row.totalExams += +count;
    }
  }

  // add commas to total exams for {# exams data} row
  formatted[0].totalExams = formatCountValue(formatted[0].totalExams);

  return formatted;
};

// @TODO - api is returning a typo with "detialData" field rather than "detailData"
const formatDetailData = ({ detialData }) => {
  const sortedObject = detialData.reduce((acc, { exam_sort_order, ap_exam_score, exam_name, total_exams_per_score }) => {
    const subject = acc[exam_sort_order] || { label: exam_name, totalExams: 0 };
    const count = parseInt(total_exams_per_score);
    subject[`${ap_exam_score}`] = formatCountValue(count);
    subject.totalExams += count;
    acc[exam_sort_order] = subject;
    return acc;
  }, {});
  return Object.keys(sortedObject).map(key => {
    // add commas to total exams value for this subject row, sicne we couldn't do this during initial tallying
    sortedObject[key].totalExams = formatCountValue(sortedObject[key].totalExams);
    return sortedObject[key];
  });
};

const CurrentYearScoreSummary = ({ apiData, year, sttVal, isDistrictAdmin, selectedOrgId, isAggregate }) => {
  const downloadModalTrigger = 'openDownloadModal';
  const totalViewModalTrigger = 'openTotalViewModal';
  const totalStudentHeader = apiData.totalApStudentData[0].reportable_student_count;

  // Set data for csv headers
  const csvHeader = [
    'Subject Totals',
    '1',
    '2',
    '3',
    '4',
    '5',
    'Total Exams'
  ];

  function HeaderComponent(props) {
    return (
      <div className="box">
        <div>{props.title}</div>
        <CircularButton icon="question" light small className="cb-margin-right-4 tooltip-icon" title="Number of AP Students Tooltip" label="Number of AP Students Tooltip" aria-haspopup="dialog" tabIndex="0" id={props.totalViewModalTrigger} />
      </div>);
  }

  HeaderComponent.propTypes = {
    title: PropTypes.string,
    totalViewModalTrigger: PropTypes.string
  };

  const topTableColumns = [
    {
      title: isAggregate ? 'District Totals for this View' : 'Totals for this view',
      field: 'label',
      className: 'text-left-align title-witdth',
      // eslint-disable-next-line react/display-name, react/prop-types
      headerComponent: ({ title }) => (<HeaderComponent totalViewModalTrigger={totalViewModalTrigger} title={title}></HeaderComponent>)
    },
    { title: '1', field: '1', className: 'text-right-align score-witdth' },
    { title: '2', field: '2', className: 'text-right-align score-witdth' },
    { title: '3', field: '3', className: 'text-right-align score-witdth' },
    { title: '4', field: '4', className: 'text-right-align score-witdth' },
    { title: '5', field: '5', className: 'text-right-align score-witdth' },
    { title: 'Total Exams', field: 'totalExams', className: 'text-right-align score-witdth' }
  ];

  const bottomTableColumns = [
    { title: 'Subject Totals', field: 'label', className: 'text-left-align title-witdth' },
    { title: '1', field: '1', className: 'text-right-align score-witdth' },
    { title: '2', field: '2', className: 'text-right-align score-witdth' },
    { title: '3', field: '3', className: 'text-right-align score-witdth' },
    { title: '4', field: '4', className: 'text-right-align score-witdth' },
    { title: '5', field: '5', className: 'text-right-align score-witdth' },
    { title: 'Total Exams', field: 'totalExams', className: 'text-right-align score-witdth' }
  ];

  const formattedSummaryData = formatSummaryData(apiData);
  const formattedDetailData = formatDetailData(apiData);
  const formattedCsvData = formattedDetailData.map(i => renameKeys({ label: 'Subject Totals', totalExams: 'Total Exams'})(i));
  const formattedTotalStudentHeader = parseInt(totalStudentHeader).toLocaleString('en-US');

  let csvFilterText;
  if (isAggregate) {
    csvFilterText = 'All Schools; All Subjects; No Filters';
  } else if (isDistrictAdmin) {
    csvFilterText = 'Selected School; All Subjects; No Filters';
  } else {
    csvFilterText = 'All Subjects; No Filters';
  }

  return (<>
    <Modal title="Number of AP Students" trigger={totalViewModalTrigger} withFooter={false}>
      <p>This is a count of the number of <span className="bold-and-underline">unique</span> students who earned this score on <span className="bold-and-underline">at least one exam in this administration</span>. This may be less than the Number of Exams per score listed above as students may choose to take more than one exam in a given administration. For example, if a student took three exams in a given administration and scored a 5 on one exam, and a 4 on the remaining two exams they would be counted once in the number of AP students who earned a 5, and only once in the number of AP students who earned a 4. </p>
    </Modal>
    <div>
      <div className={'cb-panel-summary'}>
        <SubHeader showDownload={true} downloadModalTrigger={downloadModalTrigger} />
        <DownloadModal
          title={isAggregate ? `Current Year Score Summary, Aggregated for Districts ${year}` : `Current Year Score Summary ${year}`}
          csvFilterText={csvFilterText}
          csvData={formattedCsvData}
          filename={isAggregate ? `AP District Current Year Score Summary ${year}` : `AP School Current Year Score Summary ${year}`}
          csvHeader={csvHeader}
          org={selectedOrgId}
          auditTitle={isAggregate ? 'CurrentYearScoreSummaryDAExport' : 'CurrentYearScoreSummaryExport'}
          sttVal={sttVal}
          year={year}
          trigger={downloadModalTrigger}
          isAggregate={isAggregate}
          isDistrictAdmin={isDistrictAdmin}
        />

        <div
          className="cb-scrollbar-container content-gutter cb-padding-16 cb-padding-bottom-48"
        >
          <h5>Total AP Students in Your {isAggregate ? 'District' : 'School'}: {formattedTotalStudentHeader}</h5>
          <div className={'cb-margin-top-8'} style={{ backgroundColor: 'white' }}>
            <DetailTable data={formattedSummaryData} columns={topTableColumns} striped={false} responsive />
          </div>

        </div>

      </div>
      <div className="content-gutter cb-padding-16">
        <DetailTable data={formattedDetailData} columns={bottomTableColumns} striped={false} responsive />
      </div>
    </div>
  </>

  );
};

CurrentYearScoreSummary.propTypes = {
  apiData: PropTypes.object,
  year: PropTypes.number,
  sttVal: PropTypes.string,
  isDistrictAdmin: PropTypes.bool,
  isAggregate: PropTypes.bool,
  selectedOrgId: PropTypes.number
};

export default CurrentYearScoreSummary;

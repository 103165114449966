import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import '../shared.css';
import ScholarSummary from './scholarSummary';
import ScholarRosterSection from './scholarRosterSection';
import {formatCountValue} from '../../../utils/stringUtils';
import SrFilterSection from './filterSection';
import SubHeader from '../subHeader';
import { NO_DATA_FILTERED_ERROR_MESSAGE, REPORT_ERROR_MESSAGE } from '../../../constants/reports';
import ReportError from '../../ap-osre-error-handling/ReportError';
import DownloadModal from '../common/downloads/downloadModal';
import Spinner from '../../common/loader/spinner';
import {getScholarRosterData} from '../../../api';
import { formatCsvData, formatRosterData, getRosterCsvColumns } from './utils';

const downloadModalTrigger = 'openDownloadModal';

const getCSVFilterText = ({isDistrictAdmin}) => isDistrictAdmin ? 'Selected School; All Scholars; No Filters' : 'All Scholars; No Filters';

const ScholarRoster = ({apiData, year, isDistrictAdmin, reportTitle, sttVal, selectedOrgId}) => {

  const [totalStudents, setTotalStudents] = useState(0);
  const [currentGradeLevels, setCurrentGradeLevels] = useState([]);
  const [currentScholarAwards, setCurrentScholarAwards] = useState([]);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [rosterData, setRosterData] = useState(apiData.rosterData);
  const [currentAppliedFilter, setCurrentAppliedFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [isReportError, setIsReportError] = useState(false);
  const [summaryData, setSummaryData] = useState(apiData.summaryData);
  const [avgScoreValue, setAvgScoreValue] = useState(apiData.avgScoreValue);
  const didMountRef = useRef(false);

  const getApiValue = selected => selected.length > 0 ? selected.map(i => i.value).join(',') : undefined;

  useEffect(() => {
    let isCurrent = true;
    const getAppliedFilterData = async () => {

      if (didMountRef.current) {
        if (currentAppliedFilter.length > 0) {
          setIsLoading(true);
          setNoData(false);
          try {
            const res = await getScholarRosterData({
              sttVal,
              navYear: year,
              navSchool: selectedOrgId,
              fltGrade: getApiValue(currentGradeLevels),
              fltScholarAwards: getApiValue(currentScholarAwards),
              isDistrictAdmin
            });
            if (isCurrent) {
              const {noData, summaryData, avgScoreValue} = res.data;
              if (noData) {
                setNoData(true);
              } else {
                setSummaryData(summaryData);
                setAvgScoreValue(avgScoreValue);
              }
              setIsLoading(false);
            }
          } catch (error) {
            setIsLoading(false);
            setIsReportError(true);
          }
        } else {
          setIsLoading(true);
          setNoData(false);
          setSummaryData(apiData.summaryData);
          setAvgScoreValue(apiData.avgScoreValue);
          // timeout to briefly display spinner
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      }
      didMountRef.current = true;

    };
    getAppliedFilterData();
    return () => {
      isCurrent = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAppliedFilter]);

  const {data: rawData, subjectColumns } = formatRosterData(apiData?.rosterData);
  const csvData = formatCsvData(rawData);

  return (
    <>
      {isLoading ? (<Spinner />)
        : isReportError ? (<ReportError message={REPORT_ERROR_MESSAGE} />)
          : (<>
            <SubHeader showDownload={true} showFilterButton={!showFilterSection} showHideFilterButton={showFilterSection} onFilterPress={() => setShowFilterSection(!showFilterSection)} downloadModalTrigger={downloadModalTrigger}>
            </SubHeader>
            <DownloadModal
              isAggregate={false}
              isDistrictAdmin={isDistrictAdmin}
              sttVal={sttVal}
              year={year}
              containerId='sr'
              auditTitle={'ScholarRosterExport'}
              trigger={downloadModalTrigger}
              title={<div style={{fontSize: 26}}>
                {`${reportTitle} ${year}`}
              </div>}
              filename={`AP School Scholar Roster ${year}`}
              csvFilterText={getCSVFilterText({isDistrictAdmin})}
              csvHeader={getRosterCsvColumns(subjectColumns)}
              csvData={csvData}
            />
            <SrFilterSection
              scholarAwardsFilterData={apiData.scholarAwardsFilter}
              gradeFilterData={apiData.gradeFilter}
              show={showFilterSection}
              rosterData={apiData.rosterData}
              setRosterData={setRosterData}
              setIsLoading={setIsLoading}
              setShowFilterSection={setShowFilterSection}
              currentGrades={currentGradeLevels}
              currentScholarAwards={currentScholarAwards}
              currentApplied={currentAppliedFilter}

              setCurrentGrades={setCurrentGradeLevels}
              setCurrentScholarAwards={setCurrentScholarAwards}
              setCurrentAppliedFilter={setCurrentAppliedFilter}
            />
            { noData ? <ReportError message={NO_DATA_FILTERED_ERROR_MESSAGE} /> : (<>
              <div className={'cb-panel-summary'}>
                <div
                  className="cb-scrollbar-container content-gutter cb-padding-bottom-48"
                >
                  <h5 className={'cb-padding-top-24'}>
                    {`Scholar Summary (Total Students: ${formatCountValue(totalStudents)}; Average Score: ${avgScoreValue[0].val})`}
                  </h5>
                  <div className={'cb-margin-top-8'} style={{ backgroundColor: 'white' }}>
                    <ScholarSummary summaryData={summaryData} />
                  </div>
                </div>
              </div>
              <div
                className="cb-scrollbar-container content-gutter cb-padding-bottom-48"
              >
                <h5>Scholar Roster</h5>
                <ScholarRosterSection rosterData={rosterData} setTotalStudents={setTotalStudents}
                />
              </div>
            </>
            )}
          </>)
      }
    </>
  );
};

ScholarRoster.propTypes = {
  apiData: PropTypes.object,
  summaryData: PropTypes.array,
  year: PropTypes.number,
  currentYear: PropTypes.number,
  isDistrictAdmin: PropTypes.bool,
  reportTitle: PropTypes.string,
  sttVal: PropTypes.string,
  selectedOrgId: PropTypes.number,
  selectedGradeLevels: PropTypes.array,
  selectedScholarAwards: PropTypes.array,
  appliedFilters: PropTypes.array,
  queryDataFromReport: PropTypes.func,
  reportHandledNoData: PropTypes.bool
};

export default ScholarRoster;

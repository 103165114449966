import React from 'react';

export const sectionModalContent = <>
  <p className="fw4 ffsans f12">
    <strong>Teachers</strong>
    &nbsp;- The section(s) listed reflect the class sections created in AP
    Registration and Ordering to which you are assigned as a teacher. You may
    choose to run this report for an individual section, across all your
    sections, or for a subset of your sections.
  </p>
  <p className="fw4 ffsans f12">
    <strong>School and District Administrators</strong>
    &nbsp;- The section(s) listed reflect the class sections created in AP
    Registration and Ordering for your school for the selected subject. Each
    teacher associated to a section is also listed, where applicable. You may
    choose to run this report for an individual teacher, across all your
    school&apos;s sections, for a subset of sections, or for an individual section.
  </p>
  <ul>
    <li className="fw4 ffsans f12">
      <strong>No Teacher Assigned Sections</strong>
        &nbsp;- visible to administrators. These are your school&apos;s Exam Only sections
        which include students who did not receive instruction at your school
        in this subject but for whom you submitted an exam order. These may
        include students who received instruction from an online provider or
        self-studied.
    </li>
    <li className="fw4 ffsans f12">
      <strong>No Section/Exam Only Sections</strong>
        &nbsp;- visible to administrators. These include your school&apos;s students for
        whom you did not instruct nor submit an exam order for. These may
        include students who received instruction from an online provider or
        self-studied.
    </li>
  </ul>
  <br />
  <p className="fw4 ffsans f12">
    <strong>2019 and prior year administrations</strong>
    &nbsp;- Note that you may see one or more of these options, depending on whether
    your school used sections or not -
  </p>
  <ul type="disc">
    <li className="fw4 ffsans f12">
      <strong>All Students</strong>
        &nbsp;- If your school did not use sections for this subject, this includes
        all students who attended your school. If you are an online provider,
        this includes all students in this subject to whom you provided
        instruction.
    </li>
    <li className="fw4 ffsans f12">
      <strong>Section Not Designated</strong>
        &nbsp;- If your school used sections for this subject, this group includes
        only those students that did not indicate a section on their answer
        sheet for this subject.
    </li>
    <li className="fw4 ffsans f12">
      <strong>No Section/Exam Only Sections</strong>
        &nbsp;- Includes all students who attended your school but did not receive
        instruction at your school in this subject. This may be students who
        received instruction from an online provider or self-studied.
    </li>
  </ul>
  <br />
  <p className="fw4 ffsans f12">
    <a href="https://apcentral.collegeboard.org/scores/instructions-tips/class-sections"
      target="_blank" rel="noopener noreferrer"><u>Learn more about class sections here.</u></a>
  </p>
</>;

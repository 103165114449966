import { SCHOLAR_SUMMARY_URI } from '../../constants/reports';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';

// returns report list for current request
const getScholarSummaryData = ({ sttVal, navYear, awardFilter, gradeFilter }) => {
  const url =
          config.apiBaseUrl +
          config.genesisUri +
          SCHOLAR_SUMMARY_URI;

  const params = {
    sttVal,
    navYear,
    awardFilter,
    gradeFilter
  };

  return axiosInstance.get(url, { params });
};

export default getScholarSummaryData;

/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import DetailTable from '../common/detailTable';
import {formatPercentageValue, formatCountValue} from '../../../utils/stringUtils';
import { tabCheck } from '../common/utils';

const formatSummaryData = (data) => {
  const scholarAwardColumns = [];
  const formattedData = data.reduce((acc, {nme, ap_scholar_award_descr, ap_scholar_award_cd, val}) => {
    const row = acc[nme] || {};
    if (nme === 'Number of Scholars') {
      row[ap_scholar_award_descr] = formatCountValue(val);
      scholarAwardColumns.push({ap_scholar_award_cd, ap_scholar_award_descr});
    } else {
      row[ap_scholar_award_descr] = formatPercentageValue(val, 2);
    }
    acc[nme] = {...row, nme};
    return acc;
  }, {});
  return {formattedData: Object.keys(formattedData).map(key => formattedData[key]), scholarAwardColumns};
};

const ScholarSummary = ({summaryData}) => {
  const {formattedData: formattedSummaryData, scholarAwardColumns } = formatSummaryData(summaryData);
  const sortedScholarAwardCols = scholarAwardColumns.sort((a, b) => a.ap_scholar_award_cd - b.ap_scholar_award_cd);

  const summaryTableCols = [
    {
      title: ' ',
      field: 'nme',
      rowHeader: true,
      sticky: true
    },
    ...sortedScholarAwardCols.map(award => ({title: award.ap_scholar_award_descr, field: award.ap_scholar_award_descr, className: 'rightAlignTableCells'}))
  ];
  return (<div style={{ maxHeight: '600px', overflowY: 'auto' }}>
    <DetailTable
      a11yHighlightText=''
      tableId="sr_summary_table"
      columns={summaryTableCols}
      striped={false}
      stickyHeaderParent={true}
      stickyColumn={true}
      data={formattedSummaryData}
      maxHeight={600}
      overflowX={false}
      tabIndex={tabCheck}
    />
  </div>);
};

ScholarSummary.propTypes = {
  summaryData: PropTypes.array
};

export default ScholarSummary;

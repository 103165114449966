import React, { useEffect, useContext } from 'react';
import './errorPages.css';
import GetCourseAuth from './noCourseAuth';
import config from '../../utils/envConfig.js';
import AccountConfig from '../../utils/cbAccountConfig.js';
import { AppContext } from '../../AppContext';
import { NakedButton, Input } from '@cb/apricot-react';

const GetCourseAuthPage = () => {
  const appContext = useContext(AppContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='container'>
      <div className="ap-container-border"></div>
      <div className = "general-error-page-container">
        <div className="row">
          <div className="col-xl-8 col-lg-6 col-sm-12">
            <GetCourseAuth />
            <form name = "submit" method="post" action= {AccountConfig.cbAccountLinks.loginLogoutLink}>
              <Input type="hidden" name="DURL" value= {config.UIURL}></Input>
              <Input type="hidden" name="formState" value="1"></Input>
              <Input type="hidden" name="appId" value={appContext.appId}></Input>
              <NakedButton noPadding className="logout-button" type="submit" name="lv-logout-button">
                    Logout
              </NakedButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetCourseAuthPage;

import {
  EQUITY_AND_EXCELLENCE_REPORT_TITLE,
  EQUITY_AND_EXCELLENCE_REPORT_URI
} from '../../constants/reports';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';

// returns report list for current request
const getEquityAndExcellenceData = ({ sttVal, navSchool, navYear, isDistrictAdmin, isAggregate }) => {

  const url =
    config.apiBaseUrl +
    config.genesisUri +
    EQUITY_AND_EXCELLENCE_REPORT_URI;

  const params = {
    reportName: EQUITY_AND_EXCELLENCE_REPORT_TITLE,
    sttVal,
    navYear
  };

  if (isDistrictAdmin && !isAggregate) {
    params.navSchool = navSchool;
  }

  return axiosInstance.get(url, { params });

};

export default getEquityAndExcellenceData;

import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { getClassOfText, getCongratulationsText, isTier } from '../../utils';
import { LARGE_TEXT_FONT_SIZE } from '../constants';

const styles = StyleSheet.create({
  paddingTop: {
    paddingTop: 5
  },
  font: {
    fontSize: LARGE_TEXT_FONT_SIZE,
    fontFamily: 'Roboto-Bold'
  }
});

const HonorRollCongratulationsHeaderPdf = ({school, overall, year}) => {
  return (
    <View>
      {
        // show congrats message if school achieved at least bronze tier
        isTier(overall)
          ? (<Text style={styles.font}>
            {getCongratulationsText({school, overall, year})}
          </Text>)
          : (<Text style={styles.font}>{school}</Text>)
      }
      <Text style={[styles.paddingTop, styles.font]}>{getClassOfText(year)}</Text>
    </View>
  );
};

HonorRollCongratulationsHeaderPdf.propTypes = {
  year: PropTypes.number,
  school: PropTypes.string,
  overall: PropTypes.string
};

export default HonorRollCongratulationsHeaderPdf;

import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import { AP_CENTRAL_HONOR_ROLL_LINK, CREDIT_TOOLTIP_DESC, CULTURE_TOOLTIP_DESC, ACCESS_AWARD_TITLE, getNotApplicableNoteText, getQualificationNoteText, OPTIMIZATION_TOOLTIP_DESC, SCHOOL_ENROLLMENT_TOOLTIP_DESC, SCHOOL_ENROLLMENT_TOOLTIP_TITLE } from '../../utils';
import { CREDIT, CULTURE, OPTIMIZATION } from '../../constants';
import { SMALL_TEXT_FONT_SIZE } from '../constants';

const styles = StyleSheet.create({
  font: {
    fontSize: SMALL_TEXT_FONT_SIZE,
    fontFamily: 'Roboto',
    paddingTop: 10
  },
  bold: {
    fontFamily: 'Roboto-Bold'
  }
});

const HonorRollFooterNotesPageTwoPdf = ({year, accessAward}) => {
  return (
    <View>
      <Text style={styles.font}>{getQualificationNoteText(year)}<Link src={AP_CENTRAL_HONOR_ROLL_LINK}>AP Central</Link>.</Text>
      <Text style={styles.font}>{getNotApplicableNoteText(year)}</Text>

      <Text style={styles.font}><Text style={styles.bold}>{SCHOOL_ENROLLMENT_TOOLTIP_TITLE}: </Text>{SCHOOL_ENROLLMENT_TOOLTIP_DESC}</Text>
      <Text style={styles.font}><Text style={styles.bold}>{CULTURE}: </Text>{CULTURE_TOOLTIP_DESC}</Text>
      <Text style={styles.font}><Text style={styles.bold}>{CREDIT}: </Text>{CREDIT_TOOLTIP_DESC}</Text>
      <Text style={styles.font}><Text style={styles.bold}>{OPTIMIZATION}: </Text>{OPTIMIZATION_TOOLTIP_DESC}</Text>
      {
        accessAward &&
        <Text style={styles.font}><Text style={styles.bold}>{ACCESS_AWARD_TITLE}: </Text>An additional recognition is given to schools when the percentage of underrepresented and/or low income students accessing the school&apos;s AP Program is the same as, or greater than the percentage in the school&apos;s graduating class. School level enrollment for this award is sourced from the National Center for Education Statistics using the most recent finalized dataset.</Text>
      }
    </View>
  );
};

HonorRollFooterNotesPageTwoPdf.propTypes = {
  year: PropTypes.number,
  accessAward: PropTypes.bool
};

export default HonorRollFooterNotesPageTwoPdf;

import PropTypes from 'prop-types';
import React from 'react';
import './summaryCard.css';
import SummaryCard from '../common/summaryCard';
import { ADDRESS_HEADER, CARD_1_ID, CARD_2_ID, CARD_3_ID, INFO_HEADER, SCHOOL_INFO_HEADER } from './utils';
import IconAndTitle from '../common/iconAndTitle';
import InfoDisplay from './infoDisplay';
import dateUtils from '../../../utils/dateUtils';
const SUMMARY_CARD_MIN_HEIGHT = '220px';

const isInternational = countryCode => countryCode !== 'US';

// exporting for unit testing purposes
export const getAddress = sData => `${sData.street_addr_1 ?? ''}${sData.street_addr_2 ? `${'\n'}${sData.street_addr_2}` : ''}${sData.street_addr_3 ? `${'\n'}${sData.street_addr_3}` : ''}\n${sData.city ?? ''}${sData.state_cd ? `, ${sData.state_cd} ` : ''} ${sData.zip5 ?? ''}`;

// exporting for unit testing purposes
export const getInternationalAddress = sData => `${sData.street_addr_1 ?? ''}${sData.street_addr_2 ? `${'\n'}${sData.street_addr_2}` : ''}${sData.street_addr_3 ? `${'\n'}${sData.street_addr_3}` : ''}\n${sData.city ?? ''}${sData.province ? `, ${sData.province}` : ''}${sData.country_iso_cd ? `, ${sData.country_iso_cd}` : ''}${sData.international_postal_code ? `, ${sData.international_postal_code}` : ''}`;

const SummaryDisplay = ({ summaryData }) => {
  if (!summaryData) {
    return <></>;
  }

  const dateOfBirth = dateUtils.getDisplayDate(summaryData.birth_dt);

  const addressString = isInternational(summaryData.country_iso_cd) ? getInternationalAddress(summaryData) : getAddress(summaryData);

  return (
    <div
      id="summaryCardContainer"
      className="cb-panel-blue container-fluid"
    >
      <div className="content-gutter-cards summary-card-container">
        <SummaryCard selector={CARD_1_ID} minHeight={SUMMARY_CARD_MIN_HEIGHT}>
          <IconAndTitle title={INFO_HEADER} icon='user' />
          <InfoDisplay keyId={`${CARD_1_ID}_info`} values={[
            {label: 'AP Number / AP ID', value: summaryData.ap_number},
            {label: 'Grade Level', value: summaryData.grade_level},
            {label: 'Gender', value: summaryData.gender},
            {label: 'Date of Birth', value: dateOfBirth}
          ]} />
        </SummaryCard>
        <SummaryCard selector={CARD_2_ID} minHeight={SUMMARY_CARD_MIN_HEIGHT}>
          <IconAndTitle title={ADDRESS_HEADER} icon='location' />
          <InfoDisplay keyId={`${CARD_2_ID}_info`} values={[
            {value: addressString}
          ]} />
        </SummaryCard>
        <SummaryCard selector={CARD_3_ID} minHeight={SUMMARY_CARD_MIN_HEIGHT}>
          <IconAndTitle title={SCHOOL_INFO_HEADER} icon='check' />
          <InfoDisplay keyId={`${CARD_3_ID}_info`} values={[
            {label: 'School', value: summaryData.school_name},
            {label: 'Designated College', value: summaryData.college ?? 'None'}
          ]} />
        </SummaryCard>
      </div>
    </div>

  );
};

SummaryDisplay.propTypes = {
  summaryData: PropTypes.object
};

export default SummaryDisplay;

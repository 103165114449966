import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { H4_FONT_SIZE } from '../constants';

// Create styles
const styles = StyleSheet.create({
  titleContainer: {
    paddingTop: 15
  },
  titleFont: {
    fontSize: H4_FONT_SIZE,
    fontFamily: 'Roboto-Bold'
  }
});

const PageTitle = ({title}) => (
  <View style={styles.titleContainer}>
    <Text style={styles.titleFont}>{title}</Text>
  </View>
);

PageTitle.propTypes = {
  title: PropTypes.string
};

export default PageTitle;

import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import { AP_CENTRAL_HONOR_ROLL_LINK, getQualificationNoteText } from '../../utils';
import { MEDIUM_TEXT_FONT_SIZE } from '../constants';

const styles = StyleSheet.create({
  font: {
    fontSize: MEDIUM_TEXT_FONT_SIZE,
    fontFamily: 'Roboto',
    paddingTop: 10
  }
});

const HonorRollFooterNotesPageOnePdf = ({year}) => {
  return (
    <View>
      <Text style={styles.font}>{getQualificationNoteText(year)}<Link src={AP_CENTRAL_HONOR_ROLL_LINK}>AP Central</Link>.</Text>
    </View>
  );
};

HonorRollFooterNotesPageOnePdf.propTypes = {
  year: PropTypes.number
};

export default HonorRollFooterNotesPageOnePdf;

import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import DetailTable from '../common/detailTable';
import { Pager } from '@cb/apricot-react';

const PAGE_SIZE = 10;
const ScholarSummaryDistrictTable = ({tableData, columnData}) => {

  const [currentScholarPage, setCurrentScholarPage] = useState(0);
  const [scholarPageCount, setScholarPageCount] = useState(tableData.length > 0 ? Math.ceil(tableData.length / PAGE_SIZE) : 0);
  const [paginatedScholarData, setPaginatedScholarData] = useState(tableData.slice(currentScholarPage * PAGE_SIZE, currentScholarPage * PAGE_SIZE + PAGE_SIZE));
  useEffect(() => {
    setCurrentScholarPage(0);
    setScholarPageCount(tableData.length > 0 ? Math.ceil(tableData.length / PAGE_SIZE) : 0);
    setPaginatedScholarData(tableData.slice(0 * PAGE_SIZE, 0 * PAGE_SIZE + PAGE_SIZE));
  }, [tableData]);
  return (<div>
    <DetailTable
    // noStretch={true}
      rowHighlighted={({highlighted}) => highlighted}
      columns={columnData}
      data={paginatedScholarData}
      responsive={true}
      tableId="scholar_summary_table"
    />

    {tableData.length > PAGE_SIZE && <Pager
      current={currentScholarPage + 1}
      max={scholarPageCount}
      onPageChange={page => {
        setCurrentScholarPage(page - 1);
        const thisPageData = tableData.slice((page - 1) * PAGE_SIZE, (page - 1) * PAGE_SIZE + PAGE_SIZE);
        setPaginatedScholarData(thisPageData);
      }}
    />}
  </div>);
};

ScholarSummaryDistrictTable.propTypes = {
  tableData: PropTypes.array,
  columnData: PropTypes.array,
  filters: PropTypes.array
};

export default ScholarSummaryDistrictTable;

import {
  ORG_SCORE_ROSTER_REPORT_URI
} from '../../constants/reports';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';
import { decodeUnZipApiResponse } from './utils';

// returns report list for current request
const getOrgScoreRosterData = async ({ sttVal, navYear, isDistrictAdmin, isAggregate,
  fltSubject, fltInstruction, fltScore, fltGrade, fltScholarAwards, navSchool, csv }) => {

  const url =
        config.apiBaseUrl +
        config.genesisUri +
        ORG_SCORE_ROSTER_REPORT_URI;

  const params = {
    sttVal,
    navYear,
    csv,
    fltSubject,
    fltInstruction,
    fltScore,
    fltGrade,
    fltScholarAwards
  };

  if (isDistrictAdmin && !isAggregate) {
    params.navSchool = navSchool;
  }

  const response = await axiosInstance.get(url, { params });

  return typeof (response?.data) === 'string' ? decodeUnZipApiResponse(response.data) : response;

};

export default getOrgScoreRosterData;

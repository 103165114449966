import React from 'react';
import PropTypes from 'prop-types';

const PseudoLink = ({handler, text, linkId = 'pseudoLinkId', linkTabIndex = '0', isDialog}) => {
  const ariaPressed = isDialog ? undefined : 'false';
  const ariaPopup = isDialog ? 'dialog' : undefined;
  return (

    <div className='cb-link-blue cb-blue1-color cb-cursor-pointer'
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          handler();
        }
      }}
      onClick={handler}
      id={linkId}
      tabIndex={linkTabIndex}
      role="button"
      aria-pressed={ariaPressed}
      aria-haspopup={ariaPopup}
    >
      {text}
    </div>

  );
};
PseudoLink.propTypes = {
  handler: PropTypes.func,
  text: PropTypes.string,
  linkId: PropTypes.string,
  linkTabIndex: PropTypes.string,
  isDialog: PropTypes.bool
};

export default PseudoLink;

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './legal.css';
import ShowError from '../ap-osre-error-handling/ErrorBoundary';
import analytics from '../../utils/analytics';
import '../../App.css';
import { AppContext } from '../../AppContext';
import {postTermsAndConditions} from '../../api';
import { Checkbox, YellowButton } from '@cb/apricot-react';

const Legal = ({ setShowTermsAndConditions }) => {
  const [showTermsUncheckedError, setShowTermsUncheckedError] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [apiError, setApiError] = useState(undefined);
  const appContext = useContext(AppContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!appContext.isCustomerService) {
      analytics.reportCurrentView('terms', appContext.roleCd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTandC = async () => {
    if (termsChecked) {
      if (!appContext.isCustomerService) {
        const request = {
          'profId': appContext.userId
        };
        try {
          await postTermsAndConditions(request);
          setShowTermsAndConditions(false);
        } catch (error) {
          setApiError(error);
        }

      } else {
        setShowTermsAndConditions(false);
      }
    } else {
      setShowTermsUncheckedError('error');
    }
  };

  const onCheck = e => {
    if (e) {
      setTermsChecked(true);
      setShowTermsUncheckedError(false);
    } else {
      setTermsChecked(false);
    }
  };

  return (
    apiError
      ? <ShowError error={apiError}/> : (
        <div className="row">
          <div className="col-lg-12 col-xl-10">
            <div className="ap-title">
                AP Score Reports for Educators
            </div>
            <div className="ap-container-border"></div>
            <div className = "space-above-title">
              <h3 className="legal-header">AP Score Reports for Educators Terms of Use</h3>
              <p>Before continuing, you must accept the terms of use.</p>
            </div>
            <div className = "legal-scrollbar">
              <p className="legal-text">The College Boards AP Score Reports for Educators system provides access to student AP score reports and rosters for authorized personnel at high schools, and districts.
              </p>
              <p className="legal-text">To access the AP Score Reports for Educators system, you must be a course authorized AP teacher; an
                    administrator for the AP Course Audit; an administrator for the AP Registration and Ordering system; an AP coordinator, or have
                    received an access code from the College Board, your AP coordinator or principal.
              </p>
              <p className="legal-text">By accessing the AP Score Reports for Educators system and viewing or downloading the AP score reports, you certify that:
              </p>
              <ul >
                <li className="legal-text">
                  <p>
                            you are accessing and downloading only the student information which you are authorized to view;
                  </p>
                </li>
                <li className="legal-text">
                  <p>
                            you agree to maintain strict confidentiality of the information and data included in these reports. Because
                            these reports are designed to aid educators within a specific school or district, they may be shared with authorized personnel within a
                            specific school or district.  However, neither schools nor districts are allowed to share school level AP reports with other schools.
                            Score reports are not to be disclosed or released to higher education institutions, students or parents. In addition, AP score reports
                            and/or rosters are not to be posted on any internal and/or external website, social networking site, or public access website;
                  </p>
                </li>
                <li className="legal-text">
                  <p>
                            in addition to providing exam scores, certain AP score reports include comparisons of your students’
                            performance with the performance of all students on a global and state basis. The purpose of this information is only to provide
                            information about the performance of your AP students which may help teachers target areas for increased attention and focus in
                            the curriculum. This information in not intended to be used for teacher evaluations or institutional rankings;
                  </p>
                </li>
                <li className="legal-text">
                  <p>
                            other than the authorized uses specified in these terms and conditions, you agree not to share any student
                            information obtained through this portal, with any individuals at your institution who do not have a “need to know” basis or any third
                            party outside your institution with the following exceptions. You may incorporate the data into your institutions student information
                            systems, career planning systems, instructional improvement systems, and state longitudinal P-20 databases and provide access to the
                            data in these systems to third parties for the purposes of performing services on behalf of your institution and to researchers for the
                            purposes of performing research on behalf of your institution. You affirm that all systems are secure, and access is restricted to only
                            those individuals and organizations that are authorized by law;
                  </p>
                </li>
                <li className="legal-text">
                  <p>
                            you agree to immediately report any unauthorized or inappropriate use of the AP Score Reports for Educators system by contacting AP Services
                            for Educators at 877-274-6474 (toll-free in the United States and Canada) or 212-632-1781;
                  </p>
                </li>
              </ul>
              <p>
                    Violations of these terms of use; the terms of use cited on the score reports, or unauthorized disclosure of the reports and/or rosters may result
                    in a suspension or revocation of your access to the AP Score Reports for Educators system.
              </p>
              <p>
                    Revised June 10, 2019.
              </p>
            </div>
            <Checkbox
              id="acceptTermandConditions"
              label="I have read and accept the terms of use."
              onChange={onCheck}
              required
              labelClassName="legal-star"
              validation={showTermsUncheckedError}
              validationMsg="You must accept the terms of use to continue."
            />
            <div className="continue-button-container">
              <YellowButton onClick={updateTandC} className="general-continue-button">Continue</YellowButton>
            </div>
          </div>
        </div>
      )
  );
};

Legal.propTypes = {
  setShowTermsAndConditions: PropTypes.func
};

export default Legal;

import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { BRONZE, GOLD, PLATINUM, SILVER } from '../../constants';
import { badgeImageMap, getSchoolAchievedTableHeaderText, isTier, TABLE_YOUR_SCHOOL_HEADER, tierColors } from '../../utils';
import { H5_FONT_SIZE, LARGE_TEXT_FONT_SIZE, SMALL_TEXT_FONT_SIZE } from '../constants';

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  schoolBadgeContainer: {
    flexDirection: 'row'
  },
  tableHeader: {
    margin: 'auto',
    flexDirection: 'row',
    color: 'white',
    backgroundColor: '#505050'
  },
  baseCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    justifyContent: 'center'
  },
  tierCol: {
    width: '10%'
  },
  categoryCol: {
    width: '30%'
  },
  schoolCol: {
    width: '30%'
  },
  schoolValueCol: {
    width: '15%'
  },
  tableHeaderCell: {
    margin: 'auto',
    paddingHorizontal: 1,
    marginVertical: 5,
    fontSize: LARGE_TEXT_FONT_SIZE,
    fontFamily: 'Roboto-Bold'
  },
  categoryHeaderCell: {
    marginLeft: 5
  },
  schoolTierHeader: {
    color: 'black',
    fontSize: H5_FONT_SIZE
  },
  badgeImage: {
    height: 50,
    width: 50
  },
  badgeContainer: {
    height: 55,
    width: 65,
    justifyContent: 'center',
    alignItems: 'center'
  },
  schoolTierTitle: {
    fontSize: H5_FONT_SIZE,
    fontFamily: 'Roboto-Bold',
    alignSelf: 'flex-start',
    color: 'black'
  },
  schoolTierDesc: {
    paddingTop: 2,
    paddingRight: 2,
    fontSize: SMALL_TEXT_FONT_SIZE,
    fontFamily: 'Roboto',
    color: 'black',
    width: 80 // needed to add fixed width to address issue with text wrapping in wrong place
  },
  textContainer: {
    paddingHorizontal: 2,
    justifyContent: 'flex-start'
  }
});

const getTierHeaderClass = tier => ({backgroundColor: tierColors[tier], color: 'black'});

const HonorRollTableHeaderPdf = ({
  overall
}) => {
  const hasTier = isTier(overall);
  const schoolHeaderBackgroundColor = hasTier ? 'white' : undefined;
  return (
    <View style={styles.tableHeader}>
      <View style={[styles.baseCol, styles.categoryCol]}>
        <Text style={[styles.tableHeaderCell, styles.categoryHeaderCell]}>AP School Honor Roll Metrics</Text>
      </View>
      <View style={[styles.baseCol, styles.tierCol, getTierHeaderClass(BRONZE)]}>
        <Text style={styles.tableHeaderCell}>{BRONZE} Criteria</Text>
      </View>
      <View style={[styles.baseCol, styles.tierCol, getTierHeaderClass(SILVER)]}>
        <Text style={styles.tableHeaderCell}>{SILVER} Criteria</Text>
      </View>
      <View style={[styles.baseCol, styles.tierCol, getTierHeaderClass(GOLD)]}>
        <Text style={styles.tableHeaderCell}>{GOLD} Criteria</Text>
      </View>
      <View style={[styles.baseCol, styles.tierCol, getTierHeaderClass(PLATINUM)]}>
        <Text style={styles.tableHeaderCell}>{PLATINUM} Criteria</Text>
      </View>
      <View style={[styles.baseCol, styles.schoolCol, {backgroundColor: schoolHeaderBackgroundColor}]}>
        {
          hasTier
            ? (<View>
              <Text style={[styles.tableHeaderCell, styles.schoolTierHeader]}>{TABLE_YOUR_SCHOOL_HEADER}</Text>
              <View style={styles.schoolBadgeContainer}>
                <View style={styles.badgeContainer}>
                  <Image style={styles.badgeImage} src={badgeImageMap[overall]} />
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.schoolTierTitle}>{overall.toUpperCase()}</Text>
                  <Text style={styles.schoolTierDesc}>{getSchoolAchievedTableHeaderText(overall)}</Text>
                </View>
              </View>
            </View>
            )
            : (<Text style={styles.tableHeaderCell}>{TABLE_YOUR_SCHOOL_HEADER}</Text>)
        }

      </View>
    </View>
  );
};

HonorRollTableHeaderPdf.propTypes = {
  overall: PropTypes.string
};

export default HonorRollTableHeaderPdf;

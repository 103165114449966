import React from 'react';
import {Bar} from 'react-chartjs-2';
import PropTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, registerables } from 'chart.js';
import { formatPercentageValue } from '../../../../utils/stringUtils';
import { QUERY_FIELD_SCORE_1, QUERY_FIELD_SCORE_2, QUERY_FIELD_SCORE_3, QUERY_FIELD_SCORE_4, QUERY_FIELD_SCORE_5, QUERY_FIELD_TOTAL_STUDENTS } from '../utils';
import { getDefaultSharedBarChartConfig, getDefaultSharedBarChartData } from '../../common/barChartConfig';
Chart.register(...registerables, ChartDataLabels);

const CHART_HEIGHT = 330;

const getChartValue = (count, total) => formatPercentageValue((parseInt(count) / parseInt(total)) * 100, 0);

const IprGroupDistributionChart = ({groupData}) => {

  // if we don't have data, return
  if (!groupData) {
    return <></>;
  }

  const groupTotalStudents = groupData[QUERY_FIELD_TOTAL_STUDENTS];

  const data = getDefaultSharedBarChartData({
    data: [
      getChartValue(groupData[QUERY_FIELD_SCORE_1], groupTotalStudents),
      getChartValue(groupData[QUERY_FIELD_SCORE_2], groupTotalStudents),
      getChartValue(groupData[QUERY_FIELD_SCORE_3], groupTotalStudents),
      getChartValue(groupData[QUERY_FIELD_SCORE_4], groupTotalStudents),
      getChartValue(groupData[QUERY_FIELD_SCORE_5], groupTotalStudents)
    ],
    labels: [1, 2, 3, 4, 5]
  });

  return (
    <Bar
      role='presentation'
      id='ipr_group_distribution_chart'
      data={data}
      options={getDefaultSharedBarChartConfig({
        height: CHART_HEIGHT,
        formatter: val => `${val}%`
      })}
      height={CHART_HEIGHT} />
  );
};

IprGroupDistributionChart.propTypes = {
  groupData: PropTypes.object
};

export default IprGroupDistributionChart;

import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { ACCESS_AWARD_TITLE, getAccessAwardText } from '../../utils';
import accessAwardBadge from '../../images/accessAward.png';
import { H5_FONT_SIZE, SMALL_TEXT_FONT_SIZE } from '../constants';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 5,
    paddingVertical: 10,
    backgroundColor: '#f2f8fc' // apricot cb-blue2-tint-2
  },
  title: {
    paddingTop: 5,
    fontSize: H5_FONT_SIZE,
    fontFamily: 'Roboto-Bold',
    color: '#006298' // apricot cb-blue1-color
  },
  desc: {
    paddingTop: 8,
    fontSize: SMALL_TEXT_FONT_SIZE,
    fontFamily: 'Roboto',
    width: 180 // needed to add fixed width to address issue with text wrapping in wrong place
  },
  textContainer: {
    paddingLeft: 10,
    justifyContent: 'center'
  },
  badgeContainer: {
    height: '100%',
    width: 80,
    justifyContent: 'center',
    alignItems: 'center'
  },
  badgeImage: {
    height: 65,
    width: 65
  }
});

const HonorRollAccessAwardSectionPdf = ({year, school}) => {
  return (
    <View style={styles.container}>
      <View style={styles.badgeContainer}>
        <Image style={styles.badgeImage} src={accessAwardBadge} />
      </View>

      <View style={styles.textContainer}>
        <Text style={styles.title}>{ACCESS_AWARD_TITLE}</Text>
        <Text style={styles.desc}>{getAccessAwardText({year, school})}</Text>
      </View>
    </View>
  );
};

HonorRollAccessAwardSectionPdf.propTypes = {
  year: PropTypes.number,
  school: PropTypes.string
};

export default HonorRollAccessAwardSectionPdf;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { TagSet } from '@cb/apricot-react-button';
import PropTypes from 'prop-types';
import '@cb/apricot/CBGrid';

import React from 'react';

const FilterGroup = ({
  children,
  tags,
  onFilterRemove,
  appliedFilters,
  tagProp = 'value'
}) => {

  return (
    <div className='cb-margin-top-8'>
      <div className="row">
        {children}
      </div>
      <div aria-live="polite">

        { tags && appliedFilters.length > 0
          ? <div className='cb-margin-top-16'>
            <div className="cb-padding-top-8 dropdown-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Applied Filters:</div>
            <TagSet
              label={`Applied Filters`}
              offScreen="Remove"
              tags={appliedFilters.map((s) => s[tagProp])}
              onClick={(tagName, e) => {
                e.preventDefault();
                // sends to parent
                onFilterRemove && onFilterRemove(tagName);
              }}
            />
          </div> : null }
      </div>

    </div>

  );
};

FilterGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  tags: PropTypes.bool,
  onFilterRemove: PropTypes.func,
  appliedFilters: PropTypes.arrayOf(PropTypes.object),
  tagProp: PropTypes.string
};

export default FilterGroup;

import PropTypes from 'prop-types';
import React from 'react';

const FilterWithLabel = ({ children, label }) => {
  return (<div className='cb-margin-top-8 cb-margin-left-8' style={{minWidth: '200px'}}>
    <div className="cb-padding-bottom-8 cb-gray1-color cb-font-weight-bold">{label}</div>
    {children}
  </div>);
};

FilterWithLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node
};

export default FilterWithLabel;

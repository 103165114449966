import React, { useState, useEffect, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import NoDataReport from './SdiMessages/NoDataReport';
import NoIprReport from './SdiMessages/NoIprReport';
import NoReport from './SdiMessages/NoReport';
import RosterReports from './RosterReports';
import InstructionalReports from './InstructionalReports';
import SummaryReports from './SummaryReports';
import Alert from '../../ap-osre-report/alert';
import '@cb/apricot/CBGrid';
import createMarkup from '../../../utils/createMarkup';
import { AppContext } from '../../../AppContext';
import { INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE } from '../../../constants/reports';

const reportStyles = (showRoster, showIpr, showSummary) => {
  let styles = {
    'iprReportStyles': 'col-md-12 col-sm-12 col-xs-12',
    'rosterSummaryStyles': ''
  };
  if (showSummary && showRoster) {
    styles.iprReportStyles = 'tablet-extend-margin';
  }
  if (!(showRoster && showIpr && showSummary)) {
    styles.rosterSummaryStyles = 'col-md-12 col-sm-12 col-xs-12';
  }
  return styles;
};

const DashboardContent = ({
  reportsData,
  sdiMessages,
  courses,
  genesisSttVal,
  yearMessageList,
  allYearsMessageList,
  selectedYear,
  exportStudentDatafile
}) => {
  const [rosterReports, setRosterReports] = useState([]);
  const [iprReports, setIprReports] = useState([]);
  const [summaryReports, setSummaryReports] = useState([]);
  const [showSummaryReports, setShowSummaryReports] = useState(false);
  const [showIPRReports, setShowIPRReports] = useState(false);
  const [showRosterReports, setShowRosterReports] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isSchoolAdmin, setIsSchoolAdmin] = useState(false);
  const [isDistrictAdmin, setIsDistrictAdmin] = useState(false);
  const [reportColumnClass, setReportColumnClass] = useState('');
  const [roleCode, setRoleCode] = useState('');
  const [enableRoster, setEnableRoster] = useState(false);
  const [enableIPR, setEnableIPR] = useState(false);
  const [iprReportsStyle, setIprReportsStyle] = useState('');
  const [rosterReportsStyle, setRosterReportsStyle] = useState('');
  const [summaryReportsStyle, setSummaryReportsStyle] = useState('');
  const appContext = useContext(AppContext);

  // Modal Trigger Ids, used to tie together info icons to the modals that they open

  const sumReportInfoTrigger = 'openSumReportInfoModal';
  const instructReportInfoTrigger = 'instructReportInfoTrigger';
  const rosterReportInfoTrigger = 'rosterReportInfoTrigger';
  const sdiMessageInfoTrigger = 'sdiMessageInfoTrigger';
  const sdiMessageInfoTrigger1 = 'sdiMessageInfoTrigger1';
  const sdiMessageInfoTrigger2 = 'sdiMessageInfoTrigger2';
  const studentDataDownloadTrigger = 'studentDataDownloadTrigger';

  useEffect(() => {
    if (reportsData) {
      let roster = [];
      let ipr = [];
      let summary = [];
      let showSummary = false;
      let showIpr = false;
      let showRoster = false;
      let teacher = false;
      let schoolAdmin = false;
      let districtAdmin = false;
      let rosterEnabled = false;
      let iprEnabled = false;
      let roleCd = '';

      reportsData.forEach((report, i) => {
        roleCd = report.role_cd;
        if (report.report_type === 'Roster Report') {
          let TempRosterReports = {};

          TempRosterReports.reportName = report.report_disp_nm;
          TempRosterReports.logiReportName = report.descr;
          TempRosterReports.enableReport = report.enableReport;

          roster.push(TempRosterReports);
          if (report.report_disp_nm === 'Subject Score Roster' && report.enableReport) {
            rosterEnabled = true;
          }
          showRoster = true;
        } else if (report.report_type === INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE) {
          let TempIPRReports = {};

          TempIPRReports.reportName = report.report_disp_nm;
          TempIPRReports.enableReport = report.enableReport;
          TempIPRReports.logiReportName = report.descr;
          ipr.push(TempIPRReports);

          if (report.report_disp_nm === INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE && report.enableReport) {
            iprEnabled = true;
          }
          showIpr = true;
        } else if (report.report_type === 'Summary Reports') {
          let TempSummaryReports = {};
          TempSummaryReports.reportName = report.report_disp_nm;
          TempSummaryReports.enableReport = report.enableReport;
          TempSummaryReports.logiReportName = report.descr;
          summary.push(TempSummaryReports);
          showSummary = true;
        }

        if (report.role_cd === 1 || report.role_cd === 2) {
          teacher = true;
        } else if (report.role_cd === 3 || report.role_cd === 4) {
          schoolAdmin = true;
        } else if (report.role_cd === 5 || report.role_cd === 6) {
          districtAdmin = true;
        }
      });

      let styles = reportStyles(showRoster, showIpr, showSummary);
      setIprReportsStyle(styles.iprReportStyles);
      setRosterReportsStyle(styles.rosterSummaryStyles);
      setSummaryReportsStyle(styles.rosterSummaryStyles);
      setRosterReports(roster);
      setIprReports(ipr);
      setSummaryReports(summary);
      setShowSummaryReports(showSummary);
      setShowIPRReports(showIpr);
      setShowRosterReports(showRoster);
      setIsTeacher(teacher);
      setIsSchoolAdmin(schoolAdmin);
      setIsDistrictAdmin(districtAdmin);
      setRoleCode(roleCd);
      setEnableRoster(rosterEnabled);
      setEnableIPR(iprEnabled);

      if (showSummary && showIpr && showRoster) {
        setReportColumnClass('col-xs-8 col-sm-6 col-lg-4');
      } else {
        setReportColumnClass('col-xs-4 col-sm-4 col-lg-6');
      }
    }
  }, [reportsData]);

  return (
    <Fragment>
      {allYearsMessageList ? allYearsMessageList.map((messageData, i) => {
        return <Alert key={messageData.id}>
          <p dangerouslySetInnerHTML={createMarkup(messageData.message)}></p>
        </Alert>;
      }) : null
      }
      {yearMessageList ? yearMessageList.map((messageData) => {
        return <Alert key={messageData.id}>
          <p dangerouslySetInnerHTML={createMarkup(messageData.message)}></p>
        </Alert>;
      }) : null
      }
      {(sdiMessages && sdiMessages.noDataReport) ? <NoDataReport sdiMessageInfoTrigger={sdiMessageInfoTrigger} createMarkup={createMarkup} noDataReport={sdiMessages.noDataReport}></NoDataReport> : null}
      {(sdiMessages && sdiMessages.noReport) ? <NoReport sdiMessageInfoTrigger1={sdiMessageInfoTrigger1} createMarkup={createMarkup} noReport={sdiMessages.noReport}></NoReport> : null}
      {(sdiMessages && sdiMessages.noIprReport) ? <NoIprReport sdiMessageInfoTrigger2={sdiMessageInfoTrigger2} createMarkup={createMarkup} noIprReport={sdiMessages.noIprReport}></NoIprReport> : null}
      <div className="reports-container row cb-gutterv-24">
        {showRosterReports
          ? <RosterReports genesisSttVal={genesisSttVal} rosterReports={rosterReports} isTeacher={isTeacher} isSchoolAdmin={isSchoolAdmin} isDistrictAdmin={isDistrictAdmin} reportColumnClass={reportColumnClass} roleCode={roleCode} enableRoster={enableRoster} rosterReportsStyle={rosterReportsStyle} rosterReportInfoTrigger={rosterReportInfoTrigger} studentDataDownloadTrigger={studentDataDownloadTrigger} courses={courses} selectedYear={selectedYear} exportStudentDatafile={exportStudentDatafile}></RosterReports>
          : null}
        {showIPRReports
          ? <InstructionalReports genesisSttVal={genesisSttVal} iprReports={iprReports} isTeacher={isTeacher} isSchoolAdmin={isSchoolAdmin} isDistrictAdmin={isDistrictAdmin} reportColumnClass={reportColumnClass} roleCode={roleCode} enableIPR={enableIPR} iprReportsStyle={iprReportsStyle} instructReportInfoTrigger={instructReportInfoTrigger} courses={courses} selectedYear={selectedYear}></InstructionalReports>
          : null}
        {showSummaryReports
          ? <SummaryReports genesisSttVal={genesisSttVal} summaryReports={summaryReports} isTeacher={isTeacher} isSchoolAdmin={isSchoolAdmin} isDistrictAdmin={isDistrictAdmin} reportColumnClass={reportColumnClass} roleCode={roleCode} summaryReportsStyle={summaryReportsStyle} sumReportInfoTrigger={sumReportInfoTrigger} selectedYear={selectedYear}></SummaryReports>
          : null}
        <div className="container" id={(showIPRReports && showRosterReports && showSummaryReports) ? 'report-tips-with-summary' : 'report-tips-without-summary'}>
          <div className="row cb-gutterv-24">
            <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6">
              <div id="lightbulb-changes-container"
                className="cb-card"
                dangerouslySetInnerHTML={createMarkup(appContext.content?.learn_more_link)}>
              </div>
            </div>
            <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6">
              <div
                id="bargraph-report-tips"
                className="cb-card"
                dangerouslySetInnerHTML={createMarkup(appContext.content?.tips_tricks_link)}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

DashboardContent.propTypes = {
  reportsData: PropTypes.array,
  sdiMessages: PropTypes.object,
  courses: PropTypes.array,
  yearMessageList: PropTypes.array,
  allYearsMessageList: PropTypes.array,
  selectedYear: PropTypes.number,
  exportStudentDatafile: PropTypes.func,
  genesisSttVal: PropTypes.string
};

export default DashboardContent;

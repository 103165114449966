import { SUBJECT_SCORE_ROSTER_REPORT_URI } from '../../constants/reports';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';
import { decodeUnZipApiResponse } from './utils';

const getSsrData = async ({
  sttVal,
  navYear,
  navSchool,
  navSubject,
  navSection,
  isDistrictAdmin,
  isAggregate,
  csv,

  fltScore,
  fltGrade,
  fltTeacher,
  fltInstruction
}) => {
  const url =
          config.apiBaseUrl +
          config.genesisUri +
          SUBJECT_SCORE_ROSTER_REPORT_URI;

  const params = {
    sttVal,
    navYear,
    navSubject,
    csv,
    fltScore,
    fltGrade,
    fltTeacher,
    fltInstruction
  };

  if (isDistrictAdmin && !isAggregate) {
    params.navSchool = navSchool;
  }
  if (!isAggregate) {
    params.navSection = navSection;
  }

  const response = await axiosInstance.get(url, { params });

  return typeof (response?.data) === 'string' ? decodeUnZipApiResponse(response.data) : response;
};

export default getSsrData;

// takes subject filter options and returns grouped options in Apricot expected formatting
export const getSubjectFilterGroups = (arrSubjectFilterOptions) => {
  const objSubjectGroups = arrSubjectFilterOptions.reduce((acc, i) => {
    if (typeof acc[i.group] === 'undefined') {
      acc[i.group] = [];
    }
    acc[i.group].push(i);
    return acc;
  }, {});
  const groupedSubjectFilterOptions = [];
  for (const [key, value] of Object.entries(objSubjectGroups)) {
    groupedSubjectFilterOptions.push({
      optgroup: {title: key},
      options: value
    });
  }

  return groupedSubjectFilterOptions;
};

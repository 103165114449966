import { Column, Table } from '@cb/apricot-react';
import PropTypes from 'prop-types';
import React from 'react';
import './detailTable.css';

const DetailTable = (props) => {
  if (!props.data || !props.columns || !props.columns.length) {
    return null;
  }

  return (
    <div id={props.tableId}>
      <Table
        a11yHighlightText={props.a11yHighlightText ?? undefined}
        className="table-class font-table"
        striped={props.striped}
        data={props.data}
        rowHighlighted={props.rowHighlighted}
        stickyHeader={props.stickyHeader}
        stickyHeaderParent={props.stickyHeaderParent}
        maxHeight={props.maxHeight}
        stickyColumn={props.stickyColumn}
        overflowX={props.overflowX}
        onSort={props.onSort}
        sortFn={props.sortFn}
        sortType={props.sortType}
        responsive={props.responsive}
        noStretch={props.noStretch}
        condensed={props.condensed}
        tableAttributesFunc={props.tableAttributesFunc}
        rowClassNameFunc={props.rowClassNameFunc}
        rowAttributesFunc={props.rowAttributesFunc}
        tabIndex={props.tabIndex}

        // Dev note - this prop used to not exist, hence our container id above. Leaving that as-is to support existing automation and adding `_id` to this prop.
        tableId={props.tableId ? `${props.tableId}_id` : undefined}
      >
        {props.columns.map((column, i) => (
          <Column
            {...column}
            key={`header-${i}`}
            className={`tbl-head ${column.className || ''}`}
            rowHeader={column.rowHeader ?? false}
            groupValues={column.groupValues}
            headerComponent={column.headerComponent}
          />)
        )}
      </Table>
    </div>
  );
};

DetailTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  striped: PropTypes.bool,
  rowHighlighted: PropTypes.func,
  tableId: PropTypes.string,
  a11yHighlightText: PropTypes.string,
  stickyHeader: PropTypes.bool,
  stickyHeaderParent: PropTypes.bool,
  maxHeight: PropTypes.number,
  stickyColumn: PropTypes.bool,
  overflowX: PropTypes.bool,
  onSort: PropTypes.func,
  sortFn: PropTypes.func,
  sortType: PropTypes.string,
  responsive: PropTypes.bool,
  noStretch: PropTypes.bool,
  condensed: PropTypes.bool,
  tableAttributesFunc: PropTypes.func,
  rowClassNameFunc: PropTypes.func,
  rowAttributesFunc: PropTypes.func,
  tabIndex: PropTypes.number
};

export default DetailTable;

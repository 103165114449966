import PropTypes from 'prop-types';
import React from 'react';

const InfoDisplay = ({ values, keyId }) => {

  return (
    <div className='display-flex'>
      <table className='cb-margin-left-16 cb-margin-right-16'>
        <tbody>
          {values.map((i, ind) => (
            <tr key={`${keyId}_${ind}`}>
              <td className='cb-font-size-small' style={{whiteSpace: 'pre-line'}}>{i.label ? `${i.label} ` : ''}<span className='cb-font-weight-bold'>{i.value}</span></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

  );
};

InfoDisplay.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  keyId: PropTypes.string
};

export default InfoDisplay;

import React from 'react';
import PropTypes from 'prop-types';
import IconAndTitle from '../../common/iconAndTitle';
import { GROUP_DISTRIBUTION_HEADER } from '../utils';
import GroupDistributionChart from './groupDistributionChart';

const IprSecondCardContent = ({groupData}) => {

  return (
    <>
      <IconAndTitle title={GROUP_DISTRIBUTION_HEADER} icon='graph' />
      <div className='cb-padding-4 cb-padding-left-8 cb-padding-right-8'>
        <GroupDistributionChart groupData={groupData}/>
      </div>
    </>
  );
};

IprSecondCardContent.propTypes = {
  groupData: PropTypes.object
};

export default IprSecondCardContent;

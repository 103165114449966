import React, {useContext} from 'react';
import './reportInfoModal.css';
import { Modal } from '@cb/apricot-react';
import { AppContext } from '../../AppContext';
import propTypes from 'prop-types';
import createMarkup from '../../utils/createMarkup';

const ReportInfoModal = (props) => {
  const appContext = useContext(AppContext);
  return (
    <Modal title={<b>Instructional Planning Reports</b>} trigger={props.trigger} withFooter={false}>
      {
        appContext?.content?.ipr_tooltip_modal &&
        <div dangerouslySetInnerHTML={createMarkup(appContext.content.ipr_tooltip_modal)}></div>
      }
    </Modal>
  );
};

ReportInfoModal.propTypes = {
  trigger: propTypes.string
};

export default ReportInfoModal;

import { getSubjectArr } from './dataParser';

export const getTotalsByScoreColumns = () => [
  { title: 'Subject / Score', field: 'exam_name', rowHeader: true, sticky: true, sortable: true },
  { title: '1', field: 's1', className: 'rightAlignTableCells', sortable: true },
  { title: '2', field: 's2', className: 'rightAlignTableCells', sortable: true },
  { title: '3', field: 's3', className: 'rightAlignTableCells', sortable: true },
  { title: '4', field: 's4', className: 'rightAlignTableCells', sortable: true },
  { title: '5', field: 's5', className: 'rightAlignTableCells', sortable: true },
  { title: 'Total Exams', field: 'total', className: 'cb-gray5-bg cb-gray1-color rightAlignTableCells', sortable: true }
];

const getSubjectColumns = arrSubjects => (arrSubjects.map(subject => ({ title: subject, field: subject, className: 'rightAlignTableCells', sortable: true })));

export const getTotalsBySchoolColumns = (arrApiData, appliedFilters) => {
  const arrSubjects = getSubjectArr(arrApiData, appliedFilters);

  return [
    { title: 'School / Subject', field: 'display_name', rowHeader: true, sticky: true, sortable: true },
    ...getSubjectColumns(arrSubjects),
    { title: 'Total Exams', field: 'total', className: 'cb-gray5-bg cb-gray1-color rightAlignTableCells', sticky: true, sortable: true }
  ];
};

export const getTotalsBySchoolDownloadColumns = (arrApiData) => {
  const arrSubjects = getSubjectArr(arrApiData, []);

  return [
    'School',
    'AI Code',
    ...arrSubjects,
    'Total Exams'
  ];
};

export const getTotalsByGradeLevelColumns = () => [
  { title: 'School / Grade', field: 'display_name', rowHeader: true, sticky: true, sortable: true },
  { title: '< 9th Grade', field: '< 9th Grade', className: 'rightAlignTableCells', sortable: true },
  { title: '9th Grade', field: '9th Grade', className: 'rightAlignTableCells', sortable: true },
  { title: '10th Grade', field: '10th Grade', className: 'rightAlignTableCells', sortable: true },
  { title: '11th Grade', field: '11th Grade', className: 'rightAlignTableCells', sortable: true },
  { title: '12th Grade', field: '12th Grade', className: 'rightAlignTableCells', sortable: true },
  { title: 'No longer in high school', field: 'No longer in high school', className: 'rightAlignTableCells', sortable: true },
  { title: 'Unknown', field: 'Unknown', className: 'rightAlignTableCells', sortable: true },
  { title: 'Total Students', field: 'total', className: 'cb-gray5-bg cb-gray1-color rightAlignTableCells', sortable: true }
];

import gql from 'graphql-tag';
import openDownloadInNewTab from '../../common/downloads/openDownloadTab';
import { getAuthSsrMultiPdf } from '../../../../graphql/queries';
import { subscribeToSsrMultiPdf } from '../../../../graphql/subscriptions';
import { getAppSyncResponseHandler } from '../../common/pdf/common';

export const getMultiPdf = ({
  setFileData,
  navSchool,
  orgId,
  roleCd,
  selectedYear,
  sttVal,
  reportName,
  selectedAICaption
}) => {
  const cb = window.cb;
  openDownloadInNewTab({
    subscribeParams: {
      query: gql(subscribeToSsrMultiPdf),
      variables: {
        catapultId: cb.core.iam.getTempAWSCredsSubject(),
        orgId: parseInt(orgId),
        adminYear: parseInt(selectedYear),
        roleCd: parseInt(roleCd),
        reportName
      }
    },
    queryParams: {
      query: gql(getAuthSsrMultiPdf),
      variables: {
        // required params
        sttVal,
        navYear: parseInt(selectedYear),
        selectedAICaption,
        // optional
        navSchool
      }
    },
    downloadFile: getAppSyncResponseHandler('subscribeToSsrMultiPdf', setFileData)
  });
};

import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import '../shared.css';
import './index.css';
import { Tabs, Tab, Select } from '@cb/apricot-react';
import MultipleChoiceTable from './multipleChoiceTab';
import IprSummarySection from './summary-section';
import FreeResponseTable from './freeResponseTab';
import SubScoreNotes from '../common/subScore/subScoreNotes';
import SubjectCards from './subjectCards';
import SubHeader from '../subHeader';
import DownloadModal from '../common/downloads/downloadModal';
import { INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE, INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE_DA, INSTRUCTIONAL_PLANNING_REPORT_TITLE, INSTRUCTIONAL_PLANNING_SINGLE_PDF_ID_DISTRICT, INSTRUCTIONAL_PLANNING_SINGLE_PDF_ID_SCHOOL, NO_DATA_FILTERED_ERROR_MESSAGE } from '../../../constants/reports';
import { getSinglePdf } from './pdf/singlePdf';
import IprFilterSection, { DEFAULT_ATTENDED_VALUE, DEFAULT_STANDARD_VALUE, getApiValue, getAppliedAttendedLabel, getAppliedStandardValue, getAttendedApiValue, getStandardApiValue } from '../common/filter-section';
import IprComparableGroups from '../common/comparableGroups';
import ReportError from '../../ap-osre-error-handling/ReportError';
import { getMultiPdf } from './pdf/multiPdf';
import { GLOBAL_TYPE, GROUP_TYPE } from './utils';
import IprDownloadNotes from './downloadNotes';

const downloadModalTrigger = 'openDownloadModal';

const getSinglePdfFilterText = ({isDistrictAdmin, isAggregate}) => {
  if (isDistrictAdmin) {
    return isAggregate ? 'All Schools; Selected Subject; All Forms; Filters Applied' : 'Selected School, Subject, & Sections; All Forms; Filters Applied';
  }
  return 'Selected Subject, & Sections; All Forms; Filters Applied';
};
const getMultiPdfFilterText = ({isDistrictAdmin, isAggregate}) => {
  if (isDistrictAdmin) {
    return isAggregate ? 'All Schools, Subjects, & Forms; No Filters' : 'Selected School; All Subjects, Sections, & Forms; No Filters';
  }
  return 'All Subjects, Sections, & Forms; No Filters';
};

const InstructionalPlanning = ({ isAggregate,
  year,
  apiData,
  selectedOrgId,
  selectedAICaption,
  queryDataFromReport,
  sttVal,
  isDistrictAdmin,
  isTeacher,
  selectedSubjectId,
  selectedSectionIds,
  selectedSubjectName,
  selectedSectionNames,
  reportHandledNoData,
  roleCd,

  // below are report specific props, that will be undefined at first render and set when filters applied
  selectedForm,
  selectedAttended = DEFAULT_ATTENDED_VALUE,
  selectedStandard = DEFAULT_STANDARD_VALUE,
  selectedScores = [],
  selectedTeachers = [],
  selectedGradeLevels = [],
  appliedFilters = []
}) => {

  const linkRef = useRef(undefined);
  const [fileData, setFileData] = useState();
  useEffect(() => {
    if (fileData) {
      linkRef?.current?.click();
    }
  }, [fileData]);

  // genesis will not return the property if the exam code is not applicable for standard group filter
  const hasStandardFilter = Array.isArray(apiData.standardFilter);

  // maintaining filter values here so we can easily pass to pdf
  const [currentAttended, setCurrentAttended] = useState(selectedAttended);
  const [currentStandard, setCurrentStandard] = useState(selectedStandard);
  const [currentScores, setCurrentScores] = useState(selectedScores);
  const [currentTeachers, setCurrentTeachers] = useState(selectedTeachers);
  const [currentGradeLevels, setCurrentGradeLevels] = useState(selectedGradeLevels);
  const [currentAppliedFilters, _setCurrentAppliedFilters] = useState(appliedFilters);

  const [showFilterSection, setShowFilterSection] = useState(false);

  let initialForm;
  try {
    initialForm = selectedForm ?? apiData.formFilter[0].value;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error setting initial form', error);
  }

  const [currentForm, setCurrentForm] = useState(initialForm);

  const onFormChange = (selectedValue) => {
    queryDataFromReport({navForm: selectedValue, selectedForm: selectedValue});
    setCurrentForm(selectedValue);
  };

  const {multiple_choice_main_header: mcHeaderTitle,
    free_response_main_header: frHeaderTitle,
    free_response_content_header: frContentHeader,
    notes: subScoreNotes,
    subscore_description: subScoreDescr,
    pract: sciencePractInd,
    central_content: apCentral,
    class_content: apClassroom} = apiData.subjectResource[0];

  const showMCTab = Boolean(mcHeaderTitle);
  const showNotes = Boolean(subScoreNotes);
  const showAPCentral = Boolean(apCentral);
  const showAPClassroom = Boolean(apClassroom);
  const stateProvinceObj = apiData.summary.find(el => el.type !== GROUP_TYPE && el.type !== GLOBAL_TYPE);
  const stateProvince = stateProvinceObj ? stateProvinceObj.type : false;

  const selectedFormDescription = apiData.formDescription[0].desc;

  return (<>
    <SubHeader showDownload={true} showFilterButton={!showFilterSection} showHideFilterButton={showFilterSection} onFilterPress={() => setShowFilterSection(!showFilterSection)} downloadModalTrigger={downloadModalTrigger}>
      <div className="display-inline-block cb-margin-right-8">
        {/* Dev note - we are not using apricot select label property because it cannot easily be styled in line with the select itself as of apricot 4.3. */}
        <div className="dropdown-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Select Form:</div>
      </div>
      <div className="display-inline-block" id="form_dropdown_container" style={{minWidth: '80px'}}>
        <Select
          ariaLabel="Form Select"
          values={apiData.formFilter.map(i => ({
            ...i,
            label: i.caption
          }))}
          value={currentForm}
          onChange={onFormChange}
        />
      </div>
      <div className="display-inline-block cb-margin-left-8">
        <div className="dropdown-title cb-gray1-color cb-sans-serif cb-font-size-regular">{selectedFormDescription}</div>
      </div>
    </SubHeader>

    <IprFilterSection
      reportIdentifier='ipr'
      show={showFilterSection}
      hasStandardFilter={hasStandardFilter}
      standardFilter={apiData.standardFilter}
      teacherFilter={apiData.teacherFilter}
      gradeLevelFilter={apiData.gradeLevelFilter}
      scoreFilter={apiData.scoreFilter}
      isTeacher={isTeacher}
      isAggregate={isAggregate}
      queryDataFromReport={filters => {
        queryDataFromReport({
          ...filters,
          navForm: currentForm,
          selectedForm: currentForm
        }, true);
      }}
      currentTeachers={currentTeachers}
      currentScores={currentScores}
      currentGrades={currentGradeLevels}
      currentAttended={currentAttended}
      currentStandard={currentStandard}
      currentApplied={currentAppliedFilters}

      setCurrentTeachers={setCurrentTeachers}
      setCurrentScores={setCurrentScores}
      setCurrentGrades={setCurrentGradeLevels}
      setCurrentAttended={setCurrentAttended}
      setCurrentStandard={setCurrentStandard}
    />

    {/* This is a hidden link to facilitate single pdf download */}
    <a
      ref={linkRef}
      href={fileData}
      download={`AP ${isAggregate ? 'Instructional Planning Report, Aggregated for Districts' : 'School Instructional Planning Report'} ${year}.pdf`}
      className="display-none">
        Download
    </a>
    <DownloadModal
      containerId='ipr'
      footerNotes={<IprDownloadNotes isTeacher={isTeacher} />}
      title={<div style={{fontSize: 26}}>
        {`${isAggregate ? INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE_DA : INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE} ${year}`}
      </div>}
      multiFilename={`${INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE} ${year}`}
      multiPdfFilterText={getMultiPdfFilterText({isDistrictAdmin, isAggregate})}
      multiPdfDownload={
        // omitting multiPdfDownload will omit row in modal for teachers
        isTeacher
          ? undefined
          : () => {
            getMultiPdf({
              setFileData,
              navSchool: isAggregate ? undefined : selectedOrgId,
              selectedAICaption,
              orgId: selectedOrgId,
              selectedYear: year,
              sttVal,
              reportName: `${INSTRUCTIONAL_PLANNING_REPORT_TITLE}${isAggregate ? 'DA' : ''}`,
              roleCd
            });
          }}
      singlePdfFilterText={getSinglePdfFilterText({isDistrictAdmin, isAggregate})}
      filename={`${INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE} ${year}`}
      singlePdfDownload={() => {
        getSinglePdf({
          setFileData,
          navSchool: isAggregate ? undefined : selectedOrgId,
          navSubject: selectedSubjectId,
          navSection: selectedSectionIds,
          subjectDisplay: selectedSubjectName,
          sectionDisplay: selectedSectionNames,
          selectedAICaption,
          orgId: selectedOrgId,
          selectedYear: year,
          sttVal,
          reportName: `${INSTRUCTIONAL_PLANNING_REPORT_TITLE}${isAggregate ? 'DA' : ''}`,
          roleCd,

          fltTeacher: getApiValue(currentTeachers),
          fltScore: getApiValue(currentScores),
          fltGrade: getApiValue(currentGradeLevels),
          fltStandardIndicator: hasStandardFilter ? getStandardApiValue(currentStandard) : undefined,
          fltInstruction: getAttendedApiValue(currentAttended) ?? DEFAULT_ATTENDED_VALUE,
          fltTeacherDisplay: currentTeachers.map(i => i.label).join(', '),
          fltScoreDisplay: currentScores.map(i => i.value).join(', '),
          fltGradeDisplay: currentGradeLevels.map(i => i.label).join(', '),
          fltStandardIndicatorDisplay: hasStandardFilter ? getAppliedStandardValue(currentStandard, apiData.standardFilter).join() : undefined,
          fltInstructionDisplay: currentAttended === DEFAULT_ATTENDED_VALUE ? undefined : getAppliedAttendedLabel(currentAttended, true).join(),
          showStandardFilter: hasStandardFilter
        });
      } }
      org={selectedOrgId}
      auditTitle={isAggregate ? INSTRUCTIONAL_PLANNING_SINGLE_PDF_ID_DISTRICT : INSTRUCTIONAL_PLANNING_SINGLE_PDF_ID_SCHOOL}
      sttVal={sttVal}
      year={year}
      trigger={downloadModalTrigger}
      isAggregate={isAggregate}
      isDistrictAdmin={isDistrictAdmin}
      // done on backend
      skipAuditLog={true}
    />

    {
      reportHandledNoData
        ? <ReportError message={NO_DATA_FILTERED_ERROR_MESSAGE} />
        : (<>
          <IprComparableGroups />
          {/* This is outside of main report container because we want full width for background */}
          <IprSummarySection summaryData={apiData.summary}/>

          <div id="divIprTables" className="cb-panel-summary cb-padding-top-48">
            <div className="container">
              <Tabs>
                {showMCTab
                  ? <Tab label={mcHeaderTitle} panelId="mc" tabId="mcTabId" className="cb-gray1-color" selected>
                    <MultipleChoiceTable mcData={apiData.multipleChoice} stateProvince={stateProvince} showNotes={showNotes} />
                  </Tab> : null}
                <Tab label={frHeaderTitle} panelId="fr" tabId="frTabId"className="cb-gray1-color" selected={!showMCTab}>
                  <FreeResponseTable frData={apiData.freeResponse} stateProvince={stateProvince} sciencePractInd={sciencePractInd} frContentHeader={frContentHeader}/>
                </Tab>
              </Tabs>
              {showNotes
                ? <SubScoreNotes subScoreNotes={subScoreNotes} subScoreDescr={subScoreDescr}/> : null}
              {
                (showAPCentral || showAPClassroom) && (
                  <div id="divAPLinks" className="ap-info-card-padding">
                    <span className="cb-gray1-color fw7 f16">
    Next Steps: Exploring Related Resources
                    </span>
                    <div className="ap-info-card-top"></div>
                    <div id="divAPLinksContainer" className="container-flex-h">
                      {showAPCentral
                        ? <SubjectCards cardData={apCentral}/> : null}
                      {showAPClassroom
                        ? <SubjectCards cardData={apClassroom}/> : null}
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </>)
    }
  </>);
};

InstructionalPlanning.propTypes = {
  isAggregate: PropTypes.bool,
  isTeacher: PropTypes.bool,
  year: PropTypes.number,
  apiData: PropTypes.object,
  sttVal: PropTypes.string,
  queryDataFromReport: PropTypes.func,
  isDistrictAdmin: PropTypes.bool,
  selectedOrgId: PropTypes.number,
  selectedAICaption: PropTypes.string,
  selectedSubjectId: PropTypes.string,
  selectedSectionIds: PropTypes.string,
  selectedSubjectName: PropTypes.string,
  selectedSectionNames: PropTypes.string,
  setAdditionalReportProps: PropTypes.func,
  selectedForm: PropTypes.string,
  selectedAttended: PropTypes.string,
  selectedStandard: PropTypes.string,
  selectedScores: PropTypes.array,
  selectedTeachers: PropTypes.array,
  selectedGradeLevels: PropTypes.array,
  appliedFilters: PropTypes.array,
  reportHandledNoData: PropTypes.bool,
  roleCd: PropTypes.number
};

export default InstructionalPlanning;

import React from 'react';
import PropTypes from 'prop-types';
import IconAndTitle from '../../common/iconAndTitle';
import { NO_PERCENT_DISPLAY, QUERY_FIELD_SCORE_1, QUERY_FIELD_SCORE_2, QUERY_FIELD_SCORE_3, QUERY_FIELD_SCORE_4, QUERY_FIELD_SCORE_5, QUERY_FIELD_TOTAL_STUDENTS, SCORE_DISTRIBUTION_HEADER } from '../utils';
import DetailTable from '../../common/detailTable';
import { formatCountValue, formatPercentageValue } from '../../../../utils/stringUtils';

const getPercent = (count, total) => {
  const formatted = formatPercentageValue((parseInt(count) / parseInt(total)) * 100, 1);
  return count !== '0' && formatted !== '' ? `${formatted}%` : NO_PERCENT_DISPLAY;
};

const ScoreCell = ({score}) => (<span className='cb-font-weight-bold' style={{fontSize: 20}}>{score}</span>);
ScoreCell.propTypes = {
  score: PropTypes.number
};

const GroupCell = ({count, percent}) => {
  return (<div className='row justify-content-between cb-padding-left-16 cb-padding-right-16'>
    <span><span className='cb-font-weight-bold'>{count}</span> Student{count === '1' ? '' : 's'}</span>
    <div style={{minWidth: 65}} className='cb-font-weight-bold cb-padding-left-4 cb-align-center'>{percent}</div>
  </div>);
};
GroupCell.propTypes = {
  count: PropTypes.string,
  percent: PropTypes.string
};

const getRow = ({stateProvinceAbbreviation, score, field, groupData, stateProvinceData, groupTotalStudents, stateProvinceTotalStudents, globalData, globalTotalStudents}) => {
  return {
    label: <ScoreCell score={score} />,
    group: <GroupCell count={formatCountValue(groupData[field], true)} percent={getPercent(groupData[field], groupTotalStudents)} />,
    stateProvince: stateProvinceAbbreviation ? getPercent(stateProvinceData[field], stateProvinceTotalStudents) : '',
    global: getPercent(globalData[field], globalTotalStudents)
  };
};

const IprThirdCardContent = ({stateProvinceAbbreviation, groupData, stateProvinceData, globalData}) => {

  const groupTotalStudents = groupData[QUERY_FIELD_TOTAL_STUDENTS];
  const stateProvinceTotalStudents = stateProvinceData ? stateProvinceData[QUERY_FIELD_TOTAL_STUDENTS] : '';
  const globalTotalStudents = globalData[QUERY_FIELD_TOTAL_STUDENTS];

  const columns = [
    { title: 'SCORE', field: 'label', className: 'cb-align-center'},
    { title: 'YOUR GROUP', field: 'group', className: 'cb-align-center' },
    { title: stateProvinceAbbreviation || 'STATE', field: 'stateProvince', className: 'cb-align-center cb-font-weight-bold' },
    { title: 'GLOBAL', field: 'global', className: 'cb-align-center cb-font-weight-bold' }
  ];

  const commonRowParams = {
    stateProvinceAbbreviation,
    groupData,
    stateProvinceData,
    groupTotalStudents,
    stateProvinceTotalStudents,
    globalData,
    globalTotalStudents
  };

  const tableData = [
    getRow({...commonRowParams, score: 1, field: QUERY_FIELD_SCORE_1}),
    getRow({...commonRowParams, score: 2, field: QUERY_FIELD_SCORE_2}),
    getRow({...commonRowParams, score: 3, field: QUERY_FIELD_SCORE_3}),
    getRow({...commonRowParams, score: 4, field: QUERY_FIELD_SCORE_4}),
    getRow({...commonRowParams, score: 5, field: QUERY_FIELD_SCORE_5})
  ];

  return (
    <>
      <IconAndTitle title={SCORE_DISTRIBUTION_HEADER} icon='note' />
      <div className='cb-padding-4 cb-padding-left-8 cb-padding-right-8'>
        { /* show condensed table on xs */ }
        <div className='display-sm-none'>
          <DetailTable condensed responsive tableId='ipr_score_distributions_table_small' data={tableData} columns={columns} />
        </div>
        <div className='display-xs-none-only'>
          <DetailTable responsive tableId='ipr_score_distributions_table' data={tableData} columns={columns} />
        </div>

        <div className='cb-blue2-tint-2 cb-margin-top-4 cb-padding-16 cb-font-size-small'>
          <span className='cb-font-weight-bold'>{formatCountValue(groupTotalStudents, true)}</span> student{groupTotalStudents === '1' ? '' : 's'} total in your group
        </div>
      </div>

    </>
  );
};

IprThirdCardContent.propTypes = {
  stateProvinceAbbreviation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  groupData: PropTypes.object,
  stateProvinceData: PropTypes.object,
  globalData: PropTypes.object
};

export default IprThirdCardContent;

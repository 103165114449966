import PropTypes from 'prop-types';
import React, { useState } from 'react';
import '../shared.css';
import './index.css';
import OrgSummary from './orgSummary';
import OrgRoster from './orgRoster';
import {formatCountValue} from '../../../utils/stringUtils';
import OsrFilterSection, { DEFAULT_ATTENDED_VALUE } from './filterSection';
import SubHeader from '../subHeader';
import { NO_DATA_FILTERED_ERROR_MESSAGE } from '../../../constants/reports';
import ReportError from '../../ap-osre-error-handling/ReportError';
import DownloadModal from '../common/downloads/downloadModal';
import {getOrgScoreRosterData} from '../../../api';

const downloadModalTrigger = 'openDownloadModal';
const formatSubjectFilter = (data) => {
  const subjectFilterObj = data.reduce((acc, row) => {
    const subject = acc[row.discipline_descr] || {};
    acc[row.discipline_descr] = {
      ...subject,
      optgroup: {title: row.discipline_descr},
      options: [...(subject.options || []), {label: row.ap_exam_descr, value: row.ap_exam_cd, group: row.discipline_descr}]

    };
    return acc;
  }, {});
  return Object.keys(subjectFilterObj).sort().map(key => subjectFilterObj[key]);
};
const getCSVFilterText = ({isDistrictAdmin, isAggregate}) => {
  if (isDistrictAdmin) {
    return isAggregate ? 'All Schools & Subjects; No Filters' : 'Selected School; All Subjects; No Filters';
  }
  return 'All Subjects; No Filters';
};

const OrgScoreRoster = ({apiData, year, isDistrictAdmin, isAggregate, sttVal, reportTitle, selectedOrgId,
  selectedAttended = DEFAULT_ATTENDED_VALUE,
  selectedSubjects = [],
  selectedScores = [],
  selectedGradeLevels = [],
  selectedScholarAwards = [],
  appliedFilters = [],
  queryDataFromReport,
  reportHandledNoData}) => {

  const [totalStudents, setTotalStudents] = useState(0);

  const [currentSubjects, setCurrentSubjects] = useState(selectedSubjects);
  const [currentAttended, setCurrentAttended] = useState(selectedAttended);
  const [currentScores, setCurrentScores] = useState(selectedScores);
  const [currentGradeLevels, setCurrentGradeLevels] = useState(selectedGradeLevels);
  const [currentScholarAwards, setCurrentScholarAwards] = useState(selectedScholarAwards);
  // const [currentAppliedFilters, _setCurrentAppliedFilters] = useState(appliedFilters);

  const [showFilterSection, setShowFilterSection] = useState(false);

  const subjectFilterData = formatSubjectFilter(apiData.subjectFilter);
  return (<>
    <SubHeader showDownload={true} showFilterButton={!showFilterSection} showHideFilterButton={showFilterSection} onFilterPress={() => setShowFilterSection(!showFilterSection)} downloadModalTrigger={downloadModalTrigger}>
    </SubHeader>
    <DownloadModal
      containerId='osr'
      trigger={downloadModalTrigger}
      title={<div style={{fontSize: 26}}>
        {`${reportTitle} ${year}`}
      </div>}
      skipAuditLog
      filename={`AP ${isAggregate ? 'District' : 'School'} Organization Score Roster ${year}`}
      csvFilterText={getCSVFilterText({isDistrictAdmin, isAggregate})}
      getCSVData={async () => {
        const res = await getOrgScoreRosterData({sttVal, navYear: year, isDistrictAdmin, isAggregate, navSchool: selectedOrgId, csv: true});
        return {
          data: res.data?.csvData,
          headers: res.data?.csvHeaders,
          displayHeaders: res.data?.csvDisplayHeaders
        };
      }
      }
    />
    <OsrFilterSection subjectFilterData={subjectFilterData} scoreFilterData={apiData.scoreFilter} gradeFilterData={apiData.gradeFilter}
      scholarAwardsFilterData={apiData.scholarAwardsFilter} scholarAwardEnabled={apiData.scholarAwardEnabled}
      show={showFilterSection}
      queryDataFromReport={filters => queryDataFromReport({...filters}, true)}
      currentSubjects={currentSubjects}
      currentScores={currentScores}
      currentGrades={currentGradeLevels}
      currentAttended={currentAttended}
      currentScholarAwards={currentScholarAwards}
      currentApplied={appliedFilters}

      setCurrentSubjects={setCurrentSubjects}
      setCurrentScores={setCurrentScores}
      setCurrentGrades={setCurrentGradeLevels}
      setCurrentAttended={setCurrentAttended}
      setCurrentScholarAwards={setCurrentScholarAwards}
    />
    { reportHandledNoData ? <ReportError message={NO_DATA_FILTERED_ERROR_MESSAGE} /> : (<>
      <div className={'cb-panel-summary'}>
        <div
          className="cb-scrollbar-container content-gutter cb-padding-bottom-48"
        >
          <h5 className={'cb-padding-top-24'}>
            {`Organization Summary (Total Students: ${formatCountValue(totalStudents)})`}
          </h5>
          <div className={'cb-margin-top-8'} style={{ backgroundColor: 'white' }}>
            <OrgSummary summaryData={apiData.summaryData} />
          </div>
        </div>
      </div>
      <div
        className="cb-scrollbar-container content-gutter cb-padding-bottom-48"
      >
        <h5>Organization Roster</h5>
        <OrgRoster rosterData={apiData.rosterData} setTotalStudents={setTotalStudents}
          year={year} isDistrictAdmin={isDistrictAdmin} isAggregate={isAggregate} sttVal={sttVal} />
      </div>
    </>
    )}
  </>
  );
};

OrgScoreRoster.propTypes = {
  apiData: PropTypes.object,
  summaryData: PropTypes.array,
  year: PropTypes.number,
  currentYear: PropTypes.number,
  sttVal: PropTypes.string,
  isDistrictAdmin: PropTypes.bool,
  selectedOrgId: PropTypes.number,
  isAggregate: PropTypes.bool,
  reportTitle: PropTypes.string,
  selectedAttended: PropTypes.string,
  selectedSubjects: PropTypes.array,
  selectedScores: PropTypes.array,
  selectedGradeLevels: PropTypes.array,
  selectedScholarAwards: PropTypes.array,
  appliedFilters: PropTypes.array,
  queryDataFromReport: PropTypes.func,
  reportHandledNoData: PropTypes.bool
};

export default OrgScoreRoster;

import { roleCodeMap } from './roleCodeMap';

const reportCurrentView = (pageName = 'home', roleCd = '') => {
  const role = roleCodeMap[roleCd];
  let applicationDataLayer = {
    page: {
      pageCode: pageName
    },
    user: {role}
  };
  try {
    window.kiwi.mergeDdl(applicationDataLayer);
    if (typeof window._satellite !== 'undefined') {
      window._satellite.track('cbTrack.viewInDom');
    }
  } catch (errKiwiMergeDdl) {
    if (typeof window._satellite !== 'undefined') {
      try {
        window._satellite.logger.error('Something went wrong with kiwi.mergeDdl(): ' + errKiwiMergeDdl.message);
      // eslint-disable-next-line no-empty
      } catch (errSatelliteLoggerError) {}
    }
  }
};

const reportAppErrorAsMap = (error, desc) => {
  if (typeof window._satellite !== 'undefined') {
    window._satellite.track('cbTrack.appError', {'Error': error, 'Desc': desc});
  }
};

const trackCustomReportLoadEvent = (reportType, reportCategory, eventDesc = '') => {
  if (typeof window._satellite !== 'undefined') {
    window._satellite.track('cbTrack.viewInDom', { 'ReportType': reportType, 'ReportView': reportCategory, 'Description': eventDesc });
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  reportCurrentView,
  reportAppErrorAsMap,
  trackCustomReportLoadEvent
};

import PropTypes from 'prop-types';
import React from 'react';
import DetailTable from '../common/detailTable';
import LabelWithTooltip from '../common/labelWithTooltip';
import { APCENTRAL_SCHOLAR_AWARD_LINK } from './utils';

const columns = [
  { title: 'Award Year', field: 'ap_admin_yyyy', className: 'cb-font-weight-bold', rowHeader: true },
  { title: 'Award Description', field: 'award_descr', className: 'cb-font-weight-bold' }
];

const AwardsDisplay = ({ awardData }) => {
  if (!awardData || awardData.length < 1) {
    return <></>;
  }

  return (
    <div
      id="divAwardsSection"
      className='cb-margin-top-48'
    >
      <div className='row cb-padding-left-16'>
        <h5 className='cb-margin-left-16'>AP Scholar Awards</h5>
        <LabelWithTooltip
          modalTitleComponent={<h2 className='cb-gray1-color cb-font-weight-regular cb-font-size-sm-res'>AP Scholar Awards</h2>}
          label={''}
          toolTipTitle={'AP Scholar Awards'}
          toolTipLabel={'AP Scholar Awards Tooltip'}
          modalContent={(<p className='cb-font-weight-regular cb-black1-color'>
          The AP Program offers several levels of AP Scholar Awards to recognize students who demonstrate outstanding college level achievement through AP courses and exams.{' '}<a target='_blank' href={APCENTRAL_SCHOLAR_AWARD_LINK} rel="noreferrer">
          Learn more about awards here.
            </a>
          </p>)}
          modalTrigger={'stsrScholarAwardsTrigger'}
        />
      </div>
      <div className='cb-margin-16 cb-white-bg'>
        <DetailTable
          columns={columns}
          data={awardData}
        />
      </div>

    </div>

  );
};

AwardsDisplay.propTypes = {
  awardData: PropTypes.array
};

export default AwardsDisplay;

import React from 'react';
import './loggedInAs.css';

const LoggedInAs = () => {
  return (
    <div>
      <p className ="login-as-text">ALERT: This is a ‘Login’ As session</p>
    </div>
  );
};

export default React.memo(LoggedInAs);

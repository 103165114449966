import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { H5_FONT_SIZE } from '../constants';

const styles = StyleSheet.create({
  baseCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: 'auto',
    marginVertical: 5,
    fontSize: H5_FONT_SIZE,
    fontFamily: 'Roboto-Bold'
  }
});

const HonorRollTableCellPdf = ({val, style, cellStyle}) => (
  <View style={[styles.baseCol, style]}>
    {
      typeof val !== 'object'
        ? <Text style={[styles.tableCell, cellStyle]}>{val}</Text>
        : val
    }
  </View>
);

HonorRollTableCellPdf.propTypes = {
  val: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  cellStyle: PropTypes.object
};

export default HonorRollTableCellPdf;

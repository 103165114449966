import {ascend, descend, prop, sortWith, toLower, compose} from 'ramda';

export const waitMs = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const orderBy = (arrToSort, field, ascending) => {
  const isNumericField = arrToSort.some(i => i[field].match(/^[0-9,.]+$/));
  if (isNumericField) {
    arrToSort = arrToSort.map(i => {
      return {
        ...i,
        [field]: i[field] === '' ? 0 : parseFloat(i[field].replace(/,/g, ''))
      };
    });
  }

  const ascendFunc = ascending ? ascend : descend;
  let sortedData = sortWith([
    isNumericField ? ascendFunc(prop(field)) : ascendFunc(compose(toLower, prop(field)))
  ])(arrToSort);

  // re-format numeric field
  if (isNumericField) {
    sortedData = sortedData.map(i => {
      return {
        ...i,
        [field]: i[field] === 0 ? '' : i[field].toLocaleString('en-US')
      };
    }
    );
  }

  return sortedData;
};

export const deriveFilterOptions = ({data = [], labelKey, valueKey, sortKey}) => {
  let sortedData = sortKey ? [...data].sort((a, b) => a[sortKey] > b[sortKey] ? 1 : -1) : [...data];
  const valuesWithData = sortedData.reduce((acc, item) => {
    acc[item[labelKey]] = item[valueKey];
    return acc;
  }, {});

  const options = [];
  for (const [key, value] of Object.entries(valuesWithData)) {
    options.push({
      value: value,
      label: key
    });
  }
  return options;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  waitMs,
  orderBy,
  deriveFilterOptions
};

import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PageHeader from '../common/pageHeader';
import CongratulationsHeader from './congratulationsHeader';
import { getNumberOfCategoriesWithTiers, isTier, TAB_1_TITLE } from '../../utils';
import { CREDIT, CULTURE, OPTIMIZATION } from '../../constants';
import ProgressChart from './progressChart';
import AccessAwardSection from './accessAward';
import FooterNotes from './footerNotes';
import NextTierDisplay from './nextTierDisplay';
import PageTitle from '../common/pageTitle';

const styles = StyleSheet.create({
  pageContainer: {
    width: '100%'
  },
  reportContainer: {
    paddingTop: 10,
    flexDirection: 'row',
    width: '100%'
  },
  leftContent: {
    flex: 5
  },
  rightContent: {
    flex: 4,
    paddingLeft: 6
  },
  chartsContainer: {
    paddingTop: 10,
    paddingHorizontal: 5
  },
  accessContainer: {
    marginTop: 20,
    marginBottom: 10
  },
  footerContainer: {
    paddingTop: 10,
    paddingHorizontal: 10
  }
});

const HonorRollPageOne = ({
  year,
  school,
  overall,
  accessAward,
  selectedAICaption,
  dataUpdatedDate,
  cultureRef,
  creditRef,
  optimizationRef,
  culturePercent,
  cultureTier,
  creditPercent,
  creditTier,
  optimizationPercent,
  optimizationTier,
  nextTierValues
}) => {
  const numCategoriesWithTiers = getNumberOfCategoriesWithTiers({cultureTier, creditTier, optimizationTier});

  return (
    <View style={styles.pageContainer}>
      <PageHeader year={year} selectedAICaption={selectedAICaption} dataUpdatedDate={dataUpdatedDate} />
      <PageTitle title={TAB_1_TITLE} />
      <View style={styles.reportContainer}>
        <View style={styles.leftContent}>
          <CongratulationsHeader year={year} school={school} overall={overall} />
          <View style={styles.chartsContainer}>
            <ProgressChart year={year} hasTier={isTier(cultureTier)} tier={cultureTier} val={culturePercent} chartRef={cultureRef} title={CULTURE}/>
            <ProgressChart year={year} hasTier={isTier(creditTier)} tier={creditTier} val={creditPercent} chartRef={creditRef} title={CREDIT}/>
            <ProgressChart year={year} hasTier={isTier(optimizationTier)} tier={optimizationTier} val={optimizationPercent} chartRef={optimizationRef} title={OPTIMIZATION}/>
          </View>

          {
            accessAward &&
            (<View style={styles.accessContainer}>
              <AccessAwardSection year={year} school={school} />
            </View>)
          }
        </View>
        <View style={styles.rightContent}>
          <NextTierDisplay
            numCategoriesWithTiers={numCategoriesWithTiers}
            hasTier={isTier(overall)}
            overall={overall}
            school={school}
            year={year}
            nextTierValues={nextTierValues}
          />
        </View>
      </View>

      <View style={styles.footerContainer}>
        <FooterNotes year={year} />
      </View>
    </View>
  );
};

HonorRollPageOne.propTypes = {
  year: PropTypes.number,
  selectedAICaption: PropTypes.string,
  dataUpdatedDate: PropTypes.string,
  accessAward: PropTypes.bool,
  school: PropTypes.string,
  overall: PropTypes.string,
  cultureRef: PropTypes.object,
  creditRef: PropTypes.object,
  optimizationRef: PropTypes.object,
  culturePercent: PropTypes.string,
  cultureTier: PropTypes.string,
  creditPercent: PropTypes.string,
  creditTier: PropTypes.string,
  optimizationPercent: PropTypes.string,
  optimizationTier: PropTypes.string,
  nextTierValues: PropTypes.object
};

export default HonorRollPageOne;

import PropTypes from 'prop-types';
import React, {useEffect, useState, useRef} from 'react';
import SubHeader from '../subHeader';
import DownloadModal from '../common/downloads/downloadModal';
import ScholarSummarySchoolTable from './schoolTable';
import ScholarSummaryDistrictTable from './districtTable';
import '../shared.css';
import ScholarSummaryTopDisplay from './headerDisplay';
import ScholarSummaryFilterSection from './filterSection';
import { parseScholarData, getGradeLevelFilterOptions, getAwardFilterOptions } from './dataParser';
import { getCsv, getScholarColumns } from './tableData';
import { getScholarSummaryData } from '../../../api';
import { getFilterQueryString } from '../../../utils/stringUtils';
import ReportContainer from '../common/reportContainer';

const downloadModalTrigger = 'openDownloadModal';
const EMPTY_FILTERS = {
  gradeLevelFilters: [],
  awardFilters: []
};

const noDataForAppliedFiltersMessage = (<div className='cb-red1-color'>No data for the applied filters</div>);

export const getUpdateUniqueScholarData = ({sttVal, year, setUniqueScholars, setUniqueScholarsAverage, setIsLoadingUnique}) => async (gradeFilter, awardFilter) => {
  const res = await getScholarSummaryData({ sttVal, navYear: year, awardFilter, gradeFilter });

  // only update top display and set top display loading to false if filters match currently set filters
  if (res.config.params.awardFilter === awardFilter &&
    res.config.params.gradeFilter === gradeFilter) {
    setUniqueScholars(res.data.uniqueScholarValues[1].val ?? 0);
    setUniqueScholarsAverage(res.data.uniqueScholarValues[0].val ?? '0.00');
    setIsLoadingUnique(false);
  }
};

const ScholarSummary = ({ isAggregate, apiData, sttVal, year, selectedOrgId }) => {
  const didMountRef = useRef(false);

  const gradeFilterOptions = getGradeLevelFilterOptions(apiData.scholarData);
  const [gradeLevelFilters, setGradeLevelFilters] = useState([]);
  const awardFilterOptions = getAwardFilterOptions(apiData.scholarData);
  const [awardFilters, setAwardFilters] = useState([]);
  const [uniqueScholars, setUniqueScholars] = useState(apiData.uniqueScholarValues[1].val);
  const [uniqueScholarsAverage, setUniqueScholarsAverage] = useState(apiData.uniqueScholarValues[0].val);

  const [isLoadingUnique, setIsLoadingUnique] = useState(false);

  const scholarData = parseScholarData(apiData.scholarData, {
    gradeLevelFilters,
    awardFilters
  });

  const updateUniqueScholarData = getUpdateUniqueScholarData({sttVal, year, setUniqueScholars, setUniqueScholarsAverage, setIsLoadingUnique});

  useEffect(() => {
    if (didMountRef.current) {
      setIsLoadingUnique(true);
      updateUniqueScholarData(
        getFilterQueryString(gradeLevelFilters),
        getFilterQueryString(awardFilters)
      );
    }
    didMountRef.current = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradeLevelFilters, awardFilters]);

  const removeFilter = (tagName) => {
    setGradeLevelFilters(gradeLevelFilters.filter((s) => s.label !== tagName));
    setAwardFilters(awardFilters.filter((s) => s.label !== tagName));
  };

  const columnData = getScholarColumns(isAggregate, apiData.scholarData, {
    gradeLevelFilters,
    awardFilters
  });

  const { csvData, csvHeader } = getCsv(isAggregate, parseScholarData(apiData.scholarData, EMPTY_FILTERS), getScholarColumns(isAggregate, apiData.scholarData, EMPTY_FILTERS).map(i => i.title));

  const noDataForAppliedFilters = scholarData.length === 0;
  return (<div className="container">
    <SubHeader showDownload={true} downloadModalTrigger={downloadModalTrigger} backgroundClass=""/>
    <DownloadModal
      title={isAggregate ? `Scholar Summary, Aggregated for Districts ${year}` : `Scholar Summary ${year}`}
      csvFilterText={`${isAggregate ? 'All Schools; ' : ''}All Scholars; No Filters`}
      csvData={csvData}
      filename={`AP ${isAggregate ? 'District' : 'School'} Scholar Summary ${year}`}
      csvHeader={csvHeader}
      org={selectedOrgId}
      auditTitle={isAggregate ? 'ScholarSummaryDAExport' : 'ScholarSummaryExport'}
      sttVal={sttVal}
      year={year}
      trigger={downloadModalTrigger}
      isAggregate={isAggregate}
      isDistrictAdmin={isAggregate}
    />

    <ScholarSummaryFilterSection
      setGradeLevelFilters={setGradeLevelFilters}
      setAwardFilters={setAwardFilters}
      removeFilter={removeFilter}
      gradeFilterOptions={gradeFilterOptions}
      gradeLevelFilters={gradeLevelFilters}
      awardFilterOptions={awardFilterOptions}
      awardFilters={awardFilters}
    />

    <ReportContainer>
      {
        noDataForAppliedFilters
          ? noDataForAppliedFiltersMessage
          : (
            <>
              <ScholarSummaryTopDisplay isLoading={isLoadingUnique} total={uniqueScholars} average={uniqueScholarsAverage} />
              <div className='cb-padding-top-16'>
                {
                  isAggregate
                    ? <ScholarSummaryDistrictTable tableData={scholarData} columnData={columnData} />

                    : <ScholarSummarySchoolTable tableData={scholarData} columnData={columnData} />
                }

              </div>
            </>
          )
      }

    </ReportContainer>
  </div>);
};

ScholarSummary.propTypes = {
  isAggregate: PropTypes.bool,
  apiData: PropTypes.object,
  year: PropTypes.number,
  selectedOrgId: PropTypes.number,
  sttVal: PropTypes.string
};

export default ScholarSummary;

/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState, useEffect, useContext, Fragment} from 'react';
import PropTypes from 'prop-types';
import './requestTypeSelect.css';
import RequestForm from './requestForm';
import Modal from './modal';
import analytics from '../../utils/analytics';
import '../ap-osre-home/homeSignedIn.css';
import '../../App.css';
import { AppContext } from '../../AppContext';
import { RadioButtonGroup, RadioButton, YellowButton } from '@cb/apricot-react';
import createMarkup from '../../utils/createMarkup';

const SelectRequestType = ({ reportsData, selectedOrg }) => {
  const [requestType, setRequestType] = useState('');
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [confirmationData, setConfirmationData] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(undefined);
  const appContext = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!appContext.isCustomerService) {
      analytics.reportCurrentView('selectRequestType', appContext.roleCd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadRequestForm = event => {
    event.preventDefault();
    if (requestType) {
      let requestTypeNoSpace = requestType.replace('/', ' or ');
      if (reportsData && (reportsData[0].role_cd === 5 || reportsData[0].role_cd === 6)) {
        setShowRequestForm(true);
        setConfirmationData({
          'requestType': requestTypeNoSpace,
          'firstName': '',
          'lastName': '',
          'schoolName': ''
        });
      } else {
        setShowRequestForm(true);
        setConfirmationData({
          'requestType': requestTypeNoSpace,
          'firstName': '',
          'lastName': ''
        });
      }
    } else {
      setShowErrorMessage('error');
    }
  };

  const handleOptionChange = changeEvent => {
    setRequestType(changeEvent);
    setShowErrorMessage(undefined);
  };

  const startNewRequest = () => {
    window.scrollTo(0, 0);
    setShowRequestForm(false);
    setRequestType('');
    setConfirmationData('');
  };

  return (
    <Fragment>
      <div className="org-name-container ">
        <div className="org-name admin-vertical-center-align">
          {selectedOrg.orgName}
        </div>
      </div>
      {showRequestForm ? (
        <RequestForm
          startNewRequest={startNewRequest}
          reportsData={reportsData}
          selectedOrg={selectedOrg}
          confirmationData={confirmationData}
          setConfirmationData={setConfirmationData}
          requestType={requestType}
        />
      ) : (
        <div>
          <div className="full-bleed">
            <div className="cb-hero-standard cb-hero-right banner-height-white-color full-bleed">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-6 flex-block">
                    <div className="content">
                      <p className="cb-hero-preamble"></p>
                      <p className="admin-tools-preamble breakpoint-margin">ADMINISTRATOR TOOLS</p>
                      <h2 className = "data-correction-title">Request Data Correction</h2>
                      <p className = "admin-tools-description">Report a data discrepancy on your reports and request an update.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-7 col-sm-12">
              <h2 className = "admin-title">
                Select a Request Type
              </h2>
              <p className = "email-address-description"> <b>Please note that responses may take 5-7 business days and will be sent to the email address on your&nbsp;
                <a href ={'https://cbaccount.collegeboard.org/professional/viewProfile'} target="_blank" rel="noopener noreferrer">College Board Account.</a></b>
              </p>
              <RadioButtonGroup
                name="requestRadios"
                vertical
                vSpacing="8"
                validation={showErrorMessage}
                validationMsg="You must select a request type"
                onChange={handleOptionChange}
              >
                <RadioButton
                  label={<b>Remove a Student</b>}
                  value="Remove a Student"
                />
                <p className ="type-description">Submit the name of a student appearing on your Student Score Report or Student Datafile who <a href='!#' id="removeStudentModal" aria-haspopup="dialog" tabIndex="0">does not attend your school</a> or district, and request they be removed
                </p>
                <Modal modalTitle = "Attended vs. Instructed Students" trigger = "removeStudentModal" />
                <RadioButton
                  label={<b>Add a Student</b>}
                  value="Add a Student"
                />
                <p className ="type-description">Submit the name of a student who attends your school or district but is missing from your Student Score Report or Student Datafile because they have <a href = {'https://apcentral.collegeboard.org/media/pdf/ap-students-with-wrong-AIcode.pdf'} target="_blank" rel="noopener noreferrer">an incorrect school on their AP registration.</a> Do not use this form for <a href='!#' id="submitModal" aria-haspopup="dialog" tabIndex="0">other situations where a student or a specific score may not appear in your reports</a>
                </p>
                <Modal modalTitle = "Reasons Why a Student or Score May Not Appear on your Reports" trigger = "submitModal" />
                <RadioButton
                  label={<b>Update Grade Level</b>}
                  value="Update Student Grade Level"
                />
                <p className ="type-description">Request a grade level update for a student appearing under the incorrect grade level in your reports.</p>
              </RadioButtonGroup>
              <div className="continue-button-container">
                <YellowButton className="general-continue-button" onClick={loadRequestForm}>Continue</YellowButton>
              </div>
              <div>
                {
                  appContext.content?.admin_tools_other_inquiries &&
                  <div dangerouslySetInnerHTML={createMarkup(appContext.content?.admin_tools_other_inquiries)}></div>
                }
                <Modal modalTitle = "Reasons Why a Student or Score May Not Appear on your Reports" trigger = "submitModal1" />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

SelectRequestType.propTypes = {
  reportsData: PropTypes.array,
  selectedOrg: PropTypes.object
};

export default SelectRequestType;

import { STUDENTS_URI } from '../constants/reports';
import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';

// returns list of students for a given org and year
const getReportStudentSelect = ({adminYear, reportName, sttVal}) => {
  const url =
  config.apiBaseUrl +
  config.genesisUri +
  STUDENTS_URI;

  const params = {
    sttVal,
    reportName,
    adminYear
  };

  return axiosInstance.get(url, { params });
};

export default getReportStudentSelect;

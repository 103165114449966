/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { BlackButton, MultiSelect, PrimaryButton, Select, TagSet } from '@cb/apricot-react';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import '@cb/apricot/CBGrid';
import LabelWithTooltip from '../../common/labelWithTooltip';
import FilterWithLabel from '../../common/filter-group/filterWithLabel';
import AttendedModalContent from '../../common/filter-group/attendedModalContent';
import ScholarAwardModalContent from '../../common/filter-group/scholarAwardModalContent';

// this dropdown always has these values
export const DEFAULT_ATTENDED_VALUE = '1';
const ATTENDED_INSTRUCTED_OPTIONS = [
  {value: '1', label: 'All Students'},
  {value: '2', label: 'Attending Students'},
  {value: '3', label: 'Instructed Students'},
  {value: '4', label: 'Students Both Attending & Instructed at your Organization'}
];

const getAppliedAttendedLabel = (selected, labelOnly) => ATTENDED_INSTRUCTED_OPTIONS.filter(i => i.value === selected).map(i => `${labelOnly ? '' : STUDENTS_LABEL}${i.label}`);
const getAttendedApiValue = selected => selected === DEFAULT_ATTENDED_VALUE ? undefined : selected;
const getApiValue = selected => selected.length > 0 ? selected.map(i => i.value).join(',') : undefined;

const SUBJECT_LABEL = 'Subject: ';
const STUDENTS_LABEL = 'Students: ';
const SCORE_LABEL = 'Score: ';
const GRADE_LABEL = 'Grade Level: ';
const SCHOLAR_AWARD_LABEL = 'Scholar Award: ';

const OsrFilterSection = ({
  subjectFilterData,
  scoreFilterData,
  gradeFilterData,
  scholarAwardsFilterData,
  scholarAwardEnabled,
  show,
  queryDataFromReport,

  currentSubjects,
  currentScores,
  currentGrades,
  currentAttended,
  currentScholarAwards,
  currentApplied,

  setCurrentSubjects,
  setCurrentScores,
  setCurrentGrades,
  setCurrentAttended,
  setCurrentScholarAwards
}) => {

  const onApplyFilters = () => {
    const newAppliedFilters = ([
      ...currentSubjects.map(i => `${SUBJECT_LABEL}${i.label}`),
      ...(currentAttended === DEFAULT_ATTENDED_VALUE ? [] : getAppliedAttendedLabel(currentAttended)),
      ...currentScores.map(i => `${SCORE_LABEL}${i.label}`),
      ...currentGrades.map(i => `${GRADE_LABEL}${i.label}`),
      ...currentScholarAwards.map(i => `${SCHOLAR_AWARD_LABEL}${i.label}`)
    ]);
    queryDataFromReport({
      // below are used to retain filter settings when we return
      selectedSubjects: currentSubjects,
      selectedAttended: currentAttended,
      selectedScores: currentScores,
      selectedGradeLevels: currentGrades,
      selectedScholarAwards: currentScholarAwards,
      appliedFilters: newAppliedFilters,

      // below will be used in api call
      fltSubject: getApiValue(currentSubjects),
      fltInstruction: getAttendedApiValue(currentAttended),
      fltScore: getApiValue(currentScores),
      fltGrade: getApiValue(currentGrades),
      fltScholarAwards: getApiValue(currentScholarAwards)
    });
  };

  const onRemoveFilters = () => {
    setCurrentSubjects([]);
    setCurrentAttended(DEFAULT_ATTENDED_VALUE);
    setCurrentScores([]);
    setCurrentGrades([]);
    setCurrentScholarAwards([]);
  };

  // removes AND re-queries data
  const onRemoveFilter = tagName => {
    const newSubjects = currentSubjects.filter((i) => `${SUBJECT_LABEL}${i.label}` !== tagName);
    const newScores = currentScores.filter((i) => `${SCORE_LABEL}${i.label}` !== tagName);
    const newGrades = currentGrades.filter((i) => `${GRADE_LABEL}${i.label}` !== tagName);
    const newAttended = getAppliedAttendedLabel(currentAttended).join() === tagName ? DEFAULT_ATTENDED_VALUE : currentAttended;
    const newScholarAwards = currentScholarAwards.filter((i) => `${SCHOLAR_AWARD_LABEL}${i.label}` !== tagName);
    const newAppliedFilters = [
      ...newSubjects.map(i => `${SUBJECT_LABEL}${i.label}`),
      ...(newAttended === DEFAULT_ATTENDED_VALUE ? [] : getAppliedAttendedLabel(newAttended)),
      ...newScores.map(i => `${SCORE_LABEL}${i.label}`),
      ...newGrades.map(i => `${GRADE_LABEL}${i.label}`),
      ...newScholarAwards.map(i => `${SCHOLAR_AWARD_LABEL}${i.label}`)
    ];
    queryDataFromReport({
      // below are used to retain filter settings when we return
      selectedSubjects: newSubjects,
      selectedAttended: newAttended,
      selectedScores: newScores,
      selectedGradeLevels: newGrades,
      selectedScholarAwards: newScholarAwards,
      appliedFilters: newAppliedFilters,

      // below will be used in api call
      fltSubject: getApiValue(newSubjects),
      fltInstruction: getAttendedApiValue(newAttended),
      fltScore: getApiValue(newScores),
      fltGrade: getApiValue(newGrades),
      fltScholarAwards: getApiValue(newScholarAwards)
    });
  };
  // accessibility - set focus to filter section when first shown
  const firstElementRef = useRef(null);
  useEffect(() => {
    if (show) {
      firstElementRef.current.focus();
    }
  }, [show]);
  return (<>
    <div className='container cb-margin-bottom-16' style={{display: show ? 'block' : 'none'}} ref={firstElementRef}>
      <div className='row'>
        <FilterWithLabel label='Subject'>
          <MultiSelect
            options={subjectFilterData}
            label='Subjects'
            fieldName='subjectFilter'
            selectId='subjectFilterId'
            onChange={(selections) => setCurrentSubjects(selections)}
            value={currentSubjects}
            validation={false}
            filter={true}
            selectAll={true}
            groupedCheckbox={true}
          />
        </FilterWithLabel>

        <FilterWithLabel label={(<div className='display-flex' style={{ minWidth: '470px'}}>
          <LabelWithTooltip
            label={'Attended/Instructed'}
            toolTipTitle={'Attending/Instructed'}
            toolTipLabel={'Attending/Instructed Tooltip'}
            modalContent={<AttendedModalContent />}
            modalTrigger={'osr_attended_tooltip_id'}
          />
        </div>)}>
          <Select
            id='osrAttendedFilterId'
            ariaLabel="Attended/Instructed filter select"
            values={ATTENDED_INSTRUCTED_OPTIONS}
            value={currentAttended}
            onChange={(selectedValue) => setCurrentAttended(selectedValue)}
          />
        </FilterWithLabel>

        <FilterWithLabel label='Score'>
          <MultiSelect
            options={scoreFilterData}
            label="Scores"
            fieldName="scoreFilter"
            selectId="scoreFilterId"
            onChange={(selections) => setCurrentScores(selections)}
            value={currentScores}
            validation={false}
            selectAll={true}
          />
        </FilterWithLabel>

        <FilterWithLabel label='Grade Level'>
          <MultiSelect
            options={gradeFilterData}
            label="Grade Levels"
            fieldName="gradeFilter"
            selectId="gradeFilterId"
            onChange={(selections) => setCurrentGrades(selections)}
            value={currentGrades}
            validation={false}
            selectAll={true}
          />
        </FilterWithLabel>

        {scholarAwardEnabled && <FilterWithLabel label={(<div className='display-flex'>
          <LabelWithTooltip
            label={'AP Scholar Awards'}
            toolTipTitle={'Scholar Award'}
            toolTipLabel={'Scholar Award Tooltip'}
            modalContent={<ScholarAwardModalContent />}
            modalTrigger={'osr_scholar_award_tooltip_id'}
          />
        </div>)}>
          <MultiSelect
            options={scholarAwardsFilterData}
            label='AP Scholar Awards'
            fieldName='scholarAwardsFilter'
            selectId='scholarAwardsFilterId'
            onChange={(selections) => setCurrentScholarAwards(selections)}
            value={currentScholarAwards}
            validation={false}
            selectAll={true}
          />
        </FilterWithLabel>
        }
      </div>
      <div className='cb-margin-top-8 row justify-content-end'>
        <BlackButton id='removeFiltersButton' className='cb-margin-right-8' onClick={onRemoveFilters}>Remove Filters</BlackButton>
        <PrimaryButton id='applyFiltersButton' onClick={onApplyFilters}>Apply Filters</PrimaryButton>
      </div>
    </div>
    {currentApplied.length > 0 &&
          <div className='container'>
            <div className="cb-padding-top-8 dropdown-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Applied Filters:</div>
            <TagSet
              label={`Applied Filters`}
              offScreen="Remove"
              tags={currentApplied}
              onClick={(tagName, e) => {
                e.preventDefault();
                onRemoveFilter(tagName);
              }}
            />
            <div className='cb-padding-bottom-16'></div>
          </div>
    }
  </>
  );
};

OsrFilterSection.propTypes = {
  subjectFilterData: PropTypes.array,
  scoreFilterData: PropTypes.array,
  gradeFilterData: PropTypes.array,
  scholarAwardsFilterData: PropTypes.array,
  scholarAwardEnabled: PropTypes.bool,
  show: PropTypes.bool,
  queryDataFromReport: PropTypes.func,
  currentSubjects: PropTypes.array,
  currentScores: PropTypes.array,
  currentGrades: PropTypes.array,
  currentAttended: PropTypes.string,
  currentApplied: PropTypes.array,
  currentStandard: PropTypes.string,
  currentScholarAwards: PropTypes.array,
  setCurrentSubjects: PropTypes.func,
  setCurrentScores: PropTypes.func,
  setCurrentGrades: PropTypes.func,
  setCurrentAttended: PropTypes.func,
  setCurrentStandard: PropTypes.func,
  setCurrentScholarAwards: PropTypes.func
};

export default OsrFilterSection;

let getNewTime = () => {
  let d = new Date();
  let hours = d.getHours();
  let ampm = hours >= 12 ? 'pm' : 'am';
  let dformat =
    ('00' + (d.getMonth() + 1)).slice(-2) + '/' +
    ('00' + d.getDate()).slice(-2) + '/' +
    d.getFullYear() + ' ' +
    ('00' + hours).slice(-2) + ':' +
    ('00' + d.getMinutes()).slice(-2) + ampm;
  return dformat;
};

const newTime = {
  dateTimeFormat: getNewTime()
};

const getDisplayDate = dateString => {
  try {
    const splitOnDash = dateString.split('-');
    const year = splitOnDash[0];
    const month = splitOnDash[1];
    const day = splitOnDash[2].split('T')[0];
    return `${month}/${day}/${year}`;
  } catch (err) {
    return '';
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...newTime,
  getDisplayDate
};

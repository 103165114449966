import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ReportContainer = ({children}) => (
  <div className="container content-gutter cb-padding-16 cb-padding-bottom-48 cb-margin-top-32">
    {children}
  </div>
);

ReportContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ReportContainer;

/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Route,
  Switch,
  useLocation,
  Redirect
} from 'react-router-dom';
import PropTypes from 'prop-types';
import AddPropsToRoute from './addPropsToRoute';
import ProtectedRoute from './protectedRoute';
import Home from '../ap-osre-home/home';
import NativeReports from '../ap-osre-native-reports';
import RequestTypeSelect from '../ap-osre-admin-tools/requestTypeSelect';

export const routes = {
  siteName: 'AP Score Reports for Educators',
  menu: {
    'Administrator Tools': {
      'href': '/AdministratorTools',
      'multiline': false,
      'component': RequestTypeSelect
    }
  }
};

export const teacherRoutes = {
  siteName: 'AP Score Reports for Educators',
  menu: {
  }
};

const SiteRoutes = (props) => {
  let location = useLocation();
  let isAdmin = false;
  if (props.reportsData && props.reportsData.length) {
    if (props.reportsData[0].role_cd !== 1 && props.reportsData[0].role_cd !== 2) {
      isAdmin = true;
    }
  }
  return (
    <Switch>
      <Route exact path="/" key={location.key} component={AddPropsToRoute(Home, props)} />
      {<Route path="/report/:id/:subject?" children={<NativeReports {...props} />} />}
      {isAdmin && <Route path="/AdministratorTools" children={<RequestTypeSelect {...props} />} />}
      {!isAdmin && Object.keys(teacherRoutes.menu).map((key) =>
        <ProtectedRoute exact key={key} path={teacherRoutes.menu[key].href} {...props} component={teacherRoutes.menu[key].component} />
      )
      }
      <Redirect to="/" />
    </Switch>
  );
};

SiteRoutes.propTypes = {
  reportsData: PropTypes.array
};

export default SiteRoutes;

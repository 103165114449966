import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Tabs, Tab, Modal, CircularButton, MultiSelect } from '@cb/apricot-react';
import DetailTable from '../common/detailTable';
import reportConstants from './constants';
import '@cb/apricot/CBGrid';
import './index.css';
import { getFormattedTotalsByScore,
  getFormattedStudentsByDemographics,
  getFormattedTotalsByDemographics,
  getSubjectFilterOptions,
  getScoreFilterOptions,
  getDemographicFilterOptions } from './dataParser';
import { getSubjectFilterGroups } from '../common/dataParser';
import {
  getTotalsByScoreColumns,
  getTotalsByDemographicColumns,
  getStudentsByDemographicColumns
} from './tableData';
import FilterGroup from '../common/filter-group/filterGroup';

const noDataForAppliedFiltersMessage = (<div className='cb-red1-color'>No data for the applied filters</div>);

// Copy about table in 3rd tab is dynamic based on demographic type
export const getStudentsByDemographicCopy = (demographic) => {
  switch (demographic) {
    case reportConstants.FEE_REDUCTION_LABEL:
      return `${demographic.toLowerCase()} status and `;
    case reportConstants.RACE_ETHNICITY_LABEL:
    case reportConstants.GENDER_LABEL:
      return `${demographic.toLowerCase()} and `;
    case reportConstants.GRADE_LEVEL_LABEL:
    default:
      return '';
  }
};

const StudentDemographicSummary = ({
  queryDataFromReport,
  setAdditionalReportProps,
  apiData,
  isAggregate,

  // below are report specific props, that will be undefined at first render and set using setAdditionalReportProps
  demographic = reportConstants.GRADE_LEVEL_LABEL,
  initialSubjectFilters = [],
  initialScoreFilters = [],
  initialDemographicFilters = []
}) => {

  const totalStudentsHeader = isAggregate ? apiData?.totalStudents[0].sum : apiData?.totalStudents[0].reportable_student_count;
  const totalSchoolsHeader = isAggregate ? apiData?.totalStudents[0].count : null;
  const formattedTotalStudentHeader = parseInt(totalStudentsHeader).toLocaleString('en-US');
  const formattedTotalSchoolsHeader = parseInt(totalSchoolsHeader).toLocaleString('en-US');

  const filterDropdownContainerClass = 'col-lg-3 col-md-4 col-sm-12';
  const totalStudentsModalId = 'totalStudentsTooltip';

  const [selectedDemographic, setSelectedDemographic] = useState(demographic);

  const [subjectFilters, setSubjectFilters] = useState(initialSubjectFilters);
  const [scoreFilters, setScoreFilters] = useState(initialScoreFilters);
  const [demographicFilters, setDemographicFilters] = useState(initialDemographicFilters);

  useEffect(() => {
    // maintains filter values if user goes to interstitial page then cancels
    setAdditionalReportProps({
      demographic: selectedDemographic,
      initialSubjectFilters: subjectFilters,
      initialScoreFilters: scoreFilters,
      initialDemographicFilters: demographicFilters
    });

  }, [setAdditionalReportProps, selectedDemographic, subjectFilters, scoreFilters, demographicFilters]);

  const demographicOptions = [
    {label: reportConstants.GRADE_LEVEL_LABEL},
    {label: reportConstants.RACE_ETHNICITY_LABEL},
    {label: reportConstants.FEE_REDUCTION_LABEL},
    {label: reportConstants.GENDER_LABEL}
  ];

  const removeFilter = (tagName) => {
    setSubjectFilters(subjectFilters.filter((s) => s.value !== tagName));
    setScoreFilters(scoreFilters.filter((s) => s.value !== tagName));
    setDemographicFilters(demographicFilters.filter((s) => s.value !== tagName));
  };

  const onDemographicChange = (selectedValue) => {
    queryDataFromReport({demographic: selectedValue});
    setSelectedDemographic(selectedValue);
  };

  // apply filters and format the genesis data response
  const formattedTotalsByScore = getFormattedTotalsByScore(apiData.totalsByDemographic, {
    subjectFilters,
    scoreFilters,
    demographicFilters
  });
  const formattedTotalsByDemographics = getFormattedTotalsByDemographics(apiData.totalsByDemographic, {
    subjectFilters,
    scoreFilters,
    demographicFilters
  });
  const formattedStudentsByDemographics = getFormattedStudentsByDemographics(apiData.studentsByDemographic);

  const subjectFilterOptions = getSubjectFilterOptions(apiData.totalsByDemographic);
  const groupedSubjectFilterOptions = getSubjectFilterGroups(subjectFilterOptions);
  const scoreFilterOptions = getScoreFilterOptions(apiData.totalsByDemographic);
  const demographicFilterOptions = getDemographicFilterOptions(apiData.totalsByDemographic);

  const noDataForAppliedFilters = formattedTotalsByScore.length === 2;

  return (
    <>
      <div className="container">
        <div className={'cb-padding-top-8 cb-padding-bottom-8'}>
          <div className="display-inline-block cb-margin-right-8">
            {/* Dev note - we are not using apricot select label property because it cannot easily be styled in line with the select itself as of apricot 4.3. */}
            <div className="dropdown-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Demographic:</div>
          </div>
          <div className="display-inline-block demographicDropdown" id="demographic_dropdown_container">
            <Select
              ariaLabel="Demographic Dropdown"
              values={demographicOptions}
              value={selectedDemographic}
              onChange={onDemographicChange}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="cb-padding-top-8 dropdown-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Filters:</div>
        <FilterGroup
          onFilterRemove={removeFilter}
          appliedFilters={[...subjectFilters, ...scoreFilters, ...demographicFilters]}
          tags
        >
          <div className={filterDropdownContainerClass} style={{'verticalAlign': 'top'}}>
            <MultiSelect
              options={groupedSubjectFilterOptions}
              label="Subjects"
              fieldName="subjectFilter"
              selectId="subjectFilter-dropdown"
              onChange={(selections) => setSubjectFilters(selections)}
              value={subjectFilters}
              validation={false}
              filter={true}
              selectAll={true}
              groupedCheckbox={true}
            />
          </div>

          <div className={filterDropdownContainerClass} style={{'verticalAlign': 'top'}}>
            <MultiSelect
              options={scoreFilterOptions}
              label="Scores"
              fieldName="scoreFilter"
              selectId="scoreFilter-dropdown"
              onChange={(selections) => setScoreFilters(selections)}
              value={scoreFilters}
              validation={false}
              selectAll={true}
            />
          </div>

          <div className={filterDropdownContainerClass} style={{'verticalAlign': 'top'}}>
            <MultiSelect
              options={demographicFilterOptions}
              label={selectedDemographic}
              fieldName="demographicFilter"
              selectId="demographicFilter-dropdown"
              onChange={(selections) => setDemographicFilters(selections)}
              value={demographicFilters}
              validation={false}
              selectAll={true}
            />
          </div>
        </FilterGroup>
      </div>
      <div className="container">
        <div
          className="cb-scrollbar-container content-gutter cb-padding-16 cb-padding-bottom-48 cb-margin-top-48"
        >
          <h5>
            Total Students: {formattedTotalStudentHeader}{isAggregate && `; Total Schools: ${formattedTotalSchoolsHeader}` }
            <CircularButton icon="question" small greyscale className="cb-margin-left-8" title="Total Students - More information" label="Total Students - More information" aria-haspopup="dialog" tabIndex="0" id={totalStudentsModalId} style={{position: 'absolute'}}/>
          </h5>

          <Modal title="Total Students" trigger={totalStudentsModalId} withFooter={false}>
            <p>Note: If you apply filters to this report, this total students count will not change as it is a reference point for your entire group of students.</p>
          </Modal>

          <div className='cb-margin-top-8' id='demographic_tab_container' >
            <Tabs>
              <Tab label="Totals by Scores" panelId="totalsByScores" tabId="totalsByScoresTab" selected>
                {noDataForAppliedFilters
                  ? noDataForAppliedFiltersMessage
                  : <div>
                    <DetailTable
                      a11yHighlightText=''
                      tableId="totals_by_score_table"
                      data={formattedTotalsByScore}
                      columns={getTotalsByScoreColumns()}
                      striped={false}
                      rowHighlighted={({highlighted}) => highlighted}
                      responsive
                    />
                  </div>}
              </Tab>
              <Tab label={`Totals by ${selectedDemographic}`} panelId="totalsByDemographic" tabId="totalsByDemographicTab">
                {noDataForAppliedFilters
                  ? noDataForAppliedFiltersMessage
                  : <div>
                    <DetailTable
                      a11yHighlightText=''
                      tableId="totals_by_demographic_table"
                      data={formattedTotalsByDemographics}
                      columns={getTotalsByDemographicColumns(demographic)}
                      striped={false}
                      rowHighlighted={({highlighted}) => highlighted}
                      responsive
                    />
                  </div>
                }
              </Tab>
              <Tab label={`Students by ${selectedDemographic}`} panelId="studentsByDemographic" tabId="studentsByDemographicTab">
                <div>
                  <div className="content-gutter cb-padding-8">This table shows the total number of students, by {getStudentsByDemographicCopy(demographic)}grade level, who took AP Exams in your {isAggregate ? 'district' : 'school'}. <span className='cb-font-weight-bold'>If you apply filter options to customize this report, the data in this table will not change.</span> It is available in each {isAggregate ? 'district' : 'school'} summary report as a reference.</div>
                  <DetailTable
                    tableId="students_by_demographic_table"
                    a11yHighlightText=''
                    data={formattedStudentsByDemographics}
                    columns={getStudentsByDemographicColumns(demographic)}
                    striped={true}
                    responsive
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
          <div className="content-gutter cb-padding-8 cb-padding-top-32">The data in this report differs from other College Board reports, such as The AP Cohort Data Report, which tracks exams taken by seniors throughout their time in high school (cohort-based) and includes public school data only.</div>
        </div>
      </div>
    </>
  );
};

StudentDemographicSummary.propTypes = {
  apiData: PropTypes.object,
  queryDataFromReport: PropTypes.func,
  setAdditionalReportProps: PropTypes.func,
  isAggregate: PropTypes.bool,
  demographic: PropTypes.string,
  initialSubjectFilters: PropTypes.array,
  initialScoreFilters: PropTypes.array,
  initialDemographicFilters: PropTypes.array
};

export default StudentDemographicSummary;

import PropTypes from 'prop-types';
import React from 'react';
import { Modal, CircularButton } from '@cb/apricot-react';

const LabelWithTooltip = ({label, lightTooltip = false, toolTipTitle, toolTipLabel, modalTitleComponent, modalContent, modalTrigger}) => {
  return (<>
    {
      label &&
      <span>{label}</span>
    }
    <CircularButton icon="question" greyscale light={lightTooltip} small className="cb-margin-left-4" title={toolTipLabel} label={toolTipLabel} aria-haspopup="dialog" tabIndex="0" id={modalTrigger}/>
    <div style={{position: 'absolute'}}>
      <Modal title={modalTitleComponent ?? toolTipTitle} trigger={modalTrigger} withFooter={false}>
        <div>{modalContent}</div>
      </Modal>
    </div>
  </>);
};

LabelWithTooltip.propTypes = {
  label: PropTypes.string,
  toolTipLabel: PropTypes.string,
  toolTipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  modalContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  modalTrigger: PropTypes.string,
  modalTitleComponent: PropTypes.node,
  lightTooltip: PropTypes.bool
};

export default LabelWithTooltip;

import PropTypes from 'prop-types';

const orgHs = (orgData, {setShowBlockHomeSchool}) => {

  let hsData = orgData.map(org => {
    let aiSrcCd = [];
    const orgPath = org._source;

    aiSrcCd = orgPath.ais.map(cd => cd.ai_source_cd);
    if (orgPath.org_type_cd === '9' && orgPath.org_sub_type_cd === '12' && orgPath.org_status_cd === 'A' &&
          aiSrcCd.indexOf('APO') > -1) {
      return Number(orgPath.org_id);
    }
    return null;
  }).filter(result => result !== null);

  if (hsData.length === orgData.length) {
    setShowBlockHomeSchool(true);
  } else {
    setShowBlockHomeSchool(false);
  }
  return hsData;
};

const isHomeSchoolOrg = (orgId, hsOrgs) => hsOrgs.indexOf(orgId) > -1;

orgHs.PropTypes = {
  setShowBlockHomeSchool: PropTypes.func,
  orgs: PropTypes.object
};

isHomeSchoolOrg.PropTypes = {
  orgId: PropTypes.object,
  hsOrgs: PropTypes.object
};

export {
  orgHs,
  isHomeSchoolOrg
};

import { BlackButton } from '@cb/apricot-react';
import propTypes from 'prop-types';
import React from 'react';

const DownloadRow = ({description, filterText, buttonId, buttonTitle, imageId, imageSrc, text, downloadFile, filename, isLoading, noStyling}) => {
  return (
    <div className="row justify-content-between align-items-center cb-margin-bottom-16">
      <div className="row" style={noStyling ? {} : {minWidth: '130px', marginRight: '35px'}}>
        <div className='cb-margin-right-2'> <img
          src={imageSrc}
          alt=''
          id={imageId}
          className='cb-padding-right-4'
          tabIndex="-1"
        /></div>
        <div className='display-inline cb-padding-right-16'>
          <h4 id={buttonTitle.replaceAll(' ', '-')} className="cb-sans-serif cb-font-size-regular cb-font-weight-bold">
            {text}
          </h4>
          {description && (
            <div className='cb-font-size-small'>{description}</div>
          )}
        </div>
      </div>
      {filterText && (<div style={{flexGrow: 1}}>{filterText}</div>)}
      <div style={noStyling ? {} : {minWidth: '145px', display: 'flex', justifyContent: 'flex-end'}}>
        <BlackButton
          title={buttonTitle}
          id={buttonId}
          onClick={() => {
            downloadFile({filename});
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              downloadFile({filename});
            }
          }}
          data-cbtrack-download={filename}
          loading={isLoading}
          aria-describedby={buttonTitle.replaceAll(' ', '-')}
        >
          <span className="cb-padding-right-4 cb-sans-serif cb-font-size-small cb-font-weight-bold">Download</span>
          <span className="cb-icon cb-download"></span>
        </BlackButton>
      </div>
    </div>);
};
DownloadRow.propTypes = {
  filterText: propTypes.string,
  buttonId: propTypes.string,
  buttonTitle: propTypes.string,
  imageId: propTypes.string,
  text: propTypes.string,
  imageSrc: propTypes.node,
  downloadFile: propTypes.func,
  filename: propTypes.string,
  description: propTypes.string,
  isLoading: propTypes.bool,
  noStyling: propTypes.bool
};

export default DownloadRow;

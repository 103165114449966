
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './confirmationPage.css';
import analytics from '../../utils/analytics';
import './requestTypeSelect.css';
import { AppContext } from '../../AppContext';
import { PrimaryButton, Illustration } from '@cb/apricot-react';

const ConfirmationPage = ({
  confirmationData,
  reportsData,
  startNewRequest,
  confirmationNumber,
  submitDate
}) => {
  const appContext = useContext(AppContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!appContext.isCustomerService) {
      analytics.reportCurrentView(
        'adminConfirmationPage',
        appContext.roleCd
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const underscorify = (data) => {
    if (reportsData) {
      if (reportsData[0].role_cd === 5 || reportsData[0].role_cd === 6) {
        if (!data.schoolAICode) {
          data['schoolAICode'] = '_';
        }
      }
    }
    if (!data.apNumber) {
      data['apNumber'] = '_';
    }
    if (!data.description) {
      data['description'] = '_';
    }
    return data;
  };

  const confData = underscorify(confirmationData);
  return (
    <div className="row">
      <div className="col-lg-6">
        <h2 className="admin-title">Confirmation</h2>
        <div className="confirmation-email-response">
          {' '}
          Your request has been successfully submitted. We will respond back to
          your request within 5-7 days via the email on your{' '}
          <a
            href={'https://cbaccount.collegeboard.org/professional/viewProfile'}
          >
            College Board Account
          </a>
        </div>
        <h3 className="confirmation-heading">Record Details</h3>
        <hr className="confirmation-underline" />
        <div className="confirmation-subheading">Tracking ID</div>
        <p className="confirmation-paragraph">{confirmationNumber}</p>
        <div className="confirmation-subheading">Date Submitted</div>
        <p className="confirmation-paragraph">{submitDate}</p>
        <h3 className="confirmation-heading">Request Details</h3>
        <hr className="confirmation-underline" />
        {confData
          ? Object.keys(confData).map((confirmationHeading, i) => {
            let confirmationParagraph = confData[confirmationHeading];
            let correctedHeading;
            switch (confirmationHeading) {
              case 'firstName':
                correctedHeading = 'Student First Name';
                break;
              case 'lastName':
                correctedHeading = 'Student Last Name';
                break;
              case 'schoolName':
                correctedHeading = 'School Name';
                break;
              case 'requestType':
                correctedHeading = 'Request Type';
                break;
              case 'schoolAICode':
                correctedHeading = 'School AI Code';
                break;
              case 'apNumber':
                correctedHeading = 'AP Number/AP ID';
                break;
              case 'description':
                correctedHeading = 'Issue/Request Description';
                break;
              case 'correctGradeLevel':
                correctedHeading = 'Correct Grade Level';
                confirmationParagraph = confirmationParagraph.replace(
                  '&lt;',
                  '<'
                );
                break;
              default:
                correctedHeading = confirmationHeading;
            }
            return (
              <div key={i + confirmationHeading}>
                <p
                  key={i + confirmationHeading + 'heading'}
                  className="confirmation-subheading"
                >
                  {correctedHeading}
                </p>
                <div
                  key={i + confirmationHeading + 'paragraph'}
                  className="confirmation-paragraph"
                >
                  {confirmationParagraph}
                </div>
              </div>
            );
          }, this)
          : null}
        <div className="blue-button-spacing-width">
          <PrimaryButton
            onClick={startNewRequest}
            className="cb-palette-blue2 new-request-button"
          >
            Start a new request
          </PrimaryButton>
        </div>
      </div>
      <div className="col-lg-1"></div>
      <div className="col-lg-3">
        <div className="cb-card cb-card-cta cb-white-bg cb-black1-color confirmation-title">
          <div className="cb-card-header justify-content-center">
            <Illustration
              name={'print'}
              size={'72'}
              monotone={true}
              circular={true}
            />
          </div>
          <div className="cb-card-content cb-align-center">
            <h3 className="cb-font-weight-light cb-margin-bottom-24">
              For your records, take a screenshot or print this page.
            </h3>
            <div className="cb-card-action justify-content-center">
              <button
                className="cb-btn cb-btn-yellow"
                onClick={() => {
                  window.print();
                }}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmationPage.propTypes = {
  confirmationData: PropTypes.object,
  reportsData: PropTypes.array,
  startNewRequest: PropTypes.func,
  confirmationNumber: PropTypes.string,
  submitDate: PropTypes.string
};

export default ConfirmationPage;


import {
  getEquityAndExcellenceData,
  getFiveYearScoreSummaryData,
  getCurrentYearScoreSummaryData,
  getSummaryByStudentDemographicData,
  getDistrictSummaryBySchoolData,
  getScholarSummaryData,
  getHonorRollData,
  getIprData,
  getStudentScoreData,
  getOrgScoreRosterData,
  getSubjectScoreRosterData,
  getScholarRosterData
} from './../../api/';
import {
  EQUITY_AND_EXCELLENCE_REPORT_TITLE,
  FIVE_YEAR_SCORE_SUMMARY_REPORT_TITLE,
  CURRENT_YEAR_SCORE_SUMMARY_REPORT_TITLE,
  SUMMARY_BY_STUDENT_DEMOGRAPHIC_REPORT_TITLE,
  DISTRICT_SUMMARY_BY_SCHOOL_REPORT_TITLE,
  SCHOLAR_SUMMARY_REPORT_TITLE,
  HONOR_ROLL_REPORT_TITLE,
  INSTRUCTIONAL_PLANNING_REPORT_TITLE,
  HONOR_ROLL_REPORT_DISPLAY_TITLE,
  INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE,
  STUDENT_SCORE_REPORT_TITLE,
  ORG_SCORE_ROSTER_REPORT_TITLE,
  SUBJECT_SCORE_ROSTER_REPORT_TITLE,
  SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE,
  SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE_DA,
  SCHOLAR_ROSTER_REPORT_TITLE
} from './../../constants/reports';
import EquityAndExcellence from './equity-and-excellence';
import FiveYearScoreSummary from './five-year-score-summary';
import CurrentYearScoreSummary from './current-year-score-summary';
import SummaryByStudentDemographics from './summary-by-student-demographics';
import DistrictSummaryBySchool from './district-summary-by-school';
import ScholarSummary from './scholar-summary';
import HonorRoll from './honor-roll';
import InstructionalPlanning from './instructional-planning';
import getIprDataAppSync from '../../api/appsync/instructionalPlanning';
import StudentScore from './student-score';
import OrgScoreRoster from './org-score-roster';
import SubjectScoreRoster from './subject-score-roster';
import ScholarRoster from './scholar-roster';

/*
  Below are the settings for each report.

  {
    reportName: [string] ID for the report,
    Report: [React Component] main report component,
    callApi: [function] to retrieve data for report,
    getReportTitle: [function] to retrieve report title string,
    shouldShowInterstitialPage: [function] to return boolean to indicate whether interstitial page should be shown,

    interstitialSchoolDropdown: optional [boolean] indicates whether interstitial page has school dropdown,
    interstitialSubjectDropdown: optional [boolean] indicates whether interstitial page has subject dropdown,
    interstitialSectionDropdown: optional [boolean] indicates whether interstitial page has section dropdown,
  }
*/
export const reportMap = {
  [EQUITY_AND_EXCELLENCE_REPORT_TITLE]: {
    reportName: EQUITY_AND_EXCELLENCE_REPORT_TITLE,
    Report: EquityAndExcellence,
    callApi: getEquityAndExcellenceData,
    shouldShowInterstitialSchoolDropdown: () => true,
    getReportTitle: (isAggregate) =>
      isAggregate
        ? 'Equity and Excellence, Aggregated for Districts'
        : 'Equity and Excellence',
    shouldShowInterstitialPage: ({isDistrictAdmin, isAggregate}) =>
      !isAggregate && isDistrictAdmin
  },
  [FIVE_YEAR_SCORE_SUMMARY_REPORT_TITLE]: {
    reportName: FIVE_YEAR_SCORE_SUMMARY_REPORT_TITLE,
    Report: FiveYearScoreSummary,
    callApi: getFiveYearScoreSummaryData,
    shouldShowInterstitialSchoolDropdown: () => true,
    getReportTitle: () => 'Five Year Score Summary',
    shouldShowInterstitialPage: ({isDistrictAdmin, isAggregate}) =>
      !isAggregate && isDistrictAdmin
  },
  [CURRENT_YEAR_SCORE_SUMMARY_REPORT_TITLE]: {
    reportName: CURRENT_YEAR_SCORE_SUMMARY_REPORT_TITLE,
    Report: CurrentYearScoreSummary,
    callApi: getCurrentYearScoreSummaryData,
    shouldShowInterstitialSchoolDropdown: () => true,
    getReportTitle: (isAggregate) =>
      isAggregate
        ? 'Current Year Score Summary, Aggregated for Districts'
        : 'Current Year Score Summary',
    shouldShowInterstitialPage: ({isDistrictAdmin, isAggregate}) =>
      !isAggregate && isDistrictAdmin
  },
  [SUMMARY_BY_STUDENT_DEMOGRAPHIC_REPORT_TITLE]: {
    reportName: SUMMARY_BY_STUDENT_DEMOGRAPHIC_REPORT_TITLE,
    Report: SummaryByStudentDemographics,
    callApi: getSummaryByStudentDemographicData,
    shouldShowInterstitialSchoolDropdown: () => true,
    getReportTitle: (isAggregate) =>
      isAggregate
        ? 'Summary by Student Demographics, Aggregated for Districts'
        : 'Summary by Student Demographics',
    shouldShowInterstitialPage: ({isDistrictAdmin, isAggregate}) =>
      !isAggregate && isDistrictAdmin
  },
  [DISTRICT_SUMMARY_BY_SCHOOL_REPORT_TITLE]: {
    reportName: DISTRICT_SUMMARY_BY_SCHOOL_REPORT_TITLE,
    Report: DistrictSummaryBySchool,
    callApi: getDistrictSummaryBySchoolData,
    getReportTitle: () => 'District Summary by School'
  },
  [SCHOLAR_SUMMARY_REPORT_TITLE]: {
    reportName: SCHOLAR_SUMMARY_REPORT_TITLE,
    Report: ScholarSummary,
    callApi: getScholarSummaryData,
    getReportTitle: (isAggregate) =>
      isAggregate
        ? 'Scholar Summary, Aggregated for Districts'
        : 'Scholar Summary'
  },
  [HONOR_ROLL_REPORT_TITLE]: {
    reportName: HONOR_ROLL_REPORT_TITLE,
    Report: HonorRoll,
    callApi: getHonorRollData,
    shouldShowInterstitialSchoolDropdown: () => true,
    getReportTitle: () => HONOR_ROLL_REPORT_DISPLAY_TITLE,
    shouldShowInterstitialPage: ({isDistrictAdmin}) => isDistrictAdmin
  },
  [INSTRUCTIONAL_PLANNING_REPORT_TITLE]: {
    reportName: INSTRUCTIONAL_PLANNING_REPORT_TITLE,
    Report: InstructionalPlanning,
    getApiFunction: ({isAggregate}) => isAggregate ? getIprDataAppSync : getIprData,
    // access to {isTeacher, isDistrictAdmin, isAggregate} for below
    shouldShowInterstitialSchoolDropdown: ({ isDistrictAdmin, isAggregate }) => isDistrictAdmin && !isAggregate, // only for district user when running By School
    shouldShowInterstitialSubjectDropdown: () => true,
    shouldShowInterstitialSectionDropdown: ({ isAggregate }) => !isAggregate, // only shown on non-aggregate
    getReportTitle: (isAggregate) =>
      isAggregate
        ? `${INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE}, Aggregated for Districts`
        : INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE,
    shouldShowInterstitialPage: () => true
  },
  [STUDENT_SCORE_REPORT_TITLE]: {
    reportName: STUDENT_SCORE_REPORT_TITLE,
    Report: StudentScore,
    callApi: getStudentScoreData,
    getReportTitle: () => 'Student Score Report',
    shouldShowInterstitialPage: ({ isDistrictAdmin }) => !isDistrictAdmin,
    showInterstitialStudentComponent: true
  },
  [ORG_SCORE_ROSTER_REPORT_TITLE]: {
    reportName: ORG_SCORE_ROSTER_REPORT_TITLE,
    Report: OrgScoreRoster,
    callApi: getOrgScoreRosterData,
    getReportTitle: (isAggregate) =>
      isAggregate
        ? 'Organization Score Roster, Aggregated for Districts'
        : 'Organization Score Roster',
    shouldShowInterstitialSchoolDropdown: () => true,
    shouldShowInterstitialPage: ({ isDistrictAdmin, isAggregate }) => !isAggregate && isDistrictAdmin
  },
  [SUBJECT_SCORE_ROSTER_REPORT_TITLE]: {
    reportName: SUBJECT_SCORE_ROSTER_REPORT_TITLE,
    Report: SubjectScoreRoster,
    callApi: getSubjectScoreRosterData,
    // access to {isTeacher, isDistrictAdmin, isAggregate} for below
    shouldShowInterstitialSchoolDropdown: ({ isDistrictAdmin, isAggregate }) => isDistrictAdmin && !isAggregate, // only for district user when running By School
    shouldShowInterstitialSubjectDropdown: () => true,
    shouldShowInterstitialSectionDropdown: ({ isAggregate }) => !isAggregate, // only shown on non-aggregate
    getReportTitle: (isAggregate) =>
      isAggregate
        ? SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE_DA
        : SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE,
    shouldShowInterstitialPage: () => true
  },
  [SCHOLAR_ROSTER_REPORT_TITLE]: {
    reportName: SCHOLAR_ROSTER_REPORT_TITLE,
    Report: ScholarRoster,
    callApi: getScholarRosterData,
    shouldShowInterstitialSchoolDropdown: () => true,
    getReportTitle: () => 'Scholar Roster',
    shouldShowInterstitialPage: ({isDistrictAdmin, isAggregate}) =>
      !isAggregate && isDistrictAdmin
  }
};

import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';
import { HONOR_ROLL_CERTIFICATE_PDF_URI } from '../constants/reports';

const getHonorRollCertificate = ({ year, tier, schoolName, accessAward }) => {
  const url = `${config.apiBaseUrl}${config.genesisUri}${HONOR_ROLL_CERTIFICATE_PDF_URI}`;
  const params = {
    tier,
    year,
    schoolName,
    accessAward
  };

  return axiosInstance.get(url, { params });
};

export default getHonorRollCertificate;

/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './requestForm.css';
import ConfirmationPage from './confirmationPage';
import BackModal from './backModal';
import './backModal.css';
import Modal from './modal';
import analytics from '../../utils/analytics';
import ShowError from '../ap-osre-error-handling/ErrorBoundary';
import Spinner from '../common/loader/spinner';
import MediaQuery from 'react-responsive';
import { AppContext } from '../../AppContext';
import {postCustomerFeedback} from '../../api';
import { Input, BlackButton, YellowButton, Select, NumericIcon } from '@cb/apricot-react';

const testRegex = /[^a-z0-9 ,.!&*?%@'-]/gi;

const RequestForm = ({
  confirmationData,
  setConfirmationData,
  reportsData,
  startNewRequest,
  selectedOrg,
  requestType
}) => {
  const [showschoolNameError, setShowschoolNameError] = useState(false);
  const [showfirstNameError, setShowfirstNameError] = useState(false);
  const [showlastNameError, setShowlastNameError] = useState(false);
  const [showcorrectGradeLevelError, setShowcorrectGradeLevelError] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState('');
  const [submitDate, setSubmitDate] = useState(undefined);
  const [apiError, setApiError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const appContext = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!appContext.isCustomerService) {
      analytics.reportCurrentView('adminRequestForm', appContext.roleCd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserNumberInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    // const regex = /[^0-9]/gi;
    if (value.length === 0) {
      let newConfirmationData = confirmationData;
      delete newConfirmationData[name];
      setConfirmationData(newConfirmationData);
    } else {
      setConfirmationData({
        ...confirmationData,
        [name]: value
      });
    }
  };

  const handleUserTextInput = e => {
    const {name, value} = e.target;
    if (!value || value.length === 0) {
      let newConfirmationData = confirmationData;
      delete newConfirmationData[name];
      setConfirmationData(newConfirmationData);
    } else if (value.length < 101) {
      setConfirmationData({
        ...confirmationData,
        [name]: value
      });
    } else if (value.length < 1001 && name === 'description') {
      setConfirmationData({
        ...confirmationData,
        [name]: value
      });
    }
  };

  const isNumberInput = e => {
    const charCode = e.which ? e.which : e.keyCode;
    // allow only numbers between 0-9
    if ((charCode > 31 && (charCode < 48 || charCode > 57 || (e.shiftKey && (charCode > 47 || charCode < 58))))) {
      e.preventDefault();
    }
  };

  const checkForEmptyFields = () => {
    let schoolNameError;
    let firstNameError = 'error';
    let lastNameError = 'error';
    let correctGradeLevelError;
    let errorFlag = false;
    if (reportsData) {
      if (reportsData[0].role_cd === 5 || reportsData[0].role_cd === 6) {
        if (confirmationData['schoolName']) {
          schoolNameError = false;
        } else {
          schoolNameError = 'error';
        }
      }
    }
    if (confirmationData['firstName']) {
      firstNameError = false;
    }
    if (confirmationData['lastName']) {
      lastNameError = false;
    }
    if (requestType === 'Update Student Grade Level') {
      if (confirmationData['correctGradeLevel'] && confirmationData['correctGradeLevel'] !== 'Select') {
        correctGradeLevelError = false;
      } else {
        correctGradeLevelError = 'error';
      }
    }

    errorFlag = (schoolNameError === 'error' || firstNameError === 'error' || lastNameError === 'error' || correctGradeLevelError === 'error');
    if (errorFlag) {
      setShowschoolNameError(schoolNameError);
      setShowfirstNameError(firstNameError);
      setShowlastNameError(lastNameError);
      setShowcorrectGradeLevelError(correctGradeLevelError);
    }
    return errorFlag;
  };

  const validateField = (fieldName, value, textRegex) => {
    return fieldName !== 'correctGradeLevel'
      ? value.replace(textRegex, ' ')
      : value;
  };

  const sendRequest = async () => {
    let formatConfData = {};
    let noErrors = !checkForEmptyFields();
    if (noErrors) {
      Object.keys(confirmationData).forEach((confirmationHeading, i) => {
        formatConfData[confirmationHeading] = validateField(confirmationHeading, confirmationData[confirmationHeading], testRegex);
      });
      setConfirmationData(formatConfData);
      window.scrollTo(0, 0);
      setDisableSubmitButton(true);
      setIsLoading(true);
      let requestTypeCode;
      switch (requestType) {
        case 'Remove a Student':
          requestTypeCode = 1;
          break;
        case 'Add a Student':
          requestTypeCode = 8;
          break;
        case 'Update Student Class Section':
          requestTypeCode = 2;
          break;
        case 'Update Student Grade Level':
          requestTypeCode = 3;
          break;
        default:
          break;
      }

      try {
        let admintoolRequest = {
          'feedback': formatConfData,
          'orgId': Number(selectedOrg.orgId),
          'profId': Number(appContext.userId),
          'currentYear': appContext.currentYear,
          'orgName': selectedOrg.orgName,
          'requestTypeCode': requestTypeCode
        };
        let res = await postCustomerFeedback(admintoolRequest);
        setShowConfirmationPage(true);
        setConfirmationNumber(res.data.id);
        setSubmitDate(res.data.reqSubmitDate);
      } catch (error) {
        window.scrollTo(0, 0);
        setApiError({ error });
        setDisableSubmitButton(false);
      }

      setIsLoading(false);
    }
  };

  return (
    <div>
      {
        isLoading ? <Spinner /> : (
          apiError ? <ShowError error={apiError}/> : (
            showConfirmationPage ? (
              <ConfirmationPage
                startNewRequest={startNewRequest}
                reportsData={reportsData}
                confirmationNumber={confirmationNumber}
                submitDate={submitDate}
                confirmationData={confirmationData}
              />
            ) : (
              <div className="row">
                <div className="col-lg-6 col-md-7 col-sm-12">
                  <h2 className="admin-title space-below-title">{requestType}</h2>
                  {reportsData
                    ? reportsData[0].role_cd === 5 || reportsData[0].role_cd === 6
                      ? <div>
                        <Input
                          name = 'schoolName'
                          label = {<b>School Name</b>}
                          placeholder = 'ex: ABC High School'
                          onChange={handleUserTextInput}
                          floating = {false}
                          maxLength = {255}
                          validation = {showschoolNameError}
                          validationMsg = "School name is required."
                          labelClassName = "space-above-label"
                          clearable={false}
                        />
                        <div className="cb-spacerv-24" />
                        <div className="cb-input">
                          <label htmlFor="schoolAICode" ><b>School AI Code</b> &nbsp;- Optional
                            <MediaQuery query="(max-width: 768px)">
                              <a href='!#' id="submitModal2" aria-haspopup="dialog" >
                                <NumericIcon
                                  color = "black1"
                                  size = "28"
                                  className = "custom-glyph-bottom"
                                >?
                                </NumericIcon>
                              </a>
                              <Modal modalTitle="Find a School AI Code" trigger = "submitModal2"/>
                            </MediaQuery>
                          </label>
                          <MediaQuery query="(min-width: 769px)">
                            <a href='!#' id="submitModal" aria-haspopup="dialog" style={{ float: 'right' }}>Where can I find this?</a>
                            <Modal modalTitle="Find a School AI Code" trigger = "submitModal"/>
                          </MediaQuery>
                          <input type="text" maxLength="6" name="schoolAICode" id="schoolAICode" placeholder="ex: 123456" onChange={handleUserNumberInput} onKeyDown={isNumberInput} />
                        </div>
                      </div>
                      : null
                    : null
                  }
                  <div className="near-half-width-margin-right">
                    <Input
                      name = 'firstName'
                      label = {<b>Student First Name</b>}
                      placeholder = 'ex: John'
                      onChange={handleUserTextInput}
                      floating = {false}
                      maxLength = {100}
                      validation = {showfirstNameError}
                      validationMsg = "Student first name is required"
                      labelClassName = "space-above-label"
                      clearable={false}
                    />
                  </div>
                  <div className="near-half-width">
                    <Input
                      name = 'lastName'
                      label = {<b>Student Last Name</b>}
                      placeholder = 'ex: Smith'
                      onChange={handleUserTextInput}
                      floating = {false}
                      maxLength = {100}
                      validation = {showlastNameError}
                      validationMsg = "Student last name is required"
                      labelClassName = "space-above-label"
                      clearable={false}
                    />
                  </div>
                  <div className="cb-input">
                    <label htmlFor="apNumber" className="space-above-label"><b>AP Number / AP ID</b> &nbsp;- Optional</label>
                    <input type="text" maxLength="8" name="apNumber" id="apNumber" placeholder="ex: 12345678" onChange={handleUserTextInput}/>
                  </div>
                  {requestType === 'Update Student Grade Level'
                    ? <Select id="correctGradeLevel" name="correctGradeLevel" onChange={value => handleUserTextInput({target: {name: 'correctGradeLevel', value}})}
                      labelNoValue="Select" ariaLabel="Select Correct Grade Level" label={<b>Correct Grade Level</b>} labelClassName="space-above-label"
                      values = {[
                        {
                          label: '<9th Grade',
                          value: '&lt;9th Grade'
                        },
                        {
                          label: '9th Grade'
                        },
                        {
                          label: '10th Grade'
                        },
                        {
                          label: '11th Grade'
                        },
                        {
                          label: '12th Grade'
                        },
                        {
                          label: 'No longer in high school'
                        }
                      ]}
                      validation = {showcorrectGradeLevelError}
                      validationMsg = "Grade level is required."/>
                    : null}
                  <div className="cb-input">
                    <label htmlFor="description" className="space-above-label"><b>Issue/Request Description</b> &nbsp;- Optional</label>
                    <label htmlFor="description">
                        Please enter the details for this request. Limit of 1000 characters.
                        Special characters #, $, ;, (, ), &quot;, and ^ are not accepted.
                    </label>
                    <textarea maxLength="1000" rows="7" style={{ resize: 'none' }} name="description"
                      id="description" placeholder="enter description here" onChange={handleUserTextInput}
                    />
                  </div>
                  <div className="cb-spacerv-48" />
                  <span>
                    <BlackButton id="openBackModal" aria-haspopup="dialog" tabIndex="0" style={{ width: '148px' }}>Cancel</BlackButton>
                    <YellowButton onClick={sendRequest} disabled={disableSubmitButton} style={{ float: 'right' }}>Submit Request</YellowButton>
                    <BackModal startNewRequest={startNewRequest} trigger = "openBackModal" />
                  </span>
                </div>
              </div>
            )
          )
        )
      }
    </div>
  );
};

RequestForm.propTypes = {
  confirmationData: PropTypes.object,
  setConfirmationData: PropTypes.func,
  reportsData: PropTypes.array,
  startNewRequest: PropTypes.func,
  selectedOrg: PropTypes.object,
  requestType: PropTypes.string
};

export default RequestForm;

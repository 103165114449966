import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import analytics from '../../utils/analytics';
import { AppContext } from '../../AppContext';

const DashboardHeader = ({
  selectedOrg,
  setSelectedOrg,
  searchedOrg,
  setSearchedOrg,
  setShowOrgSelection,
  setShowRegionalSearch,
  selectedYear,
  setSelectedYear,
  setYearChanged,
  setNotCourseAuthorized
}) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const appContext = useContext(AppContext);
  const currentYear = appContext.currentYear;
  const years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4 ];

  const changeOrgVal = () => {
    setShowOrgSelection(true);
    setShowRegionalSearch(false);
    setSelectedOrg(undefined);
    setSearchedOrg(undefined);
  };

  const regionalSearch = () => {
    setShowOrgSelection(false);
    setShowRegionalSearch(true);
    setSearchedOrg(undefined);
  };

  const yearChange = e => {
    if (e !== selectedYear) {
      let yearSelected = e;
      if (!appContext.isCustomerService) {
        let dashboardView = '';
        dashboardView = yearSelected !== currentYear ? 'dashboard-' + yearSelected : 'dashboard';
        analytics.reportCurrentView(dashboardView, appContext.roleCd);
      }
      setInitialLoad(false);
      setYearChanged(true);
      setSelectedYear(yearSelected);
      setNotCourseAuthorized(false);
    }
  };

  return (
    <Fragment>
      <div className="org-name-container ">
        <div className="org-name org-vertical-center-align">
          {appContext.isCustomerService
            ? <span>Logged in as: {appContext.firstName} {appContext.lastName} / {selectedOrg.orgName}</span>
            : selectedOrg.orgName}
          {appContext.orgs.length > 1
            ? <span className="changeOrg">
              <a tabIndex="0" href='!#' onClick={e => {
                e.preventDefault();
                changeOrgVal();
              }}>Change Organization</a>
            </span>
            : null}
          {searchedOrg && searchedOrg.orgId
            ? <span>
              <span className={'searched-org-name'}>{searchedOrg.orgName}</span>
              <span className={'regional-search'}>
                <a tabIndex="0" href='!#' className={'regional-search'} onClick={e => {
                  e.preventDefault();
                  regionalSearch();
                }}>Regional Search</a>
              </span>
            </span>
            : null}
        </div>
      </div>
      <div className="full-bleed">
        <div className="cb-hero cb-hero-standard cb-align-center cb-white-color">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-8 offset-md-2 col-2xl-6 offset-2xl-3">
                <h2 className="cb-band-panel-title cb-roboto-slab"> AP Score Reports</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="last-updated-years-container">
          <div className="container vertical-center-align">
            <div className = "years-container">
              <span className = "infosign administration-year">Administration Year:</span>
              <div className="cb-toggles-btn" data-cb-apricot="toggles-btn">
                {years
                  ? years.map((year, i) => {
                    return <label key={year} className="radio-inline" htmlFor={year}>
                      <input type="radio" defaultChecked={year === selectedYear} data-cb-element="no-cb" name="apricot4" id={year} value={year} onClick={(e) => yearChange(year)} />
                      <span className={(year === selectedYear) ? 'cb-blue2-bg cb-white-color' : ''}>{year}</span>
                    </label>;
                  }) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

DashboardHeader.propTypes = {
  selectedOrg: PropTypes.object,
  setSelectedOrg: PropTypes.func,
  searchedOrg: PropTypes.object,
  setSearchedOrg: PropTypes.func,
  setShowOrgSelection: PropTypes.func,
  setShowRegionalSearch: PropTypes.func,
  selectedYear: PropTypes.number,
  setSelectedYear: PropTypes.func,
  setYearChanged: PropTypes.func,
  setNotCourseAuthorized: PropTypes.func
};

export default DashboardHeader;

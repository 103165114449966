export const roleCodeMap = {
  1: 'AP Teacher without PII',
  2: 'AP Teacher with PII',
  3: 'School Admin without PII',
  4: 'School Admin with PII',
  5: 'District Admin without PII',
  6: 'District Admin with PII',
  7: 'Regional Role without PII',
  8: 'Regional Role without PII'
};

export const getRoleCodeFromReportList = reportData => Array.isArray(reportData.Reports) ? reportData.Reports[0].role_cd : '';

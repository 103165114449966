import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { getDisplayTier, getPercentageDisplay, getPercentageLabel, isTier } from '../../utils';
import TableCell from './tableCell';
import { formatCountValue } from '../../../../../utils/stringUtils';
import { H5_FONT_SIZE, LARGE_TEXT_FONT_SIZE } from '../constants';

const styles = StyleSheet.create({
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tierCol: {
    width: '10%'
  },
  categoryCol: {
    width: '30%'
  },
  schoolValueCol: {
    width: '15%'
  },
  tableCell: {
    margin: 'auto',
    marginVertical: 5,
    fontSize: H5_FONT_SIZE,
    fontFamily: 'Roboto-Bold'
  },
  schoolCellSpacing: {
    marginVertical: 2
  },
  countFont: {
    margin: 'auto',
    fontSize: LARGE_TEXT_FONT_SIZE,
    fontFamily: 'Roboto-Italic'
  },
  schoolContainer: {
    flexDirection: 'column'
  },
  categoryCell: {
    margin: 0,
    paddingLeft: 5
  }
});

const HonorRollTableRowPdf = ({
  category,
  bronze,
  silver,
  gold,
  platinum,
  percent,
  count,
  grade12Enrollment,
  tier
}) => {
  let displayPerc = getPercentageLabel(parseInt(percent));
  displayPerc = getPercentageDisplay(displayPerc);
  return (<View style={styles.tableRow}>
    <TableCell style={styles.categoryCol} cellStyle={styles.categoryCell} val={category}/>
    <TableCell style={styles.tierCol} val={`${bronze}%`}/>
    <TableCell style={styles.tierCol} val={`${silver}%`}/>
    <TableCell style={styles.tierCol} val={`${gold}%`}/>
    <TableCell style={styles.tierCol} val={`${platinum}%`}/>
    <TableCell style={styles.schoolValueCol} val={<View style={styles.schoolContainer}>
      <View><Text style={[styles.tableCell, styles.schoolCellSpacing]}>{displayPerc}</Text></View>
      {
        grade12Enrollment &&
        <View><Text style={[styles.countFont, styles.schoolCellSpacing]}>({formatCountValue(count, true)}/{formatCountValue(grade12Enrollment)})</Text></View>
      }
    </View>}/>
    <TableCell style={styles.schoolValueCol} val={isTier(tier) ? getDisplayTier(tier) : ''}/>
  </View>);
};

HonorRollTableRowPdf.propTypes = {
  category: PropTypes.string,
  grade12Enrollment: PropTypes.number,
  count: PropTypes.number,
  bronze: PropTypes.number,
  silver: PropTypes.number,
  gold: PropTypes.number,
  platinum: PropTypes.number,
  tier: PropTypes.string,
  percent: PropTypes.string
};

export default HonorRollTableRowPdf;

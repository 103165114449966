import gql from 'graphql-tag';
import openDownloadInNewTab from '../../common/downloads/openDownloadTab';
import { getAuthSsrSinglePdf } from '../../../../graphql/queries';
import { subscribeToSsrSinglePdf } from '../../../../graphql/subscriptions';
import { getAppSyncResponseHandler } from '../../common/pdf/common';

export const getSinglePdf = ({
  orgId,
  selectedYear,
  roleCd,
  reportName,

  setFileData,
  navSchool,
  navSection,
  sectionDisplay,
  subjectDisplay,
  navSubject,
  navYear,
  sttVal,
  isDistrictAdmin,
  isAggregate,
  selectedAICaption,

  fltTeacher,
  fltScore,
  fltGrade,
  fltInstruction,
  fltTeacherDisplay,
  fltScoreDisplay,
  fltGradeDisplay,
  fltInstructionDisplay
}) => {
  const cb = window.cb;
  openDownloadInNewTab({
    subscribeParams: {
      query: gql(subscribeToSsrSinglePdf),
      variables: {
        catapultId: cb.core.iam.getTempAWSCredsSubject(),
        orgId: parseInt(orgId),
        adminYear: parseInt(selectedYear),
        roleCd: parseInt(roleCd),
        reportName
      }
    },
    queryParams: {
      query: gql(getAuthSsrSinglePdf),
      variables: {
        // required params
        sttVal,
        navYear,
        isDistrictAdmin,
        isAggregate,
        navSchool,
        selectedAICaption,
        navSubject,
        navSection,
        subjectDisplay,
        sectionDisplay,

        fltTeacher,
        fltScore,
        fltGrade,
        fltInstruction,
        fltTeacherDisplay,
        fltScoreDisplay,
        fltGradeDisplay,
        fltInstructionDisplay
      }
    },
    downloadFile: getAppSyncResponseHandler('subscribeToSsrSinglePdf', setFileData)
  });
};

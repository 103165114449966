
import stringUtils from '../../../utils/stringUtils';
// totals by score parsing here

// updates total exams object with current exam values
export const updateExamObj = (aggregateExamObj, examObj) => {
  const row = { ...aggregateExamObj };
  row.s1 += parseInt(examObj.s1, 10);
  row.s2 += parseInt(examObj.s2, 10);
  row.s3 += parseInt(examObj.s3, 10);
  row.s4 += parseInt(examObj.s4, 10);
  row.s5 += parseInt(examObj.s5, 10);
  row.total += parseInt(examObj.s1, 10) + parseInt(examObj.s2, 10) + parseInt(examObj.s3, 10) + parseInt(examObj.s4, 10) + parseInt(examObj.s5, 10);
  return row;
};

const getInitialExamObj = (examName, highlighted = false) => ({
  exam_name: examName,
  s1: 0,
  s2: 0,
  s3: 0,
  s4: 0,
  s5: 0,
  total: 0,
  highlighted
});

const getEmptyScores = () => ({
  s1: '0',
  s2: '0',
  s3: '0',
  s4: '0',
  s5: '0'
});

export const applyFilters = (arrApiTotalsByDemographic, appliedFilters) => {
  // if scores filter is applied, we need to remove counts for all other non-selected score filters
  let totals = arrApiTotalsByDemographic;
  if (appliedFilters.scoreFilters.length > 0) {
    totals = arrApiTotalsByDemographic.map(item => {
      const scoresToSaveObj = {};
      appliedFilters.scoreFilters.forEach(score => {
        scoresToSaveObj['s' + score.label] = item['s' + score.label];
      });
      return {
        ...item,
        ...getEmptyScores(),
        ...scoresToSaveObj
      };
    });
  }

  return totals.filter(item => {
    return (
      (appliedFilters.subjectFilters.length === 0 || appliedFilters.subjectFilters.some((elem) => elem.label === item.exam_name)) &&
      (appliedFilters.scoreFilters.length === 0 || appliedFilters.scoreFilters.some((elem) => item['s' + elem.label] !== '0')) &&
      (appliedFilters.demographicFilters.length === 0 || appliedFilters.demographicFilters.some((elem) => elem.label === item.demographic_descr))
    );
  });
};

// Takes api array and transforms it for totals by score DetailTable data format
export const getFormattedTotalsByScore = (arrApiTotalsByDemographic, appliedFilters = {}) => {

  const filteredData = applyFilters(arrApiTotalsByDemographic, appliedFilters);

  const totalsByExam = filteredData.reduce((acc, item) => {
    if (typeof acc[item.exam_name] === 'undefined') {
      acc[item.exam_name] = getInitialExamObj(item.exam_name);
    }
    acc[item.exam_name] = updateExamObj(acc[item.exam_name], item);

    return acc;
  },
  {});

  let formatted = [];
  for (const [key, value] of Object.entries(totalsByExam)) {
    formatted.push(value);
  }
  // sort by exam_name
  formatted.sort((a, b) => {
    if (a.exam_name < b.exam_name) {
      return -1;
    }
    if (a.exam_name > b.exam_name) {
      return 1;
    }
    return 0;
  });

  const totalExamsObj = formatted.reduce((acc, item) => {
    acc = updateExamObj(acc, item);
    return acc;
  },
  getInitialExamObj('Total Exams', true));

  // caluculate % of total exams
  const percentageTotalExamsObj = {
    exam_name: '% of Total Exams',
    total: '100%',
    highlighted: true
  };
  percentageTotalExamsObj.s1 = stringUtils.getTotalsPercentageString(totalExamsObj, 's1', 'total');
  percentageTotalExamsObj.s2 = stringUtils.getTotalsPercentageString(totalExamsObj, 's2', 'total');
  percentageTotalExamsObj.s3 = stringUtils.getTotalsPercentageString(totalExamsObj, 's3', 'total');
  percentageTotalExamsObj.s4 = stringUtils.getTotalsPercentageString(totalExamsObj, 's4', 'total');
  percentageTotalExamsObj.s5 = stringUtils.getTotalsPercentageString(totalExamsObj, 's5', 'total');

  formatted.push(totalExamsObj);
  formatted = formatted.map(i => stringUtils.replaceObjZerosAndCountValues(i));
  formatted.push(percentageTotalExamsObj);

  return formatted;
};

// students by demographic parsing here

// Takes api array and transforms it for students by demographic DetailTable data format
export const getFormattedStudentsByDemographics = (arrApiStudentsByDemographic) => {
  return [...arrApiStudentsByDemographic].map(i => stringUtils.replaceObjZerosAndCountValues(i));
};

// totals by demographic parsing here

// Takes api array and transforms it for totals by demographic DetailTable data format
export const getFormattedTotalsByDemographics = (arrApiTotalsByDemographic, appliedFilters = {}) => {

  const filteredData = applyFilters(arrApiTotalsByDemographic, appliedFilters);

  const formatted = filteredData.map(item => {
    // calculate totals value for this exam
    return {
      total: parseInt(item.s1, 10) + parseInt(item.s2, 10) + parseInt(item.s3, 10) + parseInt(item.s4, 10) + parseInt(item.s5, 10),
      ...item
    };
  });

  // sort by exam_name within each demographic
  formatted.sort((a, b) => {
    if (a.demographic_code_sort_order === b.demographic_code_sort_order && a.exam_name < b.exam_name) {
      return -1;
    }
    if (a.demographic_code_sort_order === b.demographic_code_sort_order && a.exam_name > b.exam_name) {
      return 1;
    }
    return 0;
  });

  let currentTotalsObj = {};
  for (let i = 0; i < formatted.length; i++) {
    formatted[i].mean = getScoreMean(formatted[i]);
    formatted[i].standard_deviation = getStandardDeviation(formatted[i]);

    // if this is the first exam of a demographic value, initialize the currentTotalsObj to start tracking for this demographic
    if (currentTotalsObj.demographic_descr !== formatted[i].demographic_descr) {

      // if this is not the first demographic value, splice the previous totals obj at the previous index
      if (typeof currentTotalsObj.demographic_descr !== 'undefined') {
        currentTotalsObj.mean = getScoreMean(currentTotalsObj);
        currentTotalsObj.standard_deviation = getStandardDeviation(currentTotalsObj);
        currentTotalsObj = stringUtils.replaceObjZerosAndCountValues(currentTotalsObj);
        formatted.splice(i, 0, currentTotalsObj);

        // increment i since we just spliced at previous index
        i++;
      }

      currentTotalsObj = {
        demographic_descr: formatted[i].demographic_descr,
        exam_name: 'Total Exams',
        s1: parseInt(formatted[i].s1, 10),
        s2: parseInt(formatted[i].s2, 10),
        s3: parseInt(formatted[i].s3, 10),
        s4: parseInt(formatted[i].s4, 10),
        s5: parseInt(formatted[i].s5, 10),
        total: parseInt(formatted[i].total, 10),
        highlighted: true
      };
    } else {
      // updates current totals obj with this exam's values
      currentTotalsObj.s1 += parseInt(formatted[i].s1, 10);
      currentTotalsObj.s2 += parseInt(formatted[i].s2, 10);
      currentTotalsObj.s3 += parseInt(formatted[i].s3, 10);
      currentTotalsObj.s4 += parseInt(formatted[i].s4, 10);
      currentTotalsObj.s5 += parseInt(formatted[i].s5, 10);
      currentTotalsObj.total += parseInt(formatted[i].total, 10);
    }

    // replace '0's with empty strings
    formatted[i] = stringUtils.replaceObjZerosAndCountValues(formatted[i]);

  }

  // push the final totals obj to the formatted array
  currentTotalsObj.mean = getScoreMean(currentTotalsObj);
  currentTotalsObj.standard_deviation = getStandardDeviation(currentTotalsObj);
  formatted.push(stringUtils.replaceObjZerosAndCountValues(currentTotalsObj));

  return formatted;
};

const getScoreMean = (item, roundedToTwoDigits = true) => {
  if (item.total <= 0) {
    return '';
  }
  const mean = ((parseInt(item.s1, 10) +
  2 * parseInt(item.s2, 10) +
  3 * parseInt(item.s3, 10) +
  4 * parseInt(item.s4, 10) +
  5 * parseInt(item.s5, 10)) / item.total);

  return roundedToTwoDigits ? mean.toFixed(2) : mean;
};

// calculate sample standard deviation for the item obj, using fields s1-s5 for counts of scores 1-5 (ex. {s1: 3, s2: 2} would be [1,1,1,2,2])
export const getStandardDeviation = (item) => {
  const unroundedMean = getScoreMean(item);
  let varianceSum = 0;
  varianceSum += item.s1 ? item.s1 * (1 - unroundedMean) ** 2 : 0;
  varianceSum += item.s2 ? item.s2 * ((2 - unroundedMean) ** 2) : 0;
  varianceSum += item.s3 ? item.s3 * ((3 - unroundedMean) ** 2) : 0;
  varianceSum += item.s4 ? item.s4 * ((4 - unroundedMean) ** 2) : 0;
  varianceSum += item.s5 ? item.s5 * ((5 - unroundedMean) ** 2) : 0;

  if (varianceSum === 0) {
    return '0.00';
  }

  const variance = varianceSum / (item.total - 1);

  return variance === 0 ? '0.00' : Math.sqrt(variance).toFixed(2);
};

// filters
// derive subject filter options from api response
export const getSubjectFilterOptions = (arrApiTotalsByDemographic) => {
  const subjectsWithData = arrApiTotalsByDemographic.reduce((acc, item) => {
    acc[item.exam_name] = item.discipline_name;
    return acc;
  }, {});

  const subjectOptions = [];
  for (const [key, value] of Object.entries(subjectsWithData)) {
    subjectOptions.push({
      value: key,
      label: key,
      group: value
    });
  }

  return subjectOptions.sort((a, b) => {
    if (a.group < b.group) {
      return -1;
    }
    if (a.group > b.group) {
      return 1;
    }
    if (a.group === b.group) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
    }
    return 0;
  });
};

// derive score filter options from api response, every score value (1-5) that has data in any subject will be shown, sorted low to high
export const getScoreFilterOptions = (arrApiTotalsByDemographic) => {
  const scoresWithData = {};
  arrApiTotalsByDemographic.forEach(item => {
    if (parseInt(item.s1) > 0) {
      scoresWithData[1] = true;
    }
    if (parseInt(item.s2) > 0) {
      scoresWithData[2] = true;
    }
    if (parseInt(item.s3) > 0) {
      scoresWithData[3] = true;
    }
    if (parseInt(item.s4) > 0) {
      scoresWithData[4] = true;
    }
    if (parseInt(item.s5) > 0) {
      scoresWithData[5] = true;
    }
  });
  return Object.keys(scoresWithData).sort().map(item => ({
    value: 'Score: ' + item,
    label: item
  }));
};

// derive demographic filter options based on api response, only demographics with 1+ score will be shown
export const getDemographicFilterOptions = (arrApiTotalsByDemographic) => {
  const demographicsWithData = arrApiTotalsByDemographic.reduce((acc, item) => {
    acc[item.demographic_descr] = true;
    return acc;
  }, {});
  return Object.keys(demographicsWithData).map(item => ({
    value: item,
    label: item
  }));
};

import PropTypes from 'prop-types';
import React from 'react';
import '../shared.css';
import SubHeader from '../subHeader';
import { CONFIDENTIALITY_HEADER } from './utils';

import SummaryDisplay from './summaryDisplay';
import ScoresDisplay from './scoresDisplay';
import AwardsDisplay from './awardsDisplay';
import SubScoreNotes from '../common/subScore/subScoreNotes';

const StudentScore = ({apiData}) => {
  const subscoreWidth = Array.isArray(apiData.subScoreNotes) && apiData.subScoreNotes.length > 1 ? '50%' : '100%';
  return (
    <>
      <SubHeader><span className='cb-roboto-italic cb-font-size-small'>{CONFIDENTIALITY_HEADER}</span></SubHeader>
      <SummaryDisplay summaryData={apiData.summaryData[0]} />
      <div id="divStSRTables" className="cb-panel-summary cb-padding-top-32">
        <div className="container cb-padding-bottom-16">
          <ScoresDisplay scoreData={apiData.scoreData} />
          <AwardsDisplay awardData={apiData.scholarAwardData} />
          {
            Array.isArray(apiData.subScoreNotes) && apiData.subScoreNotes.length > 0 &&
            <div className='cb-margin-top-48 row'>
              {
                apiData.subScoreNotes.map((i, ind) => (
                  <SubScoreNotes key={`stsr_subscore_notes_${ind}`} subScoreNotes={i.notes} subScoreDescr={i.descr} width={subscoreWidth}/>
                ))
              }
            </div>
          }
        </div>
      </div>
    </>
  );
};

StudentScore.propTypes = {
  apiData: PropTypes.object,
  currentYear: PropTypes.number,
  sttVal: PropTypes.string,
  isDistrictAdmin: PropTypes.bool,
  selectedOrgId: PropTypes.number,
  isAggregate: PropTypes.bool
};

export default StudentScore;

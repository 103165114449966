import PropTypes from 'prop-types';
import React, {useState, useRef, useEffect, useContext} from 'react';
import {Tabs, Tab} from '@cb/apricot-react';
import ReportContainer from '../common/reportContainer';
import '../shared.css';
import './report.css';
import HonorRollNotEligibleDisplay from './notEligibleDisplay';
import ProgressSummary from './summaryTab';
import CriteriaDisplay from './criteriaTab';
import { getCriteriaTableData, getMetaData, getNextTierRequiredPercentages, getOrgAddress, getSchoolName, hasAccessAward } from './dataParser';
import HonorRollFooter from './footer';
import { CREDIT, CULTURE, OPTIMIZATION } from './constants';
import SubHeader from '../subHeader';
import DownloadModal from '../common/downloads/downloadModal';
import {HONOR_ROLL_REPORT_DISPLAY_TITLE, HONOR_ROLL_REPORT_TITLE} from '../../../constants/reports';
import { getPdfDownloadFunction } from './pdf';
import { getDisplayTier, isTier, TAB_1_TITLE, TAB_2_TITLE } from './utils';
import CelebrationKit from './celebrationKit';
import { CBInputMethod } from '@cb/apricot';

const TAB_1_ID = 'progressSummaryTab';
const TAB_2_ID = 'criteriaTab';

const downloadModalTrigger = 'openDownloadModal';
const celebrationKitModalTrigger = 'celebrationKitModal';

const HonorRoll = ({ apiData, year, selectedAICaption, selectedOrgId, sttVal, isDistrictAdmin, dataUpdatedDate }) => {

  const linkRef = useRef(undefined);
  const [fileData, setFileData] = useState();
  useEffect(() => {
    if (fileData) {
      linkRef?.current?.click();
    }
  }, [fileData]);

  // used to detect keyboard vs mouse interaction (needed for ACO when tabbing through tabs)
  useEffect(() => {
    CBInputMethod();
  }, []);

  const [activeTab, setActiveTab] = useState(TAB_1_ID);
  const CBTabsRef = useRef();

  // these refs will be used to generate chart images for pdf export
  const cultureRef = useRef(null);
  const creditRef = useRef(null);
  const optimizationRef = useRef(null);

  // if school is not eligible, show message
  if (!apiData.eligible) {
    return (<ReportContainer><HonorRollNotEligibleDisplay /></ReportContainer>);
  }

  // get school name from api data aiCode
  const schoolName = getSchoolName(selectedAICaption);
  const schoolData = apiData.honorRollData[0];

  // if school overall tier is beyond platinum, set overall tier to platinum
  const overallTier = getDisplayTier(schoolData.overall_tier);

  const cultureMetadata = getMetaData(CULTURE, apiData.metaDataValues);
  const creditMetadata = getMetaData(CREDIT, apiData.metaDataValues);
  const optimizationMetadata = getMetaData(OPTIMIZATION, apiData.metaDataValues);

  const criteriaTableData = getCriteriaTableData({schoolData, cultureMetadata, creditMetadata, optimizationMetadata });

  const setTab = (tabId, scrollTop) => {
    setActiveTab(tabId);
    // this is needed to allow usual tab behavior after we are manually switching to tab
    CBTabsRef.current.adjustTabs();

    if (scrollTop) {
      window.scrollTo(0, 0);
    }
  };

  // these props are shared between both tab components and pdf
  const commonProps = {
    school: schoolName,
    overall: overallTier,
    year
  };

  // these props are shared between tab 1 html and pdf
  const tierCountAndPercentProps = {
    culturePercent: schoolData.college_culture_pct,
    cultureTier: schoolData.college_culture_tier,
    creditPercent: schoolData.college_credit_pct,
    creditTier: schoolData.college_credit_tier,
    optimizationPercent: schoolData.college_optimization_pct,
    optimizationTier: schoolData.college_opt_tier,
    grade12Enrollment: schoolData.grade_12_enrollment,
    cultureCount: schoolData.college_culture_count,
    creditCount: schoolData.college_credit_count,
    optimizationCount: schoolData.college_optimization_count
  };

  const accessAward = hasAccessAward(apiData.honorRollData);
  const nextTierValues = getNextTierRequiredPercentages(overallTier, apiData.metaDataValues);

  const hasTier = isTier(overallTier);
  const celebrationKitButtonInfo = hasTier ? [{ title: 'Download Celebration Kit', id: celebrationKitModalTrigger, onPress: () => {}, icon: 'cb-download', hasPopup: true}] : undefined;

  return (<>

    {/* This is a hidden link to facilitate pdf download */}
    <a
      ref={linkRef}
      href={fileData ? fileData.href : ''}
      download={fileData ? fileData.download : ''}
      className="display-none">
          Download
    </a>

    <SubHeader showDownload={true} downloadModalTrigger={downloadModalTrigger} additionalButtons={celebrationKitButtonInfo} />
    {hasTier &&
    <CelebrationKit
      accessAward={accessAward}
      org={selectedOrgId}
      sttVal={sttVal}
      year={year}
      tier={overallTier}
      trigger={celebrationKitModalTrigger}
      isAggregate={false}
      isDistrictAdmin={isDistrictAdmin}
      setFileData={setFileData}
      schoolAddress={getOrgAddress(apiData.orgAddress[0])}
      schoolName={schoolName}
    />
    }

    <DownloadModal
      title={`${HONOR_ROLL_REPORT_DISPLAY_TITLE} ${year}`}
      singlePdfFilterText={''}
      filename={`${HONOR_ROLL_REPORT_DISPLAY_TITLE} ${year}`}
      singlePdfText={'PDF'}
      singlePdfDownload={getPdfDownloadFunction({
        ...commonProps,
        ...tierCountAndPercentProps,
        filename: `${HONOR_ROLL_REPORT_DISPLAY_TITLE} ${year}`,
        dataUpdatedDate,
        selectedAICaption,
        cultureRef,
        creditRef,
        optimizationRef,
        accessAward,
        nextTierValues,
        cultureMetadata,
        creditMetadata,
        optimizationMetadata
      })}
      org={selectedOrgId}
      auditTitle={`${HONOR_ROLL_REPORT_TITLE}Export`}
      sttVal={sttVal}
      year={year}
      trigger={downloadModalTrigger}
      isAggregate={false}
      isDistrictAdmin={isDistrictAdmin}
    />

    <div className="container content-gutter">
      <div className='cb-margin-top-8' id='main_tab_container' >
        <Tabs cbRef={CBTabsRef} onTabChange={(panelId) => {
          setTab(panelId);
        }}>
          <Tab label={TAB_1_TITLE} panelId={TAB_1_ID} tabId={`${TAB_1_ID}Tab`} selected={activeTab === TAB_1_ID}>
            <ProgressSummary
              {...commonProps}
              {...tierCountAndPercentProps}
              cultureRef={cultureRef}
              creditRef={creditRef}
              optimizationRef={optimizationRef}
              setTabTwoHandler={() => setTab(TAB_2_ID, true)}
              nextTierValues={nextTierValues}
              accessAward={accessAward}
              cultureMetadata={cultureMetadata}
              creditMetadata={creditMetadata}
              optimizationMetadata={optimizationMetadata}
            />
          </Tab>
          <Tab label={TAB_2_TITLE} panelId={TAB_2_ID} tabId={`${TAB_2_ID}Tab`} selected={activeTab === TAB_2_ID}>
            <CriteriaDisplay
              {...commonProps}
              tableData={criteriaTableData}
            />
          </Tab>
        </Tabs>
      </div>
      <HonorRollFooter year={year} showNaMessage={activeTab === TAB_2_ID} />
    </div>
  </>);
};

HonorRoll.propTypes = {
  isAggregate: PropTypes.bool,
  apiData: PropTypes.object,
  year: PropTypes.number,
  selectedOrgId: PropTypes.number,
  sttVal: PropTypes.string,
  selectedAICaption: PropTypes.string,
  isDistrictAdmin: PropTypes.bool,
  dataUpdatedDate: PropTypes.string
};

export default HonorRoll;

import PropTypes from 'prop-types';
import React from 'react';
import {getReportOrgSelect} from '../../../../api';
import GenericDropdown from './genericDropdown';

const InterstitialSchoolDropdown = ({
  reportName,
  sttVal,
  selectedYear,
  setSchoolDropdownSelectedId,
  setSchoolDropdownAICaption,
  showInterstitialPage,
  isVisible,
  setIsReportError,
  label,
  setNoDataError
}) => {
  return (
    <GenericDropdown
      id='selectSchool'
      ariaLabel='School Selection'
      label={label}
      showInterstitialPage={showInterstitialPage}
      isVisible={isVisible}
      setIsReportError={setIsReportError}
      placeholderText={'Select School'}
      getOptionsApiCall={async () => {
        const res = await getReportOrgSelect({
          sttVal,
          adminYear: selectedYear,
          reportName
        });

        return res;
      }}
      handleOptionChange={(value, label) => {
        setSchoolDropdownSelectedId(value);
        setSchoolDropdownAICaption(label);
      }}
      setNoDataError={setNoDataError}
    />);
};

InterstitialSchoolDropdown.propTypes = {
  reportName: PropTypes.string,
  selectedYear: PropTypes.number,
  setSchoolDropdownSelectedId: PropTypes.func,
  setSchoolDropdownAICaption: PropTypes.func,
  showInterstitialPage: PropTypes.bool,
  sttVal: PropTypes.string,
  isVisible: PropTypes.bool,
  setIsReportError: PropTypes.func,
  label: PropTypes.string,
  setNoDataError: PropTypes.func
};

export default InterstitialSchoolDropdown;

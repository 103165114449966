import propTypes from 'prop-types';
import React, { useEffect } from 'react';
import { IG_TEMPLATE_SVG } from './svgs/instagramTemplate';
import { CB_LOGO_SVG } from './svgs/cbLogo';
import { addImage, getBadgeImage, writeText } from './utils';

// coordinates and width consts
const BADGE_WIDTH = 320;
const BADGE_X = 650;
const BADGE_Y = 125;
const HEIGHT = 1080;
const WIDTH = 1080;
const YEAR_X = 120;
const YEAR_Y = 707;
const LOGO_Y = YEAR_Y + 185;
const LOGO_WIDTH = 148;
const YEAR_FONT_SIZE = 120;

export const addBackgroundImagesIg = ({year, badge, context, addImageFunc = addImage, writeTextFunc = writeText}) => () => {
  writeTextFunc(context, { text: year, x: YEAR_X, y: YEAR_Y }, { fontSize: YEAR_FONT_SIZE });
  addImageFunc({
    ctx: context,
    src: getBadgeImage(badge),
    x: BADGE_X,
    y: BADGE_Y,
    width: BADGE_WIDTH
  });
  addImageFunc({
    ctx: context,
    src: CB_LOGO_SVG,
    x: YEAR_X,
    y: LOGO_Y,
    width: LOGO_WIDTH
  });
};

export const drawIg = ({canvasRef, year, badge, addImageFunc = addImage}) => {
  if (canvasRef.current) {
    const context = canvasRef.current.getContext('2d');
    addImageFunc({
      ctx: context,
      src: IG_TEMPLATE_SVG,
      x: 0,
      y: 0,
      width: WIDTH,

      // we have to below additions after the above image loads, as it has a full white background
      doAfter: addBackgroundImagesIg({year, badge, context})
    });
  }
};

const IgImageGenerator = ({
  canvasRef,
  year,
  badge
}) => {

  useEffect(() => {
    drawIg({ canvasRef, year, badge});
  }, [canvasRef]);

  return (
    <>
      <canvas style={{display: 'none'}} ref={canvasRef} height={HEIGHT} width={WIDTH} />
    </>

  );
};

IgImageGenerator.propTypes = {
  canvasRef: propTypes.object,
  year: propTypes.number,
  badge: propTypes.string
};

export default IgImageGenerator;

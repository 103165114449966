import gql from 'graphql-tag';
import openDownloadInNewTab from '../../common/downloads/openDownloadTab';
import { getAuthIprSinglePdf } from '../../../../graphql/queries';
import { subscribeToIprSinglePdf } from '../../../../graphql/subscriptions';
import { getAppSyncResponseHandler } from '../../common/pdf/common';

export const getSinglePdf = ({
  setFileData,
  navSchool,
  navSection,
  sectionDisplay,
  subjectDisplay,
  navSubject,
  orgId,
  roleCd,
  selectedYear,
  sttVal,
  reportName,
  selectedAICaption,
  fltTeacher,
  fltScore,
  fltGrade,
  fltStandardIndicator,
  fltInstruction,
  fltTeacherDisplay,
  fltScoreDisplay,
  fltGradeDisplay,
  fltStandardIndicatorDisplay,
  fltInstructionDisplay,
  showStandardFilter
}) => {
  const cb = window.cb;
  openDownloadInNewTab({
    subscribeParams: {
      query: gql(subscribeToIprSinglePdf),
      variables: {
        catapultId: cb.core.iam.getTempAWSCredsSubject(),
        orgId: parseInt(orgId),
        adminYear: parseInt(selectedYear),
        roleCd: parseInt(roleCd),
        reportName
      }
    },
    queryParams: {
      query: gql(getAuthIprSinglePdf),
      variables: {
        // full params found - https://bitbucket.collegeboard.org/projects/APOSRE/repos/aposre-genesis-api/browse/base-app/schema.graphql?at=refs%2Fheads%2Fdev
        // required params
        sttVal,
        navYear: parseInt(selectedYear),
        navSubject,
        subjectDisplay,
        selectedAICaption,

        // optional
        navSection,
        navSchool,
        sectionDisplay,
        fltTeacher,
        fltScore,
        fltGrade,
        fltStandardIndicator,
        fltInstruction,
        fltTeacherDisplay,
        fltScoreDisplay,
        fltGradeDisplay,
        fltStandardIndicatorDisplay,
        fltInstructionDisplay,
        showStandardFilter
      }
    },
    // getSubscriptionResponse: (data) => data.data.subscribeToIprSinglePdf,
    downloadFile: getAppSyncResponseHandler('subscribeToIprSinglePdf', setFileData)
  });
};

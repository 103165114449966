import React from 'react';
import PropTypes from 'prop-types';
import BadgeDisplay from './badgeDisplay';
import { isTier, getCategoriesWithCriteriaText, nextTierMap, tierColors } from '../utils';

const HonorRollNextTierDisplay = ({
  hasTier,
  overall,
  school,
  year,
  numCategoriesWithTiers,
  nextTierValues,
  hideBadgeClass
}) => {
  const nextTier = nextTierMap[overall];
  const nextTierColor = tierColors[nextTier];
  return (

    <div id='honor_roll_next_tier_section'>
      {
        hasTier &&
        // on certain screen sizes we do not want to show the badge display here
        <div className={hideBadgeClass}>
          <BadgeDisplay tier={overall} school={school} year={year} alignCenter={true} />
        </div>
      }
      <div className='display-flex flex-column cb-margin-top-32 cb-margin-left-32'>
        {
          !isTier(overall) && numCategoriesWithTiers > 0 &&
        <p>{getCategoriesWithCriteriaText(numCategoriesWithTiers)}</p>
        }
        {
          hasTier &&
        <p>Your school achieved all criteria to be listed on this year&apos;s AP School Honor Roll as a <b>{overall}</b> school!</p>
        }
        <p>
        For planning and goal-setting, the three criteria {hasTier
            ? <span>for <span className='cb-font-weight-bold' style={{ padding: 4, borderRadius: 5, backgroundColor: nextTierColor}}>{nextTier.toUpperCase()}</span> schools </span>
            : 'to make the AP School Honor Roll '}
        are:
        </p>
        <div className='cb-margin-top-8'>
          <ul>
            <li>Expand your school&apos;s college culture by enabling <b>{nextTierValues.culture}% of your Class of {year + 1}</b> to experience 1 AP course before graduating.</li>
            <li>Assist with college affordability by enabling <b>{nextTierValues.credit}% of your Class of {year + 1}</b> to earn college credit by earning an AP Exam score of 3.</li>
            <li>Optimize student&apos;s college readiness by creating opportunities for <b>{nextTierValues.optimization}% of your Class of {year + 1}</b> to take a total of 5 APs across their high school years, with at least 1 taken in 9th or 10th grade.</li>
          </ul>
        </div>
      </div>
    </div>

  );
};

HonorRollNextTierDisplay.propTypes = {
  school: PropTypes.string,
  overall: PropTypes.string,
  nextTierValues: PropTypes.object,
  hasTier: PropTypes.bool,
  year: PropTypes.number,
  numCategoriesWithTiers: PropTypes.number,
  hideBadgeClass: PropTypes.string
};

export default HonorRollNextTierDisplay;

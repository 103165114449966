import {
  SUMMARY_BY_STUDENT_DEMOGRAPHIC_REPORT_TITLE,
  SUMMARY_BY_STUDENT_DEMOGRAPHIC_URI
} from '../../constants/reports';
import { GRADE_LEVEL_LABEL } from '../../components/ap-osre-native-reports/summary-by-student-demographics/constants';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';

// returns report list for current request
const getSummaryByStudentDemographicData = ({ demographic = GRADE_LEVEL_LABEL, sttVal, navSchool, navYear, isDistrictAdmin, isAggregate }) => {
  const url =
    config.apiBaseUrl +
    config.genesisUri +
    SUMMARY_BY_STUDENT_DEMOGRAPHIC_URI;

  const params = {
    reportName: SUMMARY_BY_STUDENT_DEMOGRAPHIC_REPORT_TITLE,
    demographic,
    sttVal,
    navYear
  };

  if (isDistrictAdmin && !isAggregate) {
    params.navSchool = navSchool;
  }

  return axiosInstance.get(url, { params });
};

export default getSummaryByStudentDemographicData;

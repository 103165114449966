import PropTypes from 'prop-types';
import React from 'react';
import DetailTable from '../common/detailTable';
import '../shared.css';

const EquityAndExcellence = ({ isAggregate, year, apiData }) => {

  // Set data object for details
  const { detailedData } = apiData;

  const columns = [
    { title: 'Group', field: 'descr', className: 'td-style' },
    { title: 'Percentage', field: 'perc', className: 'td-style' },
    { title: 'How is this calculated?', field: 'text', className: 'td-style' }
  ];

  const rowOrder = ['Graduating Class Summary', '12th Grade', '11th Grade', '10th Grade' ];
  const formattedDetailData = [];
  detailedData.forEach((row) => {
    const index = rowOrder.indexOf(row.descr);
    formattedDetailData[index] = { ...row, perc: row.perc !== null ? `${(parseFloat(row.perc) * 100).toFixed(1)}%${row.asterisk || ''}` : row.asterisk };
  });

  const adminType = isAggregate ? `district` : `school`;

  return (<div>
    <div
      className="cb-scrollbar-container content-gutter"
      tabIndex="-1"
    ></div>
    <div className="content-gutter sentenceStyle">
      This report provides you with a means to assess both the
      equity and excellence of your {adminType}’s AP program.
      Specifically, it shows the proportion of your {adminType}’s entire
      senior class who scored a 3 or higher on an AP Exam at any
      point during high school. Additionally, you can see what
      percentage of your {adminType}’s entire tenth, eleventh, and
      twelfth grade classes took and scored a 3 or higher on at
      least one AP Exam in May {year?.toString()}.
    </div>
    <div className="content-gutter sentenceStyle">
      In this calculation, students who score a 3 or higher on an AP
      Exam are counted only once, regardless of how many AP Exams
      they took and were successful in. There is no way to inflate
      this percentage by restricting access to AP; students who earn
      1s or 2s on AP Exams neither increase nor reduce the
      percentage. In addition, by showing the proportion of the
      overall population — not just the AP classroom — educators are
      better able to determine the extent to which their overall
      population is receiving access to advanced academics in high
      school.
    </div>
    {isAggregate ? <div className="content-gutter sentenceStyle">
      Note: Your district’s enrollment count is the sum of the
      district’s school enrollment counts submitted by each school’s
      AP Coordinator when placing their school’s order for AP Exams.
    </div> : <div className="content-gutter sentenceStyle">
      Note: Your school’s enrollment counts were submitted by
      your AP Coordinator when placing your school’s order for AP Exams.
    </div>}
    <div className="content-gutter"><br /></div>
    <div className="content-gutter">
      <DetailTable
        columns={columns}
        striped={true}
        data={formattedDetailData}
      />
    </div>
    {isAggregate
      ? <div className="content-gutter sentenceStyle">
        If an asterisk (*) appears in the table above it indicates that not all schools in the district were included in this calculation because one or more schools did not submit an enrollment count for this grade level. <br/>
        If &apos;n/a&apos; appears in the table above, the percentage is not available because none of the schools in the district submitted an enrollment count for this grade level.
      </div>
      : <div className="content-gutter sentenceStyle">
        If &apos;n/a&apos; appears in the table above, the percentage is not available because the enrollment count was not submitted for this grade level.
      </div>
    }
  </div>);
};

EquityAndExcellence.propTypes = {
  isAggregate: PropTypes.bool,
  year: PropTypes.number,
  apiData: PropTypes.object
};

export default EquityAndExcellence;

import React from 'react';
import PropTypes from 'prop-types';
import SsrSummaryCard from '../../common/summaryCard';
import './summaryCard.css';
import { CARD_1_ID, CARD_2_ID, CARD_3_ID, COUNT_CARD_HEADER, MEAN_CARD_HEADER, PASS_CARD_HEADER, getDistrictData, getGlobalData, getGroupData, getMeanValue, getPercentPass, getSchoolData, getStateProvinceAbbreviation, getStateProvinceData, getTotalStudentsCount } from '../utils';
import SsrCardContent from './cardContent';

const MIN_CARD_HEIGHT = '150px';

const SsrSummarySection = ({summaryData}) => {

  const groupData = getGroupData(summaryData);
  const globalData = getGlobalData(summaryData);
  const schoolData = getSchoolData(summaryData);
  const districtData = getDistrictData(summaryData);
  const stateProvinceData = getStateProvinceData(summaryData);
  const stateProvinceAbbreviation = getStateProvinceAbbreviation(stateProvinceData);

  const cardData = [{
    icon: 'user',
    id: CARD_1_ID,
    title: COUNT_CARD_HEADER,
    getValue: getTotalStudentsCount
  },
  {
    icon: 'graph',
    id: CARD_2_ID,
    title: MEAN_CARD_HEADER,
    getValue: getMeanValue
  },
  {
    icon: 'check',
    id: CARD_3_ID,
    title: PASS_CARD_HEADER,
    getValue: getPercentPass
  }];

  return (
    <div className='cb-panel-blue' id='ssrSummarySectionContainer'>
      <div className='container' >
        <div className='row'>
          {
            cardData.map((i, index) => (
              <SsrSummaryCard key={`ssrCardKey_${index}`} selector={i.id} minHeight={MIN_CARD_HEIGHT}>
                <SsrCardContent
                  icon={i.icon}
                  title={i.title}
                  stateProvinceAbbreviation={stateProvinceAbbreviation}
                  group={i.getValue(groupData)}
                  school={i.getValue(schoolData)}
                  district={i.getValue(districtData)}
                  stateProvince={i.getValue(stateProvinceData)}
                  global={i.getValue(globalData)}
                />
              </SsrSummaryCard>
            ))
          }
        </div>
      </div>
    </div>);
};

SsrSummarySection.propTypes = {
  summaryData: PropTypes.array
};

export default SsrSummarySection;

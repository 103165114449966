import DOMPurify from 'dompurify';

const createMarkup = message => {
  return {
    // we are allowing target as we have target='_blank' in many links
    __html: DOMPurify.sanitize(message, { ADD_ATTR: ['target'] })
  };
};

export default createMarkup;

import React from 'react';
import PropTypes from 'prop-types';
import ReportInfoModal from '../../../ap-osre-report/instructReportInfoModal';
import infoImage from '../../info.png';
import { INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE, INSTRUCTIONAL_PLANNING_REPORT_TITLE } from '../../../../constants/reports';
import ReportLink, {TeacherReportLink} from '../reportLink';

const IPR_REPORT_CONFIG = { reportPath: INSTRUCTIONAL_PLANNING_REPORT_TITLE, hasSchoolLink: true, hasAggregateLink: true };

function InstructionalReports({
  reportColumnClass,
  iprReportsStyle,
  instructReportInfoTrigger,
  isTeacher,
  iprReports,
  isSchoolAdmin,
  selectedYear,
  isDistrictAdmin,
  enableIPR,
  genesisSttVal,
  courses
}) {
  return (<div className={`${reportColumnClass} ${iprReportsStyle}`}>
    <div className="cb-arch-view">
      <h2 className="infosign">Instructional Reports</h2>
      <div className="">
        <input type="image" tabIndex="0" role="link" className="info-icon" style={{marginBottom: '4px'}} src={infoImage} id={instructReportInfoTrigger} aria-label="Instructional reports description" />
        <ReportInfoModal trigger={instructReportInfoTrigger} />
      </div>
      <p>Identify areas in which to improve curriculum &amp; instruction.</p>
    </div>
    <ul>
      {!isTeacher ? iprReports.map(report => {
        return <li key={report.reportName}>
          {isSchoolAdmin
            ? <ReportLink {...IPR_REPORT_CONFIG} genesisSttVal={genesisSttVal} isSchoolAdmin={isSchoolAdmin} selectedYear={selectedYear} isDistrictAdmin={isDistrictAdmin} report={report} isAggregate={false}></ReportLink>
            : null
          }
          {isDistrictAdmin
            ? <div>
              <div>{report.reportName} {!report.enableReport ? <span>(Not Available)</span> : null}</div><div>
                <ReportLink {...IPR_REPORT_CONFIG} genesisSttVal={genesisSttVal} isSchoolAdmin={isSchoolAdmin} selectedYear={selectedYear} isDistrictAdmin={isDistrictAdmin} report={report} isAggregate={false}></ReportLink>
              </div>
            </div>

            : null}
        </li>;
      }) : courses ? courses.map(course => {
        return <li key={course.subjectName}>
          <TeacherReportLink
            {...IPR_REPORT_CONFIG} genesisSttVal={genesisSttVal} reportDisplayTitle={INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE} selectedYear={selectedYear} subjectName={course.subjectName} enabled={enableIPR}
          />
        </li>;
      }) : null}
    </ul>
  </div>);
}

InstructionalReports.propTypes = {
  courses: PropTypes.array,
  enableIPR: PropTypes.bool,
  instructReportInfoTrigger: PropTypes.string,
  iprReports: PropTypes.array,
  iprReportsStyle: PropTypes.string,
  isDistrictAdmin: PropTypes.bool,
  isSchoolAdmin: PropTypes.bool,
  isTeacher: PropTypes.bool,
  reportColumnClass: PropTypes.string,
  roleCode: PropTypes.number,
  selectedYear: PropTypes.number,
  genesisSttVal: PropTypes.string
};

export default InstructionalReports;

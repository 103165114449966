import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../../../AppContext';
import csvImage from '../../csvImage.png';
import { Modal, YellowButton, Icon } from '@cb/apricot-react';

const SddModal = ({exportStudentDatafile, roleCode, selectedYear, studentDataDownloadTrigger}) => {
  const appContext = useContext(AppContext);
  return (
    <Modal
      modalId='studentDatafileModalContainer'
      title={<b id={'studentDatafileModalHeader'}>Export Student Datafile CSV</b>}
      trigger={studentDataDownloadTrigger}
      footer={
        <YellowButton
          onClick={async () => {
            await exportStudentDatafile(
              'StudentDataDownload',
              roleCode
            );
          }}
          id={'studentDatafileDownloadButton'}
          data-cbtrack-download={'student-data-file-' + selectedYear + '.csv'}
        >
          Export Report
        </YellowButton>
      }
    >
      <div
        id="studentDataFileModal"
        className="modal fade cb-modal-animation"
        role="dialog"
        aria-label="Export student data file"
        tabIndex="-1"
      >
        <div className="modal-dialog" role="document">
          <div>
            <span className="padding-right">
              <img src={csvImage} alt="" />
            </span>
            <span className="cvs-modal-text bold-text">
              This report is available in CSV format
            </span>
          </div>
          <div className="cvs-modal-text padding-bottom-text">
            You can download & open this file in Excel, or
            import into your SIS.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                appContext?.content?.ap_central_links
                  ?.studentDataFileFormatLink
              }
            >
              View the layout specification
            </a>
          </div>
          <div>
            <Icon name={'window'} className='padding-right' />
            <span className="cvs-modal-text">
              Your download will open in a new tab and may take
              a few moments to load. Do not close the tab during
              this time.
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SddModal.propTypes = {
  exportStudentDatafile: PropTypes.func,
  roleCode: PropTypes.number,
  selectedYear: PropTypes.number,
  studentDataDownloadTrigger: PropTypes.string
};

export default SddModal;

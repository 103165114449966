import React, { useEffect, useContext } from 'react';
import propTypes from 'prop-types';
import { AppContext } from '../../AppContext';
import analytics from '../../utils/analytics';
import ErrorDisplay from './errorDisplay';

const ReportError = (props) => {
  const appContext = useContext(AppContext);
  useEffect(() => {
    if (!appContext.isCustomerService) {
      analytics.reportCurrentView('ReportErrorPage', appContext.roleCd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="cb-padding-top-48">
          <div className="col-xl-10 col-lg-10 col-sm-12">
            <ErrorDisplay
              title={<span className="error-title">{props.message}</span>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ReportError.propTypes = {
  message: propTypes.string
};

export default ReportError;

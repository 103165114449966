import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getReportStudentSelect } from '../../../../api';
import { Input } from '@cb/apricot-react';
import Spinner from '../../../common/loader/spinner';
import './interstitial.css';

const InterstitialStudentComponent = ({
  reportName,
  sttVal,
  selectedYear,
  selectedStudentId,
  setStudentListSelectedId,
  setStudentListSelectedName,
  setNoDataError,
  showInterstitialPage,
  setIsReportError,
  studentFilter,
  setStudentFilter
}) => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [studentListData, setStudentListData] = useState([]);
  const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    const getStudentListData = async () => {
      if (!showInterstitialPage || selectedValue) {
        return;
      }
      try {
        setIsLoadingData(true);
        const res = await getReportStudentSelect({reportName, sttVal, adminYear: selectedYear});

        if (res.data.length < 1) {
          setNoDataError('selectStudent');
          setIsLoadingData(false);
        } else {
          const formattedList = res.data.map((student) => {
            const caption = `${student.last_name}; ${student.first_name}` +
            `${student.middle_initial ? ` ${student.middle_initial}.` : ''}` +
            `${student.apid ? ` (${student.apid})` : ''}`;
            return {...student, caption};
          });
          setStudentListData(formattedList);
          setSelectedValue(selectedStudentId);
          setIsLoadingData(false);
        }
      } catch (err) {
        setIsLoadingData(false);
        setIsReportError(true);
      }
    };
    getStudentListData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInterstitialPage]);

  const onStudentChange = e => {
    setStudentListSelectedId(e.target.value);
    setStudentListSelectedName(e.target.selectedOptions[0].label);
    setSelectedValue(e.target.value);
  };

  let studentFilterTimeout;
  const handleFilterTextInput = e => {
    const text = e.target.value;

    // debounce for 500ms
    clearTimeout(studentFilterTimeout);
    studentFilterTimeout = setTimeout(() => {
      setStudentFilter(true);
      setSelectedValue('');
      setStudentListSelectedId('');
      setStudentListSelectedName('');
      const filteredList = studentListData.filter(student => {
        return student.first_name?.toLowerCase().startsWith(text.toLowerCase()) ||
        student.last_name?.toLowerCase().startsWith(text.toLowerCase()) ||
        student.apid?.toLowerCase().startsWith(text.toLowerCase());
      });
      setFilteredStudentList(filteredList);
    }, 500);
  };

  return (
    showInterstitialPage ? (
      <div className='container'>
        <div className="stsr-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Choose a Student</div>
        <div className="cb-gray1-color cb-sans-serif cb-font-size-small">
            Select a student from the list below. Filter to narrow the list.
        </div>
        <div id='student_input_container' className='dropdown-content-container cb-padding-bottom-8'>
          <Input
            name = 'filterBy'
            label = {<div className="stsr-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Filter by First Name, Last Name, or AP ID</div>}
            onChange={handleFilterTextInput}
            placeholder = 'Type to search...'
            floating = {false}
            maxLength = {50}
            clearable={false}
            ariaLabel="Filter by First Name, Last Name, or AP ID"
            disabled={isLoadingData || studentListData.length < 1}
          />
        </div>
        {isLoadingData ? <Spinner size="md" alignment="left" removePadding={true} /> : null}
        <label htmlFor="selectStudent" className="stsr-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">
            Select Student
        </label>
        <div className='dropdown-content-container'>
          <select id='selectStudent' name='selectStudent' size={10} onChange={onStudentChange} disabled={isLoadingData || studentListData.length < 1}
            value={selectedValue} className='stsr-select'
            label='selectStudent'>
            <option style={{display: 'none'}}></option>
            {
              studentFilter
                ? filteredStudentList.map((student) => {
                  return <option key={student.value} value={student.value}>{student.caption}</option>;
                })
                : studentListData.map((student) => {
                  return <option key={student.value} value={student.value}>{student.caption}</option>;
                })
            }
          </select>
        </div>
      </div>
    ) : null
  );
};

InterstitialStudentComponent.propTypes = {
  reportName: PropTypes.string,
  selectedYear: PropTypes.number,
  sttVal: PropTypes.string,
  selectedStudentId: PropTypes.string,
  setStudentListSelectedId: PropTypes.func,
  setStudentListSelectedName: PropTypes.func,
  showInterstitialPage: PropTypes.bool,
  isVisible: PropTypes.bool,
  setIsReportError: PropTypes.func,
  setNoDataError: PropTypes.func,
  studentFilter: PropTypes.bool,
  setStudentFilter: PropTypes.func
};
export default InterstitialStudentComponent;

import { SUBJECTS_URI } from '../constants/reports';
import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';

// returns list of subjects for a given org and year
const getReportSubjectSelect = ({adminYear, reportName, navSchool, sttVal, isDistrictAdmin, isAggregate}) => {
  const url =
  config.apiBaseUrl +
  config.genesisUri +
  SUBJECTS_URI;

  const params = {
    sttVal,
    reportName,
    adminYear
  };

  if (isDistrictAdmin && !isAggregate) {
    params.navSchool = navSchool;
  }

  return axiosInstance.get(url, { params });
};

export default getReportSubjectSelect;

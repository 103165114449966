import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import DetailTable from '../common/detailTable';
import { formatPercentageValue } from '../../../utils/stringUtils';
import { Modal, CircularButton } from '@cb/apricot-react';
import { waitMs } from '../../../utils/tableUtils';
import { sortArrayData, getNotesText } from './utils';
import './tabs.css';
import PseudoLink from '../common/pseudoLink';
import ExpandIcon from '../common/expandIcon';

const TABLE_ID = 'free_response_table';

// used to shade cell background on children rows
const getHierarchyClassName = level => {
  switch (level) {
    case 2:
      return 'cb-gray5-bg';
    case 3:
      return 'cb-gray4-bg';
    default:
      return '';
  }
};

const getAllApContentAreaCodes = frData => {
  const res = {};
  frData.forEach(i => {
    res[i.ap_content_area_code] = true;
  });
  return res;
};

const getChildrenMap = frData => {
  const cMap = {};
  frData.forEach(i => {
    if (i.parent) {
      cMap[i.parent] = typeof cMap[i.parent] === 'undefined' ? 1 : cMap[i.parent] + 1;
    }
  });
  return cMap;
};

const getChildrensChildren = (code, frData) => {
  const res = [];
  frData.forEach(i => {
    if (i.parent === code) {
      res.push(i.ap_content_area_code);
    }
  });
  return res;
};

const getSetDetails = (data, row) => {
  // console.log('getSetDetails row', row);
  // top level rows are always posinset 1
  if (row.heir === 1) {
    return {
      position: 1,
      count: 1
    };
  }

  // find parent
  const {parent} = row;
  const parentIndex = data.findIndex(i => i.ap_content_area_code === parent);
  if (parentIndex > -1) {
    // starting at parent, get the total count in set and the location of this row
    let count = 0;
    let position = 0;
    for (let i = parentIndex + 1; i < data.length; i++) {
      const currentRow = data[i];
      if (currentRow.heir > row.heir) {
        continue;
      }
      if (currentRow.parent !== parent) {
        break;
      }
      count++;
      if (currentRow.ap_content_area_code === row.ap_content_area_code && currentRow.sequence_number === row.sequence_number) {
        position = count;
      }
    }
    return {
      position,
      count
    };
  }

  return {
    position: 1,
    count: 1
  };
};

const FreeResponseTable = ({ frData, stateProvince, sciencePractInd, frContentHeader}) => {

  const [selectedSort, setSelectedSort] = useState({field: 'sequence_number'});

  // set obj to indicate expanded rows
  const [expanded, setExpanded] = useState(getAllApContentAreaCodes(frData));

  const sciencePracticeModalTrigger = 'openSciencePractice';

  // check which ap_content_area_code have children (other than themselves)
  const childrenMap = getChildrenMap(frData);

  const toggleExpanded = (code, isExpanded) => {
    const newExpanded = {...expanded};
    if (isExpanded) {
      delete newExpanded[code];
      // hide all children's children as well
      const childrenWithChildren = getChildrensChildren(code, frData);
      childrenWithChildren.forEach(i => delete newExpanded[i]);
    } else {
      newExpanded[code] = true;
    }
    setExpanded(newExpanded);
  };

  const getContentAreaCell = ({text, level, isParent, index, isExpanded, code}) => {
    // dev note - not using apricot classes for padding here because max is 72
    let paddingLeft = 16;
    if (level === 2) {
      paddingLeft = 40 + (isParent ? 0 : 32);
    }
    if (level === 3) {
      paddingLeft = 96;
    }
    return (<div className='row' style={{paddingLeft, alignItems: 'center'}}>
      {isParent ? <ExpandIcon clickHandler={() => {
        toggleExpanded(code, isExpanded);
      }} text={text} id={`free_response_expand_${index}`} isExpanded={isExpanded} /> : ''}{text}
    </div>);
  };

  const getNotes = ({group, state, global, total, essayInd}) => {
    const essayNote = essayInd === 'Y'
      ? (<><span className="notes-display">
        {total} {total === '1' ? 'Student'
          : total === null ? 'No Student'
            : 'Students'} Selected This Essay</span><br /></>)
      : null;
    return <>
      {essayNote}
      {getNotesText({group, state, global, stateProvince})}
    </>;
  };

  const hasChildRecord = frData.some(i => i.heir > 1);

  const formatData = (data, expandedRows) => {
    const { field, ascending } = selectedSort;
    const filteredData = data.filter(i => !i.parent || !i.heir || i.heir === 1 || expandedRows[i.parent]);

    const formattedData = filteredData.map((row, index) => {
      const group = formatPercentageValue(row.avggroup, 1);
      const state = formatPercentageValue(stateProvince ? row.avgstate : '', 1);
      const global = formatPercentageValue(row.avgglobal, 1);
      const isExpanded = expandedRows[row.ap_content_area_code];
      const isParent = childrenMap[row.ap_content_area_code] > 1;
      return ({
        ...row,
        isExpanded,
        isParent,
        content_area_cell_contents: getContentAreaCell({
          text: row.content_area,
          level: row.heir,
          isParent,
          index,
          isExpanded,
          code: row.ap_content_area_code
        }),
        avggroup: group,
        avgstate: state,
        avgglobal: global,
        className: getHierarchyClassName(row.heir),
        notes: getNotes({group, state, global, total: row.total_students, essayInd: row.essay_ind}),
        ...(row.content_area === 'SUMMARY' ? {max_score: ''} : {max_score: row.max_score.toString()}),
        sequence_number: row.sequence_number.toString()
      });
    });
    return sortArrayData(formattedData, field, ascending);
  };

  function HeaderComponent(props) {
    return (
      <>
        <div className="box">
          <div>{props.title}</div>
          <CircularButton icon="question" light small className="cb-margin-right-4 tooltip-icon" title="Science Practice Tooltip" label="Science Practice Tooltip" aria-haspopup="dialog" tabIndex="0" id={sciencePracticeModalTrigger} />
          <Modal title={props.title} trigger={sciencePracticeModalTrigger} withFooter={false} className="cb-black1-color">
            <p className="cb-font-weight-regular">The list of science practice(s) assessed on an individual question. For the full list and descriptions of the science practices refer to the Multiple-Choice Performance tab. </p>
          </Modal>
        </div>
      </>
    );
  }

  HeaderComponent.propTypes = {
    title: PropTypes.string,
    sciencePracticeModalTrigger: PropTypes.string
  };
  const columns = [{
    title: frContentHeader, field: 'content_area_cell_contents', rowHeader: true, sortable: true, attributes: [['role', 'rowheader']]
  },
  ...(sciencePractInd === 'Y' ? [{
    title: 'Science Practice',
    field: 'science_practice',
    className: 'text-left-align title-width',
    headerComponent: ({title}) => (<HeaderComponent title={title}></HeaderComponent>),
    classNameFunc: (_val, row) => 'cb-align-right',
    attributes: [['role', 'gridcell']]
  }] : []),
  {
    title: 'Max Score', field: 'max_score', sortable: true, className: 'fontHeavyRightAlignTableCells', attributes: [['role', 'gridcell']]
  }, {
    title: 'Mean|Group', field: 'avggroup', sortable: true, className: 'fontHeavyRightAlignTableCells', attributes: [['role', 'gridcell']]
  }, {
    title: `Mean|${stateProvince || 'State'}`, field: 'avgstate', sortable: true, className: 'fontHeavyRightAlignTableCells', attributes: [['role', 'gridcell']]
  }, {
    title: 'Mean|Global', field: 'avgglobal', sortable: true, className: 'fontHeavyRightAlignTableCells', attributes: [['role', 'gridcell']]
  }, {
    title: 'Notes', field: 'notes', className: 'td-style', attributes: [['role', 'gridcell']]
  }];

  const [data, setData] = useState(formatData(frData, expanded));
  // console.log('data', data);

  useEffect(() => {
    setData(formatData(frData, expanded));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const getTableAttributes = () => hasChildRecord ? [['role', 'treegrid']] : [];

  const getRowAttributes = row => {
    // if there are no collapsable rows, do not include aria attributes
    if (!hasChildRecord) {
      return [];
    }

    const ariaExpanded = [];
    if (row.isParent) {
      ariaExpanded.push(row.isExpanded ? ['aria-expanded', 'true'] : ['aria-expanded', 'false']);
    }

    const tabIndex = [];
    if (row.isParent) {
      tabIndex.push(['tabIndex', '0']);
    }

    const {count, position} = getSetDetails(data, row);

    const ariaPosInSet = [['aria-posinset', position]];
    const ariaSetSize = [['aria-setsize', count]];
    return [...ariaExpanded, ...tabIndex, ...ariaPosInSet, ...ariaSetSize, ['role', 'row'], ['aria-level', row.heir]];
  };

  return (<>
    <div id="frTab" className="bg-white table-details">
      {hasChildRecord ? <div className="col-lg-6 col-md-6 col-sm-12 cb-spacerv-bottom-24 row">
        <div className='cb-padding-right-8'>
          <PseudoLink handler={() => {
            setExpanded(getAllApContentAreaCodes(frData));
          }} text='Expand all' linkId='frExpand' />
        </div>
        <PseudoLink handler={() => {
          setExpanded({});
        }} text='Collapse all' linkId='frCollapse' />
      </div> : null}
      <DetailTable
        tableId={TABLE_ID}
        tableAttributesFunc={getTableAttributes}
        rowClassNameFunc={r => r.className}
        rowAttributesFunc={getRowAttributes}
        columns={columns}
        striped={false}
        responsive
        rowHighlighted={r => r.content_area === 'SUMMARY'}
        data={data}
        sortType={data.length > 1 ? 'inline' : 'none'}
        sortFn={(data, sort) => {
        // we handle the sort in onSort
          return data;
        }}
        onSort={async (field, ascending) => {
          await waitMs(500);
          setSelectedSort({field, ascending});
          setData(sortArrayData(data, field, ascending));
        }}
      />
    </div>
  </>);
};

FreeResponseTable.propTypes = {
  frData: PropTypes.array,
  stateProvince: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  sciencePractInd: PropTypes.string,
  frContentHeader: PropTypes.string
};

export default FreeResponseTable;

import { useState } from 'react';
import config from './envConfig.js';
import Cookie from 'js-cookie';

export const iam = () => window.cb?.core?.iam;
export const getAuthSession = () => iam().getAuthSession() || {};
export const getAuthorizationToken = () => iam().getAuthorizationToken();
export const getAuthenticationToken = () => iam().getAuthenticationToken();
export const getJWTToken = () => typeof iam().getJWTToken === 'function' ? iam().getJWTToken() : '';
export const getCatapultId = () => iam().getTempAWSCredsSubject();

export const DEFAULT_AUTH = {
  loggedIn: false,
  loggedOut: true,
  error: false
};

const CB_AUTH_TOKEN_STARTS_WITH = 'cb_authorization_token';
const CB_LOGIN_TOKEN_INCLUDES = 'cb_login';

const clearResource = () => {
  Object.keys(Cookie.get()).filter(item => item.startsWith(CB_AUTH_TOKEN_STARTS_WITH)).forEach(function (cookieName) {
    Cookie.remove(cookieName, { domain: config.logiDomain });
  });
  sessionStorage.clear();
};

export const useAuth = () => {
  const { getEventBus, events } = iam();
  const bus = getEventBus();
  const { Logout, AWSLogin, AWSLoginFailed } = events;
  const hasLoginCookie = document.cookie.includes(CB_LOGIN_TOKEN_INCLUDES);
  const hasAWSCreds = Boolean(getCatapultId());
  const [auth, setAuth] = useState({
    loggedIn: hasAWSCreds,
    loggedOut: !hasLoginCookie && !hasAWSCreds,
    error: false
  });

  bus.on(Logout, () => clearResource() && setAuth(DEFAULT_AUTH));
  bus.on(AWSLogin, () => setAuth({ ...DEFAULT_AUTH, loggedIn: true, loggedOut: false }));
  bus.on(AWSLoginFailed, () => setAuth({ ...DEFAULT_AUTH, loggedIn: true, error: true, loggedOut: false }));

  return auth;
};

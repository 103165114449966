import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Redirect,
  Route
} from 'react-router-dom';
import { AppContext } from '../../AppContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const appContext = useContext(AppContext);
  return (
    <Route {...rest} render={(props) => (
      appContext.isLoggedIn
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/'
        }} />
    )} />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func
};

export default ProtectedRoute;

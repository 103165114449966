import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';
import { HONOR_ROLL_URI } from '../../constants/reports';

// returns report list for current request
const getHonorRollData = ({ sttVal, navYear, navSchool }) => {
  const url = `${config.apiBaseUrl}${config.genesisUri}${HONOR_ROLL_URI}`;
  const params = {
    sttVal,
    navYear,
    navSchool
  };

  return axiosInstance.get(url, { params });
};

export default getHonorRollData;

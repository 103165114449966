import React, { useEffect, useContext, Fragment } from 'react';
import './errorPages.css';
import AccountConfig from '../../utils/cbAccountConfig.js';
import analytics from '../../utils/analytics';
import { AppContext } from '../../AppContext';
import PageTitle from '../../components/common/PageTitle';
import ErrorDisplay from './errorDisplay';

const GetAccess = () => {
  const appContext = useContext(AppContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!appContext.isCustomerService) {
      analytics.reportCurrentView('addedAccessCode', appContext.roleCd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <PageTitle />
      <div className = "general-error-page-container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <ErrorDisplay
              title={<span className="error-title">Your account does not have access to AP Score Reports for Educators.</span>}
            >
              <p>Contact your AP coordinator, or principal for an access code. You may also call AP Services at (877) 274-6474 (toll free in the United States and Canada) or (212) 632-1781.</p>
              <br />
              <a href = {AccountConfig.cbAccountLinks.accessCodeLink}><b> Add your access code here.</b></a>
              <p><br/><i><b>Note:</b> If you have recently added an access code, you may have to log out and log back in again for the change to take effect.</i></p>
            </ErrorDisplay>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GetAccess;

const includes = (container, value) => {
  let returnValue = false;
  let pos = container.indexOf(value);
  if (pos >= 0) {
    returnValue = true;
  }
  return returnValue;
};

let isRegionalUser = (orgId) => {
  const knownOrgIds = [100059, 100060, 100061, 100062, 100063, 100064, 102910];
  if (includes(knownOrgIds, orgId)) {
    return true;
  } else {
    return false;
  }
};

export {isRegionalUser};

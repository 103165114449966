import { INSTRUCTIONAL_PLANNING_URI } from '../../constants/reports';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';

const getIprData = ({
  sttVal,
  navYear,
  navSchool,
  navSubject,
  navSection,
  isDistrictAdmin,
  isAggregate,
  navForm,

  fltScore,
  fltGrade,
  fltTeacher,
  fltInstruction,
  fltStandardIndicator
}) => {
  const url =
          config.apiBaseUrl +
          config.genesisUri +
          INSTRUCTIONAL_PLANNING_URI;

  const params = {
    sttVal,
    navYear,
    navForm,
    navSubject,
    fltScore,
    fltGrade,
    fltTeacher,
    fltInstruction,
    fltStandardIndicator
  };

  if (isDistrictAdmin && !isAggregate) {
    params.navSchool = navSchool;
  }
  if (!isAggregate) {
    params.navSection = navSection;
  }

  return axiosInstance.get(url, { params });
};

export default getIprData;

import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { HONOR_ROLL_REPORT_DISPLAY_TITLE } from '../../../../../constants/reports';
import { DARK_GREY, H4_FONT_SIZE, LARGE_TEXT_FONT_SIZE, SMALL_TEXT_FONT_SIZE } from '../constants';
import { getCurrentDate } from '../../../header';

// Create styles
const styles = StyleSheet.create({
  column: {
    flexDirection: 'column'
    // alignItems: 'flex-end'
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  reportFont: {
    fontSize: H4_FONT_SIZE,
    fontFamily: 'Roboto-Bold'
  },
  schoolFont: {
    fontSize: LARGE_TEXT_FONT_SIZE,
    fontFamily: 'Roboto-Bold',
    paddingTop: 5
  },
  lastUpdatedFont: {
    fontSize: SMALL_TEXT_FONT_SIZE,
    fontFamily: 'Roboto',
    color: DARK_GREY,
    paddingTop: 2
  }
});

const PageHeader = ({year, selectedAICaption, dataUpdatedDate}) => (
  <View style={styles.row}>
    <View>
      <Text style={styles.reportFont}>{HONOR_ROLL_REPORT_DISPLAY_TITLE} - {year}</Text>
      <Text style={styles.schoolFont}>{selectedAICaption}</Text>
    </View>
    <View style={styles.column}>
      <Text style={styles.lastUpdatedFont}>Data Updated: {dataUpdatedDate}</Text>
      <Text style={styles.lastUpdatedFont}>Report Run: {getCurrentDate()}</Text>
    </View>
  </View>
);

PageHeader.propTypes = {
  year: PropTypes.number,
  selectedAICaption: PropTypes.string,
  dataUpdatedDate: PropTypes.string
};

export default PageHeader;

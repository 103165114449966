import React from 'react';
import PropTypes from 'prop-types';
import { badgeImageMap } from '../utils';

const alignCenterClass = 'justify-content-center align-items-center cb-margin-left-48 cb-margin-right-48';

const BadgeDisplay = ({tier, school, year, alignCenter = false}) => {
  return (
    <div className={`display-flex flex-row cb-padding-8 cb-padding-top-16 ${alignCenter ? alignCenterClass : ''}`}>
      <div>
        <img className='display-sm-none-only display-xs-none-only' src={badgeImageMap[tier]} alt={`${tier} badge`} style={{width: '150px', height: '150px'}}></img>
        <img className='display-md-none' src={badgeImageMap[tier]} alt={`${tier} badge`} style={{width: '110px', heigh: '110px'}}></img>
      </div>
      <div className='cb-padding-8' style={{textAlign: 'left'}}>
        <h4 style={{display: 'inline', borderBottom: '3px solid #009CDE'}}>{tier.toUpperCase()}</h4>
        <h6 className='cb-margin-top-8'>AP {year} School Honor Roll</h6>
        <h6 className='cb-margin-top-8'>{school}</h6>
      </div>
    </div>);
};
BadgeDisplay.propTypes = {
  school: PropTypes.string,
  year: PropTypes.number,
  tier: PropTypes.string,
  alignCenter: PropTypes.bool
};

export default BadgeDisplay;

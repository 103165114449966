import { AP_ACCESS_BADGE_NAME, BRONZE, GOLD, PLATINUM, SILVER } from '../../constants';
import { ACCESS_BADGE_SVG } from './svgs/accessBadge';
import { BRONZE_BADGE_SVG } from './svgs/bronzeBadge';
import { GOLD_BADGE_SVG } from './svgs/goldBadge';
import { PLATINUM_BADGE_SVG } from './svgs/platinumBadge';
import { SILVER_BADGE_SVG } from './svgs/silverBadge';

const DEFAULT_FONT_SIZE = 90;
const DEFAULT_FONT_FAMILY = 'LexiaRegular';
const DEFAULT_FONT_COLOR = 'black';

export const writeText = (ctx, info, style = {}) => {
  const { text, x, y } = info;
  const { fontSize = DEFAULT_FONT_SIZE, fontFamily = DEFAULT_FONT_FAMILY, color = DEFAULT_FONT_COLOR, textAlign = 'left', textBaseline = 'top' } = style;

  ctx.beginPath();
  ctx.font = `100 ${fontSize}px ${fontFamily}`;
  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  ctx.fillStyle = color;
  ctx.fillText(text, x, y);
  ctx.stroke();
};

export const getBadgeImage = tier => {
  switch (tier) {
    case BRONZE:
      return BRONZE_BADGE_SVG;
    case SILVER:
      return SILVER_BADGE_SVG;
    case GOLD:
      return GOLD_BADGE_SVG;
    case PLATINUM:
      return PLATINUM_BADGE_SVG;
    case AP_ACCESS_BADGE_NAME:
      return ACCESS_BADGE_SVG;
    default:
      return BRONZE_BADGE_SVG;
  }
};

export const addImage = ({ctx, src, x, y, width, doAfter}) => {
  const image = new Image();
  image.src = `data:image/svg+xml;base64,${window.btoa(src)}`;
  image.onload = () => {
    ctx.drawImage(image, x, y, width, width * image.height / image.width);
    doAfter && doAfter();
  };
};

/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { CreditTooltip, CultureTooltip, OptimizationTooltip } from './summaryTab/tooltips';

import { BEYOND_PLATINUM, CREDIT, CULTURE, NONE, OPTIMIZATION, PLATINUM } from './constants';
import { formatCountValue } from '../../../utils/stringUtils';
import { getPercentageDisplay, getPercentageLabel } from './utils';

// we can get school name from the school display in the report header (taking out the ai)
export const getSchoolName = selectedAICaption => selectedAICaption.split('(')[0].trim();

export const getNextTierRequiredPercentages = (tier, metaData) => {
  // get sort order for current tier
  const currentSortOrder = metaData.find(i => i.tier === tier).sort_order;

  let nextSortOrder = currentSortOrder + 1;
  if (tier === PLATINUM) {
    nextSortOrder = currentSortOrder;
  }

  return {
    culture: metaData.find(i => i.sort_order === nextSortOrder && i.category === CULTURE).start_range,
    credit: metaData.find(i => i.sort_order === nextSortOrder && i.category === CREDIT).start_range,
    optimization: metaData.find(i => i.sort_order === nextSortOrder && i.category === OPTIMIZATION).start_range
  };
};

export const getMetaData = (category, metaData) => {
  const levels = metaData.filter(i => i.category === category).map(i => parseInt(i.start_range)).sort((a, b) => a > b ? 1 : -1);
  levels.shift();
  levels.pop();
  return levels;
};

export const hasAccessAward = honorRollData => honorRollData[0].dei_award === 'YES';

const CriteriaText = ({children}) => {
  return (<h5>{children}</h5>);
};

const getCriteriaTableRow = (category, metaData, perc, count, enrollment, tier, tooltip) => {

  let displayTier = tier === BEYOND_PLATINUM ? PLATINUM : tier;
  displayTier = displayTier === NONE ? '' : displayTier;

  let displayPerc = getPercentageLabel(parseInt(perc));
  displayPerc = getPercentageDisplay(displayPerc);

  return {
    category: <div className='row cb-padding-left-16'><CriteriaText>{category}</CriteriaText>{tooltip}</div>,
    schoolPercent: <CriteriaText>
      {displayPerc}
      {
        enrollment &&
        <>
          <div className='cb-font-size-regular cb-text-italic cb-font-weight-regular cb-padding-top-4'>{`(${formatCountValue(count, true)}/${formatCountValue(enrollment)})`}</div>
        </>
      }
    </CriteriaText>,
    schoolTier: <CriteriaText>{displayTier}</CriteriaText>,
    bronze: <CriteriaText>{metaData[0]}%</CriteriaText>,
    silver: <CriteriaText>{metaData[1]}%</CriteriaText>,
    gold: <CriteriaText>{metaData[2]}%</CriteriaText>,
    platinum: <CriteriaText>{metaData[3]}%</CriteriaText>
  };
};

export const getCriteriaTableData = ({schoolData, cultureMetadata, creditMetadata, optimizationMetadata}) => {
  return [
    getCriteriaTableRow(CULTURE, cultureMetadata, schoolData.college_culture_pct, schoolData.college_culture_count, schoolData.grade_12_enrollment, schoolData.college_culture_tier, <CultureTooltip />),
    getCriteriaTableRow(CREDIT, creditMetadata, schoolData.college_credit_pct, schoolData.college_credit_count, schoolData.grade_12_enrollment, schoolData.college_credit_tier, <CreditTooltip />),
    getCriteriaTableRow(OPTIMIZATION, optimizationMetadata, schoolData.college_optimization_pct, schoolData.college_optimization_count, schoolData.grade_12_enrollment, schoolData.college_opt_tier, <OptimizationTooltip />)
  ];

};

export const getOrgAddress = ({
  org_short_nm,
  org_street_address_1,
  org_street_address_2,
  org_city,
  org_state_cd,
  org_province,
  org_postal_cd
}) => {
  return `${org_short_nm} \n${org_street_address_1} \n${org_street_address_2 ? `${org_street_address_2} \n` : ''}${org_city ? `${org_city}, ` : ''}${org_state_cd ?? ''}${org_province ?? ''} ${org_postal_cd ?? ''}`;
};

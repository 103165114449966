import PropTypes from 'prop-types';
import React from 'react';
import {getReportSectionSelect} from '../../../../api';
import GenericDropdown from './genericDropdown';
import { Modal, CircularButton } from '@cb/apricot-react';
import { sectionModalContent } from './utils';

const InterstitialSectionDropdown = ({
  reportName,
  sttVal,
  selectedYear,
  setSectionDropdownSelectedId,
  setSectionDropdownSelectedNames,
  showInterstitialPage,
  isVisible,
  setIsReportError,
  schoolDropdownSelectedId,
  subjectDropdownSelectedId,
  label,
  isDistrictAdmin,
  isAggregate,
  setNoDataError,
  noDataError
}) => {
  // @TODO will use something like below to show message if no sections returned
  // const [noOptions, setNoOptions] = useState(false);
  const sectionModalTrigger = 'openSectionModal';
  const SectionLabelComponent = (label) => {
    return (<>
      <div className="box dropdown-content-container">
        {label}
        <CircularButton icon="question" small greyscale className="cb-margin-right-4 tooltip-icon" title="Section Tooltip"
          label="Section Tooltip" aria-haspopup="dialog" tabIndex="0" id={sectionModalTrigger} />
      </div>
    </>);
  };

  return (
    <>
      <Modal title="Section" trigger={sectionModalTrigger} withFooter={false}>
        {sectionModalContent}
      </Modal>
      <GenericDropdown
        id='selectSection'
        ariaLabel='Section Selection'
        multiSelect={true}
        isDisabled={() => subjectDropdownSelectedId === '' || noDataError === 'selectSection'}
        label={SectionLabelComponent(label)}
        showInterstitialPage={showInterstitialPage}
        isVisible={isVisible}
        setIsReportError={setIsReportError}
        placeholderText={'Sections'}
        shouldMakeApiCall={() => {
          return subjectDropdownSelectedId !== '';
        }}
        getOptionsApiCall={async () => {
        // setNoOptions(false);
          const res = await getReportSectionSelect({
            sttVal,
            adminYear: selectedYear,
            reportName,
            navSubject: subjectDropdownSelectedId,
            navSchool: schoolDropdownSelectedId, // this may be undefined and it will pull from sttVal
            isDistrictAdmin,
            isAggregate
          });
          return res;
        }}
        handleOptionChange={(values, _label) => {
          setSectionDropdownSelectedId(values.map(r => r.optionValue).join(','));
          setSectionDropdownSelectedNames((values.length > 1 ? `${values.length} Sections: ` : '') + values.map(r => r.label).join('; '));
        }}
        queryDataUseEffectConditions={[subjectDropdownSelectedId]}
        setNoDataError={setNoDataError}
      />
    </>);
};

InterstitialSectionDropdown.propTypes = {
  reportName: PropTypes.string,
  selectedYear: PropTypes.number,
  setSectionDropdownSelectedId: PropTypes.func,
  setSectionDropdownSelectedNames: PropTypes.func,
  showInterstitialPage: PropTypes.bool,
  sttVal: PropTypes.string,
  isVisible: PropTypes.bool,
  setIsReportError: PropTypes.func,
  schoolDropdownSelectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subjectDropdownSelectedId: PropTypes.string,
  label: PropTypes.string,
  isDistrictAdmin: PropTypes.bool,
  isAggregate: PropTypes.bool,
  setNoDataError: PropTypes.func,
  noDataError: PropTypes.string
};

export default InterstitialSectionDropdown;

import React from 'react';
import PropTypes from 'prop-types';
import { getNoEnrollmentMessage } from '../utils';

const HonorRollNoEnrollmentDisplay = ({year}) => {
  return (
    <p className='cb-text-italic'>
      {getNoEnrollmentMessage(year)}
    </p>);
};
HonorRollNoEnrollmentDisplay.propTypes = {
  year: PropTypes.number
};

export default HonorRollNoEnrollmentDisplay;

/* eslint-disable no-console */
import axios from 'axios';

const axiosInstance = axios.create({
  timeout: 30000 // ms
});

// used to store axios ids of interceptors to later eject them, should we need to re-initialize axios
let requestInterceptor = false;
let responseInterceptor = false;

// used in App.js to set the default headers once auth is completed
export const initializeAxiosInstance = (getHeaders) => {
  // if interceptors have been set, eject them prior to re-initializing them below
  requestInterceptor && axios.interceptors.request.eject(requestInterceptor);
  responseInterceptor && axios.interceptors.response.eject(responseInterceptor);

  // Add a request interceptor
  requestInterceptor = axiosInstance.interceptors.request.use(req => {
    // Do something before request is sent

    // refresh and set headers (except for orgsearch/aisearch api calls)
    if (!req.url?.includes('content.json') && !req.url?.includes('orgsearch') && !req.url?.includes('aisearch')) {
      req.headers = getHeaders();
    }

    return req;
  }, err => {
    // Do something with request error
    // console.log('axios request err:', err);
    throw err;
  });

  // // Add a response interceptor
  // responseInterceptor = axiosInstance.interceptors.response.use(res => {
  //   // Any status code that lie within the range of 2xx cause this function to trigger
  //   // Do something with response data
  //   console.log('axios response:', res);
  //   return res;
  // }, err => {
  //   // Any status codes that falls outside the range of 2xx cause this function to trigger
  //   // Do something with response error
  //   console.log('axios response err:', err);
  //   throw err;
  // });
};

export default axiosInstance;

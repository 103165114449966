import PropTypes from 'prop-types';
import React from 'react';
import { Page, StyleSheet } from '@react-pdf/renderer';
import ReportHeader from './header';
import ReportFooter from './footer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 45
  }
});

const BasePagePdf = ({children, reportName}) => (
  <Page size="LETTER" style={styles.page}>
    <ReportHeader />
    {children}
    <ReportFooter
      reportName={reportName}
    />
  </Page>
);

BasePagePdf.propTypes = {
  children: PropTypes.node,
  reportName: PropTypes.string
};

export default BasePagePdf;

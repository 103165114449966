import PropTypes from 'prop-types';
import React from 'react';
import DetailTable from '../common/detailTable';

const ScholarSummarySchoolTable = ({tableData, columnData}) => {
  return (<DetailTable
    // noStretch={true}
    columns={columnData}
    striped={true}
    data={tableData}
    responsive={true}
    tableId="scholar_summary_table"
  />);
};

ScholarSummarySchoolTable.propTypes = {
  tableData: PropTypes.array,
  columnData: PropTypes.array
};

export default ScholarSummarySchoolTable;

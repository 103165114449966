import { CircularButton } from '@cb/apricot-react';
import PropTypes from 'prop-types';
import React from 'react';

const ExpandIcon = ({id, text, isExpanded, clickHandler}) => {
  const desc = `${isExpanded ? 'Collapse' : 'Expand'} children of ${text}`;
  const icon = isExpanded ? 'minus' : 'plus';
  return (<div >
    <CircularButton onClick={clickHandler} icon={icon} small className="cb-margin-right-8" title={desc} label={desc} tabIndex="0" id={id} />
  </div>);
};
ExpandIcon.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  isExpanded: PropTypes.bool,
  clickHandler: PropTypes.func
};

export default ExpandIcon;

import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import analytics from '../../utils/analytics';
import { AppContext } from '../../AppContext';
import PageTitle from '../../components/common/PageTitle';
import errorConstants from '../../constants/errors';
import ErrorDisplay from './errorDisplay';

const ErrorBoundary = ({ error, notShowPageTitle }) => {
  const appContext = useContext(AppContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (error) {
      analytics.reportCurrentView('500errorpage', appContext.roleCd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return error ? (
    <div className="container">
      { !notShowPageTitle ? <PageTitle /> : null }
      <div className="row">
        <div className="cb-padding-top-48">
          <div className="col-xs-10">
            <ErrorDisplay
              title={<span className="error-title">Currently Unavailable</span>}
            >
              <div>{errorConstants.DEFAULT}</div>
            </ErrorDisplay>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

ErrorBoundary.propTypes = {
  error: PropTypes.object,
  notShowPageTitle: PropTypes.bool
};

export default ErrorBoundary;

/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import DetailTable from '../common/detailTable';
import { Input, Pager } from '@cb/apricot-react';
import './index.css';
import { waitMs } from '../../../utils/tableUtils';
import LinkToStudentScoreReport from '../common/linkToStudentScoreReport';
import { tabCheck } from '../common/utils';

export const getHandleFilterTextInput = ({setCurrentPage, setData, studentFilterTimeout, formattedRosterData, selectedSort}) => e => {
  const text = e.target.value;
  // debounce for 500ms
  clearTimeout(studentFilterTimeout);
  studentFilterTimeout = setTimeout(() => {
    const filteredList = formattedRosterData.filter(row => {
      return row.fn?.toLowerCase().startsWith(text.toLowerCase()) ||
    row.ln?.toLowerCase().startsWith(text.toLowerCase()) ||
    row.an?.toLowerCase().startsWith(text.toLowerCase());
    });
    setData(Object.hasOwn(selectedSort, 'ascending') ? sortRosterData(filteredList, selectedSort.field, selectedSort.ascending)
      : filteredList);
    setCurrentPage(0);
  }, 500);
};

const sortRosterData = (data, field, ascending) => {
  const fieldType = typeof (data[0]?.[field]) || typeof (data[0]?.studentInfo?.[field]);
  if (ascending) {
    if (field === 'bt') {
      return data.sort((a, b) => new Date(a[field]) - new Date(b[field]) || a.fullName.localeCompare(b.fullName));
    } else if (field === 'gl') {
      return data.sort((a, b) => a.elc - b.elc || a.fullName.localeCompare(b.fullName));
    } else if (fieldType === 'string') {
      return data.sort((a, b) => a[field].localeCompare(b[field]) || a.fullName.localeCompare(b.fullName));
    } else {
      return data.sort((a, b) => a[field] - b[field] || a.fullName.localeCompare(b.fullName));
    }
  } else {
    if (field === 'bt') {
      return data.sort((a, b) => new Date(b[field]) - new Date(a[field]) || b.fullName.localeCompare(a.fullName));
    } else if (field === 'gl') {
      return data.sort((a, b) => b.elc - a.elc || b.fullName.localeCompare(a.fullName));
    } else if (fieldType === 'string') {
      return data.sort((a, b) => b[field].localeCompare(a[field]) || b.fullName.localeCompare(a.fullName));
    } else {
      return data.sort((a, b) => b[field] - a[field] || b.fullName.localeCompare(a.fullName));
    }
  }
};

export const getOnSort = ({setCurrentPage, setSelectedSort, setData, data}) => async (field, ascending) => {
  await waitMs(500);
  const sortField = field === 'studentInfo' ? 'fullName' : field;
  setData(sortRosterData(data, sortField, ascending));
  setSelectedSort({field, ascending});
  setCurrentPage(0);
};

const formatRosterData = (rosterData, year, isAggregate, isDistrictAdmin, sttVal) => rosterData.map((row) => {
  // {fn: firstName, ln: lastName, mi: middleInitial, gl: gradeLevel, an: ap_number,
  // aci: candidateId, aoi: attendingOrgId, bt: birthDt, sid: studentId, elc: educationLevelCd, te: totalExams}
  const fullName = `${row.ln}; ${row.fn}${row.mi ? ` ${row.mi}.` : ''}`;

  return {
    fullName,
    studentInfo: {fullName, candidateId: row.aci, attendingOrgId: row.aoi, year, ap_number: row.an, isAggregate, isDistrictAdmin, sttVal},
    ...row
  };
});

const OrgRoster = ({rosterData, setTotalStudents, year, isAggregate, isDistrictAdmin, sttVal}) => {
  const PAGE_SIZE = 15;
  const {subjectColumns, totalStudents } = rosterData;
  const formattedRosterData = formatRosterData(rosterData.data, year, isAggregate, isDistrictAdmin, sttVal);
  const [selectedSort, setSelectedSort] = useState({field: 'fullName'});
  const [data, setData] = useState(formattedRosterData);

  useEffect(() => {
    setTotalStudents(totalStudents);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalStudents]);
  const sortedSubjectCols = Array.from(subjectColumns).sort();

  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = data.length > 0 ? Math.ceil(data.length / PAGE_SIZE) : 0;
  const pageData = data.slice(currentPage * PAGE_SIZE, currentPage * PAGE_SIZE + PAGE_SIZE);

  const rosterTableCols = [
    {
      title: 'Student Name',
      field: 'studentInfo',
      rowHeader: true,
      sticky: true,
      sortable: true,
      className: 'table-first-column-width',
      component: ({value}) => <LinkToStudentScoreReport
        year={value.year}
        isDistrictAdmin={value.isDistrictAdmin}
        isAggregate={value.isAggregate}
        candId={value.candidateId}
        fullName={value.fullName}
        apNumber={value.ap_number}
        attendingOrgId={value.attendingOrgId}
        sttVal={value.sttVal}
      />
    },
    {
      title: 'Grade Level',
      field: 'gl',
      sortable: true,
      className: 'grade-level-width'
    },
    {
      title: 'AP Number / AP ID',
      field: 'an',
      sortable: true
    },
    {
      title: 'Student ID',
      field: 'sid',
      sortable: true
    },
    {
      title: 'Date of Birth',
      field: 'bt',
      sortable: true
    },
    ...sortedSubjectCols.map(subject => ({title: subject, field: subject, className: 'rightAlignTableCells'})),
    {
      title: 'Total Exams',
      field: 'te',
      sortable: true,
      className: 'rightAlignTableCells'
    }
  ];

  let studentFilterTimeout;
  const handleFilterTextInput = getHandleFilterTextInput({setCurrentPage, setData, studentFilterTimeout, formattedRosterData, selectedSort});

  return (<div>
    <div id='stsr_student_search' className='student-search-width  cb-padding-bottom-8'>
      <Input
        name = 'searchBy'
        label = {<div className="stsr-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Search by First Name, Last Name, or AP ID</div>}
        onChange={handleFilterTextInput}
        placeholder = 'Type to search...'
        floating = {false}
        maxLength = {50}
        clearable={false}
        ariaLabel="Filter by First Name, Last Name, or AP ID"
        // disabled={isLoadingData}
      />
    </div>
    <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <DetailTable
        a11yHighlightText=''
        tableId="osr_roster_table"
        columns={rosterTableCols}
        striped={true}
        stickyHeaderParent={true}
        stickyColumn={true}
        data={pageData}
        sortType={data.length > 1 ? 'inline' : 'none'}
        maxHeight={600}
        overflowX={false}
        tabIndex={tabCheck}
        sortFn={(data, sort) => {
        // we handle the sort in onSort
          return data;
        }}
        onSort={getOnSort({setCurrentPage, setSelectedSort, setData, data})}
      />
    </div>
    {data.length > PAGE_SIZE && <Pager
      current={currentPage + 1}
      max={pageCount}
      onPageChange={page => {
        setCurrentPage(page - 1);
      }}
    />}
  </div>);

};

OrgRoster.propTypes = {
  rosterData: PropTypes.object,
  formatRosterData: PropTypes.object,
  year: PropTypes.number,
  setTotalStudents: PropTypes.func,
  isDistrictAdmin: PropTypes.bool,
  isAggregate: PropTypes.bool,
  sttVal: PropTypes.string
};

export default OrgRoster;

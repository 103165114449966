import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {DISABLED_REPORT_LINK_CSS_CLASS, ACTIVE_REPORT_LINK_CSS_CLASS, INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE} from '../../../constants/reports';

const LinkToOpenReport = ({
  enabled,
  reportName,
  selectedYear,
  reportPath,
  params,
  children,
  isSchoolReport,
  additionalTracking = ''
}) => {
  return (<NavLink className={!enabled ? DISABLED_REPORT_LINK_CSS_CLASS : ACTIVE_REPORT_LINK_CSS_CLASS} tabIndex="0" data-cbtrack-iframereport={`${isSchoolReport ? 'school' : 'district'}:` + reportName + ':' + selectedYear + ':ap' + additionalTracking} to={{
    // pathname: `/report/${reportPath}${subject ? `/${subject}` : ''}`,
    pathname: `/report/${reportPath}`,
    params
  }}>{children}</NavLink>);
};
LinkToOpenReport.propTypes = {
  reportName: PropTypes.string,
  reportPath: PropTypes.string,
  selectedYear: PropTypes.number,
  params: PropTypes.object,
  children: PropTypes.node,
  isSchoolReport: PropTypes.bool,
  additionalTracking: PropTypes.string,
  enabled: PropTypes.bool
};

export const TeacherReportLink = ({
  selectedYear,
  reportPath,
  subjectName,
  enabled,
  reportDisplayTitle,
  genesisSttVal
}) => {
  return (
    <LinkToOpenReport
      enabled={enabled}
      additionalTracking={`:${subjectName}`}
      reportName={reportDisplayTitle}
      selectedYear={selectedYear}
      reportPath={reportPath}
      params={{
        year: selectedYear,
        report: reportDisplayTitle,
        isDistrictAdmin: false,
        isAggregate: false,
        isTeacher: true,
        subjectName,
        genesisSttVal
      }}
      isSchoolReport={true}>
      <span>{subjectName} {!enabled ? <span>(Not Available)</span> : null}</span>
    </LinkToOpenReport>
  );

};

TeacherReportLink.propTypes = {
  reportPath: PropTypes.string,
  selectedYear: PropTypes.number,
  subjectName: PropTypes.string,
  enabled: PropTypes.bool,
  reportDisplayTitle: PropTypes.string,
  genesisSttVal: PropTypes.string
};

const ReportLink = ({
  selectedYear,
  report,
  isDistrictAdmin,
  isSchoolAdmin,
  reportPath,
  hasSchoolLink,
  hasAggregateLink,
  genesisSttVal
}) => {
  const params = {
    year: selectedYear,
    report: report.reportName,
    isDistrictAdmin: isDistrictAdmin,
    isAggregate: false,
    genesisSttVal
  };
  if (isSchoolAdmin) {
    return (
      <LinkToOpenReport
        enabled={report.enableReport}
        reportName={report.reportName}
        selectedYear={selectedYear}
        reportPath={reportPath}
        params={params}
        isSchoolReport={true}>
        <span>{report.reportName} {!report.enableReport ? <span>(Not Available)</span> : null}</span>
      </LinkToOpenReport>
    );
  }

  return (<>{
    hasSchoolLink
      ? <LinkToOpenReport
        enabled={report.enableReport}
        reportName={report.reportName}
        selectedYear={selectedYear}
        reportPath={reportPath}
        params={params}
        isSchoolReport={true}>
        <span>By School</span>
      </LinkToOpenReport>
      : null
  }
  {hasSchoolLink && hasAggregateLink ? <span> | </span> : null}
  {hasAggregateLink

    ? <LinkToOpenReport
      enabled={report.enableReport}
      reportName={report.reportName}
      selectedYear={selectedYear}
      reportPath={reportPath}
      params={{
        ...params, isAggregate: true
      }}>
      <span>Aggregated for Districts</span>
    </LinkToOpenReport>

    : null
  }
  </>);
};

ReportLink.propTypes = {
  hasAggregateLink: PropTypes.bool,
  hasSchoolLink: PropTypes.bool,
  isDistrictAdmin: PropTypes.bool,
  isSchoolAdmin: PropTypes.bool,
  report: PropTypes.shape({
    enableReport: PropTypes.bool,
    reportName: PropTypes.string
  }),
  reportPath: PropTypes.string,
  selectedYear: PropTypes.number,
  genesisSttVal: PropTypes.string
};

export default ReportLink;

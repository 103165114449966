import PropTypes from 'prop-types';
import React from 'react';
import createMarkup from '../../../utils/createMarkup';
import './subjectCards.css';
import '@cb/apricot/CBGrid';

const SubjectCards = ({cardData}) => {
  return (
    <>
      <span className="ap-info-flex"
        dangerouslySetInnerHTML={createMarkup(cardData)}
      ></span>
    </>
  );
};

SubjectCards.propTypes = {
  cardData: PropTypes.string
};

export default SubjectCards;

import { getAwardArr } from './dataParser';
import { omit } from 'ramda';
import { renameKeys } from 'ramda-adjunct';

const getAwardColumns = (arrAwards) => arrAwards.map(award => ({ title: award, field: award, className: 'rightAlignTableCells'}));

export const getScholarColumns = (isAggregate, arrApiData, appliedFilters) => {
  const arrSubjects = getAwardArr(arrApiData, appliedFilters);

  const cols = isAggregate ? [{ title: ' ', field: 'school', groupValues: true }] : [];
  return [
    ...cols,
    { title: ' ', field: 'label' },
    ...getAwardColumns(arrSubjects),
    { title: 'All Awards', field: 'total', className: 'rightAlignTableCells' }
  ];
};

export const getCsv = (isAggregate, data, initialHeaders) => {
  const csvHeader = initialHeaders;
  let csvData;
  if (isAggregate) {
    csvHeader.splice(0, 2, 'School', 'AI Code', 'Metric');
    csvData = data.map(i => omit(['school', 'highlighted'], renameKeys({schoolName: 'School', aiCode: 'AI Code', label: 'Metric', total: 'All Awards'})(i)));
  } else {
    csvHeader.splice(0, 1, 'Metric');
    csvData = data.map(i => omit(['school', 'schoolName', 'aiCode', 'highlighted'], renameKeys({label: 'Metric', total: 'All Awards'})(i)));
  }
  return {
    csvHeader,
    csvData
  };
};

export const FACEBOOK_TEMPLATE_SVG = `<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 628" height="628" width="1200">
  <defs>
    <style>
      .cls-1 {
        fill: #006298;
      }

      .cls-2 {
        fill: #fff;
      }

      .cls-3 {
        fill: #009ada;
      }
    </style>
  </defs>
  <g>
    <rect class="cls-2" x="12" y="12" width="1176" height="604"/>
    <path class="cls-1" d="m1176,24v580H24V24h1152m24-24H0v628h1200V0h0Z"/>
  </g>
  <g>
    <rect class="cls-3" x="412.5" y="275" width="707" height="20"/>
    <g>
      <path class="cls-3" d="m417.97,236.62l13.53-40.59h13.46l13,40.59h3.7c.22.7.38,1.4.5,2.08.11.68.17,1.33.17,1.95s-.06,1.28-.17,1.98c-.11.7-.28,1.41-.5,2.11h-19.21c-.22-.62-.39-1.26-.5-1.95-.11-.68-.17-1.31-.17-1.88s.05-1.19.17-1.85c.11-.66.27-1.3.5-1.91h3.96l-1.78-6.2h-14.12l-1.91,6.2h4.03c.22.62.38,1.25.5,1.91.11.66.17,1.28.17,1.85s-.06,1.2-.17,1.88c-.11.68-.28,1.33-.5,1.95h-18.68c-.22-.7-.39-1.41-.5-2.11-.11-.7-.17-1.36-.17-1.98s.05-1.26.17-1.95c.11-.68.27-1.37.5-2.08h4.03Zm24.75-13.07l-4.88-17.69-5.21,17.69h10.1Z"/>
      <path class="cls-3" d="m469.91,236.62v-31.88h-4.09c-.22-.7-.39-1.4-.5-2.08-.11-.68-.17-1.33-.17-1.95s.05-1.28.17-1.98c.11-.7.27-1.41.5-2.11h23.83c2.51,0,4.72.37,6.63,1.12,1.91.75,3.52,1.77,4.82,3.07,1.3,1.3,2.28,2.82,2.94,4.55.66,1.74.99,3.62.99,5.64,0,1.72-.25,3.45-.76,5.21-.51,1.76-1.37,3.34-2.57,4.75-1.21,1.41-2.79,2.56-4.75,3.46-1.96.9-4.39,1.35-7.29,1.35h-8.65v10.82h5.02c.22.7.38,1.4.49,2.08.11.68.17,1.33.17,1.95s-.06,1.28-.17,1.98c-.11.7-.28,1.41-.49,2.11h-20.2c-.22-.7-.39-1.41-.5-2.11-.11-.7-.17-1.36-.17-1.98s.05-1.26.17-1.95c.11-.68.27-1.37.5-2.08h4.09Zm17.29-19.07c2.02,0,3.59-.53,4.69-1.58,1.1-1.06,1.65-2.68,1.65-4.88,0-1.98-.54-3.5-1.62-4.55-1.08-1.06-2.58-1.58-4.52-1.58h-6.4v12.61h6.2Z"/>
      <path class="cls-3" d="m506.6,199.9c0-.57.11-1.11.33-1.6.22-.49.51-.91.88-1.27s.8-.63,1.3-.83,1.02-.3,1.57-.3,1.06.1,1.55.3.92.48,1.29.83c.37.36.66.78.88,1.27.22.49.33,1.02.33,1.6s-.11,1.12-.33,1.61-.51.92-.88,1.27c-.37.36-.8.64-1.29.84s-1.01.3-1.55.3-1.08-.1-1.57-.3-.92-.48-1.3-.84c-.37-.36-.67-.78-.88-1.27s-.33-1.03-.33-1.61Zm4.07,3.13c.42,0,.81-.08,1.18-.24.37-.16.69-.37.96-.65.27-.28.49-.61.64-.99.15-.38.23-.8.23-1.25s-.08-.85-.23-1.23c-.15-.38-.37-.71-.64-.98-.27-.28-.59-.49-.96-.65-.37-.16-.76-.24-1.18-.24s-.83.08-1.2.24c-.37.16-.69.37-.97.65-.27.28-.49.6-.64.98-.15.38-.23.79-.23,1.23s.08.87.23,1.25c.15.38.37.71.64.99s.59.49.97.65c.37.16.77.24,1.2.24Zm-1.55-1.66v-2.95h-.31c-.03-.06-.05-.12-.06-.19s-.02-.14-.02-.21,0-.14.02-.21c.01-.07.03-.14.06-.19h2.2c.23,0,.44.04.62.11.19.07.34.17.47.3.13.13.23.27.3.44.07.17.1.35.1.54,0,.09-.01.19-.03.3-.02.11-.06.23-.11.34s-.12.23-.21.33c-.09.11-.21.2-.35.27l.49,1.12h.32c.03.06.05.12.06.19.01.07.02.15.02.22s0,.14-.02.21-.03.13-.06.19h-1.2l-.68-1.79h-.56v.98h.29c.03.06.05.12.06.19,0,.07.02.15.02.22s0,.14-.02.21c-.01.07-.03.13-.06.19h-1.66c-.03-.06-.05-.12-.06-.19-.01-.07-.02-.14-.02-.21s0-.14.02-.22c0-.07.03-.14.06-.19h.3Zm1.66-1.82c.4,0,.6-.19.6-.56,0-.17-.05-.3-.15-.4-.1-.1-.24-.15-.42-.15h-.62v1.1h.6Z"/>
      <path class="cls-3" d="m527.3,227.45c.62-.35,1.34-.61,2.18-.76.84-.15,1.63-.23,2.38-.23s1.53.08,2.34.23c.81.15,1.53.41,2.14.76v2.71c0,.75.23,1.53.69,2.34s1.15,1.56,2.08,2.24c.92.68,2.07,1.24,3.43,1.68,1.36.44,2.95.66,4.75.66,2.68,0,4.66-.47,5.94-1.42,1.28-.95,1.91-2.19,1.91-3.73,0-2.73-2.11-4.71-6.34-5.94l-8.65-2.51c-1.58-.44-3.1-1.01-4.55-1.71-1.45-.7-2.73-1.58-3.83-2.64-1.1-1.06-1.98-2.32-2.64-3.79-.66-1.47-.99-3.24-.99-5.31,0-2.51.5-4.68,1.48-6.5.99-1.83,2.27-3.34,3.83-4.55,1.56-1.21,3.3-2.1,5.21-2.67,1.91-.57,3.79-.86,5.64-.86,2.77,0,5.07.41,6.9,1.22,1.83.81,3.22,1.86,4.19,3.13v-2.64c.62-.35,1.32-.6,2.11-.76.79-.15,1.54-.23,2.24-.23s1.46.08,2.28.23c.81.16,1.53.41,2.14.76v15.38c-.62.35-1.33.61-2.14.76-.81.16-1.6.23-2.34.23s-1.54-.08-2.38-.23c-.84-.15-1.56-.41-2.18-.76v-1.58c0-.88-.19-1.73-.56-2.54-.37-.81-.94-1.54-1.68-2.18-.75-.64-1.72-1.16-2.9-1.55-1.19-.4-2.57-.59-4.16-.59-1.41,0-2.61.22-3.6.66-.99.44-1.77,1.01-2.34,1.72-.62.75-.92,1.63-.92,2.64,0,.92.3,1.78.89,2.57s1.79,1.43,3.6,1.91l8.18,2.31c2.02.57,3.93,1.24,5.71,2.01,1.78.77,3.34,1.74,4.69,2.9,1.34,1.17,2.4,2.58,3.17,4.26.77,1.67,1.16,3.72,1.16,6.14,0,2.6-.52,4.82-1.55,6.67-1.04,1.85-2.4,3.37-4.09,4.55-1.69,1.19-3.6,2.06-5.71,2.61-2.11.55-4.22.83-6.34.83-2.68,0-5.18-.43-7.49-1.29-2.31-.86-4.04-2.06-5.18-3.6v3.1c-.62.35-1.33.61-2.14.76-.82.15-1.57.23-2.28.23s-1.44-.08-2.21-.23c-.77-.15-1.46-.41-2.08-.76v-16.63Z"/>
      <path class="cls-3" d="m602.21,242.56c-1.15.62-2.79,1.28-4.95,1.98-2.16.7-4.73,1.06-7.72,1.06s-5.62-.46-7.89-1.39c-2.27-.92-4.16-2.2-5.68-3.83-1.52-1.63-2.66-3.55-3.43-5.78-.77-2.22-1.15-4.63-1.15-7.23s.33-5.09.99-7.36c.66-2.27,1.64-4.23,2.94-5.91,1.3-1.67,2.89-2.98,4.79-3.93,1.89-.95,4.07-1.42,6.53-1.42,2.11,0,3.82.35,5.11,1.06,1.3.71,2.25,1.52,2.87,2.44v-2.05c.48-.22,1.07-.4,1.75-.53.68-.13,1.4-.2,2.15-.2s1.46.07,2.14.2c.68.13,1.26.31,1.75.53v14.39c-.48.22-1.12.42-1.91.59s-1.58.26-2.38.26-1.58-.09-2.38-.26-1.43-.37-1.91-.59v-2.38c0-1.89-.46-3.28-1.39-4.16-.92-.88-2.13-1.32-3.63-1.32-1.1,0-2.04.26-2.8.79-.77.53-1.41,1.24-1.91,2.14-.51.9-.88,1.97-1.12,3.2-.24,1.23-.36,2.53-.36,3.89,0,3.43.85,5.96,2.54,7.59,1.69,1.63,3.86,2.44,6.5,2.44,2.2,0,4.17-.38,5.91-1.15,1.74-.77,3.29-1.66,4.65-2.67v9.57Z"/>
      <path class="cls-3" d="m611.18,237.28v-35.11h-3.83c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h14.92v17.16c1.01-.92,2.25-1.69,3.73-2.31,1.47-.62,3.27-.92,5.38-.92,3.43,0,6.17,1,8.22,3,2.05,2,3.07,4.9,3.07,8.68v16.96h3.83c.44,1.23.66,2.49.66,3.76s-.22,2.46-.66,3.7h-14.92v-22.44c0-1.76-.29-3.1-.86-4.03-.57-.92-1.58-1.39-3.04-1.39-.62,0-1.24.09-1.88.26-.64.18-1.22.54-1.75,1.09-.53.55-.96,1.32-1.29,2.31-.33.99-.49,2.28-.49,3.86v12.87h2.84c.44,1.23.66,2.49.66,3.76s-.22,2.46-.66,3.7h-17.75c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h3.83Z"/>
      <path class="cls-3" d="m668.67,245.66c-3.04,0-5.65-.52-7.85-1.55s-4.03-2.41-5.48-4.12c-1.45-1.71-2.53-3.68-3.23-5.91-.7-2.22-1.06-4.54-1.06-6.96s.35-4.68,1.06-6.9c.7-2.22,1.78-4.19,3.23-5.91,1.45-1.72,3.28-3.09,5.48-4.12,2.2-1.03,4.82-1.55,7.85-1.55s5.66.52,7.89,1.55c2.22,1.04,4.06,2.41,5.51,4.12,1.45,1.72,2.53,3.69,3.23,5.91.7,2.22,1.06,4.52,1.06,6.9s-.35,4.74-1.06,6.96c-.7,2.22-1.78,4.19-3.23,5.91-1.45,1.72-3.29,3.09-5.51,4.12-2.22,1.03-4.85,1.55-7.89,1.55Zm0-8.25c1.14,0,2.12-.27,2.94-.82.81-.55,1.47-1.3,1.98-2.24.5-.95.88-2.05,1.12-3.3.24-1.25.36-2.56.36-3.93s-.12-2.66-.36-3.89c-.24-1.23-.62-2.31-1.12-3.23-.51-.92-1.17-1.66-1.98-2.21-.81-.55-1.79-.82-2.94-.82s-2.11.28-2.9.82c-.79.55-1.44,1.29-1.95,2.21s-.88,2-1.12,3.23c-.24,1.23-.36,2.53-.36,3.89s.12,2.67.36,3.93c.24,1.25.62,2.35,1.12,3.3.51.95,1.16,1.69,1.95,2.24.79.55,1.76.82,2.9.82Z"/>
      <path class="cls-3" d="m709.13,245.66c-3.04,0-5.65-.52-7.85-1.55s-4.03-2.41-5.48-4.12c-1.45-1.71-2.53-3.68-3.23-5.91-.7-2.22-1.06-4.54-1.06-6.96s.35-4.68,1.06-6.9c.7-2.22,1.78-4.19,3.23-5.91,1.45-1.72,3.28-3.09,5.48-4.12,2.2-1.03,4.82-1.55,7.85-1.55s5.66.52,7.89,1.55c2.22,1.04,4.06,2.41,5.51,4.12,1.45,1.72,2.53,3.69,3.23,5.91.7,2.22,1.06,4.52,1.06,6.9s-.35,4.74-1.06,6.96c-.7,2.22-1.78,4.19-3.23,5.91-1.45,1.72-3.29,3.09-5.51,4.12-2.22,1.03-4.85,1.55-7.89,1.55Zm0-8.25c1.14,0,2.12-.27,2.94-.82.81-.55,1.47-1.3,1.98-2.24.5-.95.88-2.05,1.12-3.3.24-1.25.36-2.56.36-3.93s-.12-2.66-.36-3.89c-.24-1.23-.62-2.31-1.12-3.23-.51-.92-1.17-1.66-1.98-2.21-.81-.55-1.79-.82-2.94-.82s-2.11.28-2.9.82c-.79.55-1.44,1.29-1.95,2.21s-.88,2-1.12,3.23c-.24,1.23-.36,2.53-.36,3.89s.12,2.67.36,3.93c.24,1.25.62,2.35,1.12,3.3.51.95,1.16,1.69,1.95,2.24.79.55,1.76.82,2.9.82Z"/>
      <path class="cls-3" d="m735.46,237.28v-35.11h-3.7c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h14.78v42.57h3.7c.44,1.23.66,2.49.66,3.76s-.22,2.46-.66,3.7h-18.48c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h3.7Z"/>
      <path class="cls-3" d="m777.5,236.62v-31.88h-4.09c-.22-.7-.39-1.4-.5-2.08-.11-.68-.17-1.33-.17-1.95s.05-1.28.17-1.98c.11-.7.27-1.41.5-2.11h18.81c.22.7.38,1.41.5,2.11.11.71.17,1.37.17,1.98s-.06,1.27-.17,1.95c-.11.68-.28,1.38-.5,2.08h-3.37v10.43h15.64v-10.43h-3.37c-.22-.7-.39-1.4-.5-2.08-.11-.68-.17-1.33-.17-1.95s.05-1.28.17-1.98c.11-.7.27-1.41.5-2.11h18.81c.22.7.38,1.41.49,2.11.11.71.17,1.37.17,1.98s-.06,1.27-.17,1.95c-.11.68-.27,1.38-.49,2.08h-4.09v31.88h4.09c.22.7.38,1.4.49,2.08.11.68.17,1.33.17,1.95s-.06,1.28-.17,1.98c-.11.7-.27,1.41-.49,2.11h-18.81c-.22-.7-.39-1.41-.5-2.11-.11-.7-.17-1.36-.17-1.98s.05-1.26.17-1.95c.11-.68.27-1.37.5-2.08h3.37v-12.67h-15.64v12.67h3.37c.22.7.38,1.4.5,2.08.11.68.17,1.33.17,1.95s-.06,1.28-.17,1.98c-.11.7-.28,1.41-.5,2.11h-18.81c-.22-.7-.39-1.41-.5-2.11-.11-.7-.17-1.36-.17-1.98s.05-1.26.17-1.95c.11-.68.27-1.37.5-2.08h4.09Z"/>
      <path class="cls-3" d="m841.85,245.66c-3.04,0-5.65-.52-7.85-1.55s-4.03-2.41-5.48-4.12c-1.45-1.71-2.53-3.68-3.23-5.91-.7-2.22-1.06-4.54-1.06-6.96s.35-4.68,1.06-6.9c.7-2.22,1.78-4.19,3.23-5.91,1.45-1.72,3.28-3.09,5.48-4.12,2.2-1.03,4.82-1.55,7.85-1.55s5.66.52,7.89,1.55c2.22,1.04,4.06,2.41,5.51,4.12,1.45,1.72,2.53,3.69,3.23,5.91.7,2.22,1.06,4.52,1.06,6.9s-.35,4.74-1.06,6.96c-.7,2.22-1.78,4.19-3.23,5.91-1.45,1.72-3.29,3.09-5.51,4.12-2.22,1.03-4.85,1.55-7.89,1.55Zm0-8.25c1.14,0,2.12-.27,2.94-.82.81-.55,1.47-1.3,1.98-2.24.5-.95.88-2.05,1.12-3.3.24-1.25.36-2.56.36-3.93s-.12-2.66-.36-3.89c-.24-1.23-.62-2.31-1.12-3.23-.51-.92-1.17-1.66-1.98-2.21-.81-.55-1.79-.82-2.94-.82s-2.11.28-2.9.82c-.79.55-1.44,1.29-1.95,2.21s-.88,2-1.12,3.23c-.24,1.23-.36,2.53-.36,3.89s.12,2.67.36,3.93c.24,1.25.62,2.35,1.12,3.3.51.95,1.16,1.69,1.95,2.24.79.55,1.76.82,2.9.82Z"/>
      <path class="cls-3" d="m867.26,237.28v-20.26h-3.83c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h13.2l.4,3.76c.92-1.28,2.23-2.38,3.93-3.3,1.69-.92,3.86-1.39,6.5-1.39,3.43,0,6.17,1,8.22,3,2.05,2,3.07,4.9,3.07,8.68v16.96h3.83c.44,1.23.66,2.49.66,3.76s-.22,2.46-.66,3.7h-14.92v-22.44c0-1.76-.29-3.1-.86-4.03-.57-.92-1.58-1.39-3.04-1.39-.62,0-1.24.09-1.88.26-.64.18-1.22.54-1.75,1.09-.53.55-.96,1.32-1.29,2.31-.33.99-.5,2.28-.5,3.86v12.87h2.84c.44,1.23.66,2.49.66,3.76s-.22,2.46-.66,3.7h-17.75c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h3.83Z"/>
      <path class="cls-3" d="m922.77,245.66c-3.04,0-5.65-.52-7.85-1.55s-4.03-2.41-5.48-4.12c-1.45-1.71-2.53-3.68-3.23-5.91-.7-2.22-1.06-4.54-1.06-6.96s.35-4.68,1.06-6.9c.7-2.22,1.78-4.19,3.23-5.91,1.45-1.72,3.28-3.09,5.48-4.12,2.2-1.03,4.82-1.55,7.85-1.55s5.66.52,7.89,1.55c2.22,1.04,4.06,2.41,5.51,4.12,1.45,1.72,2.53,3.69,3.23,5.91.7,2.22,1.06,4.52,1.06,6.9s-.35,4.74-1.06,6.96c-.7,2.22-1.78,4.19-3.23,5.91-1.45,1.72-3.29,3.09-5.51,4.12-2.22,1.03-4.85,1.55-7.89,1.55Zm0-8.25c1.14,0,2.12-.27,2.94-.82.81-.55,1.47-1.3,1.98-2.24.5-.95.88-2.05,1.12-3.3.24-1.25.36-2.56.36-3.93s-.12-2.66-.36-3.89c-.24-1.23-.62-2.31-1.12-3.23-.51-.92-1.17-1.66-1.98-2.21-.81-.55-1.79-.82-2.94-.82s-2.11.28-2.9.82c-.79.55-1.44,1.29-1.95,2.21s-.88,2-1.12,3.23c-.24,1.23-.36,2.53-.36,3.89s.12,2.67.36,3.93c.24,1.25.62,2.35,1.12,3.3.51.95,1.16,1.69,1.95,2.24.79.55,1.76.82,2.9.82Z"/>
      <path class="cls-3" d="m948.44,237.28v-20.26h-3.76c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h13.4l.4,5.48c1.06-2.16,2.33-3.61,3.83-4.36,1.5-.75,2.9-1.12,4.22-1.12.88,0,1.68.08,2.41.23.73.15,1.46.41,2.21.76l-1.12,8.91c-.66-.22-1.31-.42-1.95-.59-.64-.18-1.35-.26-2.15-.26-.88,0-1.75.18-2.61.53-.86.35-1.6.9-2.21,1.65-.48.57-.87,1.3-1.16,2.18-.29.88-.43,1.91-.43,3.1v11.22h4.49c.44,1.23.66,2.49.66,3.76s-.22,2.46-.66,3.7h-19.34c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h3.76Z"/>
      <path class="cls-3" d="m996.75,236.62v-31.88h-4.09c-.22-.7-.39-1.4-.5-2.08-.11-.68-.17-1.33-.17-1.95s.05-1.28.17-1.98c.11-.7.27-1.41.5-2.11h25.01c2.42,0,4.6.38,6.53,1.15,1.94.77,3.57,1.82,4.92,3.13,1.34,1.32,2.36,2.86,3.07,4.62.7,1.76,1.06,3.63,1.06,5.61,0,.97-.12,2.07-.36,3.3-.24,1.23-.66,2.45-1.25,3.66-.59,1.21-1.4,2.38-2.41,3.5-1.01,1.12-2.27,2.04-3.76,2.74l6.6,12.28h4.42c.22.7.38,1.4.5,2.08.11.68.16,1.33.16,1.95s-.06,1.28-.16,1.98c-.11.7-.28,1.41-.5,2.11h-12.87l-9.17-19.07h-6.6v10.96h4.75c.22.7.38,1.4.5,2.08.11.68.17,1.33.17,1.95s-.06,1.28-.17,1.98c-.11.7-.28,1.41-.5,2.11h-19.93c-.22-.7-.39-1.41-.5-2.11-.11-.7-.17-1.36-.17-1.98s.05-1.26.17-1.95c.11-.68.27-1.37.5-2.08h4.09Zm18.61-19.4c4.27,0,6.4-2.05,6.4-6.14,0-1.94-.54-3.42-1.62-4.46-1.08-1.03-2.63-1.55-4.65-1.55h-7.66v12.14h7.52Z"/>
      <path class="cls-3" d="m1056.55,245.66c-3.04,0-5.65-.52-7.85-1.55s-4.03-2.41-5.48-4.12c-1.45-1.71-2.53-3.68-3.23-5.91-.7-2.22-1.06-4.54-1.06-6.96s.35-4.68,1.06-6.9c.7-2.22,1.78-4.19,3.23-5.91,1.45-1.72,3.28-3.09,5.48-4.12,2.2-1.03,4.82-1.55,7.85-1.55s5.66.52,7.89,1.55c2.22,1.04,4.06,2.41,5.51,4.12,1.45,1.72,2.53,3.69,3.23,5.91.7,2.22,1.06,4.52,1.06,6.9s-.35,4.74-1.06,6.96c-.7,2.22-1.78,4.19-3.23,5.91-1.45,1.72-3.29,3.09-5.51,4.12-2.22,1.03-4.85,1.55-7.89,1.55Zm0-8.25c1.14,0,2.12-.27,2.94-.82.81-.55,1.47-1.3,1.98-2.24.5-.95.88-2.05,1.12-3.3.24-1.25.36-2.56.36-3.93s-.12-2.66-.36-3.89c-.24-1.23-.62-2.31-1.12-3.23-.51-.92-1.17-1.66-1.98-2.21-.81-.55-1.79-.82-2.94-.82s-2.11.28-2.9.82c-.79.55-1.44,1.29-1.95,2.21s-.88,2-1.12,3.23c-.24,1.23-.36,2.53-.36,3.89s.12,2.67.36,3.93c.24,1.25.62,2.35,1.12,3.3.51.95,1.16,1.69,1.95,2.24.79.55,1.76.82,2.9.82Z"/>
      <path class="cls-3" d="m1081.23,237.28v-35.11h-3.7c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h14.78v42.57h3.7c.44,1.23.66,2.49.66,3.76s-.22,2.46-.66,3.7h-18.48c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h3.7Z"/>
      <path class="cls-3" d="m1104.46,237.28v-35.11h-3.7c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h14.78v42.57h3.7c.44,1.23.66,2.49.66,3.76s-.22,2.46-.66,3.7h-18.48c-.44-1.23-.66-2.46-.66-3.7s.22-2.53.66-3.76h3.7Z"/>
    </g>
  </g>
</svg>`;

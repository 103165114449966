import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal as ReactModal } from '@cb/apricot-react';

import createMarkup from '../../utils/createMarkup';
import { AppContext } from '../../AppContext';

const Modal = ({ modalTitle, trigger }) => {
  const appContext = useContext(AppContext);
  return (
    <ReactModal
      title={modalTitle}
      trigger={trigger}
      withFooter
      withHeader
    >
      {
        modalTitle === 'Reasons Why a Student or Score May Not Appear on your Reports' ? <>
          <div dangerouslySetInnerHTML={createMarkup(appContext.content?.admin_tools_modal)}></div>
        </> : modalTitle === 'Find a School AI Code'
          ? <>
            <p>
              To find the AI code for a specific school in your district, view any report for that school. In the report header
              the name of the school will appear along with their 6 digit code in parenthesis. Example - “ABC High School (123456)”
            </p>
            <p>
              Please provide this code with your request to help expedite the response to your request.
            </p>
          </> : modalTitle === 'Attended vs. Instructed Students'
            ? <>
              <p>
                <b>Only submit a request to remove a student if a student who does not attend your school full time is appearing in your Student Datafile or Student Score Report.</b>
              </p>
              <p>
                A student will still appear in your Subject Score Roster reports or Organization Score Roster report if they were instructed by a teacher at your school for a specific subject or subject(s) and enrolled in a class section in AP Registration and Ordering even if they do not attend your school full time. Their teacher will be able to see their score for that particular subject.
              </p>
              <p>
                Most students receive instruction in AP courses at the school they attend full time. This is the school they indicate in their AP registration. In some instances, however, students may receive instruction at a different school for a specific course and enroll in a class section at this other institution. This may happen if a subject is not being offered at their attending institution, or if they are using an online learning provider.
              </p>
              <p>
                School and district administrators can filter these students out of their reports by using the &quot;Attended/Instructed&quot; filter.
              </p>
            </> : null}
    </ReactModal>
  );
};
Modal.propTypes = {
  modalTitle: PropTypes.string,
  trigger: PropTypes.string
};

export default Modal;

import React, { Fragment, memo } from 'react';

const PageTitle = () => {
  return (
    <Fragment>
      <div className="ap-title">
        AP Score Reports for Educators
      </div>
      <div className="ap-container-border"></div>
    </Fragment>
  );
};

export default memo(PageTitle);

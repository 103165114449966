import { SCHOLAR_ROSTER_REPORT_URI } from '../../constants/reports';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';

// returns report list for current request
const getScholarRosterData = ({ sttVal, navYear, navSchool, fltGrade, fltScholarAwards, isDistrictAdmin }) => {
  const url =
          config.apiBaseUrl +
          config.genesisUri +
          SCHOLAR_ROSTER_REPORT_URI;

  const params = {
    sttVal,
    navYear,
    fltGrade,
    fltScholarAwards
  };

  if (isDistrictAdmin) {
    params.navSchool = navSchool;
  }

  return axiosInstance.get(url, { params });
};

export default getScholarRosterData;

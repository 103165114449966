const ENV_NAME = process.env.REACT_APP_STAGE;

const envConfigs = {
  dev: {
    apiBaseUrl: 'https://api.aposre-nonprod.collegeboard.org/',
    logiDomain: 'dev-scores.aposre-nonprod.collegeboard.org',
    msOrgBaseUrl: 'https://organization.cds-nonprod.collegeboard.org/palm/',
    xApiKey: 'TnJlcERBa25CazdsRHBUSVdKU2d4NmxzRjFHUDhqNEJhZU83ZTRhcg==',
    UIURL: 'https://dev-scores.aposre-nonprod.collegeboard.org/',
    appsync_graphqlEndpoint: 'https://f5mjthi4zbfl5arvrvydiaffny.appsync-api.us-east-1.amazonaws.com/graphql'
  },
  qa: {
    apiBaseUrl: 'https://api.aposre-nonprod.collegeboard.org/',
    logiDomain: 'qa-scores.aposre-nonprod.collegeboard.org',
    msOrgBaseUrl: 'https://organization.cds-nonprod.collegeboard.org/palm/',
    xApiKey: 'TnJlcERBa25CazdsRHBUSVdKU2d4NmxzRjFHUDhqNEJhZU83ZTRhcg==',
    UIURL: 'https://qa-scores.aposre-nonprod.collegeboard.org/',
    appsync_graphqlEndpoint: 'https://u4knmee2b5bvplmx3sxsy45ppe.appsync-api.us-east-1.amazonaws.com/graphql'
  },
  perf: {
    apiBaseUrl: 'https://api.aposre-nonprod.collegeboard.org/',
    logiDomain: 'perf-scores.aposre-nonprod.collegeboard.org',
    msOrgBaseUrl: 'https://organization.cds-nonprod.collegeboard.org/spruce/',
    xApiKey: 'TnJlcERBa25CazdsRHBUSVdKU2d4NmxzRjFHUDhqNEJhZU83ZTRhcg==',
    UIURL: 'https://perf-scores.aposre-nonprod.collegeboard.org/',
    appsync_graphqlEndpoint: 'https://ovf3ng6gnfdthomrbq2iqanxku.appsync-api.us-east-1.amazonaws.com/graphql'
  },
  prod: {
    apiBaseUrl: 'https://aposre-api.collegeboard.org/',
    logiDomain: 'scores.collegeboard.org',
    msOrgBaseUrl: 'https://organization.cds-prod.collegeboard.org/pine/',
    xApiKey: 'UGZsd3NnNWl3QzRlY2hPZUxYOFgxOGZpUDQ1c0ZJUjgzQmh4ME1nWQ==',
    UIURL: 'https://scores.collegeboard.org/',
    envIntent: 'production',
    appsync_graphqlEndpoint: 'https://aposre-appsync.collegeboard.org/graphql'
  },
  uat: {
    apiBaseUrl: 'https://api-uat.aposre-prod.collegeboard.org/',
    logiDomain: 'uat-scores.aposre-prod.collegeboard.org',
    msOrgBaseUrl: 'https://organization.cds-prod.collegeboard.org/pine/',
    xApiKey: 'UGZsd3NnNWl3QzRlY2hPZUxYOFgxOGZpUDQ1c0ZJUjgzQmh4ME1nWQ==',
    UIURL: 'https://uat-scores.aposre-prod.collegeboard.org/',
    appsync_graphqlEndpoint: 'https://yvpf4zmgtzb6pdktpj32ytly6q.appsync-api.us-east-1.amazonaws.com/graphql'
  },
  oat: {
    apiBaseUrl: 'https://api.aposre-nonprod.collegeboard.org/',
    logiDomain: 'oat-scores.aposre-nonprod.collegeboard.org',
    msOrgBaseUrl: 'https://organization.cds-nonprod.collegeboard.org/maple/',
    xApiKey: 'TnJlcERBa25CazdsRHBUSVdKU2d4NmxzRjFHUDhqNEJhZU83ZTRhcg==',
    UIURL: 'https://oat-scores.aposre-nonprod.collegeboard.org/',
    appsync_graphqlEndpoint: 'https://vr3pswqgjvc2jimlfdjz6bhhlm.appsync-api.us-east-1.amazonaws.com/graphql'
  }
};

const localEnv = 'dev'; // switch this to any of the above envConfigs properties to set local env
const local = {
  ...envConfigs[localEnv],
  UIURL: 'https://local.aposre-nonprod.collegeboard.org:3000/',

  // the below are common for all non-local envs, but explicitly set here because ENV_NAME won't be 'dev'
  msTcUri: `mstermscondition-${localEnv}/termsconditions/`,
  msMessagesUri: `msmessaging-${localEnv}/messages`,
  mscustomerUri: `mscustomerfeedback-${localEnv}`,
  genesisUri: `genesis-${localEnv}/`
};

const config = typeof envConfigs[ENV_NAME] !== 'undefined' ? envConfigs[ENV_NAME] : local;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // common config values here ...
  appsync_region: 'us-east-1',
  domainName: 'aposre',
  msTcUri: `mstermscondition-${ENV_NAME}/termsconditions/`,
  msMessagesUri: `msmessaging-${ENV_NAME}/messages/`,
  mscustomerUri: `mscustomerfeedback-${ENV_NAME}`,
  genesisUri: `genesis-${ENV_NAME}/`,

  // env specific config from above
  ...config
};

import React from 'react';

const AddPropsToRoute = (WrappedComponent, passedProps) => {
  return (
    function Route(props) {
      let allProps = {
        ...props,
        ...passedProps
      };
      return <WrappedComponent {...allProps} />;
    }
  );
};

export default AddPropsToRoute;

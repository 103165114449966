import {
  DISTRICT_SUMMARY_BY_SCHOOL_REPORT_TITLE,
  DISTRICT_SUMMARY_BY_SCHOOL_URI
} from '../../constants/reports';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';

// returns report list for current request
const getDistrictSummaryBySchoolData = ({ sttVal, navYear }) => {
  const url =
      config.apiBaseUrl +
      config.genesisUri +
      DISTRICT_SUMMARY_BY_SCHOOL_URI;

  const params = {
    reportName: DISTRICT_SUMMARY_BY_SCHOOL_REPORT_TITLE,
    sttVal,
    navYear
  };

  return axiosInstance.get(url, { params });
};

export default getDistrictSummaryBySchoolData;

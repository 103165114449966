
import { getDisplayNameWithAi, replaceObjZerosAndCountValues, getTotalsPercentageString } from '../../../utils/stringUtils';

const TOTAL_ROW_LABEL = 'Total Exams';
const PERC_TOTAL_ROW_LABEL = '% of Total Exams';

export const applyFilters = (arrData, appliedFilters = []) => {
  if (appliedFilters.length === 0) {
    return arrData;
  }
  const subjects = appliedFilters.map(i => i.value);
  return arrData.filter(i => {
    return subjects.includes(i.exam_name);
  });
};

export const updateRowObj = (aggregateObj, obj, countField, idField) => {
  const row = { ...aggregateObj };
  const count = parseInt(obj[countField], 10);
  row[idField] += count;
  row.total += count;
  return row;
};

// updates total exams object with current exam values
export const updateTotalExamObj = (aggregateExamObj, examObj) => {
  aggregateExamObj.s1 += parseInt(examObj.s1, 10);
  aggregateExamObj.s2 += parseInt(examObj.s2, 10);
  aggregateExamObj.s3 += parseInt(examObj.s3, 10);
  aggregateExamObj.s4 += parseInt(examObj.s4, 10);
  aggregateExamObj.s5 += parseInt(examObj.s5, 10);
  aggregateExamObj.total += parseInt(examObj.s1, 10) + parseInt(examObj.s2, 10) + parseInt(examObj.s3, 10) + parseInt(examObj.s4, 10) + parseInt(examObj.s5, 10);
};

const getInitialExamObj = (examName, highlighted = false) => ({
  exam_name: examName,
  s1: 0,
  s2: 0,
  s3: 0,
  s4: 0,
  s5: 0,
  total: 0,
  highlighted
});

export const getFormattedTotalsByScore = (arrApiTotalsByScore, appliedFilters) => {

  const filteredData = applyFilters(arrApiTotalsByScore, appliedFilters);
  const arrExamObjs = filteredData.reduce((acc, item) => {
    if (typeof acc[item.exam_name] === 'undefined') {
      acc[item.exam_name] = getInitialExamObj(item.exam_name);
    }
    acc[item.exam_name] = updateRowObj(acc[item.exam_name], item, 'cnt', `s${item.ap_exam_score}`);
    return acc;
  },
  {});

  let formatted = [];
  for (const [_key, value] of Object.entries(arrExamObjs)) {
    formatted.push(value);
  }

  const totalExamsObj = formatted.reduce((acc, item) => {
    updateTotalExamObj(acc, item);
    return acc;
  },
  getInitialExamObj(TOTAL_ROW_LABEL, true));
  formatted = formatted.map(i => replaceObjZerosAndCountValues(i));

  // caluculate % of total exams
  const percentageTotalExamsObj = {
    exam_name: PERC_TOTAL_ROW_LABEL,
    total: '100%',
    highlighted: true
  };
  percentageTotalExamsObj.s1 = getTotalsPercentageString(totalExamsObj, 's1', 'total');
  percentageTotalExamsObj.s2 = getTotalsPercentageString(totalExamsObj, 's2', 'total');
  percentageTotalExamsObj.s3 = getTotalsPercentageString(totalExamsObj, 's3', 'total');
  percentageTotalExamsObj.s4 = getTotalsPercentageString(totalExamsObj, 's4', 'total');
  percentageTotalExamsObj.s5 = getTotalsPercentageString(totalExamsObj, 's5', 'total');

  formatted.push(replaceObjZerosAndCountValues(totalExamsObj));
  formatted.push(percentageTotalExamsObj);
  return formatted;
};

export const getSubjectArr = (arrApiData, appliedFilters) => {
  const filteredData = applyFilters(arrApiData, appliedFilters);
  filteredData.sort((a, b) => {
    return a.exam_sort - b.exam_sort;
  });
  return [...new Set(filteredData.map((e) => e.exam_short_desc))];
};

const getInitialSchoolObj = (schoolName, aiCd = '', arrSubjects, isDownload = false) => {
  const subj = {};
  arrSubjects.forEach(i => {
    subj[i] = 0;
  });

  const schoolRow = {
    school_name: schoolName,
    school_ai_cd: aiCd,
    ...subj,
    total: 0
  };

  if (!isDownload) {
    schoolRow.display_name = getDisplayNameWithAi(schoolName, aiCd);
  }

  return schoolRow;
};

export const getFormattedTotalsBySchool = (arrApiTotalsBySchool, appliedFilters, isDownload) => {
  const filteredData = applyFilters(arrApiTotalsBySchool, appliedFilters);
  const arrSubjects = getSubjectArr(arrApiTotalsBySchool, appliedFilters);

  const arrSchoolObjs = filteredData.reduce((acc, item) => {
    if (typeof acc[item.org_short_nm] === 'undefined') {
      acc[item.org_short_nm] = getInitialSchoolObj(item.org_short_nm, item.ai_cd, arrSubjects, isDownload);
    }
    acc[item.org_short_nm] = updateRowObj(acc[item.org_short_nm], item, 'cnt', item.exam_short_desc);

    return acc;
  },
  {});

  const formatted = [];
  for (const [_key, value] of Object.entries(arrSchoolObjs)) {
    formatted.push(replaceObjZerosAndCountValues(value));
  }

  formatted.sort((a, b) => {
    return a.school_name > b.school_name ? 1 : -1;
  });

  return formatted;
};

const getInitialGradeLevelObj = (schoolName, aiCd) => ({
  display_name: getDisplayNameWithAi(schoolName, aiCd),
  school_name: schoolName,
  '< 9th Grade': 0,
  '9th Grade': 0,
  '10th Grade': 0,
  '11th Grade': 0,
  '12th Grade': 0,
  'No longer in high school': 0,
  'Unknown': 0,
  total: 0
});

export const getFormattedTotalsByGradeLevel = (arrApiTotalsByGradeLevel) => {
  const arrSchoolObjs = arrApiTotalsByGradeLevel.reduce((acc, item) => {
    if (item.school_org_short_nm) {
      if (typeof acc[item.school_org_short_nm] === 'undefined') {
        acc[item.school_org_short_nm] = getInitialGradeLevelObj(item.school_org_short_nm, item.ai_cd);
      }
      acc[item.school_org_short_nm] = updateRowObj(acc[item.school_org_short_nm], item, 'total', item.edlvldescr);
      return acc;
    }

  },
  {});
  const formatted = [];
  for (const [_key, value] of Object.entries(arrSchoolObjs)) {
    formatted.push(replaceObjZerosAndCountValues(value));
  }
  return formatted;
};

// filters
// derive subject filter options from api response
export const getSubjectFilterOptions = (arrApiTotalsByScore) => {
  const subjectsWithData = arrApiTotalsByScore.reduce((acc, item) => {
    acc[item.exam_name] = item.discipline_name;
    return acc;
  }, {});

  const subjectOptions = [];
  for (const [key, value] of Object.entries(subjectsWithData)) {
    subjectOptions.push({
      value: key,
      label: key,
      group: value
    });
  }

  return subjectOptions.sort((a, b) => {
    if (a.group < b.group) {
      return -1;
    }
    if (a.group > b.group) {
      return 1;
    }
    if (a.group === b.group) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
    }
    return 0;
  });
};

// takes subject filter options and returns list of all groups found
export const getSubjectFilterGroups = (arrSubjectFilterOptions) => [...new Set(arrSubjectFilterOptions.map((e) => e.group))];

import './errorPages.css';
import React from 'react';
import PropTypes from 'prop-types';
import { ErrorNotification } from '@cb/apricot-react';

const ErrorDisplay = ({
  title,
  children
}) => {

  return (
    <ErrorNotification
      className="cb-border-top-4 cb-no-border-bottom cb-no-border-left cb-no-border-right cb-red1-border"
      icon="error"
      title={<span className="error-title">{title}</span>}
    >
      {children}
    </ErrorNotification>
  );
};

ErrorDisplay.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ErrorDisplay;

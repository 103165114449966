import React from 'react';
import Chart from 'chart.js/auto';
import {Bar} from 'react-chartjs-2';
import PropTypes from 'prop-types';
import 'chartjs-plugin-datalabels';
import '@cb/apricot/CBGrid';
import { BRONZE_HEX, GOLD_HEX, PLATINUM_HEX, SILVER_HEX } from '../constants';
import HonorRollNoEnrollmentDisplay from './noEnrollmentDisplay';
import { formatCountValue } from '../../../../utils/stringUtils';
import { getDisplayValue, getLeftPaddingForPercentageLabel, getPercentageLabel, getPercentageLabelMarginLeft, getTierAchievedText } from '../utils';
import './progressChart.css';

const getBarConfig = ({data, backgroundColor, yAxisID}) => ({
  barPercentage: 0.7,
  borderColor: [
    'rgb(0, 0, 0)'
  ],
  borderWidth: 1,
  backgroundColor,
  data,
  yAxisID
});

const HonorRollProgressChart = ({
  color = 'rgba(0, 0, 0, 0.9)',
  borderColor = 'rgba(0, 0, 0)',
  val,
  levels,
  title,
  tier,
  hasTier,
  tooltip = <></>,
  grade12Enrollment,
  labelCount,
  year,
  chartRef
}) => {
  const displayVal = getDisplayValue(val);

  const getLabel = (index, _value, levels) => {
    switch (index) {
      case 0:
        return ` Your School: ${getPercentageLabel(val)}% (${formatCountValue(labelCount, true)}/${formatCountValue(grade12Enrollment)})`;
      case 1:
        return ` No Recognition below ${levels[0]}%`;
      case 2:
        return ` Bronze Level: ${levels[0]}%`;
      case 3:
        return ` Silver Level: ${levels[1]}%`;
      case 4:
        return ` Gold Level: ${levels[2]}%`;
      case 5:
        return ` Platinum Level: ${levels[3]}%`;
      default:
        return '';
    }
  };

  const labels = [''];
  // we want to make sure the minimum tick for school's value is between 0 and 1 (not negative)
  const displayValStart = displayVal < 1 ? 0 : displayVal - 1;
  const displayValEnd = displayVal < 1 ? 1 : displayVal;
  const data = {
    labels: labels,
    datasets: [{
      barPercentage: 1,
      axis: 'y',
      data: [[displayValStart, displayValEnd]],
      fill: false,
      backgroundColor: [
        color
      ],
      borderColor: [
        borderColor
      ],
      borderWidth: 1
    },

    // BRONZE - GOLD bars
    getBarConfig({data: [[0, levels[0]]], backgroundColor: 'rgb(255, 255, 255)', yAxisID: 'yNone'}),
    getBarConfig({data: [[levels[0], levels[1]]], backgroundColor: BRONZE_HEX, yAxisID: 'yBronze'}),
    getBarConfig({data: [[levels[1], levels[2]]], backgroundColor: SILVER_HEX, yAxisID: 'ySilver'}),
    getBarConfig({data: [[levels[2], levels[3]]], backgroundColor: GOLD_HEX, yAxisID: 'yGold'}),
    getBarConfig({data: [[levels[3], 100]], backgroundColor: PLATINUM_HEX, yAxisID: 'yPlatinum'})]
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false
      },
      tooltip: {
        bodyFont: {
          size: 16
        },
        callbacks: {
          label: function (context) {
            return getLabel(context.datasetIndex, context.raw, levels);
          }
        }
      }
    },
    scales: {
      y: {
        stacked: true
      },
      x: {
        max: 100,
        stacked: true
      },
      yNone: {
        display: false
      },
      yBronze: {
        display: false
      },
      ySilver: {
        display: false
      },
      yGold: {
        display: false
      },
      yPlatinum: {
        display: false
      }
    }
  };

  return (
    <div className='cb-padding-8 cb-margin-bottom-16' style={{textAlign: 'left'}}>
      <div className='cb-padding-left-32 row'>
        <h6 className='cb-margin-right-8' id={`honor_roll_header_${title.replace(/ /g, '_').toLowerCase()}`} >{title}</h6>
        {tooltip}

        {
          // show [tier] achieved message, if school has bronze +
          hasTier &&
          <>
            <h6 className='cb-margin-left-8 display-xs-none-only'>{getTierAchievedText(tier)}</h6>
            <h6 className='cb-margin-left-8 cb-font-size-xsmall display-sm-none'>{getTierAchievedText(tier)}</h6>
          </>
        }
      </div>

      {
        grade12Enrollment
          ? <>
            <div style={{
              height: 25,
              // this label is positioned this way to closely mirror where the bar will appear on the bar chart below
              marginLeft: getPercentageLabelMarginLeft(displayVal),
              marginRight: '12px',
              marginBottom: '-13px',
              paddingLeft: `${getLeftPaddingForPercentageLabel(displayVal)}%`,
              fontWeight: 'bold'}}>
              {getPercentageLabel(val)}%
            </div>
            <div className='honorRoll-chart-container'>
              <Bar id={`honor_roll_chart_${title.replace(/ /g, '_').toLowerCase()}`} data={data} options={options} ref={chartRef} role='presentation' />
            </div>
          </>

          : <div className='cb-padding-16 cb-padding-left-32'>
            <HonorRollNoEnrollmentDisplay year={year} />
          </div>
      }

    </div>

  );
};

HonorRollProgressChart.propTypes = {
  color: PropTypes.string,
  borderColor: PropTypes.string,
  year: PropTypes.number,
  val: PropTypes.number,
  levels: PropTypes.array,
  title: PropTypes.string,
  tier: PropTypes.string,
  hasTier: PropTypes.bool,
  tooltip: PropTypes.object,
  grade12Enrollment: PropTypes.number,
  labelCount: PropTypes.number,
  chartRef: PropTypes.object
};

export default HonorRollProgressChart;

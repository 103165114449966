import PropTypes from 'prop-types';
import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { CREDIT, CULTURE, OPTIMIZATION } from '../../constants';
import TableHeader from './tableHeader';
import TableRow from './tableRow';

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  }
});

const HonorRollTablePdf = ({
  overall,
  culturePercent,
  cultureTier,
  creditPercent,
  creditTier,
  optimizationPercent,
  optimizationTier,
  cultureMetadata,
  creditMetadata,
  optimizationMetadata,
  grade12Enrollment,
  cultureCount,
  creditCount,
  optimizationCount
}) => {
  return (
    <View style={styles.table}>
      <TableHeader overall={overall} />
      <TableRow
        category={CULTURE}
        grade12Enrollment={grade12Enrollment}
        count={cultureCount}
        bronze={cultureMetadata[0]}
        silver={cultureMetadata[1]}
        gold={cultureMetadata[2]}
        platinum={cultureMetadata[3]}
        tier={cultureTier}
        percent={culturePercent}
      />
      <TableRow
        category={CREDIT}
        grade12Enrollment={grade12Enrollment}
        count={creditCount}
        bronze={creditMetadata[0]}
        silver={creditMetadata[1]}
        gold={creditMetadata[2]}
        platinum={creditMetadata[3]}
        tier={creditTier}
        percent={creditPercent}
      />
      <TableRow
        category={OPTIMIZATION}
        grade12Enrollment={grade12Enrollment}
        count={optimizationCount}
        bronze={optimizationMetadata[0]}
        silver={optimizationMetadata[1]}
        gold={optimizationMetadata[2]}
        platinum={optimizationMetadata[3]}
        tier={optimizationTier}
        percent={optimizationPercent}
      />
    </View>
  );
};

HonorRollTablePdf.propTypes = {
  overall: PropTypes.string,
  culturePercent: PropTypes.string,
  cultureTier: PropTypes.string,
  creditPercent: PropTypes.string,
  creditTier: PropTypes.string,
  optimizationPercent: PropTypes.string,
  optimizationTier: PropTypes.string,
  cultureMetadata: PropTypes.array,
  creditMetadata: PropTypes.array,
  optimizationMetadata: PropTypes.array,
  grade12Enrollment: PropTypes.number,
  cultureCount: PropTypes.number,
  creditCount: PropTypes.number,
  optimizationCount: PropTypes.number
};

export default HonorRollTablePdf;

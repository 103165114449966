import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DetailTable from '../../common/detailTable';
import { Input, Pager } from '@cb/apricot-react';
import { waitMs } from '../../../../utils/tableUtils';
import LinkToStudentScoreReport from '../../common/linkToStudentScoreReport';

const GRADE_LEVEL = 'grade_level';
const FULL_NAME = 'full_name';
const ED_CODE = 'education_level_cd';
const PAGE_SIZE = 15;

export const sortRosterData = (data, field, ascending) => {
  const fieldType = typeof (data[0]?.[field]);

  if (ascending) {
    if (field === GRADE_LEVEL) {
      return data.sort((a, b) => a[ED_CODE] - b[ED_CODE] || a[FULL_NAME].localeCompare(b[FULL_NAME]));
    } else if (fieldType === 'string') {
      return data.sort((a, b) => a[field].localeCompare(b[field]) || a[FULL_NAME].localeCompare(b[FULL_NAME]));
    } else {
      return data.sort((a, b) => a[field] - b[field] || a[FULL_NAME].localeCompare(b[FULL_NAME]));
    }
  } else {
    if (field === GRADE_LEVEL) {
      return data.sort((a, b) => b[ED_CODE] - a[ED_CODE] || b[FULL_NAME].localeCompare(a[FULL_NAME]));
    } else if (fieldType === 'string') {
      return data.sort((a, b) => b[field].localeCompare(a[field]) || b[FULL_NAME].localeCompare(a[FULL_NAME]));
    } else {
      return data.sort((a, b) => b[field] - a[field] || b[FULL_NAME].localeCompare(a[FULL_NAME]));
    }
  }
};

const formatApiData = data => data.map(i => ({
  ...i,
  student_id: i.student_id ?? ''
}));

const SsrStudentSection = ({detailData, year, isDistrictAdmin, isAggregate, isTeacher, sttVal}) => {

  const [selectedSort, setSelectedSort] = useState({field: FULL_NAME});

  const [data, setData] = useState(formatApiData(detailData));
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = data.length > 0 ? Math.ceil(data.length / PAGE_SIZE) : 0;
  const pageData = data.slice(currentPage * PAGE_SIZE, currentPage * PAGE_SIZE + PAGE_SIZE);

  // initial sort
  useEffect(() => {
    onSortHandler('full_name', true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { title: 'Student Name',
      field: 'studentInfo',
      className: 'cb-font-weight-bold',
      sortable: true,
      component: ({row}) => isTeacher ? <span>{row.full_name}</span> : <LinkToStudentScoreReport
        year={year}
        isDistrictAdmin={isDistrictAdmin}
        isAggregate={isAggregate}
        candId={row.ap_candidate_id}
        fullName={row.full_name}
        apNumber={row.ap_number}
        attendingOrgId={row.attending_org_ind}
        sttVal={sttVal}
      /> },
    { title: 'Score', field: 'ap_exam_score', className: 'cb-font-weight-bold', sortable: true },
    { title: 'Grade Level', field: GRADE_LEVEL, sortable: true },
    { title: 'Student ID', field: 'student_id', sortable: true },
    { title: 'AP Number / AP ID', field: 'ap_number', sortable: true }
  ];

  let studentFilterTimeout;
  const handleFilterTextInput = e => {
    const text = e.target.value;
    // debounce for 500ms
    clearTimeout(studentFilterTimeout);
    studentFilterTimeout = setTimeout(() => {
      const filteredList = detailData.filter(row => {
        return row.first_name?.toLowerCase().startsWith(text.toLowerCase()) ||
      row.last_name?.toLowerCase().startsWith(text.toLowerCase()) ||
      row.ap_number?.toLowerCase().startsWith(text.toLowerCase());
      });
      setData(Object.hasOwn(selectedSort, 'ascending') ? sortRosterData(filteredList, selectedSort.field, selectedSort.ascending)
        : filteredList);
      setCurrentPage(0);
    }, 500);
  };

  const onSortHandler = (field, ascending) => {
    const sortField = field === 'studentInfo' ? 'full_name' : field;
    setData(sortRosterData(data, sortField, ascending));
    setSelectedSort({sortField, ascending});
    setCurrentPage(0);
  };

  return (
    <div id='ssrStudentSection'>
      <div className='container cb-padding-bottom-16 cb-padding-top-32'>
        <h5 className='cb-margin-left-16'>Student Roster</h5>
        <div id='ssr_student_search' className='cb-padding-left-16 cb-padding-bottom-8' style={{maxWidth: '400px'}}>
          <Input
            name='searchBy'
            label={<div className="cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold" style={{marginTop: '1.5em'}} >Search by First Name, Last Name, or AP ID</div>}
            onChange={handleFilterTextInput}
            placeholder='Type to search...'
            floating={false}
            maxLength={50}
            clearable={false}
            ariaLabel="Filter by First Name, Last Name, or AP ID"
          />
        </div>

        <div className='cb-margin-16 cb-white-bg' id='ssrStudentTableContainer'>
          <DetailTable
            a11yHighlightText=''
            tableId="ssr_roster_table"
            sortType={'inline'}
            rowClassNameFunc={r => r.className}
            columns={columns}
            data={pageData}
            striped={true}
            stickyHeaderParent={true}
            sortFn={(data, sort) => {
              // we handle the sort in onSort
              return data;
            }}
            onSort={async (field, ascending) => {
              await waitMs(500);
              onSortHandler(field, ascending);
            }}
            responsive
          />
        </div>
        {data.length > PAGE_SIZE && <Pager
          current={currentPage + 1}
          max={pageCount}
          onPageChange={page => {
            setCurrentPage(page - 1);
          }}
        />}
      </div>
    </div>);
};

SsrStudentSection.propTypes = {
  detailData: PropTypes.array,
  year: PropTypes.number,
  isDistrictAdmin: PropTypes.bool,
  isAggregate: PropTypes.bool,
  isTeacher: PropTypes.bool,
  sttVal: PropTypes.string
};

export default SsrStudentSection;

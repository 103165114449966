import getClient, { MAX_APPSYNC_TIMEOUT, TIME_FOR_SUBSCRIPTION_CONNECTION } from '../../graphql/client';

export const finish = ({
  timeoutId,
  subscription
}) => {
  try {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (subscription) {
      subscription.unsubscribe();
    }
  } catch (err) {
    // console.log('err with cleanup', err);
  }
};

const getDataAppSync = async ({
  subscribeParams,
  queryParams,
  cb,

  // dep inj for unit tests
  getClientFunc = getClient,
  finishFunc = finish,
  appSyncTimeout = MAX_APPSYNC_TIMEOUT
}) => {

  const client = getClientFunc(cb);

  const result = new Promise((resolve, reject) => {
    // if we hit max timeout before receiving response
    const timeoutId = setTimeout(() => {
      finishFunc({subscription});
      reject(new Error('Timeout'));
    }, appSyncTimeout);

    // subscribe for result from lambda
    let subscription;
    subscription = client
      .subscribe(subscribeParams)
      .subscribe({
        next: async (data) => {
          finishFunc({subscription, timeoutId});
          resolve(data);
        },
        error: err => {
          finishFunc({subscription, timeoutId});
          reject(err);
        }
      });

    // make api call to initiate lambda, which will respond via mutation and pass to client in above subscription
    setTimeout(() => {
      client
        .query(queryParams)
        .catch((err) => {
          console.log('query error:', err);
          // for all errors other than timeout, show error
          if (!JSON.stringify(err).includes('Execution timed out')) {
            finishFunc({subscription, timeoutId});
            reject(err);
          }
        });
    }, TIME_FOR_SUBSCRIPTION_CONNECTION);
  });

  return result;
};

export default getDataAppSync;

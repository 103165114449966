/* eslint-disable */
const subscribe = (mutation, field) => `
subscription ${mutation}(
  $catapultId: String!
  $orgId: Int!
  $adminYear: Int!
  $roleCd: Int!
  $reportName: String!
) {
  ${field}(
    catapultId: $catapultId
    orgId: $orgId
    adminYear: $adminYear
    roleCd: $roleCd
    reportName: $reportName
  ) {
    error
    url
    orgId
    catapultId
    adminYear
    roleCd
    reportName
  }
}`;
export const subscribeToStudentData = subscribe('SubscribeToStudentData', 'subscribeToStudentData');
export const subscribeToIprSinglePdf = subscribe('SubscribeToIprSinglePdf', 'subscribeToIprSinglePdf');
export const subscribeToIprMultiPdf = subscribe('SubscribeToIprMultiPdf', 'subscribeToIprMultiPdf');
export const subscribeToIprAggregateData = subscribe('SubscribeToIprAggregateData', 'subscribeToIprAggregateData');
export const subscribeToSsrMultiPdf = subscribe('SubscribeToSsrMultiPdf', 'subscribeToSsrMultiPdf');
export const subscribeToSsrSinglePdf = subscribe('SubscribeToSsrSinglePdf', 'subscribeToSsrSinglePdf');

import pako from 'pako';

export const decodeUnZipApiResponse = res => {
  const gzippedData = atob(res);

  const gzippedDataArray = Uint8Array.from(gzippedData, c => c.toString().charCodeAt(0));

  const ungzippedData = pako.ungzip(gzippedDataArray);
  return { data: JSON.parse(new TextDecoder().decode(ungzippedData)) };
};

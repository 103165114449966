import PropTypes from 'prop-types';
import React, {useState} from 'react';

const IprDownloadNotes = ({isTeacher}) => {

  return (<div className='container cb-padding-bottom-4 cb-padding-top-16'>
    <div className='col-sm-12'>
      <div className='cb-roboto cb-font-size-small'>
        <div>Based on your role and the option you select, your data will be grouped in the following manner -</div>
        <div>
          <span className='cb-roboto-bold'>Single PDF:</span> by Form*
        </div>

        {
          !isTeacher &&
            <div>
              <span className='cb-roboto-bold'>Multi PDF:</span> by Subject & Form*
            </div>
        }
        <div className='cb-roboto-italic'>*Form only applicable when multiple forms were administered to your group of students.</div>
        <div className='cb-padding-top-16'>Your download will open in a new tab and may take a few moments to load. Do not close the tab during this time.</div>
      </div>
    </div>
  </div>);
};

IprDownloadNotes.propTypes = {
  isTeacher: PropTypes.bool
};

export default IprDownloadNotes;

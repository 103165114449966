import React from 'react';
import { orderBy } from '../../../utils/tableUtils';
import { Icon } from '@cb/apricot-react';

export const QUERY_FIELD_TOTAL_STUDENTS = 'total_students';
export const QUERY_FIELD_PASS_PERCENT = 'perc';
export const QUERY_FIELD_MEAN = 'avg_score';
export const QUERY_FIELD_SCORE_1 = 'num_student_1';
export const QUERY_FIELD_SCORE_2 = 'num_student_2';
export const QUERY_FIELD_SCORE_3 = 'num_student_3';
export const QUERY_FIELD_SCORE_4 = 'num_student_4';
export const QUERY_FIELD_SCORE_5 = 'num_student_5';

export const NO_PERCENT_DISPLAY = '- -';

export const CARD_1_ID = 'iprCountMeanPassCard';
export const CARD_2_ID = 'iprBarChartCard';
export const CARD_3_ID = 'iprDistributionCard';

export const TOTAL_COUNT_HEADER = 'STUDENTS TAKING EXAM';
export const MEAN_SCORE_HEADER = 'MEAN SCORE';
export const PASS_HEADER = '% SCORES 3 OR HIGHER';
export const GROUP_DISTRIBUTION_HEADER = 'GROUP DISTRIBUTION';
export const SCORE_DISTRIBUTION_HEADER = 'SCORE DISTRIBUTIONS';

// query specifies these
export const GROUP_TYPE = 'Your Group';
export const GLOBAL_TYPE = 'Global';

const MIN_SCORE_FOR_ABOVE_NOTES = 5;

// MC and FR tab functions
export const sortArrayData = (data, field, ascending) => {
  let sortedData, summaryRecord;
  if (ascending === undefined) {
    sortedData = orderBy(data, field, true);
    summaryRecord = sortedData.shift();
    sortedData.push(summaryRecord);
    // formattedMCData = sortedData;
    return sortedData;
  } else {
    summaryRecord = data.pop();
    sortedData = field.includes('content_area') ? orderBy(data, 'sequence_number', ascending) : orderBy(data, field, ascending);
    sortedData.push(summaryRecord);
    return sortedData.slice(0);
  }
};

export const notesIconText = (text, iconInfo) => {
  return (<span className="notes-display">
    <Icon {...iconInfo} />
    <span className="notes-icon-padding">{text}</span>
  </span>);
};
export const getNotesText = ({group, state, global, max = MIN_SCORE_FOR_ABOVE_NOTES, stateProvince}) => {
  const groupFloat = parseFloat(group);
  const stateFloat = parseFloat(state);
  const globalFloat = parseFloat(global);
  if (stateProvince !== false && groupFloat > stateFloat && groupFloat > globalFloat && max >= MIN_SCORE_FOR_ABOVE_NOTES) {
    return notesIconText(`Above ${stateProvince} and Global`, {name: 'north', size: 12, color: 'green3', circular: true});
  } else if (stateProvince !== false && groupFloat > stateFloat && groupFloat <= globalFloat && max >= MIN_SCORE_FOR_ABOVE_NOTES) {
    return notesIconText(`Above ${stateProvince}`, {name: 'north', size: 12, color: 'green3', circular: true});
  } else if ((stateProvince === false || groupFloat <= stateFloat) && groupFloat > globalFloat && max >= MIN_SCORE_FOR_ABOVE_NOTES) {
    return notesIconText('Above Global', {name: 'north', size: 12, color: 'green3', circular: true});
  } else {
    return null;
  }
};

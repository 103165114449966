import { Modal } from '@cb/apricot-react';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { postReportAudit } from '../../../../api';
import csvImage from './csv-icon.png';
import pdfImage from './pdf-icon.png';
import DownloadRow from './downloadRow';

// code to log audit on download
export const logDownload = async (options) => {
  try {
    await postReportAudit(options);
  } catch (err) {
    console.log(`err:${err}`);
  }
};

// this is common function for all csvs, pdfs will pass in entire generation function
export const exportToCSV = ({
  setIsCSVLoading,
  getCSVData,
  csvHeader,
  csvData,
  filename
}) => async () => {
  const wb = XLSX.utils.book_new();
  let ws;
  if (typeof getCSVData === 'function') {
    try {
      setIsCSVLoading(true);
      const {data, headers, displayHeaders} = await getCSVData();
      ws = XLSX.utils.json_to_sheet(data, { header: headers });
      XLSX.utils.sheet_add_aoa(ws, [displayHeaders], { origin: 'A1' });
      setIsCSVLoading(false);
    } catch (err) {
      setIsCSVLoading(false);
      console.log(`error :${err}`);
    }
  } else {
    ws = csvHeader
      ? XLSX.utils.json_to_sheet(csvData, { header: csvHeader })
      : XLSX.utils.json_to_sheet(csvData);
  }

  const csvString = XLSX.utils.sheet_to_csv(ws, { forceQuotes: true });
  const file = new File([csvString], `${filename}.csv`, {
    type: 'text/plain;charset=utf-8'
  });
  saveAs(file);
};

const DownloadModal = ({
  title,
  skipAuditLog,
  multiPdfText = 'Multi PDF',
  multiPdfFilterText,
  multiPdfDownload,
  singlePdfText = 'Single PDF',
  singlePdfFilterText,
  singlePdfDownload,
  csvFilterText,
  filename,
  multiFilename,
  csvData,
  csvHeader,
  org,
  auditTitle,
  multiAuditTitle,
  sttVal,
  year,
  trigger,
  isAggregate,
  isDistrictAdmin,
  footerNotes,
  getCSVData,
  containerId
}) => {

  const [isCSVLoading, setIsCSVLoading] = useState(false);
  // makes API call for reporting audit log entry after report is generated
  const auditWrapper = async (reportGenerationFunc, type, name) => {
    let startTime = Date.now();
    await reportGenerationFunc({filename});
    let endTime = Date.now();
    if (!skipAuditLog) {
      logDownload({
        sttVal,
        name,
        type,
        year,
        startTime,
        endTime,
        orgId: org,
        isAggregate,
        isDistrictAdmin
      });
    }
  };

  return (
    <div id={containerId ? `downloadContainer_${containerId}` : undefined}>
      <Modal title={title} trigger={trigger} withFooter={false} modalId='reportDownloadModalContainer'>
        <div className="container">
          <div className="row">
            <h3 id="ttSubTitle" className="cb-font-weight-bold cb-sans-serif cb-font-size-regular">
            Available formats for download:
            </h3>
          </div>
          <div className="row">
            <br />
          </div>
          {typeof singlePdfDownload !== 'undefined'
            ? <DownloadRow
              filename={`${filename}.pdf`}
              filterText={singlePdfFilterText}
              buttonId={'exportSinglePdfButton'}
              buttonTitle={'Download Single PDF'}
              imageId={'imgPDFSingle'}
              imageSrc={pdfImage}
              text={singlePdfText}
              downloadFile={async () => {
                await auditWrapper(singlePdfDownload, 'PDF', auditTitle);
              }}
            /> : null}
          {typeof multiPdfDownload !== 'undefined'
            ? <DownloadRow
              filename={`${multiFilename}.pdf`}
              filterText={multiPdfFilterText}
              buttonId={'exportMultiPdfButton'}
              buttonTitle={'Download Multi PDF'}
              imageId={'imgPDFMulti'}
              imageSrc={pdfImage}
              text={multiPdfText}
              downloadFile={async () => {
                await auditWrapper(multiPdfDownload, 'PDF', multiAuditTitle);
              }}
            /> : null}
          {typeof csvData !== 'undefined' || typeof getCSVData === 'function'
            ? <DownloadRow
              filename={`${filename}.csv`}
              filterText={csvFilterText}
              buttonId={'csv-download'}
              buttonTitle={'Download CSV'}
              imageId={'imgCSVComp'}
              imageSrc={csvImage}
              text={'CSV'}
              downloadFile={async () => {
                await auditWrapper(exportToCSV({
                  setIsCSVLoading,
                  getCSVData,
                  csvHeader,
                  csvData,
                  filename
                }), 'CSV', auditTitle);
              }}
              isLoading={isCSVLoading}
            /> : null}

          { footerNotes ?? null }
        </div>
      </Modal>
    </div>

  );
};

DownloadModal.propTypes = {
  // single pdf
  singlePdfText: propTypes.string,
  singlePdfFilterText: propTypes.string,
  singlePdfDownload: propTypes.func,

  // multi pdf
  multiFilename: propTypes.string,
  multiPdfText: propTypes.string,
  multiAuditTitle: propTypes.string,
  multiPdfFilterText: propTypes.string,
  multiPdfDownload: propTypes.func,

  // csv
  csvFilterText: propTypes.string,
  csvData: propTypes.array,
  csvHeader: propTypes.array,
  getCSVData: propTypes.func,

  filename: propTypes.string, // used for single pdf and csv
  auditTitle: propTypes.string, // used for single pdf and csv
  title: propTypes.oneOfType([propTypes.string, propTypes.object]),
  org: propTypes.number,
  sttVal: propTypes.string,
  year: propTypes.number,
  children: propTypes.instanceOf(Object),
  trigger: propTypes.string,
  isAggregate: propTypes.bool,
  isDistrictAdmin: propTypes.bool,
  skipAuditLog: propTypes.bool,
  footerNotes: propTypes.node,
  containerId: propTypes.string
};

export default DownloadModal;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAuthStudentData = /* GraphQL */ `
  query GetAuthStudentData(
    $adminYear: Int!
    $sttVal: String!
    $reportName: String!
  ) {
    getAuthStudentData(
      adminYear: $adminYear
      sttVal: $sttVal
      reportName: $reportName
    ) {
      error
      url
      orgId
      catapultId
      adminYear
      roleCd
      reportName
    }
  }
`;

export const getAuthIprSinglePdf = /* GraphQL */ `
  query GetAuthIprSinglePdf(
    $sttVal: String!,
		$navYear: Int!,
		$navSchool: String,
		$navSubject: String!,
		$navSection: String,
		$fltTeacher: String,
		$fltScore: String,
		$fltGrade: String,
		$fltStandardIndicator: String,
		$fltInstruction: String,
    $selectedAICaption: String!,
    $subjectDisplay: String!,
		$sectionDisplay: String,
    $fltTeacherDisplay: String,
    $fltScoreDisplay: String,
    $fltGradeDisplay: String,
    $fltStandardIndicatorDisplay: String,
    $fltInstructionDisplay: String,
    $showStandardFilter: Boolean
  ) {
    getAuthIprSinglePdf(
      sttVal: $sttVal,
      navYear: $navYear,
      navSchool: $navSchool,
      navSubject: $navSubject,
      navSection: $navSection,
      fltTeacher: $fltTeacher,
      fltScore: $fltScore,
      fltGrade: $fltGrade,
      fltStandardIndicator: $fltStandardIndicator,
      fltInstruction: $fltInstruction,
      selectedAICaption: $selectedAICaption,
      subjectDisplay: $subjectDisplay,
      sectionDisplay: $sectionDisplay,
      fltTeacherDisplay: $fltTeacherDisplay,
      fltScoreDisplay: $fltScoreDisplay,
      fltGradeDisplay: $fltGradeDisplay,
      fltStandardIndicatorDisplay: $fltStandardIndicatorDisplay,
      fltInstructionDisplay: $fltInstructionDisplay,
      showStandardFilter: $showStandardFilter
    ) {
      error
      url
      orgId
      catapultId
      adminYear
      roleCd
      reportName
    }
  }
`;

export const getAuthIprMultiPdf = /* GraphQL */ `
  query GetAuthIprMultiPdf(
    $sttVal: String!,
		$navYear: Int!,
		$navSchool: String,
    $selectedAICaption: String!
  ) {
    getAuthIprMultiPdf(
      sttVal: $sttVal,
      navYear: $navYear,
      navSchool: $navSchool,
      selectedAICaption: $selectedAICaption
    ) {
      error
      url
      orgId
      catapultId
      adminYear
      roleCd
      reportName
    }
  }
`;

export const getAuthIprAggregateData = /* GraphQL */ `
query GetAuthIprAggregateData(
  $sttVal: String!,
	$navYear: Int!,
	$navForm: String,
	$navSubject: String!,
  $fltScore: String,
	$fltGrade: String,
	$fltStandardIndicator: String,
	$fltInstruction: String
) {
  getAuthIprAggregateData(
    sttVal: $sttVal,
    navYear: $navYear,
    navForm: $navForm,
    navSubject: $navSubject,
    fltScore: $fltScore,
    fltGrade: $fltGrade,
    fltStandardIndicator: $fltStandardIndicator,
    fltInstruction: $fltInstruction
  ) {
    error
    url
    orgId
    catapultId
    adminYear
    roleCd
    reportName
  }
}
`;

export const getAuthSsrMultiPdf = /* GraphQL */ `
  query GetAuthSsrMultiPdf(
    $sttVal: String!,
		$navYear: Int!,
		$navSchool: String,
    $selectedAICaption: String!
  ) {
    getAuthSsrMultiPdf(
      sttVal: $sttVal,
      navYear: $navYear,
      navSchool: $navSchool,
      selectedAICaption: $selectedAICaption
    ) {
      error
      url
      orgId
      catapultId
      adminYear
      roleCd
      reportName
    }
  }
`;

export const getAuthSsrSinglePdf = /* GraphQL */ `
  query GetAuthSsrSinglePdf(
    $sttVal: String!,
		$navYear: Int!,
		$navSchool: String,
		$navSubject: String!,
		$navSection: String,
		$fltTeacher: String,
		$fltScore: String,
		$fltGrade: String,
		$fltInstruction: String,
    $selectedAICaption: String!,
    $subjectDisplay: String!,
		$sectionDisplay: String,
    $fltTeacherDisplay: String,
    $fltScoreDisplay: String,
    $fltGradeDisplay: String,
    $fltInstructionDisplay: String
  ) {
    getAuthSsrSinglePdf(
      sttVal: $sttVal,
      navYear: $navYear,
      navSchool: $navSchool,
      navSubject: $navSubject,
      navSection: $navSection,
      fltTeacher: $fltTeacher,
      fltScore: $fltScore,
      fltGrade: $fltGrade,
      fltInstruction: $fltInstruction,
      selectedAICaption: $selectedAICaption,
      subjectDisplay: $subjectDisplay,
      sectionDisplay: $sectionDisplay,
      fltTeacherDisplay: $fltTeacherDisplay,
      fltScoreDisplay: $fltScoreDisplay,
      fltGradeDisplay: $fltGradeDisplay,
      fltInstructionDisplay: $fltInstructionDisplay
    ) {
      error
      url
      orgId
      catapultId
      adminYear
      roleCd
      reportName
    }
  }
`;
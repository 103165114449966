import React from 'react';
import ErrorDisplay from './errorDisplay';
const SiteDown = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="cb-padding-top-48">
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <div className="cb-site-name">
              <h4> AP Score Reports for Educators </h4>
              <ErrorDisplay
                title={<span className="error-title">Session Inactivity Warning</span>}
              >
                <div>Due to an extended period of inactivity, your session may be expiring shortly. If you would like to continue viewing reports refresh your page to the Reports homepage.</div>
              </ErrorDisplay>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteDown;

import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import SsrFilterSection, { DEFAULT_ATTENDED_VALUE, DEFAULT_STANDARD_VALUE, getApiValue, getAppliedAttendedLabel, getAppliedStandardValue, getAttendedApiValue, getStandardApiValue } from '../common/filter-section';
import '../shared.css';
import { NO_DATA_FILTERED_ERROR_MESSAGE, SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE, SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE_DA, SUBJECT_SCORE_ROSTER_SINGLE_PDF_ID_DISTRICT, SUBJECT_SCORE_ROSTER_SINGLE_PDF_ID_SCHOOL, SUBJECT_SCORE_ROSTER_REPORT_TITLE } from '../../../constants/reports';
import { GROUP_TYPE, GLOBAL_TYPE } from '../instructional-planning/utils';
import SubHeader from '../subHeader';
import { Select } from '@cb/apricot-react';
import DownloadModal from '../common/downloads/downloadModal';
import ReportError from '../../ap-osre-error-handling/ReportError';
import SsrComparableGroups from '../common/comparableGroups';
import SsrSummarySection from './summary-section';
import SsrComparablesSection from './comparables-section';
import SsrStudentSection from './student-table-section';
import { getSinglePdf } from './pdf/singlePdf';
import { getMultiPdf } from './pdf/multiPdf';
import getSsrData from '../../../api/reports/subjectScore';
import DownloadNotes from './downloadNotes';

const downloadModalTrigger = 'openDownloadModal';

const getSinglePdfFilterText = ({isDistrictAdmin, isAggregate}) => {
  if (isDistrictAdmin) {
    return isAggregate ? 'All Schools; Selected Subject; Filters Applied' : 'Selected School, Subject & Sections; Filters Applied';
  }
  return 'Selected Subject & Sections; Filters Applied';
};
const getMultiPdfFilterText = ({isDistrictAdmin, isAggregate}) => {
  if (isDistrictAdmin) {
    return isAggregate ? 'All Schools & Subjects; No Filters' : 'Selected School; All Subjects & Sections; No Filters';
  }
  return 'All Subjects & Sections; No Filters';
};
const getCSVFilterText = ({isDistrictAdmin, isAggregate}) => {
  if (isDistrictAdmin) {
    return isAggregate ? 'All Schools & Subjects; No Filters' : 'Selected School; All Subjects & Sections; No Filters';
  }
  return 'All Subjects & Sections; No Filters';
};

const SubjectScoreRoster = ({isAggregate,
  year,
  apiData,
  selectedOrgId,
  selectedAICaption,
  queryDataFromReport,
  sttVal,
  isDistrictAdmin,
  isTeacher,
  selectedSubjectId,
  selectedSectionIds,
  selectedSubjectName,
  selectedSectionNames,
  reportHandledNoData,
  roleCd,

  // below are report specific props, that will be undefined at first render and set when filters applied
  selectedAttended = DEFAULT_ATTENDED_VALUE,
  selectedScores = [],
  selectedTeachers = [],
  selectedGradeLevels = [],
  appliedFilters = []}) => {

  // pdf download
  const linkRef = useRef(undefined);
  const [fileData, setFileData] = useState();
  useEffect(() => {
    if (fileData) {
      linkRef?.current?.click();
    }
  }, [fileData]);

  // maintaining filter values here so we can easily pass to pdf
  const [currentTeachers, setCurrentTeachers] = useState(selectedTeachers);
  const [currentAttended, setCurrentAttended] = useState(selectedAttended);
  const [currentScores, setCurrentScores] = useState(selectedScores);
  const [currentGradeLevels, setCurrentGradeLevels] = useState(selectedGradeLevels);
  const [currentAppliedFilters, _setCurrentAppliedFilters] = useState(appliedFilters);

  const [showFilterSection, setShowFilterSection] = useState(false);

  return (<>
    <SubHeader showDownload={true} showFilterButton={!showFilterSection} showHideFilterButton={showFilterSection} onFilterPress={() => setShowFilterSection(!showFilterSection)} downloadModalTrigger={downloadModalTrigger} />

    <SsrFilterSection
      reportIdentifier='ssr'
      show={showFilterSection}
      hasStandardFilter={false}
      teacherFilter={apiData.teacherFilter}
      gradeLevelFilter={apiData.gradeLevelFilter}
      scoreFilter={apiData.scoreFilter}
      isTeacher={isTeacher}
      isAggregate={isAggregate}
      queryDataFromReport={filters => {
        queryDataFromReport({
          ...filters
        }, true);
      }}
      currentTeachers={currentTeachers}
      currentScores={currentScores}
      currentGrades={currentGradeLevels}
      currentAttended={currentAttended}
      currentApplied={currentAppliedFilters}

      setCurrentTeachers={setCurrentTeachers}
      setCurrentScores={setCurrentScores}
      setCurrentGrades={setCurrentGradeLevels}
      setCurrentAttended={setCurrentAttended}
    />

    {/* This is a hidden link to facilitate single pdf download */}
    <a
      ref={linkRef}
      href={fileData}
      download={`AP ${isAggregate ? `District ${SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE}` : `School ${SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE}`} ${year}.pdf`}
      className="display-none">
        Download
    </a>

    <DownloadModal
      title={<div style={{fontSize: 26}}>
        {`${isAggregate ? SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE_DA : SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE} ${year}`}
      </div>}
      multiFilename={`${SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE} ${year}`}
      multiPdfFilterText={getMultiPdfFilterText({isDistrictAdmin, isAggregate})}
      multiPdfDownload={
        // omitting multiPdfDownload will omit row in modal for teachers
        isTeacher
          ? undefined
          : () => {
            getMultiPdf({
              setFileData,
              navSchool: isAggregate ? undefined : selectedOrgId,
              selectedAICaption,
              orgId: selectedOrgId,
              selectedYear: year,
              sttVal,
              reportName: `${SUBJECT_SCORE_ROSTER_REPORT_TITLE}${isAggregate ? 'DAExport' : 'Export'}`,
              roleCd
            });
          }}
      singlePdfFilterText={getSinglePdfFilterText({isDistrictAdmin, isAggregate})}
      filename={`AP ${isAggregate ? 'District' : 'School'} ${SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE} ${year}`}
      singlePdfDownload={async () => {
        await getSinglePdf({sttVal,
          navYear: year,
          isDistrictAdmin,
          isAggregate,
          setFileData,
          navSchool: isAggregate ? undefined : selectedOrgId,
          selectedAICaption,
          navSubject: selectedSubjectId,
          navSection: selectedSectionIds,
          subjectDisplay: selectedSubjectName,
          sectionDisplay: selectedSectionNames,

          orgId: selectedOrgId,
          selectedYear: year,
          roleCd,
          reportName: `${SUBJECT_SCORE_ROSTER_REPORT_TITLE}${isAggregate ? 'DAExport' : 'Export'}`,

          fltTeacher: getApiValue(currentTeachers),
          fltScore: getApiValue(currentScores),
          fltGrade: getApiValue(currentGradeLevels),
          fltInstruction: getAttendedApiValue(currentAttended) ?? DEFAULT_ATTENDED_VALUE,
          fltTeacherDisplay: currentTeachers.map(i => i.label).join(', '),
          fltScoreDisplay: currentScores.map(i => i.value).join(', '),
          fltGradeDisplay: currentGradeLevels.map(i => i.label).join(', '),
          fltInstructionDisplay: currentAttended === DEFAULT_ATTENDED_VALUE ? undefined : getAppliedAttendedLabel(currentAttended, true).join()
        });
      } }
      csvFilterText={getCSVFilterText({isDistrictAdmin, isAggregate})}
      getCSVData={isTeacher ? undefined : async () => {
        const res = await getSsrData({sttVal,
          navYear: year,
          isDistrictAdmin,
          isAggregate,
          navSchool: selectedOrgId,
          navSubject: selectedSubjectId,
          csv: true});
        return {
          data: res.data?.csvData,
          headers: res.data?.csvHeaders,
          displayHeaders: res.data?.csvDisplayHeaders
        };
      } }
      footerNotes={<DownloadNotes isAggregate={isAggregate}/>}
      org={selectedOrgId}
      auditTitle={isAggregate ? SUBJECT_SCORE_ROSTER_SINGLE_PDF_ID_DISTRICT : SUBJECT_SCORE_ROSTER_SINGLE_PDF_ID_SCHOOL}
      sttVal={sttVal}
      year={year}
      trigger={downloadModalTrigger}
      isAggregate={isAggregate}
      isDistrictAdmin={isDistrictAdmin}
      // done on backend
      skipAuditLog={true}
    />

    {
      reportHandledNoData
        ? <ReportError message={NO_DATA_FILTERED_ERROR_MESSAGE} />
        : (<>
          <SsrComparableGroups />
          {/* This is outside of main report container because we want full width for background */}

          <SsrSummarySection summaryData={apiData.summaryData} />

          <SsrComparablesSection summaryData={apiData.summaryData} />

          <SsrStudentSection isTeacher={isTeacher} detailData={apiData.detailData} year={year} isDistrictAdmin={isDistrictAdmin} isAggregate={isAggregate} sttVal={sttVal} />
        </>)
    }

  </>);
};

SubjectScoreRoster.propTypes = {
  isAggregate: PropTypes.bool,
  isTeacher: PropTypes.bool,
  year: PropTypes.number,
  apiData: PropTypes.object,
  sttVal: PropTypes.string,
  queryDataFromReport: PropTypes.func,
  isDistrictAdmin: PropTypes.bool,
  selectedOrgId: PropTypes.number,
  selectedAICaption: PropTypes.string,
  selectedSubjectId: PropTypes.string,
  selectedSectionIds: PropTypes.string,
  selectedSubjectName: PropTypes.string,
  selectedSectionNames: PropTypes.string,
  setAdditionalReportProps: PropTypes.func,
  selectedAttended: PropTypes.string,
  selectedScores: PropTypes.array,
  selectedTeachers: PropTypes.array,
  selectedGradeLevels: PropTypes.array,
  appliedFilters: PropTypes.array,
  reportHandledNoData: PropTypes.bool,
  roleCd: PropTypes.number
};

export default SubjectScoreRoster;

// This file will be used for all logic and content that is shared between honor roll pdf and html,
//      so that content updates can be made in one place.

import { BEYOND_PLATINUM, BRONZE, BRONZE_HEX, GOLD, GOLD_HEX, NONE, PLATINUM, PLATINUM_HEX, SILVER, SILVER_HEX } from './constants';
import bronzeBadge from './images/bronze.png';
import silverBadge from './images/silver.png';
import goldBadge from './images/gold.png';
import platinumBadge from './images/platinum.png';

export const TAB_1_TITLE = 'Progress Summary';
export const TAB_2_TITLE = 'Honor Roll Criteria';
export const ACCESS_AWARD_TITLE = 'AP Access Award';
export const AP_CENTRAL_HONOR_ROLL_LINK = 'https://apcentral.collegeboard.org/exam-administration-ordering-scores/scores/awards/school-districts-awards';

export const TABLE_YOUR_SCHOOL_HEADER = 'Your School';

// tooltip related content
export const SCHOOL_ENROLLMENT_TOOLTIP_TITLE = 'Your School Enrollment';
export const SCHOOL_ENROLLMENT_TOOLTIP_DESC = 'The school level enrollment data was submitted by your AP Coordinator in AP Registration and Ordering and checked against federal registrar data from the National Center for Education Statistics. Your AP Coordinator should annually check that the correct data is provided in AP Registration and Ordering at the start of the school year.';
export const CULTURE_TOOLTIP_DESC = 'Percent of students in the graduating class who took an AP exam at any point in high school, regardless of score.  To avoid pressure on students to take large numbers of APs, only 1 AP exam per student contributes to this indicator.';
export const CREDIT_TOOLTIP_DESC = 'Percent of students in the graduating class who scored 3+ on any AP Exam in high school. To avoid pressure on students to take large numbers of APs, only 1 AP exam score of 3 or higher, per student, contributes to this indicator.';
export const OPTIMIZATION_TOOLTIP_DESC = 'Percent of students in the graduating class who took 5 or more AP Exams in high school, where at least 1 exam was taken in 9th or 10th grade. Because research finds that 6 or more APs do not improve college graduation rates beyond the optimal total of 5 such courses in high school, no exams beyond 5 per student contribute to this indicator. The Honor Roll also recognizes schools that encourage students earlier on their AP journeys so as not to overload them with AP courses their junior and senior years.';
export const ADDITIONAL_INFO = ' Additional information can be found on ';

// footer notes
export const getNotApplicableNoteText = year => `If 'N/A' is shown this means that the percentage could not be calculated as your school did not provide 12th grade enrollment data for the ${getYearDisplay(year)} school year or it was reported as zero.`;
export const getQualificationNoteText = year => `To qualify for the ${year} AP School Honor Roll, schools must meet or exceed the criteria for a given recognition level for all three metrics. This report always reflects the most recent data for your organization. Additional information about the AP School Honor Roll can be found on `;

export const getAccessAwardText = ({year, school}) => `The ${year} AP School Honor Roll also recognizes ${school} as providing all students the chance to participate in AP, including students of underrepresented populations.`;

export const getNumberOfCategoriesWithTiers = ({cultureTier, creditTier, optimizationTier}) => (cultureTier !== NONE) + (creditTier !== NONE) + (optimizationTier !== NONE);
export const getYearDisplay = year => `${year - 1}-${year.toString().slice(-2)}`; // year should be displayed as YYYY-YY
export const getNoEnrollmentMessage = year => `This percentage could not be calculated as your school did not provide 12th grade enrollment data for the ${getYearDisplay(year)} school year or it was reported as zero.`;
export const getCongratulationsText = ({school, overall, year}) => `Congratulations! ${school} has earned ${overall} recognition on the ${year} AP School Honor Roll.`;
export const getClassOfText = year => `Class of ${year}`;

export const getSchoolAchievedTableHeaderText = tier => `Your school achieved all ${tier} criteria!`;

export const isTier = tier => tier !== NONE;

export const getTierAchievedText = tier => ` ${getDisplayTier(tier).toUpperCase()}`;

// display PLATINUM for BEYOND PLATINUM
export const getDisplayTier = tier => tier === BEYOND_PLATINUM ? PLATINUM : tier;

// chart formatting
export const getPercentageLabelMarginLeft = displayVal => `${Math.floor(displayVal * -1 / 10)}px`;
export const getDisplayValue = val => val > 100 ? 100 : val;

// handles 'beyond platinum' logic, otherwise just returns value
export const getPercentageLabel = val => val > 100 ? `>100` : val;
export const getPercentageDisplay = displayPerc => displayPerc || displayPerc === 0 ? `${displayPerc}%` : 'n/a';

// label we are displaying above the chart needs to be positioned above the chart tick, using left padding for this but requires additional below logic
export const getLeftPaddingForPercentageLabel = val => {
  if (val < 30) {
    return val;
  }
  if (val < 60) {
    return val - 1;
  }
  if (val < 80) {
    return val - 2;
  }
  return val - 3;
};

export const getCategoriesWithCriteriaText = numCategoriesWithTiers => `Your school achieved ${numCategoriesWithTiers === 2 ? 'two' : 'one'} of the three criteria for this year's AP School Honor Roll.`;

export const nextTierMap = {
  [NONE]: BRONZE,
  [BRONZE]: SILVER,
  [SILVER]: GOLD,
  [GOLD]: PLATINUM,
  [PLATINUM]: PLATINUM
};
export const tierColors = {
  [BRONZE]: BRONZE_HEX,
  [SILVER]: SILVER_HEX,
  [GOLD]: GOLD_HEX,
  [PLATINUM]: PLATINUM_HEX
};

export const badgeImageMap = {
  [BRONZE]: bronzeBadge,
  [SILVER]: silverBadge,
  [GOLD]: goldBadge,
  [PLATINUM]: platinumBadge
};

import PropTypes from 'prop-types';
import React, {useState} from 'react';
import DetailTable from '../common/detailTable';
import { formatPercentageValue } from '../../../utils/stringUtils';
import { Button, MultiSelect } from '@cb/apricot-react';
import './tabs.css';
import { waitMs } from '../../../utils/tableUtils';
import { sortArrayData, getNotesText, notesIconText } from './utils';

const MultipleChoiceTable = ({ mcData, stateProvince, showNotes }) => {
  const [reset, setReset] = useState(false);
  const [reportingCategoryTypeFilters, setReportingCategoryTypeFilters] = useState([]);
  const [selectedSort, setSelectedSort] = useState({field: 'sequence_number'});
  let formattedMCData;
  const reportingCategoryTypeFilterOptions = data => {
    const distinctReportingCategoryType = new Set(data.map(row => row.content_area !== 'SUMMARY' ? row.content_type : null));
    distinctReportingCategoryType.delete(null);
    return [...distinctReportingCategoryType].map(row => ({'label': row, 'value': row}));
  };

  const getNotes = ({group, state, global, max}) => {
    return max < 5 ? notesIconText(`<5 questions in content area`, {name: 'error', size: 14})
      : getNotesText({group, state, global, max, stateProvince});
  };

  const formatMeanValue = (value, maxscore) => {
    return maxscore < 5 ? notesIconText('', {name: 'error', size: 14}) : value;
  };

  const formatData = data => {
    const formattedData = data.map(row => {
      const group = formatPercentageValue(row.avggroup, 1);
      const state = formatPercentageValue(stateProvince ? row.avgstate : '', 1);
      const global = formatPercentageValue(row.avgglobal, 1);
      return ({
        ...row,
        avggroupValue: group,
        avgstateValue: state,
        avgglobalValue: global,
        avggroup: formatMeanValue(group, row.max_score),
        avgstate: formatMeanValue(state, row.max_score),
        avgglobal: formatMeanValue(global, row.max_score),
        notes: getNotes({group, state, global, max: row.max_score}),
        ...(row.content_area === 'SUMMARY' ? {max_score: ''} : {max_score: row.max_score.toString()}),
        sequence_number: row.sequence_number.toString()
      });
    });
    formattedMCData = sortArrayData(formattedData, 'sequence_number');
    return formattedMCData;
  };

  const [data, setData] = useState(formatData(mcData));
  const columns = [{
    title: 'Reporting Category Type', field: 'content_type', rowHeader: true
  }, {
    title: 'Reporting Category', field: 'content_area', sortable: true
  }, {
    title: '# of Questions', field: 'max_score', sortable: true, className: 'fontHeavyRightAlignTableCells'
  }, {
    title: 'Mean Number of Correct Answers|Group', field: 'avggroup', sortable: true, className: 'fontHeavyRightAlignTableCells'
  }, {
    title: `Mean Number of Correct Answers|${stateProvince || 'State'}`, field: 'avgstate', sortable: true, className: 'fontHeavyRightAlignTableCells'
  }, {
    title: 'Mean Number of Correct Answers|Global', field: 'avgglobal', sortable: true, className: 'fontHeavyRightAlignTableCells'
  }, {
    title: 'Notes', field: 'notes', className: 'notes-padding'
  }];

  const filterMCData = typeFilter => {
    const { field, ascending } = selectedSort;
    const filteredData = typeFilter.length === 0 ? formattedMCData : formattedMCData.filter(row => row.content_area === 'SUMMARY' || typeFilter.some(e => e.value === row.content_type));
    const sortFilteredData = Object.keys(selectedSort).length === 0 ? filteredData : sortArrayData(filteredData, field, ascending);
    setReportingCategoryTypeFilters(typeFilter);
    setData(sortFilteredData);
  };

  return (<>
    <div id="mcTab" className="bg-white table-details">
      <div className="col-lg-6 col-md-6 col-sm-12 cb-spacerv-bottom-24">
        {!showNotes ? <>
          <h2 className="cb-padding-bottom-16 cb-gray1-color cb-font-weight-bold cb-font-size-regular">Refine by</h2>
          <MultiSelect
            options={reportingCategoryTypeFilterOptions(formattedMCData)}
            label="Reporting Category Type"
            fieldName="reportingCategoryTypeFilter"
            validation={false}
            onChange={ contentType => {
              filterMCData(contentType);
            }}
            tags={true}
            reset={reset}
            onReset={() => {
              setReset(false);
              const { field, ascending } = selectedSort;
              setData(sortArrayData(formattedMCData, field, ascending));
              setReportingCategoryTypeFilters([]);
            }}
          />
        </> : null}
        {reportingCategoryTypeFilters.length !== 0
          ? (<Button icon="x-mark"
            onClick={() => {
              setReset(!reset);
            }}
            small
            className="cb-margin-top-32"
          >
                Clear All
          </Button>) : null}
      </div>
      <div>
        <DetailTable
          columns={columns}
          striped={false}
          responsive
          rowHighlighted={r => r.content_area === 'SUMMARY'}
          data={data}
          sortType={data.length > 1 ? 'inline' : 'none'}
          sortFn={(data, sort) => {
            // we handle the sort in onSort
            return data;
          }}
          onSort={async (field, ascending) => {
            await waitMs(500);
            // handle sort for mean values with notes with Icon and text <5 questions in content area
            const sortField = field.startsWith('avg') ? field + 'Value' : field;
            setData(sortArrayData(data, sortField, ascending));
            setSelectedSort({field: sortField, ascending});
          }}
        />
      </div>
    </div>
  </>);
};

MultipleChoiceTable.propTypes = {
  mcData: PropTypes.array,
  stateProvince: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showNotes: PropTypes.bool
};

export default MultipleChoiceTable;

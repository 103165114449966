import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Pager, CircularButton, Modal, MultiSelect } from '@cb/apricot-react';
import DetailTable from '../common/detailTable';
import '@cb/apricot/CBGrid';
import { CBInputMethod } from '@cb/apricot';
import './index.css';
import DownloadModal from '../common/downloads/downloadModal';
import '../shared.css';
import { tabCheck } from '../common/utils';
import SubHeader from '../subHeader';
import {
  getFormattedTotalsByScore,
  getSubjectFilterOptions,
  getFormattedTotalsBySchool,
  getFormattedTotalsByGradeLevel
} from './dataParser';
import { getSubjectFilterGroups } from '../common/dataParser';
import {
  getTotalsByScoreColumns,
  getTotalsBySchoolColumns,
  getTotalsByGradeLevelColumns,
  getTotalsBySchoolDownloadColumns
} from './tableData';
import FilterGroup from '../common/filter-group/filterGroup';

import {
  waitMs,
  orderBy
} from '../../../utils/tableUtils';
import { renameKeys } from 'ramda-adjunct';
import ReportContainer from '../common/reportContainer';

const DistrictSummaryBySchool = ({
  apiData,
  isAggregate,
  year,
  selectedOrgId,
  sttVal
}) => {

  // filter related
  const filterDropdownContainerClass = 'col-lg-3 col-md-4 col-sm-12';
  const [subjectFilters, setSubjectFilters] = useState([]);

  const removeFilter = (tagName) => {
    setSubjectFilters(subjectFilters.filter((s) => s.value !== tagName));
  };

  const totalStudentsHeader = apiData?.totalStudentsAndSchools[0].sum;
  const totalSchoolsHeader = apiData?.totalStudentsAndSchools[0].count;
  const formattedTotalStudentHeader = parseInt(totalStudentsHeader).toLocaleString('en-US');
  const formattedTotalSchoolsHeader = parseInt(totalSchoolsHeader).toLocaleString('en-US');

  const subjectFilterOptions = getSubjectFilterOptions(apiData.totalsByScore);
  const groupedSubjectFilterOptions = getSubjectFilterGroups(subjectFilterOptions);
  const totalStudentsModalId = 'totalStudentsTooltip';

  const PAGE_SIZE = 20;

  // Pagination
  const allScoresData = getFormattedTotalsByScore(apiData.totalsByScore, subjectFilters);
  const [sortedAllScoresData, setSortedAllScoresData] = useState(allScoresData);

  const allSchoolsData = orderBy(getFormattedTotalsBySchool(apiData.totalsBySchool, subjectFilters), 'display_name', true);
  const [currentSchoolsPage, setCurrentSchoolsPage] = useState(0);
  const [schoolsPageCount, setSchoolsPageCount] = useState(allSchoolsData.length > 0 ? Math.ceil(allSchoolsData.length / PAGE_SIZE) : 0);
  const schoolsData = allSchoolsData.slice(currentSchoolsPage * PAGE_SIZE, currentSchoolsPage * PAGE_SIZE + PAGE_SIZE);
  const [sortedAllSchoolsData, setSortedAllSchoolsData] = useState(allSchoolsData);
  const [currentSchoolsData, setCurrentSchoolsData] = useState(schoolsData);
  const [totalsBySchoolCols, setTotalSchoolCols] = useState(getTotalsBySchoolColumns(apiData.totalsBySchool, subjectFilters));

  const allGradeData = orderBy(getFormattedTotalsByGradeLevel(apiData.totalsByGradeLevel), 'display_name', true);
  const [currentGradePage, setCurrentGradePage] = useState(0);
  const gradePageCount = allGradeData.length > 0 ? Math.ceil(allGradeData.length / PAGE_SIZE) : 0;
  const gradeData = allGradeData.slice(currentGradePage * PAGE_SIZE, currentGradePage * PAGE_SIZE + PAGE_SIZE);
  const [sortedAllGradeData, setSortedAllGradeData] = useState(allGradeData);
  const [currentGradeData, setCurrentGradeData] = useState(gradeData);

  // download
  const downloadModalTrigger = 'openDownloadModal';
  const csvHeader = getTotalsBySchoolDownloadColumns(apiData.totalsBySchool);
  const csvData = getFormattedTotalsBySchool(apiData.totalsBySchool, [], true).map(i => renameKeys({school_ai_cd: 'AI Code', school_name: 'School', total: 'Total Exams'})(i));

  // used to detect keyboard vs mouse interaction (needed for ACO when tabbing through tabs)
  useEffect(() => {
    CBInputMethod();
  }, []);

  // on subject filter change, update first two tables
  useEffect(() => {
    const allScoresData = getFormattedTotalsByScore(apiData.totalsByScore, subjectFilters);
    setSortedAllScoresData(allScoresData);

    const allSchoolsData = orderBy(getFormattedTotalsBySchool(apiData.totalsBySchool, subjectFilters), 'display_name', true);
    setCurrentSchoolsPage(0);
    setSchoolsPageCount(allSchoolsData.length > 0 ? Math.ceil(allSchoolsData.length / PAGE_SIZE) : 0);
    const schoolsData = allSchoolsData.slice(0 * PAGE_SIZE, 0 * PAGE_SIZE + PAGE_SIZE);
    setSortedAllSchoolsData(allSchoolsData);
    setCurrentSchoolsData(schoolsData);
    setTotalSchoolCols(getTotalsBySchoolColumns(apiData.totalsBySchool, subjectFilters));
    setIsLoadingFilters(false);
  }, [subjectFilters]);

  // used to re-render apricot DetailTable to clear sort UI
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  return (
    <>

      <div className="container">
        <SubHeader showDownload={true} downloadModalTrigger={downloadModalTrigger} backgroundClass=""/>
        <DownloadModal
          title={`District Summary By School ${year}`}
          csvFilterText={'All Schools; All Subjects; No Filters'}
          csvData={csvData}
          filename={`AP District Summary By School ${year}`}
          csvHeader={csvHeader}
          org={selectedOrgId}
          auditTitle='DistrictSummaryBySchoolExport'
          sttVal={sttVal}
          year={year}
          trigger={downloadModalTrigger}
          isAggregate={isAggregate}
          isDistrictAdmin={true}
        />

        <div className="container">
          <div className="dropdown-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Filters:</div>
          <FilterGroup
            onFilterRemove={(tagName) => {
              setIsLoadingFilters(true);
              return removeFilter(tagName);
            }}
            appliedFilters={subjectFilters}
            tags
          >
            <div className={filterDropdownContainerClass} style={{'verticalAlign': 'top'}}>
              {/* <MultiSelectCheckbox
                label="Subjects"
                labelComponent={subjectFilters.length === 0 ? <>All Subjects</> : <>Subjects</>}
                onChange={(selections) => {
                  setIsLoadingFilters(true);
                  return setSubjectFilters(selections);
                }}
                options={subjectFilterOptions}
                groups={subjectFilterGroups}
                fieldName="subjectFilter"
                value={subjectFilters}
                selectAll
              /> */}

              <MultiSelect
              // defaultValue={[
              //   { label: 'Option 2', value: 2, disabled: true, isChecked: true },
              //   { label: 'Option 62', value: 62 }
              // ]}
                options={groupedSubjectFilterOptions}
                label="Subjects"
                fieldName="subjectFilter"
                selectId="subjectFilter-dropdown"
                onChange={(selections) => {
                  setIsLoadingFilters(true);
                  return setSubjectFilters(selections);
                }}
                value={subjectFilters}
                validation={false}
                // onSelectAll={action('onSelectAll')}
                // floating={boolean('floating', true)}
                // disabled={boolean('disabled', false)}
                // condensed={true}
                filter={true}
                // validationMsg="Make sure to select an option"
                // tagPosition={selectOptional('Tag Position', msTagPosition, 'bottom')}
                // tags={true}
                // tagSmall={boolean('Small Tags', true)}
                selectAll={true}
                // maxCheckboxText="{availableOptions} items available, filter to see them."
                groupedCheckbox={true}
              />
            </div>
          </FilterGroup>
        </div>

        <ReportContainer>
          <h5>
            {`Total Students: ${formattedTotalStudentHeader}; Total Schools: ${formattedTotalSchoolsHeader}`}
            <CircularButton icon="question" small greyscale className="cb-margin-left-8" title="Total Students - More information" label="Total Students - More information" aria-haspopup="dialog" tabIndex="0" id={totalStudentsModalId} style={{position: 'absolute'}} />
          </h5>

          <Modal title="Total Students" trigger={totalStudentsModalId} withFooter={false}>
            <p>Note: If you apply filters to this report, the total students and total schools counts will not change as they are a reference point for your entire district.</p>
          </Modal>

          <div className='cb-margin-top-8' id='main_tab_container' >
            <Tabs>
              <Tab label="Totals by Scores" panelId="totalsByScores" selected tabId="totalsByScoresTab">
                {
                  isLoadingFilters
                    ? <></>
                    : <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                      <DetailTable
                        a11yHighlightText=''
                        tableId="totals_by_score_table"
                        data={sortedAllScoresData}
                        sortType={allScoresData.length > 1 ? 'inline' : 'none'}
                        columns={getTotalsByScoreColumns()}
                        striped={false}
                        stickyHeaderParent={true}
                        maxHeight={600}
                        overflowX={false}
                        rowHighlighted={({highlighted}) => highlighted}
                        stickyColumn={true}
                        tabIndex={tabCheck}
                        sortFn={(data, sort) => {
                          // we handle the sort in onSort here because we want to use pagination and keep totals rows at end
                          return data;
                        }}
                        onSort={async (field, ascending) => {
                          await waitMs(500);
                          // remove totals rows during sorting
                          let extractedTotalsRows = allScoresData.splice(allScoresData.length - 2, allScoresData.length);
                          let sortedData = orderBy(allScoresData, field, ascending);
                          sortedData.push(extractedTotalsRows[0], extractedTotalsRows[1]);
                          setSortedAllScoresData(sortedData);
                        }}
                      />
                    </div>
                }

              </Tab>
              <Tab label={`Totals by School`} panelId="totalBySchool" tabId="totalBySchoolTab">
                {
                  isLoadingFilters
                    ? <></>
                    : <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                      <DetailTable
                        a11yHighlightText=''
                        tableId="totals_by_school_table"
                        data={currentSchoolsData}
                        sortType={allSchoolsData.length > 1 ? 'inline' : 'none'}
                        columns={totalsBySchoolCols}
                        striped={true}
                        rowHighlighted={({highlighted}) => highlighted}
                        stickyColumn={true}
                        stickyHeaderParent={true}
                        maxHeight={600}
                        overflowX={false}
                        tabIndex={tabCheck}
                        sortFn={(data, sort) => {
                          // we handle the sort in onSort here because we want to use pagination and keep totals rows at end
                          return data;
                        }}
                        onSort={async (field, ascending) => {
                          await waitMs(500);
                          const sortedData = orderBy(allSchoolsData, field, ascending);
                          setSortedAllSchoolsData(sortedData);
                          setCurrentSchoolsData(sortedData.slice(0, PAGE_SIZE));
                          setCurrentSchoolsPage(0);
                          return sortedData.slice(0, PAGE_SIZE);
                        }}
                      />
                      {allSchoolsData.length > PAGE_SIZE && <Pager
                        current={currentSchoolsPage + 1}
                        max={schoolsPageCount}
                        onPageChange={page => {
                          setCurrentSchoolsPage(page - 1);
                          const thisPageData = sortedAllSchoolsData.slice((page - 1) * PAGE_SIZE, (page - 1) * PAGE_SIZE + PAGE_SIZE);
                          setCurrentSchoolsData(thisPageData);
                        }}
                      />}
                    </div>
                }

              </Tab>
              <Tab label={`Students by Grade Level`} panelId="studentsByGradeLevel" tabId="studentsByGradeLevelTab">

                <div>
                  <div className="content-gutter cb-padding-8" id="studentsByGradeLevelNote">This table shows the total number of students, by school and by grade level, who took AP Exams in your district. If you apply filter options to customize this report, the data in this table will not change. It is available in each District Summary Report as a reference.</div>
                  <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    <DetailTable
                      a11yHighlightText=''
                      tableId="students_by_grade_level_table"
                      data={currentGradeData}
                      sortType={allGradeData.length > 1 ? 'inline' : 'none'}
                      columns={getTotalsByGradeLevelColumns()}
                      striped={true}
                      rowHighlighted={({highlighted}) => highlighted}
                      stickyColumn={true}
                      stickyHeaderParent={true}
                      maxHeight={600}
                      overflowX={false}
                      tabIndex={tabCheck}
                      sortFn={(data, sort) => {
                      // we handle the sort in onSort here because we want to use pagination and keep totals rows at end
                        return data;
                      }}
                      onSort={async (field, ascending) => {
                        await waitMs(500);
                        const sortedData = orderBy(allGradeData, field, ascending);
                        setSortedAllGradeData(sortedData);
                        setCurrentGradeData(sortedData.slice(0, PAGE_SIZE));
                        setCurrentGradePage(0);
                        return sortedData.slice(0, PAGE_SIZE);
                      }}
                    />
                  </div>
                  {allGradeData.length > PAGE_SIZE && <Pager
                    current={currentGradePage + 1}
                    max={gradePageCount}
                    onPageChange={page => {
                      setCurrentGradePage(page - 1);
                      const thisPageData = sortedAllGradeData.slice((page - 1) * PAGE_SIZE, (page - 1) * PAGE_SIZE + PAGE_SIZE);
                      setCurrentGradeData(thisPageData);
                    }}
                  />}
                </div>

              </Tab>
            </Tabs>
          </div>
          <div id="reportDiffersNote" className="content-gutter cb-padding-8 cb-padding-top-32">The data in this report differs from other College Board reports, such as The AP Cohort Data Report, which tracks exams taken by seniors throughout their time in high school (cohort-based) and includes public school data only.</div>
        </ReportContainer>
      </div>
    </>
  );
};

DistrictSummaryBySchool.propTypes = {
  apiData: PropTypes.object,
  queryDataFromReport: PropTypes.func,
  setAdditionalReportProps: PropTypes.func,
  isAggregate: PropTypes.bool,
  demographic: PropTypes.string,
  initialSubjectFilters: PropTypes.array,
  initialScoreFilters: PropTypes.array,
  initialDemographicFilters: PropTypes.array,
  year: PropTypes.number,
  selectedOrgId: PropTypes.number,
  sttVal: PropTypes.string
};

export default DistrictSummaryBySchool;

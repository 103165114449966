import React from 'react';

const ScholarAwardModalContent = () => {
  return (
    <div className="cb-padding-bottom-8 cb-font-weight-regular">
        Identify students who have earned a specific scholar award.  <a href="https://apcentral.collegeboard.org/about-ap/awards/scholar-awards" target="_blank" rel="noreferrer">Learn more about awards here.</a>
    </div>
  );
};

export default ScholarAwardModalContent;

// The below color codes have been pulled from apricot 4.5 docs - https://uihub.collegeboard.org/4.5.0/develop/colors

export const LIGHT_GREY = '#D9D9D9'; // neutral 4
export const DARK_GREY = '#505050'; // neutral 1
export const LIGHT_BLUE = '#009CDE'; // blue 3

export const H3_FONT_SIZE = 15;
export const H4_FONT_SIZE = 13;
export const H5_FONT_SIZE = 12;
export const LARGE_TEXT_FONT_SIZE = 10;
export const MEDIUM_TEXT_FONT_SIZE = 9;
export const SMALL_TEXT_FONT_SIZE = 8;

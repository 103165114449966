import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';
import { FIVE_YEAR_SCORE_SUMMARY_REPORT_TITLE, FIVE_YEAR_SCORE_SUMMARY_URI } from '../../constants/reports';

// returns report list for current request
const getFiveYearScoreSummaryData = ({sttVal, navSchool, navYear}) => {
  let url = config.apiBaseUrl + config.genesisUri + FIVE_YEAR_SCORE_SUMMARY_URI + '?reportName=' + FIVE_YEAR_SCORE_SUMMARY_REPORT_TITLE +
  '&sttVal=' + encodeURIComponent(sttVal) +
  '&navSchool=' + navSchool +
  '&navYear=' + navYear;

  return axiosInstance.get(url);
};

export default getFiveYearScoreSummaryData;

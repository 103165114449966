import React from 'react';
import PropTypes from 'prop-types';
import { APCentralLink } from './summaryTab/tooltips';
import { getNotApplicableNoteText, getQualificationNoteText } from './utils';

const HonorRollFooter = ({year, showNaMessage}) => {
  return (
    <div className='cb-margin-top-32' id='honor_roll_footer_container'>
      {getQualificationNoteText(year)}{<APCentralLink />}.
      {
        showNaMessage &&
        (<>
          <br />
          <br />
          {getNotApplicableNoteText(year)}
        </>)
      }
    </div>);
};
HonorRollFooter.propTypes = {
  year: PropTypes.number,
  showNaMessage: PropTypes.bool
};

export default HonorRollFooter;

import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';

// returns messages for current request
const postMessages = (year) => {
  let url = config.apiBaseUrl + config.msMessagesUri;
  return axiosInstance.post(url, { yearSelected: year});
};

export default postMessages;


import { BlackButton } from '@cb/apricot-react';
import propTypes from 'prop-types';
import React from 'react';
import './shared.css';

const SubHeader = ({backgroundClass, showFilterButton, showHideFilterButton, onFilterPress, showDownload, downloadModalTrigger, children, additionalButtons}) => {
  const backgroundClassName =
    typeof backgroundClass !== 'undefined'
      ? backgroundClass
      : ' cb-panel-summary';

  return (
    <div
      id="divFilterBar"
      className={
        'container-flex-v container-flex-align-center' + backgroundClassName
      }
    >
      <div
        id="divPanelSubHeader"
        className="container-flex-h child-flex-1 container-flex-spread container-flex-align-center content-gutter flex-end padding-8"
      >
        <div id="subHeaderLeftContent" className="child-flex-2">
          {children}
        </div>
        <div
          id="divFltBarButtons"
          className="child-flex-3 cb-spacerv-xs-bottom cb-spacerv-xs-top"
        >
          {showFilterButton && (
            <BlackButton className='cb-margin-right-8' onClick={() => {
              onFilterPress && onFilterPress();
            }} id='filterDataButton' aria-expanded="false">
              Filter Data{' '}
              <span className="cb-icon cb-filter" aria-hidden="true"></span>
            </BlackButton>
          )}
          {showHideFilterButton && (
            <BlackButton className='cb-margin-right-8' onClick={() => {
              onFilterPress && onFilterPress();
            }} id='hideFiltersButton' aria-expanded="true">
              Hide Filters{' '}
              <span className="cb-icon cb-x-mark" aria-hidden="true"></span>
            </BlackButton>
          )}
          {Array.isArray(additionalButtons) && additionalButtons.length > 0 && (
            additionalButtons.map((i, index) => {
              return (<BlackButton className='cb-margin-right-8' key={`additionalSubHeaderButton${index}`} aria-haspopup={i.hasPopup ? 'dialog' : undefined} id={i.id}>
                {`${i.title} `}
                <span className={i.icon ? `cb-icon ${i.icon}` : ''} aria-hidden="true"></span>
              </BlackButton>);
            })

          )}
          {showDownload && (
            <BlackButton aria-haspopup="dialog" id={downloadModalTrigger}>
              Download Report{' '}
              <span className="cb-icon cb-download" aria-hidden="true"></span>
            </BlackButton>
          )}
        </div>
      </div>
    </div>
  );
};

SubHeader.propTypes = {
  showFilterButton: propTypes.bool,
  showHideFilterButton: propTypes.bool,
  showDownload: propTypes.bool,
  children: propTypes.instanceOf(Object),
  downloadModalTrigger: propTypes.string,
  backgroundClass: propTypes.string,
  onFilterPress: propTypes.func,
  additionalButtons: propTypes.arrayOf(propTypes.object)
};

export default SubHeader;

import React from 'react';
import { BRONZE, SILVER, GOLD, PLATINUM, NONE } from '../constants';
import bronzeBadge from '../images/bronze.png';
import silverBadge from '../images/silver.png';
import goldBadge from '../images/gold.png';
import platinumBadge from '../images/platinum.png';
import LabelWithTooltip from '../../common/labelWithTooltip';
import { getSchoolAchievedTableHeaderText, SCHOOL_ENROLLMENT_TOOLTIP_DESC, SCHOOL_ENROLLMENT_TOOLTIP_TITLE, TABLE_YOUR_SCHOOL_HEADER } from '../utils';
import './schoolHeader.css';

const badgeImageMap = {
  [BRONZE]: bronzeBadge,
  [SILVER]: silverBadge,
  [GOLD]: goldBadge,
  [PLATINUM]: platinumBadge
};

export const getSchoolHeaderComponent = (tier) => {
  const hasTier = tier !== NONE;
  const fontColorOverride = hasTier ? 'cb-black1-color' : '';
  const backgroundColorOverride = hasTier ? 'cb-white-bg' : '';
  // eslint-disable-next-line react/display-name
  return () => (<div className={`cb-padding-8 cb-border-radius ${backgroundColorOverride}`}>
    <div className='row justify-content-center'>
      <div className={`cb-font-weight-regular school-header-font-size ${fontColorOverride}`}>{TABLE_YOUR_SCHOOL_HEADER}</div>
      <LabelWithTooltip
        lightTooltip={!hasTier}
        label={''}
        toolTipTitle={SCHOOL_ENROLLMENT_TOOLTIP_TITLE}
        toolTipLabel={SCHOOL_ENROLLMENT_TOOLTIP_TITLE + ' Tooltip'}
        modalTitleComponent={<h2 className='cb-gray1-color cb-font-weight-regular cb-font-size-sm-res'>{SCHOOL_ENROLLMENT_TOOLTIP_TITLE}</h2>}
        modalContent={(<p className='cb-gray1-color cb-font-weight-regular cb-align-left'>{SCHOOL_ENROLLMENT_TOOLTIP_DESC}</p>)}
        modalTrigger={`yourSchoolTrigger`}
      />
    </div>

    { hasTier &&
    <div className='row justify-content-center align-items-center cb-margin-top-8'>
      <img src={badgeImageMap[tier]} alt={`${tier} badge`} style={{width: '100px'}}></img>
      <div className='cb-align-left cb-padding-left-8' style={{maxWidth: '200px'}}>
        <div className={`school-header-font-size ${fontColorOverride}`}>{tier.toUpperCase()}</div>
        {/* small font size on header for small screens */}
        <>
          <p className={`cb-font-weight-regular display-sm-none-only display-xs-none-only ${fontColorOverride}`}>{getSchoolAchievedTableHeaderText(tier)}</p>
          <p className={`cb-font-weight-regular cb-font-size-xsmall display-md-none cb-padding-4 ${fontColorOverride}`}>{getSchoolAchievedTableHeaderText(tier)}</p>
        </>
      </div>
    </div>
    }
  </div>);
};

import React, { useEffect, useContext } from 'react';
import analytics from '../../utils/analytics';
import '@cb/apricot/CBBase';
import '@cb/apricot/CBButton';
import '@cb/apricot/CBCard';
import '@cb/apricot/CBGrid';
import {APP_ID} from '../../constants/appInfo';
import config from './../../utils/envConfig';
import './login.css';
import { AppContext } from '../../AppContext.js';
import createMarkup from '../../utils/createMarkup';

const HomeSignedOut = () => {
  useEffect(() => {
    analytics.reportCurrentView('login');
  }, []);

  const appContext = useContext(AppContext);

  return (
    <div
      id="cb-atlas-identity-1"
      data-cbtrack-widget="identity"
      className="cb-widget YspEjjMJprS3N5lXkjwxR"
    >
      <div className="_1pdV3SNsleunl1Ymlse_Dp _1FfrF6t9oOse-aV4-iTh5e"></div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-7 col-lg-7 offset-xl-0 col-xl-7 offset-2xl-0 col-2xl-7 offset-3xl-1 col-3xl-5">
            <div className="_1kRqTh230ZekuWvtNvJR50">
              <img src="/assets/homepage_hero1.jpg" alt="" />
              <div className="_3rqppAsd4ImkvlsGUi2YVS">
                <h2 className='login-header'>AP Score Reports for Educators</h2>
                {
                  appContext?.content?.login_page &&
                  <div dangerouslySetInnerHTML={createMarkup(appContext.content.login_page)}></div>
                }
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-5 col-lg-5 col-xl-5 col-2xl-5 col-3xl-5">
            <div className="cb-card cb-identity-card cb-margin-top-72">
              <div className="cb-identity-card-container">
                <h2 className="cb-paragraph6-st display-inline-flex">Sign In</h2>
                <p className="cb-margin-top-8 cb-roboto-light cb-align-center">
                Sign in to your account to get started. <br />
                </p>
                <a href={'https://account.collegeboard.org/login/login?idp=EPL&appId=' + APP_ID + '&skipEnroll=Y&DURL=' + config.UIURL}
                  className="cb-btn cb-btn-primary cb-margin-top-16" id="login-continue-btn">
              Continue
                </a>
                <div className="cb-margin-top-32 cb-padding-top-16 cb-border-top-1 cb-border-dashed cb-gray4-border">
                  <p className="cb-margin-top-8 cb-roboto-light cb-align-center">Don&apos;t have an account?</p>
                  <a href={'https://account.collegeboard.org/login/signUp?idp=EPL&appId=' + APP_ID + '&DURL=' + config.UIURL}
                    className="cb-link-black cb-roboto-medium display-block cb-align-center" >
                Create Account
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSignedOut;

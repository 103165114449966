import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { getCategoriesWithCriteriaText, isTier, nextTierMap, tierColors } from '../../utils';
import BadgeDisplay from './badgeDisplay';
import { LARGE_TEXT_FONT_SIZE } from '../constants';

const styles = StyleSheet.create({
  font: {
    fontSize: LARGE_TEXT_FONT_SIZE,
    fontFamily: 'Roboto',
    paddingTop: 10
  },
  bold: {
    fontFamily: 'Roboto-Bold'
  },
  list: {
    paddingLeft: 10
  },
  container: {
    paddingLeft: 10,
    paddingRight: 20
  }
});

const HonorRollNextTierDisplayPdf = ({
  hasTier,
  overall,
  school,
  year,
  numCategoriesWithTiers,
  nextTierValues
}) => {
  const nextTier = nextTierMap[overall];
  const nextTierColor = tierColors[nextTier];

  //    DEV NOTE - had issues with making bolding/styling of certain pieces of content between html/pdf,
  //    so currently not using utils.js as much for this component.
  return (
    <View style={styles.container}>
      {
        hasTier &&
        <BadgeDisplay tier={overall} school={school} year={year} />
      }
      {
        !isTier(overall) && numCategoriesWithTiers > 0 &&
            <Text style={styles.font}>{getCategoriesWithCriteriaText(numCategoriesWithTiers)}</Text>
      }
      {
        hasTier &&
        <Text style={styles.font}>Your school achieved all criteria to be listed on this year&apos;s AP School Honor Roll as a <Text style={styles.bold}>{overall}</Text> school!</Text>
      }
      <Text style={styles.font}>For planning and goal-setting, the three criteria {
        hasTier
          ? <Text>for <Text style={{ padding: 1, backgroundColor: nextTierColor}}> {nextTier.toUpperCase()} </Text> schools </Text>
          : 'to make the AP School Honor Roll '
      }are:</Text>

      <View style={styles.list}>
        <Text style={styles.font}>• Expand your school&apos;s college culture by enabling <Text style={styles.bold}>{nextTierValues.culture}% of your Class of {year + 1}</Text> to experience 1 AP course before graduating.</Text>
        <Text style={styles.font}>• Assist with college affordability by enabling <Text style={styles.bold}>{nextTierValues.credit}% of your Class of {year + 1}</Text> to earn college credit by earning an AP Exam score of 3.</Text>
        <Text style={styles.font}>• Optimize student&apos;s college readiness by creating opportunities for <Text style={styles.bold}>{nextTierValues.optimization}% of your Class of {year + 1}</Text> to take a total of 5 APs across their high school years, with at least 1 taken in 9th or 10th grade.</Text>
      </View>

    </View>
  );
};

HonorRollNextTierDisplayPdf.propTypes = {
  hasTier: PropTypes.bool,
  overall: PropTypes.string,
  school: PropTypes.string,
  year: PropTypes.number,
  numCategoriesWithTiers: PropTypes.number,
  nextTierValues: PropTypes.object
};

export default HonorRollNextTierDisplayPdf;

/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import DetailTable from '../common/detailTable';
import { Input, Pager } from '@cb/apricot-react';
import { waitMs } from '../../../utils/tableUtils';
import './index.css';
import { formatRosterData, getRosterColumns } from './utils';
import { tabCheck } from '../common/utils';

export const sortRosterData = (data, field, ascending, subjectColumns) => {
  const fieldType = typeof (data[0]?.[field]);
  if (ascending) {
    if (field === 'bt') {
      return data.sort((a, b) => new Date(a[field]) - new Date(b[field]) || a.fullName.localeCompare(b.fullName));
    } else if (field === 'gl') {
      return data.sort((a, b) => a.elc - b.elc || a.fullName.localeCompare(b.fullName));
    } else if (field === 'ad') {
      return data.sort((a, b) => a.sac - b.sac || a.fullName.localeCompare(b.fullName));
    } else if (subjectColumns.some(el => el === field)) {
      return data.sort((a, b) => (a[field] ?? 0) - (b[field] ?? 0));
    } else if (fieldType === 'string') {
      return data.sort((a, b) => a[field].localeCompare(b[field]) || a.fullName.localeCompare(b.fullName));
    } else {
      return data.sort((a, b) => a[field] - b[field] || a.fullName.localeCompare(b.fullName));
    }
  } else {
    if (field === 'bt') {
      return data.sort((a, b) => new Date(b[field]) - new Date(a[field]) || b.fullName.localeCompare(a.fullName));
    } else if (field === 'gl') {
      return data.sort((a, b) => b.elc - a.elc || b.fullName.localeCompare(a.fullName));
    } else if (field === 'ad') {
      return data.sort((a, b) => b.sac - a.sac || b.fullName.localeCompare(a.fullName));
    } else if (subjectColumns.some(el => el === field)) {
      return data.sort((a, b) => (b[field] ?? 0) - (a[field] ?? 0));
    } else if (fieldType === 'string') {
      return data.sort((a, b) => b[field].localeCompare(a[field]) || b.fullName.localeCompare(a.fullName));
    } else {
      return data.sort((a, b) => b[field] - a[field] || b.fullName.localeCompare(a.fullName));
    }
  }
};

const ScholarRosterSection = ({rosterData, setTotalStudents}) => {
  const PAGE_SIZE = 15;
  const {data: formattedRosterData, subjectColumns, totalStudents } = formatRosterData(rosterData);
  const [selectedSort, setSelectedSort] = useState({field: 'fullName'});
  const [sortedSubjectColumns, setSortedSubjectColumns] = useState(subjectColumns);
  const [data, setData] = useState(formattedRosterData);

  useEffect(() => {
    setTotalStudents(totalStudents);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalStudents]);

  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = data.length > 0 ? Math.ceil(data.length / PAGE_SIZE) : 0;
  const pageData = data.slice(currentPage * PAGE_SIZE, currentPage * PAGE_SIZE + PAGE_SIZE);

  const rosterTableCols = getRosterColumns(sortedSubjectColumns);

  let studentFilterTimeout;
  const handleFilterTextInput = e => {
    const text = e.target.value;
    // debounce for 500ms
    clearTimeout(studentFilterTimeout);
    studentFilterTimeout = setTimeout(() => {
      const subjectColumnsSet = new Set();
      const filteredList = formattedRosterData.filter(row => {

        if (row.fn?.toLowerCase().startsWith(text.toLowerCase()) ||
          row.ln?.toLowerCase().startsWith(text.toLowerCase()) ||
          row.an?.toLowerCase().startsWith(text.toLowerCase())) {
          Object.keys(row).forEach(key => subjectColumnsSet.add(key));
          return true;
        } else {
          return false;
        }
      });
      const fltSubjectColumns = subjectColumns.filter(el => subjectColumnsSet.has(el));
      setSortedSubjectColumns(fltSubjectColumns.sort());
      setData(Object.hasOwn(selectedSort, 'ascending') ? sortRosterData(filteredList, selectedSort.field, selectedSort.ascending, subjectColumns)
        : filteredList);
      setCurrentPage(0);
    }, 500);
  };

  return (<div>
    <div id='sr_student_search' className='student-search-width  cb-padding-bottom-8'>
      <Input
        name = 'searchBy'
        label = {<div className="stsr-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Search by First Name, Last Name, or AP ID</div>}
        onChange={handleFilterTextInput}
        placeholder = 'Type to search...'
        floating = {false}
        maxLength = {50}
        clearable={false}
        // disabled={isLoadingData}
      />
    </div>
    <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <DetailTable
        a11yHighlightText=''
        tableId="sr_roster_table"
        columns={rosterTableCols}
        striped={true}
        stickyHeaderParent={true}
        stickyColumn={true}
        data={pageData}
        sortType={data.length > 1 ? 'inline' : 'none'}
        maxHeight={600}
        overflowX={false}
        tabIndex={tabCheck}
        sortFn={(data, sort) => {
        // we handle the sort in onSort
          return data;
        }}
        onSort={async (field, ascending) => {
          await waitMs(500);
          setData(sortRosterData(data, field, ascending, subjectColumns));
          setSelectedSort({field, ascending});
          setCurrentPage(0);
        }}
      />
    </div>
    {data.length > PAGE_SIZE && <Pager
      current={currentPage + 1}
      max={pageCount}
      onPageChange={page => {
        setCurrentPage(page - 1);
      }}
    />}
  </div>);

};

ScholarRosterSection.propTypes = {
  rosterData: PropTypes.object,
  setTotalStudents: PropTypes.func
};

export default ScholarRosterSection;

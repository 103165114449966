import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@cb/apricot-react';

const LocalNavListItem = (props) => {
  const active = props.isActive ? 'active' : '';
  const multiline = props.multiline ? 'cb-multi' : '';
  const cssClasses = `cb-nav-link cb-procedural ${active} ${multiline}`;

  return (
    <li className={cssClasses}>
      <NavLink
        id={`${props.name}NavLinkId`}
        to={props.href}
        className="cb-menu-link"
        activeClassName={props.isActive ? 'cb-selected' : ''}
      >
        {props.href === '/' ? (
          <span
            className="cb-icon cb-home hidden-xs cb-margin-right-8 "
            aria-hidden="true"
          />
        ) : (
          ''
        )}
        {props.href === '/AdministratorTools' ? (
          <Icon aria-hidden="true" name={'settings'} id={`${props.name}IconId`} className='hidden-xs cb-margin-right-8' />
        ) : (
          ''
        )}
        <span>{props.name}</span>
      </NavLink>
    </li>
  );
};

LocalNavListItem.propTypes = {
  isActive: PropTypes.bool,
  href: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired
};

export default LocalNavListItem;

import {
  STUDENT_SCORE_URI
} from '../../constants/reports';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';

// returns report list for current request
const getStudentScoreData = ({ sttVal, navYear, navStudent }) => {

  const url =
      config.apiBaseUrl +
      config.genesisUri +
      STUDENT_SCORE_URI;

  const params = {
    sttVal,
    navYear,
    navStudent
  };

  return axiosInstance.get(url, { params });

};

export default getStudentScoreData;

import React from 'react';
import PropTypes from 'prop-types';
import IconAndTitle from '../../common/iconAndTitle';
import IprGroupValuesRow from '../../common/groupValuesRow';
import { MEAN_SCORE_HEADER, NO_PERCENT_DISPLAY, PASS_HEADER, QUERY_FIELD_MEAN, QUERY_FIELD_PASS_PERCENT, QUERY_FIELD_TOTAL_STUDENTS, TOTAL_COUNT_HEADER } from '../utils';
import { formatCountValue, formatPercentageValue } from '../../../../utils/stringUtils';

const getTotalStudentsCount = (apiObj) => apiObj ? formatCountValue(apiObj[QUERY_FIELD_TOTAL_STUDENTS], true) : '';

const getPercentPass = apiObj => {
  if (!apiObj) {
    return '';
  }
  const percent = apiObj[QUERY_FIELD_PASS_PERCENT];
  const formatted = formatPercentageValue(apiObj[QUERY_FIELD_PASS_PERCENT] * 100, 1);
  return percent !== 0 && formatted !== '' ? `${formatted}%` : NO_PERCENT_DISPLAY;
};

const getMean = apiObj => apiObj ? formatPercentageValue(apiObj[QUERY_FIELD_MEAN]) : '';
const IprFirstCardContent = ({stateProvinceAbbreviation, groupData, stateProvinceData, globalData}) => {

  return (
    <>
      <IconAndTitle title={TOTAL_COUNT_HEADER} icon='user' />
      <IprGroupValuesRow
        id='total_count'
        stateProvinceAbbreviation={stateProvinceAbbreviation}
        group={getTotalStudentsCount(groupData)}
        state={getTotalStudentsCount(stateProvinceData)}
        global={getTotalStudentsCount(globalData)}
      />
      <IconAndTitle title={MEAN_SCORE_HEADER} icon='graph' />
      <IprGroupValuesRow
        id='mean'
        stateProvinceAbbreviation={stateProvinceAbbreviation}
        group={getMean(groupData)}
        state={getMean(stateProvinceData)}
        global={getMean(globalData)}
      />
      <IconAndTitle title={PASS_HEADER} icon='check' />
      <IprGroupValuesRow
        id='pass'
        stateProvinceAbbreviation={stateProvinceAbbreviation}
        group={getPercentPass(groupData)}
        state={getPercentPass(stateProvinceData)}
        global={getPercentPass(globalData)}
        omitBorderBottom
      />
    </>
  );
};

IprFirstCardContent.propTypes = {
  stateProvinceAbbreviation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  groupData: PropTypes.object,
  stateProvinceData: PropTypes.object,
  globalData: PropTypes.object
};

export default IprFirstCardContent;

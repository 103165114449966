import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';

// returns messages for current request
const getOrganizations = (url) => {
  return axiosInstance.get(url, {
    headers: {
      'x-api-key': atob(config.xApiKey)
    }
  });
};

export default getOrganizations;

import React from 'react';
import {formatCountValue, formatPercentageValue} from '../../../../utils/stringUtils';
import { SCHOOL_TYPE, GROUP_TYPE, DISTRICT_TYPE } from '../utils';

const sortFunc = (a, b) => {
  // sort the section rows alphabetically
  if (a.type === 'inv' && b.type === 'inv') {
    return a.table_caption.localeCompare(b.table_caption);
  }
  return a.sort > b.sort ? 1 : -1;
};

const isSectionRow = i => i.card_caption === '';

const wrapSectionTitleCell = row => isSectionRow(row) ? <div className='cb-padding-left-32' >{row.table_caption}</div> : row.table_caption;

export const getScoreCellValue = (count, total, showCount) => {
  const countDisplay = showCount ? ` (${formatCountValue(count, true)})` : '';
  return `${formatPercentageValue(parseInt(count) / parseInt(total) * 100, 0)}%${countDisplay}`;
};

export const getComparablesData = (apiSummaryData, isGroupExpanded) => {
  const sorted = apiSummaryData.sort(sortFunc);

  const formatted = sorted.reduce((acc, i) => {
    if (!isGroupExpanded && isSectionRow(i)) {
      return acc;
    }
    const mean = formatPercentageValue(i.avg_score);
    const formattedTotal = formatCountValue(i.total_students);

    // count isn't shown for score cells on global/state rows
    const showCount = isSectionRow(i) || i.card_caption === SCHOOL_TYPE || i.card_caption === GROUP_TYPE || i.card_caption === DISTRICT_TYPE;
    acc.push({
      ...i,
      score1: getScoreCellValue(i.num_student_1, i.total_students, showCount),
      score2: getScoreCellValue(i.num_student_2, i.total_students, showCount),
      score3: getScoreCellValue(i.num_student_3, i.total_students, showCount),
      score4: getScoreCellValue(i.num_student_4, i.total_students, showCount),
      score5: getScoreCellValue(i.num_student_5, i.total_students, showCount),
      mean,
      titleCellValue: wrapSectionTitleCell(i),
      isSectionRow: isSectionRow(i),
      formattedTotal,
      className: isSectionRow(i) ? 'cb-gray5-bg' : ''
    });
    return acc;
  }, []);
  return formatted;
};

import React from 'react';
import PropTypes from 'prop-types';

const GROUP_LABEL = 'YOUR GROUP';
const SCHOOL_LABEL = 'SCHOOL';
const DISTRICT_LABEL = 'DISTRICT';
const GLOBAL_LABEL = 'GLOBAL';

const ValueDisplay = ({label, val, id, bold = false, colSpanClass, borderLeft = false}) => {
  return (<div style={{paddingLeft: '4px', paddingRight: '4px'}} className={`${colSpanClass} ${borderLeft ? 'cb-border-left' : ''}`}>
    <div className={`cb-gray1-color cb-font-size-xsmall ${bold ? 'cb-font-weight-bold' : ''}`}>{label}</div>
    <div id={`ipr_card_value_${id}`} style={{fontSize: 20}} className={`cb-gray1-color ${bold ? 'cb-font-weight-bold' : ''}`}>{val}</div>
  </div>);
};

ValueDisplay.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  val: PropTypes.string,
  colSpanClass: PropTypes.string,
  bold: PropTypes.bool,
  borderLeft: PropTypes.bool
};

const getColSpanClass = numValues => {
  if (numValues < 4) {
    return 'col-xs-4';
  }
  return 'col-xs-3';
};

const GroupValuesRow = ({stateProvinceAbbreviation, group, state, school, district, global, id, omitBorderBottom = false}) => {

  // used to calculate each cell width
  const colSpanClass = getColSpanClass(2 + (stateProvinceAbbreviation ? 1 : 0) + (school || district ? 1 : 0));

  return (
    <div className={`${omitBorderBottom ? '' : 'cb-border-bottom cb-blue2-border'}`}>
      <div className='row cb-padding-left-32 cb-padding-right-32 cb-padding-bottom-16 align-items-center'>
        <ValueDisplay id={`group_${id}`} label={GROUP_LABEL} val={group} colSpanClass={colSpanClass} bold/>
        {
          school &&
          <ValueDisplay id={`school_${id}`} label={SCHOOL_LABEL} val={school} colSpanClass={colSpanClass} borderLeft />
        }
        {
          district &&
          <ValueDisplay id={`district_${id}`} label={DISTRICT_LABEL} val={district} colSpanClass={colSpanClass} borderLeft />
        }
        {
          stateProvinceAbbreviation &&
          <ValueDisplay id={`state_${id}`} label={stateProvinceAbbreviation} val={state} colSpanClass={colSpanClass} borderLeft />
        }
        <ValueDisplay id={`global_${id}`} label={GLOBAL_LABEL} val={global} colSpanClass={colSpanClass} borderLeft />
      </div>
    </div>
  );
};

GroupValuesRow.propTypes = {
  stateProvinceAbbreviation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  group: PropTypes.string,
  school: PropTypes.string,
  state: PropTypes.string,
  district: PropTypes.string,
  global: PropTypes.string,
  id: PropTypes.string,
  omitBorderBottom: PropTypes.bool
};

export default GroupValuesRow;

/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import DetailTable from '../common/detailTable';
import {formatPercentageValue, formatCountValue} from '../../../utils/stringUtils';
import './index.css';
import { tabCheck } from '../common/utils';

const formatSummaryData = (data) => {
  const subjectColumns = new Set();
  const formattedData = data.reduce((acc, {lbl, exam_short_desc, value, totalvalue, lbltype}) => {
    const row = acc[lbltype] || {};
    if (lbl === 'Number of Exams') {
      row[exam_short_desc] = formatCountValue(value);
      row.totalExams = formatCountValue(totalvalue, true);
    } else if (lbl === 'Average Score') {
      row[exam_short_desc] = formatPercentageValue(value, 1);
      row.totalExams = totalvalue === null ? '0' : formatPercentageValue(totalvalue, 1);
    } else {
      row[exam_short_desc] = `${formatPercentageValue(value * 100, 0)}%`;
      row.totalExams = `${formatPercentageValue(totalvalue * 100, 0)}%`;
    }
    acc[lbltype] = {...row,
      lbl
    };
    subjectColumns.add(exam_short_desc);
    return acc;
  }, {});
  return {formattedData: Object.keys(formattedData).map(key => formattedData[key]), subjectColumns};
};

const OrgSummary = ({summaryData}) => {

  const {formattedData: formattedSummaryData, subjectColumns } = formatSummaryData(summaryData);
  const sortedSubjectCols = Array.from(subjectColumns).sort();

  const summaryTableCols = [
    {
      title: ' ',
      field: 'lbl',
      rowHeader: true,
      sticky: true,
      className: 'table-first-column-width'
    },
    ...sortedSubjectCols.map(subject => ({title: subject, field: subject, className: 'rightAlignTableCells'})),
    {
      title: 'Total Exams',
      field: 'totalExams',
      className: 'rightAlignTableCells'
    }
  ];
  return (<div style={{ maxHeight: '600px', overflowY: 'auto' }}>
    <DetailTable
      a11yHighlightText=''
      tableId="osr_summary_table"
      columns={summaryTableCols}
      striped={false}
      stickyHeaderParent={true}
      stickyColumn={true}
      data={formattedSummaryData}
      maxHeight={600}
      overflowX={false}
      tabIndex={tabCheck}
    />
  </div>);
};

OrgSummary.propTypes = {
  summaryData: PropTypes.array
};

export default OrgSummary;

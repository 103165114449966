import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ACTIVE_REPORT_LINK_CSS_CLASS, STUDENT_SCORE_REPORT_TITLE } from '../../../constants/reports';

const LinkToStudentScoreReport = ({
  year,
  isDistrictAdmin,
  isAggregate,
  candId,
  fullName,
  apNumber,
  attendingOrgId,
  sttVal
}) => (<span>{attendingOrgId === 'Y'
  ? (<NavLink className={ACTIVE_REPORT_LINK_CSS_CLASS} to={{
    pathname: `/report/${STUDENT_SCORE_REPORT_TITLE}`,
    params: {
      year: year,
      report: STUDENT_SCORE_REPORT_TITLE,
      isDistrictAdmin: isDistrictAdmin,
      isAggregate: isAggregate,
      candId,
      fullName: `${fullName} (${apNumber})`,
      genesisSttVal: sttVal
    }
  }}>{fullName}</NavLink>) : fullName}</span>
);

LinkToStudentScoreReport.propTypes = {
  year: PropTypes.number,
  isDistrictAdmin: PropTypes.bool,
  isAggregate: PropTypes.bool,
  candId: PropTypes.string,
  fullName: PropTypes.string,
  apNumber: PropTypes.string,
  attendingOrgId: PropTypes.string,
  sttVal: PropTypes.string
};

export default LinkToStudentScoreReport;

import React, { useState, Fragment, useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import {Helmet} from 'react-helmet';
import OrgSelection from './components/ap-osre-org-selection/orgSelection';
import Spinner from './components/common/loader/spinner';
import Login from './components/ap-osre-home/login';
import isEmpty from 'lodash/isEmpty';
import GetAccess from './components/ap-osre-error-handling/accessCodePage';
import Legal from './components/ap-osre-legal/legal';
import LoggedInAs from './components/ap-osre-logged-in-as/loggedInAs';
import SiteDown from './components/ap-osre-error-handling/SiteDown';
import ShowError from './components/ap-osre-error-handling/ErrorBoundary';
import HomeSchoolError from './components/ap-osre-error-handling/errorHomeSchool';
import config from './utils/envConfig.js';
import analytics from './utils/analytics';
import {orgHs} from './utils/homeSchool';
import { AppContext } from './AppContext';
import _ from 'lodash';
import {initializeAxiosInstance} from './utils/axiosInstance';
import {getTermsAndConditions, getCurrentYear, getContent, getOrganizations} from './api';
import {APP_ID, MSORG_SIZE_LIMIT} from './constants/appInfo';
import { getAuthSession, getAuthenticationToken, getAuthorizationToken, getJWTToken, useAuth } from './utils/auth.js';

// ignores benign dev environment error
window.ResizeObserver = class {
  constructor() {
    this.observe = () => {};
    this.unobserve = () => {};
    this.disconnect = () => {};
  }
};

const isJwtTokenReady = () => {
  const jwtToken = getJWTToken();
  return !isEmpty(jwtToken);
};

const App = () => {
  const auth = useAuth();
  const [conxt, setConxt] = useState('');
  const [roleCd, setRoleCd] = useState('');
  const [hasApiError, setHasApiError] = useState(undefined);
  const [hasJWTToken, setHasJwtToken] = useState(isJwtTokenReady());
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(undefined);
  const [showNoAccessPage, setShowNoAccessPage] = useState(false);
  const [showBlockHomeSchool, setShowBlockHomeSchool] = useState(false);
  const [content, setContent] = useState();

  // Load content on initial app load
  useEffect(() => {
    const initialContentLoad = async () => {
      try {
        const res = await getContent();
        setContent(res?.data);
      } catch (err) {
        console.log('error loading content.json', err);
      }
    };
    initialContentLoad();
  }, []);

  const getApiHeader = () => {
    return {
      'X-CB-Catapult-Authentication-Token': getAuthenticationToken(),
      'X-CB-Catapult-Authorization-Token': getAuthorizationToken(),
      'Content-Type': 'application/json'
    };
  };

  const loadLoginUser = async () => {
    try {
      let authValues;
      authValues = await extractDataFromToken(getJWTToken(), getAuthSession());
      setShowTermsAndConditions(authValues.showTermsAndConditions);
      setShowNoAccessPage(authValues.showNoAccessPage);
      setConxt({
        token: authValues.token,
        orgs: _.orderBy(authValues.orgs, ['orgName']),
        userId: authValues.userId,
        userName: authValues.userName,
        authzToken: authValues.authzToken,
        authnToken: authValues.authnToken,
        firstName: authValues.firstName,
        lastName: authValues.lastName,
        isCustomerService: !!authValues.isCustomerService,
        singleOrgUser: authValues.singleOrgUser,
        singleOrgId: authValues.singleOrgId,
        singleOrgName: authValues.singleOrgName,
        currentYear: authValues.currentYear,
        appId: APP_ID,
        orgHs: authValues.orgHsInfo
      });
      setIsDataLoading(false);
    } catch (error) {
      // console.log('ERROR while loading user data:', error);
      setHasApiError(error);
      setIsDataLoading(false);
    }
  };

  const updateAuthState = async () => {
    const jwtTokenReady = isJwtTokenReady();
    const authSession = getAuthSession();
    // update api headers in axios
    initializeAxiosInstance(getApiHeader);
    setIsLoggedIn(authSession.isLoggedIn);
    if (jwtTokenReady && authSession.isLoggedIn) {
      await loadLoginUser();
    }
    setHasJwtToken(jwtTokenReady);
  };

  useEffect(() => {
    if (auth.loggedIn) {
      updateAuthState();
    } else {
      setIsLoggedIn(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.loggedIn, auth.loggedOut, auth.error]);

  const extractDataFromToken = (jwtToken, userData) => {
    return new Promise(async resolve => {
      let decodedToken = JSON.parse(atob(jwtToken.split('.')[1]));
      // console.log('decodedToken', decodedToken)
      let userName = userData.basicProfile.userName;
      let userId = decodedToken.cb.pid;
      let singleOrgId;
      let singleOrgName;
      let showTNC;
      let noAccessPage = false;
      let roleOrgsList = decodedToken.cb.ro;
      let thisYear;
      let fn;
      let ln;
      let isCsr = false;
      let orgHsInfo;

      if (userData.csrId) {
        isCsr = true;
      }
      if (userData.basicProfile) {
        fn = userData.basicProfile.firstName;
        ln = userData.basicProfile.lastName;
      }
      let orgs = [];
      let msorgs = [];
      let roleList = [];
      let appData = {};
      if (!isEmpty(roleOrgsList)) {
        roleOrgsList.forEach(pair => {
          if (pair.rid === '221' || pair.rid === '301' || pair.rid === '303' || pair.rid === '223' || pair.rid === '300' || pair.rid === '701' || pair.rid === '703' || pair.rid === '304' || pair.rid === '704') {
            let duplicateOrg = orgs.some(function (org) {
              return org.orgId === pair.oid;
            });
            if (!duplicateOrg) {
              orgs.push({
                orgId: pair.oid
              });
            }
            roleList.push({ rid: pair.rid });
          }
        });
        if (orgs.length > 0) {
          let msOrgData = [];
          [showTNC, msOrgData, thisYear] = await Promise.all([
            callTermsAndConditions(userId),
            callToMsOrg(orgs),
            pullCurrentYear()
          ]);
          msorgs = msOrgData.map(el => ({
            orgId: el._source.org_id,
            orgName: el._source.org_short_name
          }));
          orgHsInfo = msOrgData.length > 0 ? orgHs(msOrgData, {setShowBlockHomeSchool}) : [];
        } else {
          noAccessPage = true;
        }
        if (msorgs.length === 1 && msorgs[0].orgId) {
          singleOrgId = Number(msorgs[0].orgId);
          singleOrgName = msorgs[0].orgName;
        } else if (msorgs.length === 0) {
          singleOrgId = Number(orgs[0].orgId);
        }

        appData = {
          'token': roleOrgsList,
          'orgs': msorgs,
          'userId': userId,
          'userName': userName,
          'singleOrgId': singleOrgId,
          'singleOrgName': singleOrgName,
          'singleOrgUser': !!singleOrgId,
          'authzToken': getAuthorizationToken(),
          'authnToken': getAuthenticationToken(),
          'showNoAccessPage': noAccessPage,
          'showTermsAndConditions': showTNC,
          'firstName': fn,
          'lastName': ln,
          'isCustomerService': isCsr,
          'currentYear': thisYear,
          'orgHsInfo': orgHsInfo
        };
      } else {
        noAccessPage = true;
        appData = {
          'showNoAccessPage': noAccessPage,
          'userId': userId,
          'userName': userName,
          'authzToken': getAuthorizationToken(),
          'authnToken': getAuthenticationToken()
        };
      }
      resolve(appData);
    });
  };

  const callTermsAndConditions = async userId => {
    let showTNC = false;
    try {
      let res = await getTermsAndConditions(userId);
      res = res.data;
      if (res.result === 'N') {
        showTNC = true;
      } else if (res.result === 'Y') {
        showTNC = false;
      } else {
        throw new Error('error');
      }
      return showTNC;

    } catch (error) {
      console.log('err', error);
      setHasApiError(error);
      return error;
    }
  };

  const pullCurrentYear = async () => {
    try {
      let result = await getCurrentYear();
      return result.data.currentYear;
    } catch (error) {
      setHasApiError(error);
      return 'error';
    }
  };

  const callToMsOrg = async orgs => {
    try {
      let baseURL = config.msOrgBaseUrl;
      let orgList = orgs.map(org => org.orgId).join(',');
      let url = `${baseURL}orgsearch?orgid=${orgList}&size=${MSORG_SIZE_LIMIT}`;
      const res = await getOrganizations(url);
      return res.data.hits.hits;
    } catch (err) {
      console.log('error: ', err);
      setHasApiError(err);
    }
  };

  const isProd = config.envIntent;
  return (
    <AppContext.Provider value={{...conxt, isLoggedIn, getApiHeader, content, roleCd, setRoleCd }}>
      <Router>
        <CompatRouter>
          <Fragment>
            {
              conxt && conxt.isCustomerService && isLoggedIn
                ? <div className="container">
                  <LoggedInAs />
                </div> : null
            }
            {
              !isLoggedIn || isLoggedIn === false ? <Login /> : (
                isDataLoading || !hasJWTToken ? <Spinner /> : (
                  auth.error ? <SiteDown /> : (
                    hasApiError ? (
                      <ShowError error={hasApiError}/>
                    ) : (
                      showNoAccessPage ? (
                        <div className="container">
                          <GetAccess />
                        </div>
                      ) : (
                        showTermsAndConditions ? (
                          <div className="container">
                            <Legal
                              setShowTermsAndConditions={setShowTermsAndConditions}
                            />
                          </div>
                        ) : (
                          showBlockHomeSchool ? (
                            <div className="container">
                              <HomeSchoolError showLogout={true} />
                            </div>
                          ) : (
                            <OrgSelection />
                          )
                        )
                      )
                    )
                  )
                )
              )
            }
            {isProd ? <Helmet><meta name="google-site-verification" content="6qHW-qKK-k8r4WZ-oBfy2P7N4Gp2G5OXgaRIpkZFW_4" /></Helmet> : null}
          </Fragment>
        </CompatRouter>
      </Router>
    </AppContext.Provider>
  );
};
export default App;

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { BlackButton, MultiSelect, PrimaryButton, TagSet } from '@cb/apricot-react';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import '@cb/apricot/CBGrid';
import LabelWithTooltip from '../../common/labelWithTooltip';
import FilterWithLabel from '../../common/filter-group/filterWithLabel';
import ScholarAwardModalContent from '../../common/filter-group/scholarAwardModalContent';

const GRADE_LABEL = 'Grade Level: ';
const SCHOLAR_AWARD_LABEL = 'Scholar Award: ';

const SrFilterSection = ({
  gradeFilterData,
  scholarAwardsFilterData,

  show,
  rosterData,
  setRosterData,
  setShowFilterSection,

  currentGrades,
  currentScholarAwards,
  currentApplied,

  setCurrentGrades,
  setCurrentScholarAwards,
  setCurrentAppliedFilter
}) => {

  const getFilteredRosterData = (subjectColumnsSet, fltGrades, fltScholarAwards) => rosterData.data.filter(el => {
    if ((fltGrades.some(val => val.value === el.elc) || fltGrades.length === 0) &&
          (fltScholarAwards.some(val => val.value === el.sac) || fltScholarAwards.length === 0)
    ) {
      Object.keys(el).forEach(key => subjectColumnsSet.add(key));
      return true;
    } else {
      return false;
    }
  });

  const getRosterData = ({newAppliedFilters, fltGrades = currentGrades, fltScholarAwards = currentScholarAwards}) => {
    if (newAppliedFilters.length > 0) {
      const subjectColumnsSet = new Set();
      const filterRosterData = getFilteredRosterData(subjectColumnsSet, fltGrades, fltScholarAwards);
      const filterSubjectColumns = rosterData.subjectColumns.filter(el => subjectColumnsSet.has(el)).sort();
      return {data: filterRosterData, subjectColumns: filterSubjectColumns};
    } else {
      return rosterData;
    }
  };

  const onApplyFilters = () => {
    const newAppliedFilters = ([
      ...currentGrades.map(i => `${GRADE_LABEL}${i.label}`),
      ...currentScholarAwards.map(i => `${SCHOLAR_AWARD_LABEL}${i.label}`)
    ]);
    setShowFilterSection(false);
    setCurrentAppliedFilter(newAppliedFilters);
    const rosterFilteredData = getRosterData({newAppliedFilters});
    setRosterData(rosterFilteredData);
  };

  const onRemoveFilters = () => {
    setCurrentGrades([]);
    setCurrentScholarAwards([]);
  };

  // removes AND re-queries data
  const onRemoveFilter = tagName => {
    const newGrades = currentGrades.filter((i) => `${GRADE_LABEL}${i.label}` !== tagName);
    const newScholarAwards = currentScholarAwards.filter((i) => `${SCHOLAR_AWARD_LABEL}${i.label}` !== tagName);
    const newAppliedFilters = [
      ...newGrades.map(i => `${GRADE_LABEL}${i.label}`),
      ...newScholarAwards.map(i => `${SCHOLAR_AWARD_LABEL}${i.label}`)
    ];
    setCurrentGrades(newGrades);
    setCurrentScholarAwards(newScholarAwards);
    setShowFilterSection(false);
    setCurrentAppliedFilter(newAppliedFilters);
    const rosterFilteredData = getRosterData({newAppliedFilters, fltGrades: newGrades, fltScholarAwards: newScholarAwards});
    setRosterData(rosterFilteredData);
  };
  // accessibility - set focus to filter section when first shown
  const firstElementRef = useRef(null);
  useEffect(() => {
    if (show) {
      firstElementRef.current.focus();
    }
  }, [show]);
  return (<>
    <div className='container cb-margin-bottom-16' style={{display: show ? 'block' : 'none'}} ref={firstElementRef}>
      <div className='row'>
        <FilterWithLabel label='Grade Level'>
          <MultiSelect
            options={gradeFilterData}
            label="Grade Levels"
            fieldName="gradeFilter"
            selectId="gradeFilterId"
            onChange={(selections) => setCurrentGrades(selections)}
            value={currentGrades}
            validation={false}
            selectAll={true}
          />
        </FilterWithLabel>

        <FilterWithLabel label={(<div className='display-flex'>
          <LabelWithTooltip
            label={'AP Scholar Awards'}
            toolTipTitle={'Scholar Award'}
            toolTipLabel={'Scholar Award Tooltip'}
            modalContent={<ScholarAwardModalContent />}
            modalTrigger={'sr_scholar_award_tooltip_id'}
          />
        </div>)}>
          <MultiSelect
            options={scholarAwardsFilterData}
            label='AP Scholar Awards'
            fieldName='scholarAwardsFilter'
            selectId='scholarAwardsFilterId'
            onChange={(selections) => setCurrentScholarAwards(selections)}
            value={currentScholarAwards}
            validation={false}
            selectAll={true}
          />
        </FilterWithLabel>
      </div>
      <div className='cb-margin-top-8 row justify-content-end'>
        <BlackButton id='removeFiltersButton' className='cb-margin-right-8' onClick={onRemoveFilters}>Remove Filters</BlackButton>
        <PrimaryButton id='applyFiltersButton' onClick={onApplyFilters}>Apply Filters</PrimaryButton>
      </div>
    </div>
    {currentApplied.length > 0 &&
          <div className='container'>
            <div className="cb-padding-top-8 dropdown-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold"><h3>Applied Filters:</h3></div>
            <TagSet
              label={`Applied Filters`}
              offScreen="Remove"
              tags={currentApplied}
              onClick={(tagName, e) => {
                e.preventDefault();
                onRemoveFilter(tagName);
              }}
            />
            <div className='cb-padding-bottom-16'></div>
          </div>
    }
  </>
  );
};

SrFilterSection.propTypes = {
  gradeFilterData: PropTypes.array,
  scholarAwardsFilterData: PropTypes.array,
  show: PropTypes.bool,
  rosterData: PropTypes.object,
  setRosterData: PropTypes.func,
  currentGrades: PropTypes.array,
  currentApplied: PropTypes.array,
  currentScholarAwards: PropTypes.array,
  setCurrentGrades: PropTypes.func,
  setCurrentScholarAwards: PropTypes.func,
  setCurrentAppliedFilter: PropTypes.func,
  setShowFilterSection: PropTypes.func
};

export default SrFilterSection;

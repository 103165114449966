import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';
import { HONOR_ROLL_LETTER_PDF_URI } from '../constants/reports';

const getHonorRollLetter = ({ name, year, schoolAddress, accessAward }) => {
  const url = `${config.apiBaseUrl}${config.genesisUri}${HONOR_ROLL_LETTER_PDF_URI}`;
  const params = {
    name,
    year,
    schoolAddress,
    accessAward
  };

  return axiosInstance.get(url, { params });
};

export default getHonorRollLetter;

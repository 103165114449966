import { CURRENT_YEAR_SCORE_SUMMARY_REPORT_TITLE, CURRENT_YEAR_SCORE_SUMMARY_URI } from '../../constants/reports';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';

// returns report list for current request
const getCurrentYearScoreSummaryData = ({ sttVal, navSchool, navYear, isDistrictAdmin, isAggregate }) => {

  const url =
    config.apiBaseUrl +
    config.genesisUri +
    CURRENT_YEAR_SCORE_SUMMARY_URI;

  const params = {
    reportName: CURRENT_YEAR_SCORE_SUMMARY_REPORT_TITLE,
    sttVal,
    navYear
  };

  if (isDistrictAdmin && !isAggregate) {
    params.navSchool = navSchool;
  }

  return axiosInstance.get(url, { params });
};

export default getCurrentYearScoreSummaryData;

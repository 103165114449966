import axiosInstance from '../utils/axiosInstance';

const download = async (url) => {
  try {
    const { data } = await axiosInstance.get(url, { responseType: 'arraybuffer' });
    return { data: URL.createObjectURL(new Blob([data], { type: 'application/zip' })) };
  } catch (err) {
    console.log('download error: ', err);
    return { error: err.message || 'Error: Unable to download file.' };
  }
};

export default download;

export const getTotalsByScoreColumns = () => [
  { title: 'Subject / Score', field: 'exam_name', rowHeader: true },
  { title: '1', field: 's1', className: 'rightAlignTableCells' },
  { title: '2', field: 's2', className: 'rightAlignTableCells' },
  { title: '3', field: 's3', className: 'rightAlignTableCells' },
  { title: '4', field: 's4', className: 'rightAlignTableCells' },
  { title: '5', field: 's5', className: 'rightAlignTableCells' },
  { title: 'Total Exams', field: 'total', className: 'cb-gray5-bg cb-gray1-color rightAlignTableCells' }
];

export const getTotalsByDemographicColumns = (demographic) => [
  { title: demographic, field: 'demographic_descr', rowHeader: true, groupValues: true },
  { title: 'Subject', field: 'exam_name', rowHeader: true },
  { title: '1', field: 's1', className: 'rightAlignTableCells' },
  { title: '2', field: 's2', className: 'rightAlignTableCells' },
  { title: '3', field: 's3', className: 'rightAlignTableCells' },
  { title: '4', field: 's4', className: 'rightAlignTableCells' },
  { title: '5', field: 's5', className: 'rightAlignTableCells' },
  { title: 'Total Exams', field: 'total', className: 'cb-gray5-bg cb-gray1-color rightAlignTableCells' },
  { title: 'Mean Score', field: 'mean', className: 'cb-gray5-bg cb-gray1-color rightAlignTableCells' },
  { title: 'Standard Deviation', field: 'standard_deviation', className: 'cb-gray5-bg cb-gray1-color rightAlignTableCells' }
];

export const getStudentsByDemographicColumns = (demographic) => [
  { title: demographic, field: 'demographic_descr', rowHeader: true },
  { title: '<9th Grade', field: 'lt_9th_grade_count', className: 'rightAlignTableCells' },
  { title: '9th Grade', field: 'ninth_grade_count', className: 'rightAlignTableCells' },
  { title: '10th Grade', field: 'tenth_grade_count', className: 'rightAlignTableCells' },
  { title: '11th Grade', field: 'eleventh_grade_count', className: 'rightAlignTableCells' },
  { title: '12th Grade', field: 'twelfth_grade_count', className: 'rightAlignTableCells' },
  { title: 'No Longer in High School', field: 'not_in_hs_count', className: 'rightAlignTableCells' },
  { title: 'Unknown', field: 'unknown_grade_count', className: 'rightAlignTableCells' },
  { title: 'Total Students', field: 'total', className: 'cb-gray5-bg cb-gray1-color rightAlignTableCells' }
];

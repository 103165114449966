import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';

// returns report list for current request
const postReportAudit = ({sttVal, name, type, year, startTime, endTime, orgId, isAggregate, isDistrictAdmin}) => {
  let url = config.apiBaseUrl + config.genesisUri + 'audit';

  let params = {
    startTime,
    endTime,
    name,
    type,
    year,
    sttVal
  };

  if (isDistrictAdmin && !isAggregate) {
    params.navSchool = orgId;
  }

  // need to remove the hardcoded values here for orgId and appRole
  return axiosInstance.post(url, params);
};

export default postReportAudit;

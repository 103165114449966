/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { BlackButton, MultiSelect, PrimaryButton, Select, TagSet } from '@cb/apricot-react';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import '@cb/apricot/CBGrid';
import LabelWithTooltip from '../../common/labelWithTooltip';
import FilterWithLabel from '../../common/filter-group/filterWithLabel';
import AttendedModalContent from '../../common/filter-group/attendedModalContent';
import StandardModalContent from '../filter-group/standardModalContent';

// this dropdown always has these values
export const DEFAULT_ATTENDED_VALUE = '1';
export const ATTENDED_INSTRUCTED_OPTIONS = [
  {value: '1', label: 'All Students'},
  {value: '2', label: 'Attending Students'},
  {value: '3', label: 'Instructed Students'},
  {value: '4', label: 'Students Both Attending & Instructed at your Organization'}
];
export const DEFAULT_STANDARD_VALUE = 'N';

const STANDARD_LABEL = 'Standard Group: ';
const SCORE_LABEL = 'Score: ';
const STUDENTS_LABEL = 'Students: ';
const GRADE_LABEL = 'Grade Level: ';
const TEACHER_LABEL = 'Teacher: ';

const sortApiValue = (a, b) => {
  // we use sort_order prop if it's available (grades)
  if (typeof a.sort_order !== 'undefined') {
    return a.sort_order - b.sort_order;
  }
  return a.value - b.value;
};

// used to get query string param values from dropdown selections
export const getApiValue = selected => {
  const hasValues = selected.length > 0;
  if (hasValues) {
    // we sort here to ensure they appear correctly in download
    selected.sort(sortApiValue);
  }
  return hasValues ? selected.map(i => i.value).join(',') : undefined;
};
export const getAttendedApiValue = selected => selected === DEFAULT_ATTENDED_VALUE ? undefined : selected;
export const getAppliedAttendedLabel = (selected, labelOnly) => ATTENDED_INSTRUCTED_OPTIONS.filter(i => i.value === selected).map(i => `${labelOnly ? '' : STUDENTS_LABEL}${i.label}`);

export const getStandardApiValue = selected => selected === DEFAULT_STANDARD_VALUE ? undefined : selected;
export const getAppliedStandardLabel = (selected, standardFilter) => Array.isArray(standardFilter) ? standardFilter.filter(i => i.value === selected).map(i => `${STANDARD_LABEL}${i.caption}`) : [];
export const getAppliedStandardValue = (selected, standardFilter) => Array.isArray(standardFilter) ? standardFilter.filter(i => i.value === selected).map(i => i.caption) : [];

const GenericFilterSection = ({
  isTeacher,
  isAggregate,
  show,
  standardFilter,
  teacherFilter,
  gradeLevelFilter,
  scoreFilter,
  queryDataFromReport,
  hasStandardFilter,

  currentTeachers,
  currentScores,
  currentGrades,
  currentAttended,
  currentStandard,
  currentApplied,

  setCurrentTeachers,
  setCurrentScores,
  setCurrentGrades,
  setCurrentAttended,
  setCurrentStandard,

  reportIdentifier
}) => {

  const onApplyFilters = () => {
    const newAppliedFilters = [
      ...(currentStandard === DEFAULT_STANDARD_VALUE ? [] : getAppliedStandardLabel(currentStandard, standardFilter)),
      ...(currentAttended === DEFAULT_ATTENDED_VALUE ? [] : getAppliedAttendedLabel(currentAttended)),
      ...currentScores.map(i => `${SCORE_LABEL}${i.label}`),
      ...currentGrades.map(i => `${GRADE_LABEL}${i.label}`),
      ...currentTeachers.map(i => `${TEACHER_LABEL}${i.label}`)
    ];
    queryDataFromReport({
      // below are used to retain filter settings when we return
      selectedStandard: currentStandard,
      selectedAttended: currentAttended,
      selectedScores: currentScores,
      selectedTeachers: currentTeachers,
      selectedGradeLevels: currentGrades,
      appliedFilters: newAppliedFilters,

      // below will be used in api call
      fltScore: getApiValue(currentScores),
      fltGrade: getApiValue(currentGrades),
      fltTeacher: getApiValue(currentTeachers),
      fltInstruction: getAttendedApiValue(currentAttended),
      fltStandardIndicator: getStandardApiValue(currentStandard)
    });
  };

  const onRemoveFilters = () => {
    setCurrentAttended(DEFAULT_ATTENDED_VALUE);
    setCurrentScores([]);
    setCurrentTeachers([]);
    setCurrentGrades([]);
    if (hasStandardFilter) {
      setCurrentStandard(DEFAULT_STANDARD_VALUE);
    }
  };

  // removes AND re-queries data
  const onRemoveFilter = tagName => {
    const newScores = currentScores.filter((i) => `${SCORE_LABEL}${i.label}` !== tagName);
    const newGrades = currentGrades.filter((i) => `${GRADE_LABEL}${i.label}` !== tagName);
    const newTeachers = currentTeachers.filter((i) => `${TEACHER_LABEL}${i.label}` !== tagName);
    const newStandard = getAppliedStandardLabel(currentStandard, standardFilter).join() === tagName ? DEFAULT_STANDARD_VALUE : currentStandard;
    const newAttended = getAppliedAttendedLabel(currentAttended).join() === tagName ? DEFAULT_ATTENDED_VALUE : currentAttended;
    const newAppliedFilters = [
      ...(newStandard === DEFAULT_STANDARD_VALUE ? [] : getAppliedStandardLabel(newStandard, standardFilter)),
      ...(newAttended === DEFAULT_ATTENDED_VALUE ? [] : getAppliedAttendedLabel(newAttended)),
      ...newScores.map(i => `${SCORE_LABEL}${i.label}`),
      ...newGrades.map(i => `${GRADE_LABEL}${i.label}`),
      ...newTeachers.map(i => `${TEACHER_LABEL}${i.label}`)
    ];
    queryDataFromReport({
      // below are used to retain filter settings when we return
      selectedStandard: newStandard,
      selectedAttended: newAttended,
      selectedScores: newScores,
      selectedTeachers: newTeachers,
      selectedGradeLevels: newGrades,
      appliedFilters: newAppliedFilters,

      // below will be used in api call
      fltScore: getApiValue(newScores),
      fltGrade: getApiValue(newGrades),
      fltTeacher: getApiValue(newTeachers),
      fltInstruction: getAttendedApiValue(newAttended),
      fltStandardIndicator: getStandardApiValue(newStandard)
    });
  };

  // accessibility - set focus to filter section when first shown
  const firstElementRef = useRef(null);
  useEffect(() => {
    if (show) {
      firstElementRef.current.focus();
    }
  }, [show]);

  const hasTeacherFilter = !isTeacher && !isAggregate;

  return (<>
    <div className='container cb-margin-bottom-16' style={{display: show ? 'block' : 'none'}} ref={firstElementRef}>
      <div className='row' >
        {
          // show teacher filter if not teacher
          hasTeacherFilter && (
            <FilterWithLabel label='Teacher'>
              <MultiSelect
                selectId='teacherFilterId'
                options={teacherFilter.map(i => ({
                  ...i,
                  label: i.label ?? i.caption
                }))}
                label={`Teachers`}
                fieldName="teacherFilter"
                onChange={(selections) => setCurrentTeachers(selections)}
                value={currentTeachers}
                validation={false}
                selectAll={true}
              />
            </FilterWithLabel>
          )
        }
        {!isTeacher && (
          <FilterWithLabel label={(<div className='display-flex' style={{minWidth: '500px'}}>
            <LabelWithTooltip
              label={'Attended/Instructed'}
              toolTipTitle={'Attending/Instructed'}
              toolTipLabel={'Attending/Instructed Tooltip'}
              modalContent={<AttendedModalContent />}
              modalTrigger={`${reportIdentifier}_attended_tooltip_id`}
            />
          </div>)}>
            <Select
              id='attendedFilterId'
              ariaLabel="Attended/Instructed filter select"
              values={ATTENDED_INSTRUCTED_OPTIONS}
              value={currentAttended}
              onChange={(selectedValue) => setCurrentAttended(selectedValue)}
            />
          </FilterWithLabel>)
        }
        <FilterWithLabel label='Score'>
          <MultiSelect
            selectId='scoreFilterId'
            options={scoreFilter.map(i => ({
              ...i,
              label: i.label ?? i.caption
            }))}
            // label={`${selectedScores.length < 1 ? 'All ' : ''}Scores`}
            label={`Scores`}
            fieldName="scoreFilter"
            onChange={(selections) => setCurrentScores(selections)}
            value={currentScores}
            validation={false}
            selectAll={true}
          />
        </FilterWithLabel>

        <FilterWithLabel label='Grade Level'>
          <MultiSelect
            selectId='gradeFilterId'
            options={gradeLevelFilter.map(i => ({
              ...i,
              label: i.label ?? i.caption
            }))}
            label={`Grade Levels`}
            fieldName="gradeLevelFilter"
            onChange={(selections) => setCurrentGrades(selections)}
            value={currentGrades}
            validation={false}
            selectAll={true}
          />
        </FilterWithLabel>

        {
          hasStandardFilter && (
            <FilterWithLabel label={(<div className='display-flex'>
              <LabelWithTooltip
                label={'Standard Group'}
                toolTipTitle={'Standard Group'}
                toolTipLabel={'Standard Group Tooltip'}
                modalContent={<StandardModalContent />}
                modalTrigger={`${reportIdentifier}_standard_tooltip_id`}
              />
            </div>)}>
              <Select
                id='standardFilterId'
                ariaLabel="Standard group filter select"
                values={standardFilter.map(i => ({
                  ...i,
                  label: i.caption
                }))}
                value={currentStandard}
                onChange={(selectedValue) => setCurrentStandard(selectedValue)}
              />
            </FilterWithLabel>)
        }
      </div>
      <div className='cb-margin-top-8 row justify-content-end'>
        <BlackButton id='removeFiltersButton' className='cb-margin-right-8' onClick={onRemoveFilters}>Remove Filters</BlackButton>
        <PrimaryButton id='applyFiltersButton' onClick={onApplyFilters}>Apply Filters</PrimaryButton>
      </div>
    </div>
    {currentApplied.length > 0 &&
       <div className='container'>
         <div className="cb-padding-top-8 dropdown-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Applied Filters:</div>
         <TagSet
           label={`Applied Filters`}
           offScreen="Remove"
           tags={currentApplied}
           onClick={(tagName, e) => {
             e.preventDefault();
             // onFilterRemove && onFilterRemove(tagName);
             onRemoveFilter(tagName);
           }}
         />
       </div>
    }
  </>);
};

GenericFilterSection.propTypes = {
  hasStandardFilter: PropTypes.bool,
  teacherFilter: PropTypes.array,
  gradeLevelFilter: PropTypes.array,
  scoreFilter: PropTypes.array,
  standardFilter: PropTypes.array,
  show: PropTypes.bool,
  isTeacher: PropTypes.bool,
  isAggregate: PropTypes.bool,
  queryDataFromReport: PropTypes.func,
  currentTeachers: PropTypes.array,
  currentScores: PropTypes.array,
  currentGrades: PropTypes.array,
  currentAttended: PropTypes.string,
  currentApplied: PropTypes.array,
  currentStandard: PropTypes.string,
  setCurrentTeachers: PropTypes.func,
  setCurrentScores: PropTypes.func,
  setCurrentGrades: PropTypes.func,
  setCurrentAttended: PropTypes.func,
  setCurrentStandard: PropTypes.func,
  reportIdentifier: PropTypes.string
};

export default GenericFilterSection;

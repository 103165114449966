import PropTypes from 'prop-types';
import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { getDisplayValue, getLeftPaddingForPercentageLabel, getNoEnrollmentMessage, getPercentageLabel, getPercentageLabelMarginLeft, getTierAchievedText } from '../../utils';
import { LARGE_TEXT_FONT_SIZE, SMALL_TEXT_FONT_SIZE } from '../constants';

const styles = StyleSheet.create({
  container: {
    paddingTop: 8
  },
  titleText: {
    fontSize: LARGE_TEXT_FONT_SIZE,
    fontFamily: 'Roboto-Bold',
    paddingLeft: 6
  },
  percentageText: {
    fontSize: SMALL_TEXT_FONT_SIZE,
    fontFamily: 'Roboto-Bold'
  },
  noEnrollmentText: {
    paddingTop: 5,
    paddingLeft: 10,
    fontSize: LARGE_TEXT_FONT_SIZE,
    fontFamily: 'Roboto-Italic'
  },
  chartImage: {
    marginTop: `-2px`,
    zIndex: -1
  }
});

// this is needed to prevent overlow on 100%
const ADDITIONAL_LEFT_PADDING_FOR_PDF = 4;
const getAdditionalPdfPaddingLeft = val => val > 95 ? ADDITIONAL_LEFT_PADDING_FOR_PDF : 0;

const HonorRollProgressChartPdf = ({chartRef, title, val, tier, hasTier, year}) => {
  const displayVal = getDisplayValue(val);

  let paddingLeftValue = getLeftPaddingForPercentageLabel(displayVal) - getAdditionalPdfPaddingLeft(displayVal);
  paddingLeftValue = paddingLeftValue === 0 ? '' : `${paddingLeftValue}%`;

  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>{title}{hasTier ? `  | ${getTierAchievedText(tier)}` : ''}</Text>
      {
        chartRef && chartRef.current !== null
          ? (
            <View>
              <Text style={[styles.percentageText, {marginLeft: getPercentageLabelMarginLeft(displayVal), paddingLeft: paddingLeftValue}]}>{getPercentageLabel(val)}%</Text>
              <Image style={styles.chartImage} src={chartRef.current.toBase64Image()} />
            </View>
          )
          : (<Text style={styles.noEnrollmentText}>{getNoEnrollmentMessage(year)}</Text>)
      }

    </View>
  );
};

HonorRollProgressChartPdf.propTypes = {
  chartRef: PropTypes.object,
  title: PropTypes.string,
  val: PropTypes.string,
  tier: PropTypes.string,
  hasTier: PropTypes.bool,
  year: PropTypes.number
};

export default HonorRollProgressChartPdf;

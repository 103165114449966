export const CB_LOGO_SVG = `<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225.25 100" height="100" width="225">
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
      }

      .cls-2 {
        fill: #009ade;
      }

      .cls-3 {
        fill: #231f20;
      }
    </style>
  </defs>
  <g>
    <rect class="cls-2" x="100.15" width="125.1" height="100"/>
    <g>
      <path class="cls-1" d="m127.15,62.5l10.6-31.9h10.6l10.2,31.9h2.9c.2.5.3,1.1.4,1.6.1.5.1,1,.1,1.5s0,1-.1,1.5-.2,1.1-.4,1.7h-15.1c-.2-.5-.3-1-.4-1.5-.1-.5-.1-1-.1-1.5s0-.9.1-1.5c.1-.5.2-1,.4-1.5h3.1l-1.4-4.9h-11.1l-1.5,4.9h3.2c.2.5.3,1,.4,1.5.1.5.1,1,.1,1.5s0,.9-.1,1.5c-.1.5-.2,1-.4,1.5h-14.7c-.2-.5-.3-1.1-.4-1.7-.1-.5-.1-1.1-.1-1.5,0-.5,0-1,.1-1.5s.2-1.1.4-1.6h3.2Zm19.4-10.2l-3.8-13.9-4.1,13.9s7.9,0,7.9,0Z"/>
      <path class="cls-1" d="m169.25,62.5v-25h-3.2c-.2-.5-.3-1.1-.4-1.6s-.1-1-.1-1.5,0-1,.1-1.5.2-1.1.4-1.7h18.7c2,0,3.7.3,5.2.9,1.5.6,2.8,1.4,3.8,2.4s1.8,2.2,2.3,3.6c.5,1.4.8,2.8.8,4.4,0,1.3-.2,2.7-.6,4.1-.4,1.4-1.1,2.6-2,3.7-1,1.1-2.2,2-3.7,2.7s-3.5,1.1-5.7,1.1h-6.8v8.5h3.9c.2.5.3,1.1.4,1.6.1.5.1,1,.1,1.5s0,1-.1,1.5-.2,1.1-.4,1.7h-15.8c-.2-.5-.3-1.1-.4-1.7-.1-.5-.1-1.1-.1-1.5,0-.5,0-1,.1-1.5s.2-1.1.4-1.6l3.1-.1h0Zm13.6-15c1.6,0,2.8-.4,3.7-1.2s1.3-2.1,1.3-3.8c0-1.5-.4-2.8-1.3-3.6-.8-.8-2-1.2-3.5-1.2h-5v9.9l4.8-.1h0Z"/>
    </g>
  </g>
  <path class="cls-1" d="m198.46,34.45c0-.55.1-1.06.31-1.52.21-.47.49-.87.84-1.21.35-.34.77-.61,1.24-.8s.97-.28,1.5-.28,1.02.09,1.48.28.88.46,1.23.8c.35.34.63.75.84,1.21s.31.98.31,1.52-.11,1.07-.31,1.54-.49.88-.84,1.22c-.36.34-.77.61-1.23.8-.47.19-.96.29-1.48.29s-1.03-.1-1.5-.29c-.47-.2-.89-.46-1.24-.8-.35-.34-.64-.75-.84-1.22-.21-.47-.31-.98-.31-1.54Zm3.89,2.99c.4,0,.78-.07,1.13-.23.35-.15.66-.36.92-.62.26-.26.47-.58.61-.94.15-.37.22-.77.22-1.2s-.07-.81-.22-1.17c-.15-.36-.35-.67-.61-.94-.26-.26-.56-.47-.92-.62-.35-.15-.73-.23-1.13-.23s-.79.08-1.14.23c-.36.15-.66.36-.92.62s-.47.58-.61.94c-.15.36-.22.75-.22,1.17s.07.83.22,1.2c.15.36.35.68.61.94.26.26.57.47.92.62.35.15.73.23,1.14.23Zm-1.37-1.42v-3.14h-.31c-.03-.09-.05-.16-.05-.21s-.01-.09-.01-.14c0-.03,0-.07.01-.12s.02-.12.05-.21h1.95c.21,0,.4.03.57.1s.32.16.45.28c.12.12.22.26.28.42.07.16.1.33.1.51,0,.21-.05.44-.15.68-.1.24-.3.43-.58.57l.58,1.26h.32c.03.09.05.16.05.21,0,.05,0,.09,0,.14,0,.03,0,.07-.01.12,0,.05-.03.11-.06.2h-.96l-.79-1.82h-.65v1.15h.38c.03.09.04.16.05.21,0,.05.01.09.01.14,0,.03,0,.07-.01.12s-.03.12-.06.2h-1.48c-.03-.09-.05-.16-.05-.2s-.01-.09-.01-.12c0-.05,0-.09.01-.14,0-.05.02-.12.05-.21h.31Zm1.48-1.86c.21,0,.37-.06.48-.18.11-.12.17-.27.17-.47,0-.18-.05-.32-.16-.43-.11-.12-.26-.17-.46-.17h-.71v1.24h.68Z"/>
  <g>
    <rect class="cls-3" x=".15" width="100" height="100"/>
    <path class="cls-1" d="m69.65,33.5c-1.4-1.4-3.2-2.4-5.1-3-1.4-.5-2.9-.8-4.4-1.1-2.3-.5-4.6-.7-6.8-.8-.1,0-.1-.1-.1-.2.4-.4.8-.9,1.2-1.4,1.8-2.7,1.4-6.2,1.2-7.4,0-.2-.3-.4-.5-.3-1.2.3-4.6,1.4-6.4,4.1-1,1.5-1.3,3.3-1.4,4.8,0,.2-.2.4-.4.4-2.2.1-4.5.4-6.8.8-1.5.2-2.9.6-4.4,1.1-1.9.6-3.7,1.5-5.1,3s-2,3.3-1.9,5.2c0,2,.7,3.9,1.7,5.7.6,1,1.2,1.9,1.9,2.6.2.3.4.6.4,1,.3,5.7,3.1,11.2,6.7,15.5,1,1.2,1.7,1.9,2.8,3,.5.5,2.4,2.2,3.1,2.8,1.5,1.2,2.7,2.3,4.8,2.3s3.2-1,5-2.5c.5-.5,2.4-2.1,2.9-2.6,1-1,1.8-1.8,2.8-3,3.6-4.3,6.4-9.8,6.7-15.5,0-.4.2-.7.4-1,.7-.7,1.3-1.6,1.9-2.6,1-1.7,1.7-3.7,1.7-5.7,0-1.8-.6-3.7-1.9-5.2h0Zm-18.3-9c.6-.9,1.3-1.6,2.2-2,.2-.1.3,0,.3.2,0,1-.4,2-1,2.8-.4.6-1.1,1.5-2.3,2-.1,0-.2,0-.2-.1.1-1.2.5-2.2,1-2.9h0Zm15.4,15.5c-.1.4-.3.8-.5,1.2-.7,1.1-1.3,2.2-1.7,3.5-.4,1.3-.3,2.7-.3,4.1,0,3.6-1,7.2-3,10.2-.3.4-.5.7-.8,1.1-2,2.7-4.5,5.2-7.1,7.2-.9.7-1.9,1.6-3.1,1.7-1.1,0-2.1-.9-2.9-1.7-.8-.7-1.6-1.6-2.2-2.4-1.2-1.7-2.3-3.3-3.2-5.2-.1-.3-.2-.6-.4-1-.7-1.9-1.1-4-1.1-6-.1-1.9.2-3.9,1.2-5.5.8-1.2,2-2.1,3.3-2.6,5.6-2.4,14-.7,14.3-.7.2,0,.4,0,.5-.1.3-.2.1-.6,0-.8-.2-.2-.5-.4-.8-.5-.5-.2-1-.4-1.5-.5-.9-.2-1.9-.4-2.8-.5-3.2-.3-6.4-.2-9.6.1-1.1.1-2.2.3-3.3.3-1.2,0-2.5-.1-3.6-.7-.9-.6-1.5-1.6-1.7-2.7-.2-1.2.1-2.4.8-3.3,1.7-2.7,5.3-3.3,8.2-3.7,1.2-.2,2.5-.2,3.8-.2,2.8-.1,5.7.1,8.5.6,2.4.4,4.9,1.1,6.9,2.5,1.1.8,2,1.8,2.3,3.2.2.7.1,1.6-.2,2.4h0Z"/>
  </g>
</svg>`;

import React from 'react';
import PropTypes from 'prop-types';
import IprSummaryCard from '../../common/summaryCard';
import './summaryCard.css';
import FirstCardContent from './firstCardContent';
import { CARD_1_ID, CARD_2_ID, CARD_3_ID, GLOBAL_TYPE, GROUP_TYPE } from '../utils';
import IprSecondCardContent from './secondCardContent';
import IprThirdCardContent from './thirdCardContent';

const IprSummarySection = ({summaryData}) => {

  const groupData = summaryData.find(i => i.type === GROUP_TYPE);
  const globalData = summaryData.find(i => i.type === GLOBAL_TYPE);
  const stateProvinceData = summaryData.find(i => i.type !== GROUP_TYPE && i.type !== GLOBAL_TYPE);
  const stateProvinceAbbreviation = stateProvinceData ? stateProvinceData.type : false;

  return (
    <div className='cb-panel-blue' id='iprSummarySectionContainer'>
      <div className='container' >
        <div className='row'>
          <IprSummaryCard selector={CARD_1_ID}>
            <FirstCardContent
              stateProvinceAbbreviation={stateProvinceAbbreviation}
              groupData={groupData}
              stateProvinceData={stateProvinceData}
              globalData={globalData}
            />
          </IprSummaryCard>
          <IprSummaryCard selector={CARD_2_ID}>
            <IprSecondCardContent groupData={groupData} />
          </IprSummaryCard>
          <IprSummaryCard selector={CARD_3_ID} >
            <IprThirdCardContent
              stateProvinceAbbreviation={stateProvinceAbbreviation}
              groupData={groupData}
              stateProvinceData={stateProvinceData}
              globalData={globalData}
            />
          </IprSummaryCard>
        </div>
      </div>
    </div>);
};

IprSummarySection.propTypes = {
  summaryData: PropTypes.array
};

export default IprSummarySection;

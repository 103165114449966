import React from 'react';
import PropTypes from 'prop-types';
import IconAndTitle from '../../common/iconAndTitle';
import GroupValuesRow from '../../common/groupValuesRow';

const SsrCardContent = ({stateProvinceAbbreviation, group, stateProvince, global, school, district, cardId, title, icon}) => {

  return (
    <>
      <IconAndTitle title={title} icon={icon} />
      <GroupValuesRow
        omitBorderBottom={true}
        id={cardId}
        stateProvinceAbbreviation={stateProvinceAbbreviation}
        group={group}
        school={school}
        district={district}
        state={stateProvince}
        global={global}
      />
    </>
  );
};

SsrCardContent.propTypes = {
  stateProvinceAbbreviation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  icon: PropTypes.string,
  title: PropTypes.string,
  group: PropTypes.string,
  school: PropTypes.string,
  district: PropTypes.string,
  stateProvince: PropTypes.string,
  global: PropTypes.string,
  cardId: PropTypes.string
};

export default SsrCardContent;

import React from 'react';
import {Bar} from 'react-chartjs-2';
import PropTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, registerables } from 'chart.js';
import { getDefaultSharedBarChartConfig, getDefaultSharedBarChartData } from '../common/barChartConfig';
Chart.register(...registerables, ChartDataLabels);

const CHART_HEIGHT = 330;

const FiveYearScoreSummaryChart = (props) => {

  // if we don't have data, return
  if (!props.data || !props.labels) {
    return <></>;
  }

  const data = getDefaultSharedBarChartData({
    data: props.data,
    labels: props.labels
  });

  return (
    <Bar data={data} role='presentation' options={getDefaultSharedBarChartConfig({
      height: CHART_HEIGHT,
      formatter: function (value) {
        if (isNaN(value)) {
          return '';
        }
        let a = value;
        a = a.toFixed(0);
        return a + '%';
      }
    })} height={CHART_HEIGHT} />
  );
};

FiveYearScoreSummaryChart.propTypes = {
  labels: PropTypes.array,
  data: PropTypes.array
};

export default FiveYearScoreSummaryChart;

import { formatPercentageValue } from '../../../utils/stringUtils';

export const formatRosterData = (rosterData) => {
  const candidateIds = new Set();
  const formattedData = rosterData.data.map((row) => {
    // {fn: firstName, ln: lastName, mi: middleInitial, gl: gradeLevel, an: ap_number, avs: average_score, sac: ap_scholar_award_cd, ad: award_descr,
    // aci: candidateId, aoi: attendingOrgId, bt: birthDt, sid: studentId, elc: educationLevelCd, te: totalExams}
    const fullName = `${row.ln}; ${row.fn}${row.mi ? ` ${row.mi}.` : ''}`;
    candidateIds.add(row.aci);
    return {
      fullName,
      ...row,
      avs: formatPercentageValue(row.avs, 2)
    };
  });

  return {
    data: formattedData,
    subjectColumns: rosterData.subjectColumns,
    totalStudents: candidateIds.size
  };
};

const abbreviatedColMap = {
  'fullName': 'Student Name',
  'ad': 'Scholar Award',
  'gl': 'Grade Level',
  'an': 'AP Number / AP ID',
  'sid': 'Student ID',
  'bt': 'Date of Birth',
  'avs': 'Average Score',
  'te': 'Total Exams'
};

const propertiesToIgnoreOnCsv = [
  'fn',
  'ln',
  'mi',
  'aci',
  'sac',
  'elc'
];

const getFullColumnName = abbr => abbreviatedColMap[abbr] ?? abbr;

export const getRosterColumns = sortedSubjectColumns => [
  {
    title: getFullColumnName('fullName'),
    field: 'fullName',
    rowHeader: true,
    sticky: true,
    sortable: true,
    className: 'table-first-column-width cb-font-weight-bold'
  },
  {
    title: getFullColumnName('ad'),
    field: 'ad',
    sortable: true,
    className: 'scholar-award-width'
  },
  {
    title: getFullColumnName('gl'),
    field: 'gl',
    sortable: true,
    className: 'grade-level-width'
  },
  {
    title: getFullColumnName('an'),
    field: 'an',
    sortable: true
  },
  {
    title: getFullColumnName('sid'),
    field: 'sid',
    sortable: true
  },
  {
    title: getFullColumnName('bt'),
    field: 'bt',
    sortable: true
  },
  {
    title: getFullColumnName('avs'),
    field: 'avs',
    sortable: true,
    className: 'rightAlignTableCells'
  },
  ...sortedSubjectColumns.map(subject => ({title: subject, field: subject, sortable: true, className: 'rightAlignTableCells'})),
  {
    title: getFullColumnName('te'),
    field: 'te',
    sortable: true,
    className: 'rightAlignTableCells'
  }
];

export const getRosterCsvColumns = sortedSubjectColumns => getRosterColumns(sortedSubjectColumns).map(i => i.title);

export const formatCsvData = abbreviatedData => abbreviatedData.map(i => {
  const full = {};
  for (const property in i) {
    if (!propertiesToIgnoreOnCsv.includes(property)) {
      full[getFullColumnName(property)] = i[property];
    }
  }
  return full;
});

import PropTypes from 'prop-types';
import React from 'react';
import { formatCountValue } from '../../../utils/stringUtils';
import Spinner from '../../common/loader/spinner';

const InlineSpinner = () => {
  return (
    <div style={{display: 'inline-block'}}>
      <Spinner size='sm' removePadding alignment='left' show ariaLabel='Loading...' />
    </div>
  );
};

const ScholarSummaryHeaderDisplay = ({total, average, isLoading}) => {
  return (<h5>
    Scholar Summary (Total Scholars: {
      !isLoading ? formatCountValue(total) : (<InlineSpinner />)
    }; Average Score: {
      !isLoading ? average : (<InlineSpinner />)
    })
  </h5>);
};

ScholarSummaryHeaderDisplay.propTypes = {
  total: PropTypes.string,
  average: PropTypes.string,
  isLoading: PropTypes.bool
};

export default ScholarSummaryHeaderDisplay;

import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';

// gets whether or not current user has accepted terms and conditions
export const getTermsAndConditions = (userId) => {
  let url = config.apiBaseUrl + config.msTcUri + userId;
  return axiosInstance.get(url);
};

// updates terms and conditions for current user
export const postTermsAndConditions = (body) => {
  let url = config.apiBaseUrl + config.msTcUri;
  return axiosInstance.post(url, body);
};

/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from 'react';
import PropTypes from 'prop-types';
import RosterReportInfoModal from '../../../ap-osre-report/rosterReportsModal';
import infoImage from '../../info.png';
import { Icon } from '@cb/apricot-react';
import {
  DISABLED_REPORT_LINK_CSS_CLASS,
  ACTIVE_REPORT_LINK_CSS_CLASS,
  STUDENT_SCORE_REPORT_TITLE,
  ORG_SCORE_ROSTER_REPORT_TITLE,
  SUBJECT_SCORE_ROSTER_REPORT_TITLE,
  SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE,
  SCHOLAR_ROSTER_REPORT_TITLE
} from '../../../../constants/reports';
import ReportLink, { TeacherReportLink } from '../reportLink';
import SddModal from './sddModal';

const districtAdminReportMap = {
  'Organization Score Roster': { reportPath: ORG_SCORE_ROSTER_REPORT_TITLE, hasSchoolLink: true, hasAggregateLink: true },
  'Subject Score Roster': { reportPath: SUBJECT_SCORE_ROSTER_REPORT_TITLE, hasSchoolLink: true, hasAggregateLink: true },
  'Scholar Roster': { reportPath: SCHOLAR_ROSTER_REPORT_TITLE, hasSchoolLink: true, hasAggregateLink: false }
};

const reportMap = {
  'Student Score Report': { reportPath: STUDENT_SCORE_REPORT_TITLE, hasSchoolLink: false, hasAggregateLink: false },
  'Scholar Roster': { reportPath: SCHOLAR_ROSTER_REPORT_TITLE, hasSchoolLink: false, hasAggregateLink: false },
  ...districtAdminReportMap
};

function RosterReports({
  reportColumnClass,
  rosterReportsStyle,
  rosterReportInfoTrigger,
  isTeacher,
  rosterReports,
  studentDataDownloadTrigger,
  isSchoolAdmin,
  selectedYear,
  isDistrictAdmin,
  roleCode,
  exportStudentDatafile,
  courses,
  genesisSttVal
}) {
  return (
    <div className={`${reportColumnClass} ${rosterReportsStyle}`}>
      <div className="cb-arch-view">
        <h2 className="infosign">Roster and Student Reports</h2>
        <div className="">
          <input
            type="image"
            className="info-icon"
            style={{ marginBottom: '4px' }}
            src={infoImage}
            data-toggle="modal"
            id={rosterReportInfoTrigger}
            aria-label="Roster and student reports description"
            tabIndex="0"
          />
          <RosterReportInfoModal trigger={rosterReportInfoTrigger} />
        </div>
        <p>View performance by subject, by organization, or by student.</p>
      </div>
      <ul>
        {!isTeacher
          ? rosterReports.map((report) => {
            return (
              <li key={report.reportName}>
                {report.reportName === 'Student Datafile' ? (
                  <div>
                    <a
                      href="!#"
                      className={
                        !report.enableReport
                          ? DISABLED_REPORT_LINK_CSS_CLASS
                          : ACTIVE_REPORT_LINK_CSS_CLASS
                      }
                      id={studentDataDownloadTrigger}
                      aria-haspopup="dialog"
                      tabIndex="0"
                    >
                      <span className="padding-right">
                        {report.reportName}
                      </span>
                      <Icon name={'download'} />
                      <span>
                        {!report.enableReport ? (
                          <span>&nbsp;(Not Available)</span>
                        ) : null}
                      </span>
                    </a>
                  </div>
                ) : null}
                {isSchoolAdmin ? (
                  <div>
                    {report.reportName !== 'Student Datafile' ? (
                      // react reports
                      <div>
                        <ReportLink {...reportMap[report.reportName]} genesisSttVal={genesisSttVal} isSchoolAdmin={isSchoolAdmin} selectedYear={selectedYear} isDistrictAdmin={isDistrictAdmin} report={report} isAggregate={false}></ReportLink>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {isDistrictAdmin ? (
                  <div>
                    {report.reportName !== 'Student Datafile' ? (
                      <div>
                        <div>
                          <div>{report.reportName} {!report.enableReport ? <span>(Not Available)</span> : null}</div>
                          {/* React District Report */}
                          <div>
                            <div>
                              <ReportLink {...reportMap[report.reportName]} genesisSttVal={genesisSttVal} selectedYear={selectedYear} isDistrictAdmin={isDistrictAdmin} report={report} ></ReportLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {report.reportName === 'Student Datafile' && <SddModal roleCode={roleCode} studentDataDownloadTrigger={studentDataDownloadTrigger} selectedYear={selectedYear} exportStudentDatafile={exportStudentDatafile}></SddModal>}
              </li>
            );
          })
          : courses
            ? courses.map((course) => {
              return <li key={course.subjectName}>
                <TeacherReportLink
                  {...reportMap['Subject Score Roster']} genesisSttVal={genesisSttVal} reportDisplayTitle={SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE} selectedYear={selectedYear} subjectName={course.subjectName} enabled={true}
                />
              </li>;
            })
            : null}
      </ul>
    </div>
  );
}

RosterReports.propTypes = {
  courses: PropTypes.array,
  enableRoster: PropTypes.bool,
  exportStudentDatafile: PropTypes.func,
  isDistrictAdmin: PropTypes.bool,
  isSchoolAdmin: PropTypes.bool,
  isTeacher: PropTypes.bool,
  reportColumnClass: PropTypes.string,
  roleCode: PropTypes.number,
  rosterReportInfoTrigger: PropTypes.string,
  rosterReports: PropTypes.array,
  rosterReportsStyle: PropTypes.string,
  selectedYear: PropTypes.number,
  studentDataDownloadTrigger: PropTypes.string,
  genesisSttVal: PropTypes.string
};

export default RosterReports;

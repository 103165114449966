import React, { useState, useEffect, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import SiteRoutes from './siteRoutes';
import Spinner from '../common/loader/spinner';
import ErrorBoundary from '../ap-osre-error-handling/ErrorBoundary';
import OrgSelection from '../ap-osre-org-selection/orgSelection';
import LocalNav from './localNav';
import { AppContext } from '../../AppContext';
import {postMessages, getReportList} from '../../api';
import axios from 'axios';
import {MSORG_ERROR, ORG_ERROR, ROLE_ERROR} from '../../constants/errors';
import { getRoleCodeFromReportList } from '../../utils/roleCodeMap';

// setup reports and other data for selected or searched org
const DataSetup = ({
  selectedOrg,
  setSelectedOrg,
  searchedOrg,
  setSearchedOrg,
  selectedYear,
  showOrgSelection,
  showRegionalSearch,
  setShowOrgSelection,
  setShowRegionalSearch,
  notCourseAuthorized,
  setNotCourseAuthorized,
  setRoleError,
  setOrgError,
  setClosedOrgError
}) => {
  const [apiError, setApiError] = useState(undefined);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [reportsData, setReportsData] = useState(undefined);
  const [courses, setCourses] = useState(undefined);
  const [sdiMessages, setSdiMessages] = useState(undefined);
  const [genMessages, setGenMessages] = useState(undefined);
  const [genesisSttVal, setGenesisSttVal] = useState(undefined);
  const appContext = useContext(AppContext);

  const orgId = searchedOrg && searchedOrg.orgId ? searchedOrg.orgId : selectedOrg.orgId;
  const roleCd = searchedOrg && searchedOrg.roleCd ? searchedOrg.roleCd : undefined;
  const year = selectedYear || appContext.currentYear;

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        let [reports, messages] = await axios.all([
          getReportList({
            orgId: Number(orgId),
            year,
            roleCd,
            currentYear: appContext.currentYear
          }),
          postMessages(year)
        ]);
        // Both requests are now complete
        appContext.setRoleCd(getRoleCodeFromReportList(reports.data));
        reports = reports.data;
        messages = messages.data;

        if (reports.Reports && messages.Items) {
          if (Number(reports.Reports[0].role_cd) === 1 || Number(reports.Reports[0].role_cd) === 2) {
            if (!reports.Courses.length) {
              setSelectedOrg(undefined);
              setSearchedOrg(undefined);
              setShowRegionalSearch(false);
              setShowOrgSelection(false);
              setNotCourseAuthorized(true);
            } else {
              setReportsData(reports.Reports);
              setCourses(reports.Courses);
              setSdiMessages(reports.SDIMessages);
              setGenesisSttVal(reports.sttValue2);
            }
          } else {
            setReportsData(reports.Reports);
            setSdiMessages(reports.SDIMessages);
            setGenesisSttVal(reports.sttValue2);
          }
          setGenMessages(messages.Items);
          setIsLoadingData(false);
        } else {
          setApiError({error: reports});
        }
        setIsLoadingData(false);
      } catch (err) {
        if (err?.response?.data?.message === ROLE_ERROR) {
          setRoleError(true);
          setSelectedOrg(undefined);
          setSearchedOrg(undefined);
        } else if (err?.response?.data?.message === ORG_ERROR) {
          setOrgError(true);
          setSelectedOrg(undefined);
          setSearchedOrg(undefined);
        } else if (err?.response?.data?.message === MSORG_ERROR) {
          setClosedOrgError(true);
          setSelectedOrg(undefined);
          setSearchedOrg(undefined);
        } else {
          const errorObj = err && err.response && err.response.data ? err.response.data : err;
          setApiError(errorObj);
          setIsLoadingData(false);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedOrg, selectedOrg]);

  return (
    isLoadingData ? <Spinner /> : (
      apiError ? (
        <ErrorBoundary error={apiError}/>
      ) : (
        notCourseAuthorized ? (
          <OrgSelection />
        ) : (
          reportsData && genMessages ? (
            <Fragment>
              <LocalNav reportsData={reportsData} />
              <div className='container'>
                <SiteRoutes
                  reportsData={reportsData}
                  courses={courses}
                  sdiMessages={sdiMessages}
                  // genesisSttVal needed here for initial load, year changes will update genesisSttVal internally on the dashboard
                  genesisSttVal={genesisSttVal}
                  genMessages={genMessages}
                  selectedYear={selectedYear}
                  selectedOrg={selectedOrg}
                  setSelectedOrg={setSelectedOrg}
                  searchedOrg={searchedOrg}
                  setSearchedOrg={setSearchedOrg}
                  setShowOrgSelection={setShowOrgSelection}
                  setShowRegionalSearch={setShowRegionalSearch}
                />
              </div>
            </Fragment>
          ) : null
        )
      )
    )
  );
};

DataSetup.propTypes = {
  selectedOrg: PropTypes.object,
  setSelectedOrg: PropTypes.func,
  searchedOrg: PropTypes.object,
  setSearchedOrg: PropTypes.func,
  selectedYear: PropTypes.number,
  showOrgSelection: PropTypes.bool,
  showRegionalSearch: PropTypes.bool,
  setShowOrgSelection: PropTypes.func,
  setShowRegionalSearch: PropTypes.func,
  notCourseAuthorized: PropTypes.bool,
  setNotCourseAuthorized: PropTypes.func,
  setRoleError: PropTypes.func,
  setOrgError: PropTypes.func,
  setClosedOrgError: PropTypes.func
};

export default DataSetup;

import PropTypes from 'prop-types';
import React from 'react';
import { Document, Text, pdf, Font } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import robotoFont from './fonts/Roboto-Regular.ttf';
import robotoFontBold from './fonts/Roboto-Bold.ttf';
import robotoFontItalic from './fonts/Roboto-Italic.ttf';
import BasePage from './basePage';
import PageOne from './pageOne';
import PageTwo from './pageTwo';
import { HONOR_ROLL_REPORT_DISPLAY_TITLE } from '../../../../constants/reports';

// registering fonts, fontFamily is only style needed to use below fonts
Font.register({ family: 'Roboto-Bold', src: robotoFontBold });
Font.register({ family: 'Roboto-Italic', src: robotoFontItalic });
Font.register({ family: 'Roboto', src: robotoFont });

// this prevents word wrap from breaking words with dashes on new lines
const hyphenationCallback = (word) => [word];
Font.registerHyphenationCallback(hyphenationCallback);

const PDF_METADATA_APOSRE = 'AP Score Reports for Educators';

export const HonorRollPdf = (props) => {
  const commonFooterProps = {
    reportName: HONOR_ROLL_REPORT_DISPLAY_TITLE
  };

  return (
    <Document
      title={props.filename}
      author={PDF_METADATA_APOSRE}
      creator={PDF_METADATA_APOSRE}
      producer={PDF_METADATA_APOSRE}
    >
      <BasePage {...props} {...commonFooterProps} >
        <PageOne {...props} />
      </BasePage>
      <BasePage {...props} {...commonFooterProps} >
        <PageTwo {...props} />
      </BasePage>
    </Document>
  );
};

HonorRollPdf.propTypes = {
  selectedAICaption: PropTypes.string,
  year: PropTypes.number,
  school: PropTypes.string,
  overall: PropTypes.string,
  accessAward: PropTypes.bool,
  dataUpdatedDate: PropTypes.string,
  cultureRef: PropTypes.object,
  creditRef: PropTypes.object,
  optimizationRef: PropTypes.object,
  culturePercent: PropTypes.string,
  cultureTier: PropTypes.string,
  creditPercent: PropTypes.string,
  creditTier: PropTypes.string,
  optimizationPercent: PropTypes.string,
  optimizationTier: PropTypes.string,
  nextTierValues: PropTypes.object,
  filename: PropTypes.string,
  grade12Enrollment: PropTypes.number,
  cultureCount: PropTypes.number,
  creditCount: PropTypes.number,
  optimizationCount: PropTypes.number,
  cultureMetadata: PropTypes.array,
  creditMetadata: PropTypes.array,
  optimizationMetadata: PropTypes.array
};

// this allows us to pass in data during react render without having to actually render <HonorRollPdf />
export const getPdfDownloadFunction = (props) => {
  return async ({filename}) => {
    const blob = await pdf(
      <HonorRollPdf {...props} />
    ).toBlob();

    saveAs(blob, `${filename}.pdf`);
  };
};

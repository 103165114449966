import download from '../../../../api/download';

export const getAppSyncResponseHandler = (subscription, setFileData) => {
  return async data => {
    if (data.data[subscription].error) {
      throw data.data[subscription].error;
    }
    if (data.data[subscription].url) {
      const {data: pdfContent, error} = await download(data.data[subscription].url);
      if (error) {
        throw error;
      }
      setFileData(pdfContent);
    }
  };
};


export const chunk = (input, length) => {
  return input.match(new RegExp('.{1,' + length + '}', 'g'));
};

// Return percentage with 2 decimal places, or empty string if there's an error
export const formatPercentageValue = (str, digits = 2) => {
  try {
    const percentage = parseFloat(str).toFixed(digits);
    return isNaN(percentage) ? '' : percentage;
  } catch (err) {
    return '';
  }
};

// Returns string as int, or empty string if value is 0
export const formatCountValue = (str, keepZero = false) => {
  let returnValue = parseInt(str);
  return (returnValue === 0 && !keepZero) || isNaN(returnValue) ? '' : returnValue.toLocaleString('en-US');
};

// replaces 0s with '', formats counts over 1000 to 1,000
export const replaceObjZerosAndCountValues = obj => {
  const result = {...obj};
  for (const property in result) {
    if (result[property] === '0' || result[property] === 0) {
      result[property] = '';
    } else if (typeof result[property] === 'number') {
      result[property] = formatCountValue(obj[property]);
    }
  }
  return result;
};

// calculates percentage to 1 decimal for totals obj and score prop
export const getTotalsPercentageString = (obj, divisorProp, dividendProp) =>
  obj[divisorProp] === 0 ? '' : ((obj[divisorProp] / obj[dividendProp]) * 100).toFixed(1) + '%';

export const getFilterQueryString = (arrFilter) => arrFilter.map(i => i.value).join();

export const getDisplayNameWithAi = (schoolName, aiCd) => aiCd ? `${schoolName} (${aiCd})` : schoolName;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  chunk,
  formatPercentageValue,
  formatCountValue,
  replaceObjZerosAndCountValues,
  getTotalsPercentageString,
  getFilterQueryString,
  getDisplayNameWithAi
};

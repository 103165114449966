import gql from 'graphql-tag';
import { getAuthStudentData } from '../../../graphql/queries';
import { subscribeToStudentData } from '../../../graphql/subscriptions';
import openDownloadInNewTab from '../common/downloads/openDownloadTab';

export const getStudentDatafileExportFunction = ({orgId, selectedYear, genesisSttVal}) => {
  return async (reportName, roleCode) => {
    const cb = window.cb;
    openDownloadInNewTab({
      subscribeParams: {
        query: gql(subscribeToStudentData),
        variables: {
          catapultId: cb.core.iam.getTempAWSCredsSubject(),
          orgId: parseInt(orgId),
          adminYear: parseInt(selectedYear),
          roleCd: parseInt(roleCode),
          reportName
        }
      },
      queryParams: {
        query: gql(getAuthStudentData),
        variables: {
          adminYear: parseInt(selectedYear),
          sttVal: genesisSttVal,
          reportName
        }
      },
      getSubscriptionResponse: (data) => data.data.subscribeToStudentData
    });
  };
};

import PropTypes from 'prop-types';
import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PageHeader from '../common/pageHeader';
import { TAB_2_TITLE } from '../../utils';
import PageTitle from '../common/pageTitle';
import CongratulationsHeader from '../pageOne/congratulationsHeader';
import FooterNotes from './footerNotes';
import CriteriaTable from './table';

const styles = StyleSheet.create({
  pageContainer: {
    width: '100%'
  },
  reportContainer: {
    width: '100%',
    marginTop: 10
  },
  tableContainer: {
    paddingTop: 15
  }
});

const HonorRollPageTwo = (props) => {
  const { year, school, overall, dataUpdatedDate, selectedAICaption} = props;

  return (
    <View style={styles.pageContainer}>
      <PageHeader year={year} selectedAICaption={selectedAICaption} dataUpdatedDate={dataUpdatedDate} />
      <PageTitle title={TAB_2_TITLE} />
      <View style={styles.reportContainer}>
        <CongratulationsHeader year={year} school={school} overall={overall} />

        <View style={styles.tableContainer}>
          <CriteriaTable {...props} />
        </View>

        <FooterNotes {...props} />
      </View>
    </View>
  );
};

HonorRollPageTwo.propTypes = {
  year: PropTypes.number,
  selectedAICaption: PropTypes.string,
  dataUpdatedDate: PropTypes.string,
  accessAward: PropTypes.bool,
  school: PropTypes.string,
  overall: PropTypes.string,
  cultureRef: PropTypes.object,
  creditRef: PropTypes.object,
  optimizationRef: PropTypes.object,
  culturePercent: PropTypes.string,
  cultureTier: PropTypes.string,
  creditPercent: PropTypes.string,
  creditTier: PropTypes.string,
  optimizationPercent: PropTypes.string,
  optimizationTier: PropTypes.string,
  cultureMetadata: PropTypes.array,
  creditMetadata: PropTypes.array,
  optimizationMetadata: PropTypes.array,
  grade12Enrollment: PropTypes.number,
  cultureCount: PropTypes.number,
  creditCount: PropTypes.number,
  optimizationCount: PropTypes.number
};

export default HonorRollPageTwo;

import React from 'react';
import PropTypes from 'prop-types';

const SummaryCard = ({selector, children, minHeight = '430px'}) => {

  return (
    <div className='col-lg-4 col-md-6 col-sm-12 cb-padding-32' id={selector}>
      <div className='cb-white-bg' style={{minHeight}}>
        {children}
      </div>
    </div>);
};

SummaryCard.propTypes = {
  children: PropTypes.node,
  selector: PropTypes.string,
  minHeight: PropTypes.string
};

export default SummaryCard;

import CBLocalNavigation from '@cb/apricot/CBLocalNavigation';
import CBUtils from '@cb/apricot/CBUtils';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import './localNav.css';
import LocalNavListItem from './localNavListItem';
import { teacherRoutes } from './siteRoutes';

const isCurrent = (path) => {
  const currentPathname = window.location.pathname;
  let isActive = currentPathname === path;
  return isActive;
};

const LocalNav = ({ reportsData }) => {
  const localNavRef = useRef(null);
  let history = useHistory();
  let location = useLocation();

  // callback function only triggered by mobile view, workaround for unnecessary rerender in mobile view
  const routeInteraction = (e, data) => {
    e.preventDefault();
    const targetPath = CBUtils.attr(data, 'href');
    history.push(targetPath);
  };

  useEffect(() => {
    CBLocalNavigation({
      elem: localNavRef.current, // document.querySelector('.cb-local-navigation'),
      sticky: true,
      selected: true,
      mobileLayout: true,
      mobileEscClose: true,
      mobileInteractionClose: true,
      offsetTop: 0,
      callBack: routeInteraction
    });
  }, []);

  const siteName = teacherRoutes.siteName;
  let isAdmin = false;
  if (reportsData && reportsData.length) {
    if (reportsData[0].role_cd > 2) {
      isAdmin = true;
    }
  }
  return (
    <div
      className="cb-local-navigation cb-white-bg cb-box-shadow-bottom"
      data-cb-apricot="local-navigation"
      ref={localNavRef}
    >
      {/* <!-- container for desktop items-->
      <!-- in combination with GH Widget --> */}
      <div className="cb-desktop-navigation container">
        <div className="row">
          <nav
            className="col-xs cb-nav-container"
            aria-label="AP Score Reports for Educators Navigation"
          >
            <div className="cb-site-name">
              <NavLink
                className="black-a-tag hover cb-roboto-slab-bold site-name-size"
                style={{ fontWeight: 'bold' }}
                to={'/'}
              >
                {siteName}
              </NavLink>
            </div>
            {/* <!-- menu item with icon --> */}
            <div
              className="cb-nav-items hidden-xs-only nav-items-height"
              role="navigation"
            >
              <nav
                className="cb-menu-list cb-menu-list-horizontal cb-no-wrap menu-list-height"
                aria-label={siteName + ' Navigation'}
              >
                <ul>
                  {/* <!-- procedural --> */}
                  <LocalNavListItem
                    key="home"
                    name="Reports"
                    href="/"
                    isActive={
                      isCurrent('/') || location.pathname.startsWith('/report/')
                    }
                  />
                  {isAdmin ? (
                    <LocalNavListItem
                      key="requestTypeSelect"
                      name="Administrator Tools"
                      href="/AdministratorTools"
                      isActive={isCurrent('/AdministratorTools')}
                    />
                  ) : null}
                  <li className="cb-nav-link cb-procedural">
                    <a
                      className="cb-menu-link"
                      data-cbtrack
                      href={'https://apcentral.collegeboard.org/scores'}
                      id="helpLinkId"
                    >
                      <span
                        className="cb-icon cb-question hidden-xs cb-margin-right-8"
                        aria-hidden="true"
                      ></span>
                      Help
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            {/* <!-- mobile trigger --> */}
            <div className="cb-mobile-trigger hidden-sm-up">
              <a
                href="#0"
                className="cb-icon cb-menu cb-no-text-decoration cb-link-black"
                aria-expanded="false"
                tabIndex="-1"
                aria-hidden="true"
                id="siteNavLinkId"
              >
                <span className="sr-only">Display Site Navigation</span>
              </a>
            </div>
          </nav>
        </div>
      </div>
      {/* <!-- container for mobile panels--> */}
      <div className="cb-mobile-navigation"></div>
    </div>
  );
};
LocalNav.propTypes = {
  triggerExternal: PropTypes.func,
  history: PropTypes.object,
  reportsData: PropTypes.array
};

export default LocalNav;

import React from 'react';
import propTypes from 'prop-types';
import './header.css';

export const getCurrentDate = () => new Date().toLocaleDateString('default', {year: 'numeric', month: 'long', day: '2-digit'});

const ReportHeader = (props) => {

  // let curDate = getCurrentDate();

  return (
    <div id="divHeader" className="col-12 bg-header cb-padding-4">
      <div className="container-flex-h child-flex-1 container-flex-spread content-gutter padding-v-48-t padding-v-16-b">
        <div
          id="divHeaderLeft"
          className="child-flex-1 container-flex-v"
        >
          <div id="divHeaderTitle" className="child-flex-1">
            <h1 className="clear-line ffser f20 fwhite fw6 ffser text-wrap ThemeAlignTopLeft header-letter-spacing">
              {props.title} - {props.year}
            </h1>
            {props.showCustomize ? (
              <div
                role="button"
                tabIndex={0}
                id="btnChange"
                className="ffsans f14 fwhite fw5 funder 3up ffsans pseudo-link customizeLink"
                onKeyPress={ props.customizeHandler }
                onClick={ props.customizeHandler}
              >
              Customize
              </div>) : null}
          </div>
          {!props.showInterstitialStudentComponent ? (
            <div className="child-flex-2 padding-4 padding-t-6 padding-l-0">
              <h2 id="lblHeadSchool" className="clear-line ffsans f14 fw4 fwhite head-label header-letter-spacing">
                {props.school ? props.school : 'No School Selected'}
              </h2>
            </div>) : null}

          {props.showInterstitialSubjectDropdown ? (
            <div className="child-flex-3 padding-4 padding-t-6 padding-l-0">
              <h2 id="lblHeadSubject" className="clear-line ffsans f14 fw4 fwhite head-label">
                {props.subject ? props.subject : 'No Subject Selected'}
              </h2>
            </div>
          ) : null }
          {props.showInterstitialSectionDropdown ? (
            <div className="child-flex-4 padding-4 padding-t-6 padding-l-0">
              <h2 id="lblHeadSection" className="clear-line ffsans f14 fw4 fwhite head-label header-letter-spacing">
                {props.section ? props.section : 'No Section Selected'}
              </h2>
            </div>
          ) : null }
          {props.student ? (
            <div className="child-flex-5 padding-4 padding-t-6 padding-l-0">
              <h2 id="lblHeadStudent" className="clear-line ffsans f14 fw4 fwhite head-label header-letter-spacing">
                {props.student}
              </h2>
            </div>
          ) : null }
        </div>
        <span
          id="divHeaderRight"
          className="child-flex-2 container-flex-v">
          <span className="child-flex-1">
            <span className="ffsans f14 fwhite fitalic">
              {props.updateDate ? 'Data Updated: ' + props.updateDate : ''}
            </span>
          </span>
          <span className="child-flex-2">
            <span className="ffsans f14 fwhite fitalic">
            Report Run: {getCurrentDate()}
            </span>
          </span>
        </span>
      </div>
    </div>
  );
};

ReportHeader.propTypes = {
  title: propTypes.string.isRequired,
  year: propTypes.number.isRequired,
  school: propTypes.string,
  subject: propTypes.string,
  showInterstitialSubjectDropdown: propTypes.bool,
  section: propTypes.string,
  showInterstitialSectionDropdown: propTypes.bool,
  student: propTypes.string,
  updateDate: propTypes.string.isRequired,
  showCustomize: propTypes.bool,
  customizeHandler: propTypes.func,
  showInterstitialStudentComponent: propTypes.bool
};

export default ReportHeader;

import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../ap-osre-report/alert';
import { Modal } from '@cb/apricot-react';
import {AppContext} from '../../../../AppContext';

function NoDataReport(props) {
  const appContext = useContext(AppContext);
  return (<Alert>
    <>
      <p>{props.noDataReport.warningMsg} <a href='!#' data-toggle="modal" id={props.sdiMessageInfoTrigger} tabIndex="0">View possible reasons why.</a></p>
      <div className="submitModal">
        <div id="submitModal" className="modal fade cb-modal-animation" role="dialog" aria-label="..." tabIndex="-1">
          <div className="modal-dialog" role="document">
            <div className="modal-content width--size">
              <Modal trigger={props.sdiMessageInfoTrigger}>
                <div className="modal-body">
                  {
                    appContext?.content?.sdi_modal_content_data_not_available &&
                    <p dangerouslySetInnerHTML={props.createMarkup(appContext.content.sdi_modal_content_data_not_available)}></p>
                  }
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  </Alert>);
}

NoDataReport.propTypes = {
  createMarkup: PropTypes.func,
  noDataReport: PropTypes.object,
  sdiMessageInfoTrigger: PropTypes.string
};

export default NoDataReport;

import PropTypes from 'prop-types';
import React from 'react';
import FilterGroup from '../common/filter-group/filterGroup';
import LabelWithTooltip from '../common/labelWithTooltip';
import { MultiSelect } from '@cb/apricot-react';

const filterDropdownContainerClass = 'col-lg-3 col-md-4 col-sm-12';
const ScholarSummaryFilterSection = ({
  setGradeLevelFilters,
  setAwardFilters,
  removeFilter,
  gradeLevelFilters,
  awardFilters,
  gradeFilterOptions,
  awardFilterOptions
}) => {
  return (<div className="container">
    <div className="dropdown-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">Filters:</div>
    <FilterGroup
      onFilterRemove={(tagName) => {
        return removeFilter(tagName);
      }}
      appliedFilters={[...gradeLevelFilters, ...awardFilters]}
      tags
      tagProp={'label'}
    >
      <div className={filterDropdownContainerClass} style={{'verticalAlign': 'top'}}>
        <MultiSelect
          options={gradeFilterOptions}
          label="Grade Levels"
          fieldName="gradeLevel"
          selectId="gradeLevelFilter-dropdown"
          onChange={(selections) => setGradeLevelFilters(selections)}
          value={gradeLevelFilters}
          validation={false}
          selectAll={true}
        />
      </div>

      <div className={filterDropdownContainerClass} style={{'verticalAlign': 'top'}}>
        <MultiSelect
          options={awardFilterOptions}
          label="AP Scholar Awards"
          fieldName="awardFilter"
          selectId="awardFilter-dropdown"
          onChange={(selections) => setAwardFilters(selections)}
          value={awardFilters}
          validation={false}
          selectAll={true}
        />

      </div>
      <div className={filterDropdownContainerClass + ' cb-margin-top-8'} style={{'verticalAlign': 'top'}}>
        <LabelWithTooltip
          label={''}
          toolTipTitle={'Scholar Award'}
          toolTipLabel={'Scholar Award Tooltip'}
          modalContent={<div>
            Identify students who have earned a specific scholar award.{' '}
            <a target='_blank' href='https://apcentral.collegeboard.org/about-ap/awards/scholar-awards' rel="noreferrer">
              Learn more about awards here.
            </a>
          </div>}
          modalTrigger={'scholarAwardFilterModalTrigger'}
        />
      </div>
    </FilterGroup>
  </div>);
};

ScholarSummaryFilterSection.propTypes = {
  removeFilter: PropTypes.func,
  awardFilters: PropTypes.array,
  gradeLevelFilters: PropTypes.array,
  gradeFilterOptions: PropTypes.array,
  setGradeLevelFilters: PropTypes.func,
  setAwardFilters: PropTypes.func,
  awardFilterOptions: PropTypes.array
};

export default ScholarSummaryFilterSection;

import { formatCountValue, formatPercentageValue } from '../../../utils/stringUtils';

export const NO_PERCENT_DISPLAY = '- -';

export const CARD_1_ID = 'ssrStudentCountCard';
export const CARD_2_ID = 'ssrMeanScoreCard';
export const CARD_3_ID = 'ssrPassPercentageCard';

// query specifies these
export const GROUP_TYPE = 'YOUR GROUP';
export const GLOBAL_TYPE = 'GLOBAL';
export const SCHOOL_TYPE = 'SCHOOL';
export const DISTRICT_TYPE = 'DISTRICT';

// summary sections constants
export const COUNT_CARD_HEADER = 'STUDENTS TAKING EXAM';
export const MEAN_CARD_HEADER = 'MEAN SCORE';
export const PASS_CARD_HEADER = '% SCORES 3 OR HIGHER';

// genesis/query fields
export const QUERY_FIELD_TOTAL_STUDENTS = 'total_students';
export const QUERY_FIELD_PERCENT = 'perc';
export const QUERY_FIELD_MEAN = 'avg_score';
export const QUERY_FIELD_SCORE_1 = 'num_student_1';
export const QUERY_FIELD_SCORE_2 = 'num_student_2';
export const QUERY_FIELD_SCORE_3 = 'num_student_3';
export const QUERY_FIELD_SCORE_4 = 'num_student_4';
export const QUERY_FIELD_SCORE_5 = 'num_student_5';

// functions for parsing summaryData api response
export const getGroupData = summaryData => summaryData.find(i => i.card_caption === GROUP_TYPE);
export const getGlobalData = summaryData => summaryData.find(i => i.card_caption === GLOBAL_TYPE);
export const getSchoolData = summaryData => summaryData.find(i => i.card_caption === SCHOOL_TYPE);
export const getDistrictData = summaryData => summaryData.find(i => i.card_caption === DISTRICT_TYPE);
export const getStateProvinceData = summaryData => summaryData.find(i => i.card_caption !== '' && ![DISTRICT_TYPE, SCHOOL_TYPE, GROUP_TYPE, GLOBAL_TYPE].find(el => el === i.card_caption));
export const getStateProvinceAbbreviation = stateProvinceData => stateProvinceData ? stateProvinceData.card_caption : '';
export const getTotalStudentsCount = (apiObj) => apiObj ? formatCountValue(apiObj[QUERY_FIELD_TOTAL_STUDENTS], true) : '';
export const getMeanValue = (apiObj) => apiObj ? formatPercentageValue(apiObj[QUERY_FIELD_MEAN]) : '';
export const getPercentPass = apiObj => {
  if (!apiObj) {
    return '';
  }
  const percent = apiObj[QUERY_FIELD_PERCENT];
  const formatted = formatPercentageValue(apiObj[QUERY_FIELD_PERCENT] * 100, 1);
  return percent !== 0 && formatted !== '' ? `${formatted}%` : NO_PERCENT_DISPLAY;
};
